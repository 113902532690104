import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import {
  AgentWalletInfo,
  AgentWalletProvider,
  ChangeLogQuery,
  WithdrawOrderType,
} from '../../domain/report-provider/agentWalletProvider';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { Button, message, Modal, PageHeader, Row } from 'antd';
import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { useWithdrawStatus } from '../hook/orderStatus';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import HexLabel from '../../core/components/HexLabel';
import _module from '../configs/module';
import { OrderStatus } from '../domain/orderStatus';
import AgentWalletCard from '../components/AgentWalletCard';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { filter } from 'rxjs/operators';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import RefundWithdrawForm, { OrderCreatedEvent } from '../components/RefundWithdrawForm';
import { APPROVE_REFUND_WITHDRAW_ORDER, OPERATION_REFUND_RETRY_ORDER } from '../configs/scopes';
import WithdrawAgreeForm, { WithdrawAgreeEvent } from '../components/WithdrawAgreeForm';
import { TxnHashPoper } from '../components/TxnHashPoper';
import { TransferStatus } from '../domain/transferStatus';
import { AgentToken, ChannelValueEnum } from '../../domain/channel/channel';
import _ from 'lodash';

interface PageOp extends CoreProTableState<WithdrawOrderType> {
}

// eslint-disable-next-line
const TaskStatus = {
  Draft: 'Draft',
  Start: 'Start',
  Pending: 'Pending',
  Success: 'Success',
  TransferFail: 'TransferFail',
  TransactionFail: 'TransactionFail',
};

class OrderChangedEvent extends BaseEvent<WithdrawOrderType> {
  static symbol = Symbol();

  constructor(payload: WithdrawOrderType) {
    super({
      payload: payload,
      eventSymbol: OrderChangedEvent.symbol,
      type: OrderChangedEvent.name,
    });
  }
}

export default function RefundWithdrawOrder(props: { title: string }) {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [withdrawFrom, setWithdrawFrom] = useState(false);
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.orderCreatedAt'),
    pageOp,
    state: dateRangeState,
  });
  const statusEnum = useWithdrawStatus();
  const [wallet, setWallet] = useState<AgentWalletInfo[]>([]);
  const [selectToken, setSelectToken] = useState<AgentWalletInfo>({
    token: '',
    systemId: '',
    available: true,
    balance: 0,
  });
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const [agreeForm, setAgreeForm] = useState<{
    order?: WithdrawOrderType;
    visible: boolean;
  }>({
    order: undefined,
    visible: false,
  });
  const refreshPage = () => {
    pageOp.update((x) => {
      x.needRequest = true;
      return x;
    });
    actionRef.current?.reload();
  };

  const isMerchantUser = () => {
    return !!user.systemId;
  };

  const refreshWallet = () => {
    if (isMerchantUser())
      AgentWalletProvider.getCurrentBalance({
        systemId: user.systemId,
      })
        .then((rs) => setWallet(rs))
        .catch((e) => {
          console.error('refreshWallet', e);
        });
  };

  const canRetry = (order: WithdrawOrderType) => {
    return (
      order.transferStatus === TransferStatus.WithdrawTransferStatus.Fail
    );
  };


  const canAgree = (order: WithdrawOrderType) => {
    return order.status === OrderStatus.WithdrawOrderStatus.Reviewing;
  };

  const canReject = (order: WithdrawOrderType) => {
    return (
      order.status === OrderStatus.WithdrawOrderStatus.Reviewing
    );
  };

  const agree = async (order: WithdrawOrderType) => {
    setAgreeForm({
      visible: true,
      order: order,
    });
  };
  const reject = async (order: WithdrawOrderType) => {
    Modal.confirm({
      title: t('funding.withdraw_action.reject'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      content: t('funding.withdraw_action_confirm.reject'),
      centered: true,
      onOk: async () => {
        const res = await AgentWalletProvider.rejectWithdraw({
          operatorId: user.userId,
          orderId: order.orderId,
        });
        EventProvider.addEvents(new OrderChangedEvent(res.data));
        message.success(t('common.success'), 2);
      },
    });
  };

  const retry = async (order: WithdrawOrderType) => {
    Modal.confirm({
      title: t('funding.withdraw_action.retry'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      content: t('funding.withdraw_action_confirm.retry'),
      centered: true,
      onOk: async () => {
        const res = await AgentWalletProvider.retryWithdraw({
          operatorId: user.userId,
          orderId: order.orderId,
        });
        EventProvider.addEvents(new OrderChangedEvent(res.data));
        message.success(t('common.success'), 2);
      },
    });
  }

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    refreshWallet();
    EventProvider.subject
      .pipe(
        filter(
          (x) =>
            x.eventSymbol === OrderChangedEvent.symbol ||
            x.eventSymbol === OrderCreatedEvent.symbol,
        ),
      )
      .subscribe((event) => {
        refreshWallet();
      });
    EventProvider.subject
      .pipe(
        filter(
          (x) =>
            x.eventSymbol === OrderChangedEvent.symbol ||
            x.eventSymbol === OrderCreatedEvent.symbol ||
            // x.eventSymbol === WithdrawSucceedEvent.symbol ||
            x.eventSymbol === WithdrawAgreeEvent.symbol,
        ),
      )
      .subscribe((event) => {
        refreshPage();
      });
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === WithdrawAgreeEvent.symbol))
      .subscribe((event) => {
        setAgreeForm({ visible: false, order: undefined });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader title={props.title} subTitle='' extra={<></>} >
        <Row>
          {isMerchantUser() &&
          AgentToken.map((it) => {
            {
              const index = _.findIndex(wallet, (x) => x.token === it.label);
              if(index!=-1)
              {
                return <AgentWalletCard token={it.label} balance={wallet[index].balance} />;
              }
            }
          })
          }
        </Row>
      </PageHeader>
      <CoreProTable<WithdrawOrderType>
        bordered
        rowKey='txnId'
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        tableState={pageOp.get()}
        // toolBarRender={() => [
        //   <Button
        //     key={'withdraw_buttib'}
        //     type='primary'
        //     disabled={!_module.can([COMMIT_WITHDRAW_ORDER])}
        //     onClick={() => setWithdrawFrom(true)}
        //   >
        //     {t('funding.withdraw_action.withdraw')}
        //   </Button>
        // ]}
        requestData={async (params) => {
          const param: PaginationQuery &
            ChangeLogQuery = PaginationTransformer.paramTransfer({
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
          });
          const res = await AgentWalletProvider.getWithdrawOrderList(param);
          return PaginationTransformer.responseTransfer(res);
        }}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          dateRangeColumn.column,
          {
            align: 'center',
            hideInSearch: isMerchantUser(),
            hideInTable: isMerchantUser(),
            title: '代理商戶',
            dataIndex: 'systemId',
            width: 70,
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: !isMerchantUser(),
            width: 70,
            title: t('common.orderCreatedAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            ellipsis: true,
            title: t('payment.order_no'),
            dataIndex: 'orderNo',
            width: 70,
          },
          {
            hideInSearch: isMerchantUser(),
            hideInTable: isMerchantUser(),
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            hideInTable: false,
            hideInSearch: true,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            width: 70,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.withdraw_amount'),
            dataIndex: 'orderAmount',
            width: 70,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('comment.network_fee'),
            dataIndex: 'fee',
            width: 70,
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            title: t('payment.order_status'),
            dataIndex: 'status',
            valueEnum: statusEnum,
            width: 90,
            render: (node, row) =>
              isMerchantUser() ? (
                node
              ) : (
                <TxnHashPoper
                  showText={node}
                  channel={row.channel}
                  orderId={row.orderId}
                />
              ),
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.wallet_address'),
            dataIndex: 'toAddress',
            render: (n, row) => <HexLabel hex={row.toAddress} size={'small'} />,
            width: 80,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: isMerchantUser(),
            width: 70,
            title: t('common.orderCreatedAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            align: 'center',
            hideInSearch: !isMerchantUser(),
            hideInTable: !isMerchantUser(),
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
            width: 80,
          },
          {
            key: '_action',
            fixed: 'right',
            align: 'center',
            valueType: 'option',
            hideInTable: isMerchantUser(),
            title: t('common.operate'),
            width: 100,
            render: (node, row) => [
              canAgree(row) && (
                <Button
                  disabled={!_module.can([APPROVE_REFUND_WITHDRAW_ORDER])}
                  key={1}
                  size={'small'}
                  onClick={() => agree(row)}
                >
                  {t('funding.withdraw_action.agree')}
                </Button>
              ),
              canReject(row) && (
                <Button
                  disabled={!_module.can([APPROVE_REFUND_WITHDRAW_ORDER])}
                  key={2}
                  size={'small'}
                  onClick={() => reject(row)}
                >
                  {t('funding.withdraw_action.reject')}
                </Button>
              ),
              canRetry(row) && (
                <Button
                  disabled={!_module.can([OPERATION_REFUND_RETRY_ORDER])}
                  key={3}
                  size={'small'}
                  onClick={() => retry(row)}
                >
                  {t('funding.withdraw_action.retry')}
                </Button>
              ),
            ],
          },
        ]}
      />
      <WithdrawAgreeForm
        visible={agreeForm.visible}
        order={agreeForm.order!}
      />
      <RefundWithdrawForm
        visible={withdrawFrom}
        onClose={() => {
          setWithdrawFrom(false);
        }}
      />
    </>
  );
}
