import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Switch } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { UserWallet, UserWalletProvider } from '../../domain/usdt-providers/userWalletProvider';
import { ProColumns } from '@ant-design/pro-table';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import CreateWalletForm from '../../domain/components/setttings/CreateWalletForm';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ChannelValueEnum, MchToken } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';
import _module from '../configs/module';
import { ADD_POLLING_WALLET } from '../configs/scopes';

interface PageOp extends CoreProTableState<UserWallet> {
}

// eslint-disable-next-line
export default function UserWalletList() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const getAvailable = (available: any) => {
    if (available) {
      return <Switch checked={true} disabled={true} />;
    } else {
      return <Switch checked={false} disabled={true} />;
    }
  };
  const [createWalletVisible, setCreateWalletVisible] = useState(false);
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);
  const CollectWalletCommonColumn: ProColumns<UserWallet>[] = [
    {
      hideInSearch: false,
      hideInTable: false,
      width: 100,
      align: 'center',
      title: t('payment.wallet_id'),
      fixed: 'left',
      dataIndex: 'userId',
    },
    {
      align: 'center',
      title: t('payment.channel'),
      dataIndex: 'channel',
      valueType: 'select',
      valueEnum: ChannelValueEnum,
      onFilter: true,
      width: 70,
    },
    {
      align: 'center',
      title: t('payment.token'),
      dataIndex: 'token',
      valueType: 'select',
      valueEnum: MchToken,
      onFilter: true,
      width: 70,
    },
    {
      hideInSearch: false,
      hideInTable: false,
      width: 100,
      align: 'center',
      title: t('payment.wallet_address'),
      dataIndex: 'walletAddress',
      render: (n, row) => <HexLabel hex={row.walletAddress} size={'small'} />,
    },
    {
      hideInSearch: true,
      hideInTable: false,
      width: 100,
      align: 'center',
      title: t('payment.available'),
      dataIndex: 'available',
      render: (row) => getAvailable(row),
    },
    {
      hideInSearch: true,
      hideInTable: true,
      width: 100,
      align: 'center',
      title: t('payment.txnBalance'),
      dataIndex: 'txnBalance',
    },
    {
      hideInSearch: true,
      hideInTable: false,
      width: 100,
      align: 'center',
      title: t('payment.availableBalance'),
      dataIndex: 'availableBalance',
    },
    {
      hideInSearch: true,
      hideInTable: false,
      width: 100,
      align: 'center',
      title: t('common.createdAt'),
      dataIndex: 'createdAt',
      render: (text: any, row) => transfer2LocalTime(new Date(row.createdAt)),
    },
  ];

  const disableUserWallet = (row: UserWallet) => {
    Modal.confirm({
      title: t('credit.confirm_disable_polling_wallet_message'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      centered: true,
      onOk: () => {
        return UserWalletProvider.disableWallet({
          walletAddress: row.walletAddress,
          channel: row.channel,
          token: row.token,
        }).then((res) => {
          pageOp.update((v) => ({ ...v, needRequest: true }));
          actionRef.current?.reload();
        });
      },
    });
  };
  const enableUserWallet = (row: UserWallet) => {
    Modal.confirm({
      title: t('credit.confirm_enable_polling_wallet_message'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      centered: true,
      onOk: () => {
        return UserWalletProvider.enableWallet({
          walletAddress: row.walletAddress,
          channel: row.channel,
          token: row.token,
        }).then((res) => {
          pageOp.update((v) => ({ ...v, needRequest: true }));
          actionRef.current?.reload();
        });
      },
    });
  };
  return (
    <>
      <PageHeader
        title={t('payment.polling_wallet')}
        subTitle=''
        extra={<></>}
      />
      <CoreProTable<UserWallet>
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        scroll={{ x: 'fixed' }}
        search={{ layout: 'vertical' }}
        actionRef={actionRef as any}
        toolBarRender={() => [
          _module.canRender(
            ADD_POLLING_WALLET,
          <Button
            key={1}
            type='primary'
            onClick={() => setCreateWalletVisible(true)}
          >
            <PlusOutlined />
            {t('payment.create_wallet')}
          </Button>,
            )
        ]}
        rowKey='walletAddress'
        requestData={(params: any, sort, filter) => {
          if (params.status === 'ALL') {
            delete params.status;
          }
          return UserWalletProvider.mchGetUserWalletList({
            page: params.current,
            limit: params.pageSize,
            uid: params.userId,
            walletAddress: params.walletAddress,
            channel: params.channel,
            token: params.token,
          }).then((res) => {
            return PaginationTransformer.responseTransfer(res);
          });
        }}
        columns={[
          ...CollectWalletCommonColumn,
          {
            title: '操作',
            key: '_action',
            align: 'center',
            dataIndex: 'id',
            valueType: 'option',
            width: 100,
            render: (text, row) =>
              !row.collectStatus || row.collectStatus === 'UnCollect'
                ? [
                  <Button
                    key={1}
                    type={'primary'}
                    size={'small'}
                    disabled={!_module.can([ADD_POLLING_WALLET])}
                    danger={row.available && _module.can([ADD_POLLING_WALLET])}
                    onClick={() => {
                      if (row.available) {
                        disableUserWallet(row);
                      } else {
                        enableUserWallet(row);
                      }
                    }}
                  >
                    {row.available ? t('common.disable') : t('common.enable')}
                  </Button>,
                ]
                : null,
          },
        ]}
      />
      <CreateWalletForm
        visible={createWalletVisible}
        onClosed={() => {
          setCreateWalletVisible(false);
          pageOp.update((v) => ({ ...v, needRequest: true }));
          actionRef.current?.reload();
        }}
      />
    </>
  );
}
