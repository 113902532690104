import React, { useEffect, useRef } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { Button, Modal, PageHeader } from 'antd';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { Log, LogProvider, UserLogInfo } from '../../domain/admin-providers/logProvider';
import ApiLogDetail from '../../domain/components/setttings/ApiLogDetail';
import { useMenuIdTranslate } from '../../core/hooks/menuIdTranslateHook';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import ActionSearch from '../components/ActionSearch';

interface PageOp extends CoreProTableState<UserLogInfo> {
}

export default function ApiLogPage() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const menuTraslateEnum = useMenuIdTranslate();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const loggger = useOperatorLogger();

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageHeader
        title={t('api_log.api_log_label')}
        subTitle={''}
        extra={<></>}
      />

      <CoreProTable<UserLogInfo>
        bordered
        rowKey={(row) => `${row.id}`}
        updateTableState={(state) => {
          pageOp.update((v: any) => ({ ...v, ...state }));
          searchFormRef.current?.setFieldsValue(pageOp.get().params);
        }}
        onReset={() => {
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        requestData={async (params) => {
          const res = await LogProvider.getList(
            PaginationTransformer.paramTransfer(params),
          );
          loggger.log({
            action: '查詢',
            payload: { req: params },
          });
          return PaginationTransformer.responseTransfer(res);
        }}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            align: 'center',
            hideInTable: true,
            hideInSearch: true,
            title: 'userId',
            dataIndex: 'userId',
            width: 70,
          },
          {
            align: 'center',
            title: t('api_log.operator'),
            dataIndex: 'username',
            render: (node, row) => row.user?.username,
            width: 70,
          },
          {
            align: 'center',
            title: t('api_log.function'),
            dataIndex: 'function',
            width: 70,
            valueEnum: menuTraslateEnum,
            renderFormItem: (_) => (
              <ActionSearch
                funcId={pageOp.get().params.function}
                onChange={(functionId) => {
                  searchFormRef.current?.setFieldsValue({
                    [_.dataIndex as string]: functionId,
                  });
                }}
              />
            ),
          },
          {
            align: 'center',
            title: t('api_log.action'),
            dataIndex: 'action',
            width: 70,
          },
          {
            hideInSearch: true,
            width: 70,
            align: 'center',
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            title: '',
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 80,
            render: (text: any, row: Log) => (
              <Button
                size={'small'}
                key='editable'
                onClick={async (e) => {
                  e.preventDefault();
                  const res = await LogProvider.getPayload(
                    row.id,
                  );
                  loggger.log({
                    action: '查看',
                    payload: { ...row, payload: res.data.data },
                  });
                  Modal.info({
                    title: '',
                    width: '640px',
                    content: (
                      <ApiLogDetail
                        title={t('api_log.log_list_title')}
                        payload={res.data.data}
                        log={row}
                      />
                    ),
                  });
                }}
              >
                {t('common.view')}
              </Button>
            ),
          },
        ]}
      />
    </>
  );
}
