import React, { useEffect, useRef, useState } from 'react';
import ProTable, { ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, Modal, PageHeader } from 'antd';
import * as _ from 'lodash';
import { omit } from 'lodash';
import { UsdtUri } from '../../core/configs/services';
import { FormInstance } from 'antd/lib/form';
import { useDispatch, useStore } from 'react-redux';
import { ProCoreActionType } from '@ant-design/pro-utils';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { AdminListResponse } from '../../domain/models/adminListResponse';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { refreshSystemListState } from '../../../stores/system-list/actions';
import adminRequest from '../../../config/adminRequest';
import { SystemSettingActionType } from '../components/SystemSettingForm';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { WRITE_SYSTEM } from '../configs/scopes';
import _module from '../configs/module';
import CollectSettingProvider, {
  CollectSetting,
  TokenRowType,
} from '../../domain/usdt-providers/collectSettingProvider';
import CollectSettingForm from '../components/CollectSettingForm';
import HexLabel from '../../core/components/HexLabel';
import { TypeTransferMode } from '../../domain/usdt-providers/systemProvider';
import { useTranslation } from 'react-i18next';

interface FormState {
  systemId?: string;
  visible: boolean;
  type: SystemSettingActionType;
}

interface PageOp extends CoreProTableState<CollectSetting> {
  formType?: SystemSettingActionType;
  formPayload?: any;
  expendedRows?: { [key: string]: CoreProTableState<CollectSetting> };
}

// eslint-disable-next-line
export default () => {
  const dispatch = useDispatch();
  const systemListStore = useStore().getState().systemList;
  useEffect(() => {
    dispatch(refreshSystemListState());
  }, [systemListStore, dispatch]);
  const { t } = useTranslation();
  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const logger = useOperatorLogger();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [formState, setFormState] = useState<FormState>({
    systemId: undefined,
    visible: false,
    type: SystemSettingActionType.AddNormal,
  });
  const expandColumns: ProColumns<TokenRowType>[] = [
    {
      title: '渠道',
      dataIndex: 'channel',
      align: 'center',
      width: 200,
    },
    {
      title: '代幣',
      dataIndex: 'token',
      align: 'center',
      width: 100,
    },
    {
      title: '礦工費模式',
      dataIndex: 'transferInfo',
      align: 'center',
      width: 100,
    },
    {
      title: '最小歸集金額',
      dataIndex: 'minimumAmount',
      align: 'center',
      width: 100,
    },
    {
      title: '歸集地址',
      dataIndex: 'collectDest',
      align: 'center',
      width: 100,
      render: (node, row) => <HexLabel hex={row.collectDest} size={'small'} />,
    },
  ];
  const columns: ProColumns<CollectSetting>[] = [
    {
      title: '商戶代號',
      dataIndex: 'systemId',
      align: 'center',
      width: 200,
      renderFormItem: (_, row, form) => (
        <SystemIdSearchList
          onChange={(value) =>
            form.setFieldsValue({ [_.dataIndex as string]: value })
          }
          systemId={pageOp.get().params.systemId}
        />
      ),
      fieldProps: {
        showSearch: true,
      },
    },
    {
      title: '操作',
      key: '_action',
      align: 'center',
      width: 200,
      valueType: 'option',
      render: (text, row) => [
        <Button
          key={2}
          size="small"
          type="primary"
          disabled={!_module.can([WRITE_SYSTEM])}
          onClick={() => openSystemForm(SystemSettingActionType.Edit, row)}
        >
          修改
        </Button>,
      ],
    },
  ];

  const openSystemForm = (
    type: SystemSettingActionType,
    payload?: CollectSetting,
  ) => {
    pageOp.update((prevState) => ({
      ...prevState,
      formType: type,
      formPayload: payload,
    }));
    if (
      type === SystemSettingActionType.Edit ||
      SystemSettingActionType.Extend
    ) {
      setFormState((s) => ({
        ...s,
        type,
        systemId: payload?.systemId,
        visible: true,
      }));
    } else {
      setFormState((s) => ({
        ...s,
        type,
        visible: true,
      }));
    }
  };
  const closeSystemForm = () => {
    pageOp.update((prevState) => ({
      ...prevState,
      needRequest: true,
      formType: undefined,
      formPayload: undefined,
    }));
    setFormState((s) => ({ ...s, visible: false, systemId: undefined }));
    if (tableActionRef.current) tableActionRef.current.reload();
  };

  const expandedRow = (row: CollectSetting) => {
    return (
      <ProTable<TokenRowType>
        rowKey={(r) => `${r.channel}-${r.token}`}
        columns={expandColumns}
        request={async (params) => {
          const data = await settingReq(row.systemId);
          const rs: RequestData<TokenRowType> = {
            data: data,
            success: true,
          };
          return rs;
        }}
        search={false}
        options={false}
        pagination={false}
        style={{
          border: '1px dotted #bbbbbb',
          boxShadow: '3px 3px #e6e6e6',
        }}
      />
    );
  };
  const settingReq = async (systemId: string) => {
    const settingData = await CollectSettingProvider.getSetting(systemId);
    const tokenRow: TokenRowType[] = [];
    _.map(settingData.tokenSetting, (channelObj, channel) => {
      _.map(channelObj, (tokenObj, token) => {
        if (tokenObj.active === true) {
          tokenRow.push({
            channel: channel,
            token: token,
            transferInfo: t(settingData.networkFeeSetting[channel][token].transferMode) + (settingData.networkFeeSetting[channel][token].transferMode==TypeTransferMode.Static ? '：' + settingData.networkFeeSetting[channel][token].staticFee : ''),
            minimumAmount: tokenObj.minimumAmount,
            collectDest: tokenObj.collectDest,
          });
        }
      });
    });
    return tokenRow;
  };
  const req = (params: any, sort: any, filter: any) => {
    return adminRequest
      .get<AdminListResponse<CollectSetting>>(
        UsdtUri(`admin/collect/setting/all/pagination`),
        {
          params: {
            page: params.current,
            limit: params.pageSize,
            ...omit(params, ['current', 'pageSize']),
          },
        },
      )
      .then((rs) => {
        logger.log({
          payload: { req: params },
          systemId: '',
          action: '查詢',
        });
        return PaginationTransformer.responseTransfer(rs.data);
      })
      .catch((e) => {
        Modal.error({ content: '系統錯誤' });
        logger.log({
          payload: { req: params, res: e },
          systemId: '',
          action: '查詢',
        });
        return { data: [], success: false };
      });
  };

  useEffect(() => {
    const state = pageOp.get();
    if (typeof state.formType !== 'undefined') {
      openSystemForm(state.formType, state.formPayload);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (searchFormRef.current)
      searchFormRef.current.setFieldsValue(pageOp.get().params);
    // eslint-disable-next-line
  }, [searchFormRef, pageOp.get()]);

  return (
    <>
      <PageHeader title="歸集設定" extra={<></>} />
      <CoreProTable<CollectSetting>
        bordered
        actionRef={tableActionRef as any}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        columns={columns}
        expandable={{
          expandedRowRender: expandedRow,
          rowExpandable: (row) => true,
        }}
        rowKey="systemId"
        requestData={(params, sort, filter) => {
          return req(
            {
              ...params,
            },
            sort,
            filter,
          );
        }}
      />
      <CollectSettingForm
        type={formState.type}
        visible={formState.visible}
        closeForm={closeSystemForm}
        systemId={formState.systemId ?? ''}
      />
    </>
  );
};
