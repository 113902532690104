import { Card, Form, Input, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { ProFormSwitch } from '@ant-design/pro-form';

enum actionType {
  Create,
  Edit,
}

const DepositModeUrlFrom = (props: {
  depositMode: number;
  actionType: actionType;
}) => {
  const [depositMode, setDepositMode] = useState<number>(
    props.depositMode ?? 1,
  );
  useEffect(() => {
    setDepositMode(props.depositMode);
  }, [props.depositMode]);
  return (
    <>
      <Card title='充值模式'>
        <Form.Item
          label='充值模式'
        >
          <Radio.Group
            defaultValue = {props.depositMode}
            value={props.depositMode}
            name='depositMode'
            buttonStyle='solid'
          >
            <Radio.Button disabled={depositMode !== 1} value={1}>
              自動上分模式
            </Radio.Button>
            <Radio.Button disabled={depositMode !== 2} value={2}>
              訂單模式
            </Radio.Button>
            <Radio.Button disabled={depositMode !== 3} value={3}>
              輪詢錢包模式
            </Radio.Button>
            <Radio.Button disabled={depositMode !== 4} value={4}>
              收付一體
            </Radio.Button>
            <Radio.Button disabled={depositMode !== 5} value={5}>
              輪詢自動歸集模式
            </Radio.Button>
          </Radio.Group>
          <br />
          <br />
          {props.depositMode === 1 ? (
            <>
              <Form.Item
                name='notifySuccessUrl'
                label='充值通知地址'
                rules={[{ required: true, message: '請輸入 `充值通知地址`' }]}
              >
                <Input />
              </Form.Item>
            </>
          ) : props.depositMode === 2 ? (
            <>
              <Form.Item name='notifySuccessUrl' label='訂單符合通知地址'
                         rules={[{ required: true, message: '請輸入 `訂單符合通知地址`' }]}>
                <Input max={255} />
              </Form.Item>
              <Form.Item
                name='notifyFailUrl'
                label='訂單不符合通知地址' rules={[{ required: true, message: '請輸入 `訂單不符合通知地址`' }]}
              >
                <Input max={255} />
              </Form.Item>
            </>
          ) : props.depositMode === 3 ? (
            <>
              <Form.Item name='notifySuccessUrl' label='訂單符合通知地址'
                         rules={[{ required: true, message: '請輸入 `訂單符合通知地址`' }]}>
                <Input max={255} />
              </Form.Item>
              <ProFormSwitch
                name='changeOrderEnable'
                label='調帳功能開啟'
                // disabled={props.changeOrderEnable}
              />
            </>
          ) : props.depositMode === 4 ? (
            <>
              <Form.Item name='notifySuccessUrl' label='訂單符合通知地址'
                         rules={[{ required: true, message: '請輸入 `訂單符合通知地址`' }]}>
                <Input max={255} />
              </Form.Item>
            </>
          ) : props.depositMode === 5 ? (
            <>
              <Form.Item name='notifySuccessUrl' label='訂單符合通知地址'
                         rules={[{ required: true, message: '請輸入 `訂單符合通知地址`' }]}>
                <Input max={255} />
              </Form.Item>
            </>
          ): null}
        </Form.Item>
      </Card>
    </>
  );
};

export default DepositModeUrlFrom;
