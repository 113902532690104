import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../../core/hooks/usePageState';
import { PageHeader } from 'antd';
import CoreProTable, { CoreProTableState } from '../../../core/components/CoreProTable';
import WalletProvider, { MchWalletChangeLog } from '../../usdt-providers/walletProvider';
import SystemIdSearchList from '../system-search-list/SystemIdSearchList';
import { useTranslation } from 'react-i18next';
import { useTradeCodeTranslate } from '../../trade-code/tradeCodeHook';
import { ChannelValueEnum, Token } from '../../channel/channel';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../../stores/merge';
import { UserStore } from '../../../../stores/user';
import { useOperatorLogger } from '../../../core/hooks/useOperatorLogger';
import Text from 'antd/es/typography/Text';
import ChannelTokenCascader from '../setttings/ChannelTokenCascader';

interface PageOp extends CoreProTableState<MchWalletChangeLog> {}

export default function UserWalletChangeLogTable() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const tradeCodeTranslate = useTradeCodeTranslate();
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);

  const [tokenData, setTokenData] = useState<{channel?:string,token?:string}>({ channel: undefined, token: undefined });
  const pageTokenOp = usePageState('SELECT_TOKEN', {
    channel: tokenData.channel,
    token: tokenData.token,
  });

  const pageOp = usePageState<PageOp>('MchWalletChangeLogTable', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const loggger = useOperatorLogger();

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  function onCascaderChange(value: { channel?: string; token?: string }) {
    setTokenData({ channel: value.channel??undefined, token: value.token??undefined });
    pageTokenOp.update({ channel: value.channel, token: value.token });
  }

  return (
    <>
      <PageHeader
        title={t('payment.mch_user_wallet_change_log')}
        subTitle=""
        extra={<></>}
      >
        <Text>{t('payment.channel') + '/' + t('payment.token')}</Text>
        <br />
        <ChannelTokenCascader
          onChange={onCascaderChange}
          option={{
            channel: pageTokenOp.get().channel,
            token: pageTokenOp.get().token,
          }}
        />
      </PageHeader>
      <CoreProTable
        bordered
        rowKey="txnId"
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        tableState={pageOp.get()}
        requestData={(params) => {
          const param = {
            ...params,
            systemId: user.systemId,
            channel: tokenData.channel,
            token: tokenData.token,
          };
          const res = WalletProvider.getUserWalletTxnPagination(param, {
            systemId: user.systemId,
          }).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: param },
              systemId: param.systemId,
            });
            return rs;
          });
          return res;
        }}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            fixed: 'left',
            title: t('change_log.txn_id'),
            dataIndex: 'txnId',
            width: 60,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: true,
            title: '商戶代號',
            dataIndex: 'systemId',
            width: 60,
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.merchant_user_id'),
            dataIndex: 'merchantUserId',
            ellipsis: true,
            width: 100,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.trade_genre'),
            dataIndex: 'tradeCode',
            valueEnum: tradeCodeTranslate,
            width: 70,
          },
          {
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            hideInSearch: true,
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 60,
          },
          {
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            valueType: 'select',
            valueEnum: Token,
            onFilter: true,
            hideInSearch: true,
            width: 60,
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            title: t('payment.blockchain_hash'),
            dataIndex: 'txnHash',
            ellipsis: true,
            width: 120,
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            ellipsis: true,
            title: t('payment.wallet_address'),
            dataIndex: 'walletAddress',
            width: 120,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.available_balance_change'),
            dataIndex: 'availableAmount',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: true,
            title: t('change_log.txn_balance_change'),
            dataIndex: 'txnAmount',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.available_before_balance'),
            dataIndex: 'availableBeforeBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.available_after_balance'),
            dataIndex: 'availableAfterBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: true,
            title: t('change_log.txn_before_balance'),
            dataIndex: 'txnBeforeBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: true,
            title: t('change_log.txn_after_balance'),
            dataIndex: 'txnAfterBalance',
            width: 100,
          },
          {
            align: 'left',
            hideInSearch: true,
            hideInTable: false,
            title: t('common.comment'),
            // render: (text) => commentTranslate.parse(text as string),
            dataIndex: 'comment',
            width: 250,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              new Date(row.createdAt).toLocaleString(),
          },
        ]}
      />
    </>
  );
}
