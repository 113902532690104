import { AdminUri } from '../../core/configs/services';
import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';
import { Group } from './mchGroupProvider';
import { omit } from 'lodash';


export interface UserBase {
  id?: string;
  username: string;
  password: string;
  displayName: string;
  email: string;
  firstTimeLogin: boolean;
}

export interface User extends UserBase {
  key: string;
  id: string;
  username: string;
  password: string;
  displayName: string;
  email: string;
  groupId: number;
  group: Group;
  groupDisplayName: string;
  systemId: string;
}

export class AdminUserProvider {

  static async getUser(id: string) {
    const res = await adminRequest.get<AdminResponse<User>>(
      AdminUri(`admin/users/${id}`),
    );
    return res.data.data;
  }

  static async update(user: Partial<User>) {
    const res = await adminRequest.patch<AdminResponse<User>>(
      AdminUri(`admin/users/${user.id}`),
      user,
    );
    return res.data.data;
  }

  static async add(user: Partial<User>) {
    const res = await adminRequest.post<AdminResponse<User>>(
      AdminUri(`admin/users`),
      user,
    );
    return res.data.data;
  }

  static async delete(id: string) {
    const res = await adminRequest.delete<AdminResponse<any>>(
      AdminUri(`admin/users/${id}`),
    );
    return res.data.data;
  }
  static async getList(params: any) {
    const res = await adminRequest
      .get<AdminListResponse<User>>(AdminUri('admin/users'), {
        params: {
          ...omit(params, ['current', 'pageSize']),
          page: params.current,
          limit: params.pageSize,
        },
      });
    return res.data
  }
}
