import { Form, Input, Modal } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Subject } from 'rxjs';
import { UserWalletProvider } from '../../usdt-providers/userWalletProvider';
import { filter } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import ChannelTokenCascader from './ChannelTokenCascader';

enum ActionType {
  Created,
  Close,
}

type TokenId = { channel: string, token: string }

export default function CreateWalletForm(props: {
  visible: boolean;
  onClosed?: () => any;
}) {
  const [eventState] = useState({ action: new Subject<ActionType>() });
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [channelToken, setChannelToken] = useState<{channel?:string,token?:string}>({ channel: undefined, token: undefined });
  const { t } = useTranslation();

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    eventState.action
      .pipe(filter((x) => x === ActionType.Close))
      .subscribe((x) => {
        setVisible(false);
        setLoading(false);
        if (props.onClosed) props.onClosed();
      });
    // eslint-disable-next-line
  }, [eventState]);

  function onCascaderChange(value: { channel?: string, token?: string }) {
    setChannelToken({ channel: value.channel??'', token: value.token??'' });
  }

  const from = React.createRef<FormInstance>();
  return (
    <>
      <Modal
        destroyOnClose
        title={t('payment.create_wallet')}
        visible={visible}
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
        confirmLoading={loading}
        onOk={() => {
          from.current?.validateFields().then((data) => {
            setLoading(true);
            UserWalletProvider.mchIssueWallet({ ...data, channel: channelToken.channel, token: channelToken.token })
              .then((res) => {
                Modal.success({
                  title: t('common.update_success'),
                  content: (
                    <ProDescriptions column={1}>
                      <ProDescriptions.Item label={t('payment.wallet_address')}>
                        {res.data.walletAddress}
                      </ProDescriptions.Item>
                    </ProDescriptions>
                  ),
                  onOk: () => {
                    eventState.action.next(ActionType.Close);
                  },
                });
              })
              .finally(() => setLoading(false));
          });
        }}
        onCancel={() => eventState.action.next(ActionType.Close)}
      >
        <Form ref={from}>
          <Form.Item
            name='uid'
            label={t('payment.wallet_id')}
            rules={[{ required: true, message: '此欄位為必填' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'channel'}
            label={t('payment.channel') + '/' + t('payment.token')}
            rules={[{ required: true, message: '此欄位為必填' }]}
          >
            <ChannelTokenCascader onChange={onCascaderChange} option={{ channel: '', token: '' }} />
            {/*<Select options={ChannelSelect} />*/}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
