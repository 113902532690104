import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';

export type GlobalSetting = {
  CUSTOM_EXCHANGE_RATE: boolean;
  CNY_TO_USDT_SELL_PRICE: number;
  CNY_TO_USDT_BUY_PRICE: number;
  ETH_TO_USDT_RATE?: number;
  TRANSFER_THRESHOLD_RATE: number;
  TRX_TO_USDT_RATE?: number;
  SOL_TO_USDT_RATE?: number;
  AGENTFUNDING_ETH_FEE?: number;
  AGENTFUNDING_TRX_FEE?: number;
  AGENTFUNDING_SOL_FEE?: number;
  AGENTFUNDING_BNB_FEE?: number;
};

export class GlobalSettingProvider {
  static async get() {
    const res = await adminRequest.get<AdminResponse<GlobalSetting>>(
      UsdtUri(`/admin/global-setting`),
    );
    return res.data;
  }

  static async put(data: GlobalSetting) {
    const res = await adminRequest.put<AdminResponse<GlobalSetting>>(
      UsdtUri(`/admin/global-setting`),
      data,
    );
    return res.data;
  }
}
