import React, { useState } from 'react';
import ProTable, { ProTableProps, RequestData } from '@ant-design/pro-table';
import { SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import _ from 'lodash';

export interface CoreProTableState<T> {
  params: Partial<T>;
  collapsed: boolean;
  pagination: false | TablePaginationConfig;
  dataSource?: T[];
  needRequest: boolean;
  expandableKeys?: Array<string | number>;
}

export default function CoreProTable<
  T extends { [key in string]: any },
  U extends Partial<T> & {
    [key: string]: any;
  } = {}
>(
  props: ProTableProps<T, U> & {
    updateTableState: (state: Partial<CoreProTableState<T>>) => void;
    tableState: CoreProTableState<T>;
    requestData?: (
      params: any,
      sort: any,
      filter: any,
    ) => Promise<RequestData<T>>;
  },
) {
  const [loading, setLoading] = useState(false);
  if (!props.tableState || !props.updateTableState) {
    return <></>;
  }
  const originRequest = typeof props.requestData === 'undefined';
  const needRequest =
    typeof props.tableState.dataSource === 'undefined' ||
    props.tableState.needRequest;
  return (
    <ProTable<T, U>
      {...props}
      loading={loading}
      expandable={{
        ...(props.expandable ?? {}),
        expandedRowKeys: props.tableState.expandableKeys,
        onExpandedRowsChange: (expandedKeys) => {
          props.updateTableState({
            expandableKeys: _.cloneDeep(expandedKeys as any),
          });
        },
      }}
      options={
        props.options === false
          ? false
          : {
              ...(props.options ?? {}),
              density: false,
              fullScreen: false,
              setting: false,
              reload: () => {
                props.updateTableState({
                  needRequest: true,
                });
              },
            }
      }
      pagination={
        props.pagination === false
          ? false
          : {
              ...(props.pagination ?? {}),
              ...props.tableState.pagination,
              onChange:
                (props.pagination as TablePaginationConfig)?.onChange ??
                ((page: number, pageSize?: number) => {
                  props.updateTableState({
                    pagination: {
                      ...props.tableState.pagination,
                      pageSize: pageSize,
                      current: page,
                    },
                    needRequest: true,
                  });
                }),
            }
      }
      search={
        props.search === false
          ? false
          : {
              ...(props.search ?? {}),
              ...{
                collapsed: props.tableState.collapsed,
                onCollapse: (collapsed) => {
                  props.updateTableState({
                    collapsed,
                  });
                },
              },
            }
      }
      onReset={
        props.onReset ??
        (() => {
          props.updateTableState({
            params: {},
            needRequest: true,
          });
        })
      }
      onSubmit={
        props.onSubmit ??
        ((params) => {
          props.updateTableState({
            params,
            needRequest: true,
          });
        })
      }
      // onLoad={props.onLoad ?? ((dataSource) => {
      //     props.updateTableState({
      //         dataSource,
      //         needRequest: false,
      //     })
      // })}
      // manualRequest={manualRequest}
      // beforeSearchSubmit={undefined}
      dataSource={(() => {
        return !needRequest ? props.tableState.dataSource : undefined;
      })()}
      request={
        !needRequest
          ? undefined
          : originRequest
          ? props.request
          : (
              params: U & { pageSize?: number; current?: number },
              sort: { [key: string]: SortOrder },
              filter: { [key: string]: React.ReactText[] },
            ) => {
              let search = props.tableState.params;
              Object.keys(props.tableState.params).forEach((k: string) => {
                if (!search[k]) {
                  delete search[k];
                }
              });
              // NOTE: for resolve eslint
              if (typeof props.requestData === 'undefined') {
                return Promise.resolve({
                  data: props.tableState.dataSource ?? [],
                });
              }
              setLoading(true);
              return props
                .requestData(
                  {
                    ...search,
                    pageSize: params.pageSize,
                    current: params.current,
                  },
                  sort,
                  filter,
                )
                .then((rs) => {
                  props.updateTableState({
                    dataSource: rs.data,
                    pagination: {
                      ...props.tableState.pagination,
                      total: rs.total,
                    },
                    needRequest: false,
                  });
                  setLoading(false);
                  return Promise.resolve(rs);
                });
            }
      }
    />
  );
}
