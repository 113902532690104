import { Button, Form, Input, Modal, Radio, Select, Spin } from 'antd';
import CoreDrawer from '../../core/components/CoreDrawer';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { AdminUserProvider, User } from '../../domain/admin-providers/adminUserProvider';
import { BaseEvent } from '../../../event/baseEvent';
import { AdminUserGroupProvider, Group } from '../../domain/admin-providers/adminUserGroupProvider';
import { EventProvider } from '../../../event/eventProvider';

export type UserFormProps = {
  record?: User;
  visible: boolean;
  isEdit: boolean;
};

export class CloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: CloseEvent.symbol,
      payload: undefined,
      type: CloseEvent.name,
    });
  }
}

export default function UserForm(props: UserFormProps) {
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<{
    isEdit: boolean;
    user?: User;
  }>({
    isEdit: props.isEdit,
    user: undefined,
  });
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [formSystemId, setFormSystemId] = useState<string | undefined>(
    undefined,
  );

  const close = () => {
    setConfirmPasswordVisible(false)
    EventProvider.addEvents(new CloseEvent());
  };

  const refreshUserData = async (userId: string) => {
    const res = await AdminUserProvider.getUser(userId);
    setFormState({ ...formState, user: res, isEdit: true });
  };

  const save = (formRef: React.RefObject<FormInstance>) => {
    formRef.current?.validateFields().then(async (values: any) => {
      if (formState.isEdit) {
        // 編輯
        const payload: Partial<User> = {
          systemId: values.systemId,
          id: formState.user?.id,
          username: values.username,
          displayName: values.displayName,
          email: values.email,
          groupId: values.groupId,
          firstTimeLogin: values.firstTimeLogin,
        };
        if (values.password) {
          payload.password = values.password;
        }
        await AdminUserProvider.update(payload);
        close();
      } else {
        // 新增
        const payload: Partial<User> = {
          systemId: values.systemId,
          username: values.username,
          password: values.password,
          displayName: values.displayName,
          email: values.email,
          groupId: values.groupId,
          firstTimeLogin: values.firstTimeLogin,
        };
        await AdminUserProvider.add(payload).then((res) => {
          Modal.success({
            content: '新增成功',
            okText: '確認',
          });
        });
        close();
        //await refreshUserData(user.id);
      }
    });
  };
  useEffect(() => {
    setFormState({ user: props.record, isEdit: props.isEdit });
    if (!props.visible) {
      return;
    }
    const pArr: Promise<any>[] = [];
    pArr.push(AdminUserGroupProvider.getAll().then((groups) => setGroupList(groups)));
    if (!!props.record?.id) {
      pArr.push(refreshUserData(props.record.id));
    }
    setLoading(true);
    Promise.all(pArr)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.record, props.visible]);

  useEffect(() => {
    if (!formState.user || !formRef.current) {
      return;
    }
    formRef.current?.setFieldsValue({
      ...formState.user,
    });
  }, [formState, formRef]);
  return (
    <CoreDrawer
      width={640}
      title={
        formState.isEdit
          ? `編輯使用者(${formState.user?.id})`
          : `新增使用者`
      }
      visible={props.visible}
      onClose={() => close()}
      footerbuttons={[
        <Button key={1} type="primary" onClick={() => save(formRef)}>
          儲存
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          ref={formRef}
          layout="vertical"
          name="user_form"
          onValuesChange={(val) => {
            if (typeof val.password === 'string') {
              setConfirmPasswordVisible(!!val.password);
            }
            if (typeof val.systemId === 'string') {
              setFormSystemId(val.systemId);
            }
          }}
        >
          <Form.Item
            name="username"
            label="用戶代號"
            rules={[{ required: true, message: '請輸入用戶代號' }]}
          >
            <Input
              allowClear
              bordered={!formState.isEdit}
              readOnly={formState.isEdit}
              addonBefore={
                formState.isEdit || !formSystemId ? '' : `${formSystemId}_`
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="用戶密碼"
            extra={formState.isEdit ? `如不修改，請留空` : ''}
            rules={
              formState.isEdit
                ? []
                : [{ required: true, message: '請輸入用戶密碼' }]
            }
          >
            <Input.Password />
          </Form.Item>
          {formState.isEdit && !confirmPasswordVisible ? null : (
            <Form.Item
              name="confirm-password"
              label="確認用戶密碼"
              rules={[
                { required: true, message: '請輸入正確用戶密碼' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('請確認兩組密碼輸入相同');
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="displayName"
            label="用戶顯示名稱"
            rules={[{ required: true, message: '請輸入用戶顯示名稱' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="email"
            label="郵電地址"
            rules={[{ type: 'email', message: '請輸入正確的郵電地址' }]}
          >
            <Input allowClear type="email" />
          </Form.Item>
          {formState.isEdit ? (
            <Form.Item name="firstTimeLogin" label="完成第一次登入">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}>未登入</Radio.Button>
                <Radio.Button value={false}>已登入</Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : null}
          <Form.Item
            name="groupId"
            label="指定群組"
            rules={[{ required: true, message: '請指定群組' }]}
          >
            <Select showSearch style={{ width: 200 }} optionFilterProp="children">
              {groupList.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {`${x.name} - ${x.displayName}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </CoreDrawer>
  );
}
