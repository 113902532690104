import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import {
  AgentWalletInfo,
  AgentWalletProvider,
  ChangeLogQuery,
  ChangeLogType,
} from '../../domain/report-provider/agentWalletProvider';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import { useTxnType } from '../../agent-report/hook/orderStatus';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { Button, PageHeader, Row } from 'antd';
import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import AgentWalletCard from '../components/AgentWalletCard';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import RefundWithdrawForm, { OrderCreatedEvent } from '../components/RefundWithdrawForm';
import ExcelDowloadButton from '../../core/components/ExcelDowloadButton';
import _module from '../configs/module';
import { COMMIT_WITHDRAW_ORDER } from '../configs/scopes';
import { AgentToken } from '../../domain/channel/channel';
import * as _ from 'lodash';

interface PageOp extends CoreProTableState<ChangeLogType> {
}

export default function FundingDetails() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [withdrawFrom, setWithdrawFrom] = useState(false);
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [wallet, setWallet] = useState<AgentWalletInfo[]>([]);
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const txnType = useTxnType();
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });

  const refreshWallet = () => {
    AgentWalletProvider.getCurrentBalance({
      systemId: user.systemId,
    })
      .then((rs) => setWallet(rs))
      .catch((e) => {
        console.error('refreshWallet', e);
      });
  };

  const reloadPage = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
    refreshWallet();
  };

  useEffect(() => {
    reloadPage();
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === OrderCreatedEvent.symbol))
      .subscribe((event) => {
        console.log('refresh wallet');
        refreshWallet();
      });
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === OrderCreatedEvent.symbol))
      .subscribe((event) => {
        console.log('refresh page');
        reloadPage();
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <div style={{ backgroundColor: '#ececec;' }}>
      <PageHeader
        title={t('funding.funding_details')}
        subTitle=''
        extra={<></>}>
        <Row>
          {
            AgentToken.map((it) => {
              {
                const index = _.findIndex(wallet, (x) => x.token === it.label);
                if(index!=-1)
                {
                  return <AgentWalletCard token={it.label} balance={wallet[index].balance} />;
                }
              }
            })
          }
        </Row>
      </PageHeader>

      <CoreProTable<ChangeLogType>
        bordered
        rowKey='createdAt'
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        tableState={pageOp.get()}
        requestData={async (params) => {
          const param: PaginationQuery &
            ChangeLogQuery = PaginationTransformer.paramTransfer({
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
          });
          const res = await AgentWalletProvider.getChangeLogList(param);
          return PaginationTransformer.responseTransfer(res);
        }}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        toolBarRender={() => [
          <Button
            key={'withdraw_buttib'}
            type='primary'
            disabled={!_module.can([COMMIT_WITHDRAW_ORDER])}
            onClick={() => setWithdrawFrom(true)}
          >
            {t('funding.withdraw_action.withdraw')}
          </Button>,
          <ExcelDowloadButton
            getExcel={async () => {
              return await AgentWalletProvider.getChangeLogExcel({
                ...pageOp.get().params,
                ...dateRangeColumn.getParamAndUpdatePage(),
              });
            }}
            filePrefix={'daily'}
          />,
        ]}
        columns={[
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            width: 70,
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            align: 'center',
            hideInSearch: false,
            title: t('common.type'),
            valueEnum: txnType,
            dataIndex: 'txnType',
            initialValue: pageOp.get().params.txnType,
            width: 70,
          },
          {
            hideInTable: false,
            hideInSearch: true,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            width: 70,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('agent.refund_amount'),
            dataIndex: 'txnAmount',
            width: 80,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.balance'),
            dataIndex: 'txnAfterBalance',
            width: 80,
          },
          {
            align: 'left',
            hideInSearch: true,
            hideInTable: false,
            title: t('common.comment'),
            dataIndex: 'comment',
            // render: (text) => commentTranslate.parse(text as string),
            width: 80,
          },
          dateRangeColumn.column,
        ]}
      />
      <RefundWithdrawForm
        visible={withdrawFrom}
        onClose={() => {
          setWithdrawFrom(false);
        }}
      />
    </div>
  );
}
