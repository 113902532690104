import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getStorage } from '../lib/webStorage';
import { message } from 'antd';
import qs from 'qs';

// const CancelToken = Axios.CancelToken;
// const source = CancelToken.source();

const defaultRequestInterceptor = (config: AxiosRequestConfig) => {
  // if (pending.indexOf(configs.url) >= 0) {
  //   configs.cancelToken = source.token;
  //   source.cancel("请求过于频繁！");
  // } else {
  //   pending.push(configs.url);
  // }
  const token = getStorage('token');
  const temporaryToken = getStorage('temporaryToken');
  const lang = getStorage('lang', 0);
  config.headers.Authorization = 'Bearer ' + (token || temporaryToken || '');
  config.headers.systemId =
    config.headers.SYSTEM_ID || getStorage('systemId') || '';
  config.headers.SYSTEM_ID =
    config.headers.SYSTEM_ID || getStorage('systemId') || '';
  //config.headers.language = lang === 'zhCN' ? 'zh_CN' : 'en_US';
  switch(lang){
    case'zh':{
      config.headers.language = 'zhCN'
      break;
    }
    case'en':{
      config.headers.language = 'en_US'
      break;
    }
    case'vi':{
      config.headers.language = 'vi_VN'
      break;
    }
    default:{
      config.headers.language = 'zhCN'
      break;
    }
  }
  return config;
};

const defaultResponseHandle = (response: AxiosResponse) => {
  const { data } = response;
  if (data && data.hasOwnProperty('ErrorCode') && data.ErrorCode !== 0) {
    message.error(data.Message, 2, () => {
      // window.location.href = "/";
    });
    return Promise.reject(data.Message);
  }
  return data;
};

const defaultResponseErrorHandle = (error: any) => {
  const clientMessage = new Map([
    ['mch.user.login.error', '不合法的使用者'],
    ['mch.user.duplicate', '請勿新增重複的使用者'],
    ['mch.user.group.duplicate', '請勿新增重複的群組'],
    ['mch.user.role.duplicate', '請勿新增重複的角色'],
    ['admin.user.duplicate', '請勿新增重複的使用者'],
    ['admin.user.group.duplicate', '請勿新增重複的群組'],
    ['admin.user.role.duplicate', '請勿新增重複的角色'],
    ['admin.user.group.cascade', '無法執行，該群組尚有使用者存在'],
    ['user.not.found', '無法登入，請確認帳號或密碼輸入是否正確'],
    ['user.login.error', '無法登入，請確認帳號或密碼輸入是否正確'],
    ['user.system.disabled', '該商戶已被禁用'],
    [
      'usdt.create.normal.system.response.error',
      '創建商戶服務回應錯誤，請稍後重新嘗試',
    ],
    [
      'usdt.create.agent.system.response.error',
      '創建代理商戶服務回應錯誤，請稍後重新嘗試',
    ],
    [
      'usdt.get.system.settings.response.error',
      '取得商戶服務回應錯誤，請稍後重新嘗試',
    ],
    ['proxy.token.expired', '驗證過期'],
    ['proxy.token.invalid', '驗證失效'],
  ]);
  if (error.response) {
    const { data, status } = error.response;
    if (status === 401) {
      message.error('你沒有權限存取此功能 api', 2, () => {});
      // message.error(data.errorCode, 2, () => {
      //   window.sessionStorage.clear();
      //   window.location.href = "/";
      // });
    } else if (status === 403) {
      message.error('你沒有權限存取此功能 api', 2, () => {});
    } else if (status === 500) {
      message.warning(clientMessage.get(data.clientCode) ?? data.message);
    }
    return Promise.reject(error);
  } else {
    message.warning(error.message);
    return Promise.resolve(error);
  }
};

function paramsEmpty(data: any, type: boolean) {
  if (type) {
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      } else if (
        typeof data[key] === 'object' &&
        Object.keys(data[key]).length < 1
      ) {
        delete data[key];
      }
    }
    return data;
  } else {
    return data;
  }
}

let instance = Axios.create({
  method: 'get',
  headers: {
    Authorization: '',
    language: '',
    platform: '',
  },
  params: {},
  data: {},
  responseType: 'json',
  timeout: 2 * 60 * 1000,
  // withCredentials: true
});
instance.interceptors.request.use(defaultRequestInterceptor, Promise.reject);

instance.interceptors.response.use(
  defaultResponseHandle,
  defaultResponseErrorHandle,
);

// eslint-disable-next-line
export default {
  resetInstance: (
    requestInterceptor: (
      config: AxiosRequestConfig,
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
  ) => {
    instance = Axios.create();
    instance.interceptors.request.use(requestInterceptor, Promise.reject);
    instance.interceptors.response.use(
      defaultResponseHandle,
      defaultResponseErrorHandle,
    );
  },
  get: (url: string, data: any = {}, headers: Object = {}) => {
    return instance.get(url, { params: data, headers });
  },
  post: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    return instance.post(url, paramsEmpty(data, type), { headers });
  },
  formPost: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    return instance.post(url, qs.stringify(paramsEmpty(data, type)), {
      headers,
    });
  },

  stringPost: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    return instance.post(
      `${url}?${qs.stringify(paramsEmpty(data, type))}`,
      '',
      { headers },
    );
  },

  uploadPost: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    const list: any = paramsEmpty(data, type);
    const params = new FormData();
    for (const key in list) {
      params.append(key, list[key]);
    }
    return instance.post(url, params, { headers });
  },
  patch: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    return instance.patch(url, paramsEmpty(data, type), { headers });
  },
  put: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    return instance.put(url, paramsEmpty(data, type), { headers });
  },
  stringPut: (
    url: string,
    data: any = {},
    headers: Object = {},
    type: boolean = false,
  ) => {
    return instance.put(`${url}?${qs.stringify(paramsEmpty(data, type))}`, '', {
      headers,
    });
  },
  delete: (url: string, data: any = {}, headers: Object = {}) => {
    return instance.delete(`${url}?${qs.stringify(data)}`, { headers });
  },
};
