import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { TransferBackOrder, TransferBackOrderProvider } from '../../domain/usdt-providers/transferBackOrderProvider';
import React, { useEffect, useRef, useState } from 'react';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import * as _ from 'lodash';
import { round } from 'lodash';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { useTransferBackTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { useTranslation } from 'react-i18next';
import ReportDetail from '../../domain/components/order/PayforReportDetail';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType, DateRangeType } from '../../core/utils/tableUtilType';
import SpeedUpForm, { SpeedUpFormClosedEvent, SpeedUpFormProps } from '../component/SpeedUpForm';
import { SpeedUpTxnType } from '../../domain/usdt-providers/speedupProvirder';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import { PayforOrderStatus } from '../../domain/order-status/payforOrderTypes';
import { Channel, ChannelValueEnum, Token } from '../../domain/channel/channel';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { OPERATION_WITHDRAW_SPEEDUP } from '../configs/scopes';
import _module from '../configs/module';
import { parseErrorCause } from '../../core/utils/errorParse';

const defaulSpeedUpData: SpeedUpFormProps = {
  visible: false,
  orderId: '',
  txnType: SpeedUpTxnType.transferback,
  systemId: '',
};

// eslint-disable-next-line
export default (props: { title: string; subTitle: string }) => {
  const { t } = useTranslation();
  const transferbackStatusEnum = useTransferBackTranslate();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<
    CoreProTableState<TransferBackOrder> & DateRangeType
  >('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });
  const loggger = useOperatorLogger();
  const [speedUpState, setSpeedUpState] = useState<SpeedUpFormProps>(
    defaulSpeedUpData,
  );

  const speedup = (row: TransferBackOrder) => {
    setSpeedUpState({
      orderId: row.orderId,
      visible: true,
      txnType: SpeedUpTxnType.transferback,
      systemId: row.systemId,
    });
  };

  const checkSpeedUp = (row: TransferBackOrder) => {
    if (row.status !== PayforOrderStatus.OrderStatus.Pending) {
      return false;
    }
    if (row.channel !== Channel.ETHEREUM) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === SpeedUpFormClosedEvent.symbol))
      .subscribe((x) => {
        setSpeedUpState(defaulSpeedUpData);
      });
  }, []);

  return (
    <>
      <PageHeader title={props.title} subTitle={props.subTitle} extra={<></>} />
      <CoreProTable<TransferBackOrder>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        scroll={{ x: 'fixed' }}
        actionRef={actionRef as any}
        rowKey="orderNo"
        requestData={(params) => {
          const param = {
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
          };
          const res = TransferBackOrderProvider.getAdminTransferBackOrder(
            param,
          ).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: param },
              systemId: param.systemId,
            });
            return rs;
          });
          return res;
        }}
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: false,
            hideInTable: false,
            width: 120,
            align: 'center',
            title: '訂單編號',
            dataIndex: 'orderNo',
            fixed: 'left',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.channel'),
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.token'),
            valueEnum: Token,
            dataIndex: 'token',
          },
          {
            hideInSearch: false,
            width: 70,
            align: 'center',
            title: '商戶名稱',
            dataIndex: 'systemId',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 70,
            align: 'center',
            title: '訂單狀態',
            dataIndex: 'status',
            valueEnum: transferbackStatusEnum,
            render: (n, row) => {
              let statusText = t('transferback.status.'+row.status.toLowerCase());
              if (row.status === 'Fail') {
                if (!_.isNil(row.failReason)) {
                  statusText += parseErrorCause(row.failReason);
                }
              }
              return statusText;
            },
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            align: 'center',
            title: '商戶訂單編號',
            dataIndex: 'customOrderNo',
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: false,
            width: 120,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'right',
            title: '金額',
            dataIndex: 'orderAmount',
            render: (text, row) => round(Number(row.orderAmount), 4).toFixed(4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'right',
            title: '服務費',
            dataIndex: 'fee',
          },
          // {
          //   title: '轉帳模式',
          //   hideInSearch: true,
          //   dataIndex: 'transferMode',
          //   width: 100,
          //   align: 'center',
          //   valueEnum: TypeTransferMode,
          // },
          {
            title: '轉帳費用',
            hideInSearch: true,
            dataIndex: 'networkFee',
            width: 100,
            align: 'center',
          },
          dateRangeColumn.column,
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '創建時間',
            dataIndex: 'createdAt',
            render: (text: any, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            title: '操作',
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 100,
            render: (text: any, row: TransferBackOrder) => [
              <Button
                size={'small'}
                key="editable"
                onClick={(e) => {
                  e.preventDefault();
                  loggger.log({
                    action: '查看',
                    payload: { data: row },
                    systemId: row.systemId,
                  });
                  Modal.info({
                    title: '',
                    width: '640px',
                    content: (
                      <ReportDetail
                        title={t('transferback.order_list_title')}
                        order={row}
                        isTransferBackOrder
                        isAdmin={true}
                      />
                    ),
                  });
                }}
              >
                {t('common.view')}
              </Button>,
              checkSpeedUp(row) && (
                <Button
                  key={2}
                  type={'primary'}
                  disabled={!_module.can([OPERATION_WITHDRAW_SPEEDUP])}
                  size={'small'}
                  onClick={() => speedup(row)}
                >
                  {t('speedup.speedup')}
                </Button>
              ),
            ],
          },
        ]}
      />
      <SpeedUpForm {...speedUpState} />
    </>
  );
};
