import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProCoreActionType } from '@ant-design/pro-utils';
import _module from '../configs/module';
import { FormInstance } from 'antd/lib/form';
import { Group } from '../types';
import { DELETE_GROUPS, WRITE_GROUPS } from '../configs/scopes';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { AdminUserGroupProvider } from '../../domain/admin-providers/adminUserGroupProvider';
import GroupForm, { CloseEvent, GroupFormProps } from '../components/GroupForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useTranslation } from 'react-i18next';

interface PageOp extends CoreProTableState<Group> {
}

const defultGroupFormProp: GroupFormProps = {
  group: undefined,
  visible: false,
  isEdit: false,
};

export default function GroupTableList() {
  const { t } = useTranslation();
  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [groupFormState, setGroupFormState] = useState(defultGroupFormProp);
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const refresh = () => {
    pageOp.update((x) => {
      x.needRequest = true;
      return x;
    });
    tableActionRef.current?.reload();
  };

  const deleteCommand = (row: Group) => {
    Modal.confirm({
      title: `刪除?`,
      content: `確認刪除 ${row.displayName} (編號: ${row.id})?`,
      icon: <ExclamationCircleOutlined />,
      okText: '確認',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        AdminUserGroupProvider.delete(row.id).then((rs: any) => {
          pageOp.update((s) => ({ ...s, needRequest: true }));
          refresh();
        });
      },
    });
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === CloseEvent.symbol))
      .subscribe((x) => {
        setGroupFormState(defultGroupFormProp);
        refresh();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader
        title={t('permission.GroupList')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<Group>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={tableActionRef as any}
        rowKey='id'
        columns={[
          {
            hideInSearch: true,
            title: '群組編號',
            dataIndex: 'id',
            width: 50,
            fixed: 'left',
            align: 'center',
          },
          {
            title: '群組代碼',
            dataIndex: 'name',
            width: 100,
            fixed: 'left',
          },
          {
            title: '群組名稱',
            dataIndex: 'displayName',
            width: 100,
            fixed: 'left',
            fieldProps: {
              extra: '(關鍵字查詢)',
            },
          },
          {
            width: 100,
            hideInSearch: true,
            title: '群組說明',
            dataIndex: 'description',
            fixed: 'left',
          },
          {
            hideInSearch: true,
            key: 'operation',
            title: '操作',
            width: 50,
            fixed: 'right',
            align: 'center',
            render: (text, record) =>
              <Space>
                <Button
                  size={'small'}
                  key='editable'
                  disabled={!_module.can([WRITE_GROUPS])}
                  onClick={(x) => {
                    setGroupFormState({
                      group: record,
                      visible: true,
                      isEdit: true,
                    });
                  }}
                >
                  編輯
                </Button>
                <Button
                  size={'small'}
                  key='delete'
                  disabled={!_module.can([DELETE_GROUPS])}
                  danger={_module.can([DELETE_GROUPS])}
                  onClick={(x) => {
                    deleteCommand(record);
                  }}
                >
                  刪除
                </Button>
              </Space>,
          },
        ]}
        toolBarRender={() => [
          _module.canRender(
            WRITE_GROUPS,
            <Button
              key={1}
              type='primary'
              onClick={() => setGroupFormState({ visible: true, isEdit: false })}
            >
              新增群組
            </Button>),
        ]}
        requestData={(params) =>
          AdminUserGroupProvider.getList(PaginationTransformer.paramTransfer(params)).then((res) => {
            return {
              success: res.code === '00',
              data: res.data.items,
              total: res.data.meta.totalItems,
            };
          })
        }
      />
      <GroupForm {...groupFormState} />
    </>
  );
};
