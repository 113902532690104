import React, { useEffect, useState } from 'react';
import { Col, Collapse, PageHeader, Row } from 'antd';
import WalletProvider, { MchWallet } from '../../domain/usdt-providers/walletProvider';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import WalletInfo from '../../domain/components/wallet/WalletInfo';
import { Token } from '../../domain/channel/channel';

const { Panel } = Collapse;
// eslint-disable-next-line
export default () => {
  const [wallets, setWallets] = useState<MchWallet[]>([]);
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const { t } = useTranslation();

  const refreshData = async () => {
    const res = await WalletProvider.getMchWalletPage({
      systemId: user.systemId,
    });
    setWallets(res.data.items);
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      refreshData().catch((e) => console.error(e));
    }, 60000);
    refreshData().catch((e) => console.error(e));
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageHeader title={t('payment.mch_walletInfo_title')}>
        <Collapse defaultActiveKey={['1', '2', '3', '4']}>
          <Panel header={'USDT'} key={1}>
            {wallets.map((wallet) => {
              if (wallet.token === Token.USDT) {
                return (
                  <Row>
                    <Col style={{ margin: '1rem' }}>
                      <WalletInfo wallet={wallet} />
                    </Col>
                  </Row>
                );
              }
              return null;
            })}
          </Panel>
          <Panel header={'USDC'} key={2}>
            {wallets.map((wallet) => {
              if (wallet.token === Token.USDC) {
                return (
                  <Row>
                    <Col style={{ margin: '1rem' }}>
                      <WalletInfo wallet={wallet} />
                    </Col>
                  </Row>
                );
              }
              return null;
            })}
          </Panel>
          <Panel header={'BUSD'} key={3}>
            {wallets.map((wallet) => {
              if (wallet.token === Token.BUSD) {
                return (
                  <Row>
                    <Col style={{ margin: '1rem' }}>
                      <WalletInfo wallet={wallet} />
                    </Col>
                  </Row>
                );
              }
              return null;
            })}
          </Panel>
          <Panel header={'ETH'} key={4}>
            {wallets.map((wallet) => {
              if (wallet.token === Token.ETH) {
                return (
                  <Row>
                    <Col style={{ margin: '1rem' }}>
                      <WalletInfo wallet={wallet} />
                    </Col>
                  </Row>
                );
              }
              return null;
            })}
          </Panel>
        </Collapse>
      </PageHeader>
    </>
  );
};
