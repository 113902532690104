import React from 'react';
import { Button, Dropdown, Form, Image, Input, message } from 'antd';
import { LockOutlined, QrcodeOutlined, UserOutlined } from '@ant-design/icons';

import { getStorage, removeStorage, setStorage, sortList } from '../../lib/webStorage';
import authorize from '../../modules/core/libs/auth';
import { generateMenus } from '../../modules/core/bootstrap';
import { get_settings } from '../../modules/merchant-center/configs/actions';
import { TwoFactoryOpen } from '../../modules/core/configs/services';
import { AuthProvider } from '../../core/providers/authProvider';
import UserStoreAction from '../../stores/user/action';
import MenuStoreAction from '../../stores/menu/action';
import { TwoFactoryProvider } from '../../core/providers/twoFactoryProvider';
import { withTranslation } from 'react-i18next';
import { refreshSystemListState } from '../../stores/system-list/actions';
import { LangMenu } from '../../components/langMenu';
import SystemProvider from '../../modules/domain/usdt-providers/systemProvider';

interface props {
  properties?: any;
  dispatch: any;
  history: any;
  t: any;

  [lang: string]: any;
}

interface state {
  twoFactoryOpen: boolean;
  locale: any;
  loginStatus: number;
  userName: string;
  rs: any;
  qrcodeUrl: string;
  loginResult: any;
  loading: boolean;
}

class LoginForm extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      twoFactoryOpen: TwoFactoryOpen(),
      locale: 'zh',
      loginStatus: 0,
      userName: '',
      rs: {},
      qrcodeUrl: '',
      loginResult: {},
      loading: false,
    };
    this.isChangePwd = this.isChangePwd.bind(this);
  }

  isChangePwd = (rs: any = this.state.rs) => {
    const keyArry = ['token', 'user', 'platform', 'scopes'];
    sortList(rs, keyArry);

    // 開啟自動refresh token
    authorize.autoAuthRefresh(this.props.dispatch);
    const treeMenu = generateMenus();
    MenuStoreAction.reset(this.props.dispatch, treeMenu);
    setStorage('merchantParam', rs.user ? rs.user.baseMerchantCode : '');
    setStorage('pageSize', 20, 0);
    setStorage('platform', rs.platform, 0);
    window.sessionStorage.setItem('TOKEN', rs.token);
    window.localStorage.setItem('TOKEN', rs.token);
    removeStorage('temporaryToken');

    this.props.history.push({
      pathname: '/index',
    });
  };

  handleSubmit = (values: any) => {
    this.setState({ loading: true });
    // 加驚嘆號 => 取消二階段
    if (this.state.twoFactoryOpen) {
      // 二階段驗證的流程
      AuthProvider.login(values)
        .then((res) => {
          const rs = res.data;
          this.props.dispatch((dispatch: any) =>
            UserStoreAction.updateUserLoginInfo(dispatch, {
              ...rs,
              isLogin: false,
            }),
          );
          this.setState({
            loginStatus: 4,
            loginResult: rs,
          });
          if (rs.systemId) {
            this.props.dispatch(get_settings(rs.systemId));
          } else {
            this.props.dispatch(refreshSystemListState());
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      // 一般帳密流程
      AuthProvider.login(values)
        .then((res) => {
          const rs = res?.data;
          this.props.dispatch((dispatch: any) =>
            UserStoreAction.updateUserLoginInfo(dispatch, rs),
          );
          if (rs.systemId) {
            // debugger;
            this.props.dispatch(get_settings(rs.systemId));
            SystemProvider.getSystemVar(rs.systemId).then((res) => {
              this.props.dispatch((dispatch: any) =>
                UserStoreAction.updateMerchantType(dispatch, {
                  merchantType: res.merchantType,
                }),
              );
            });
          } else {
            this.props.dispatch(refreshSystemListState());
          }
          this.isChangePwd(rs);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };
  handle2FASubmit = (value: any) => {
    const { loginResult } = this.state;
    console.log('handle2FASubmit');
    // switch
    TwoFactoryProvider.validate(value.code).then((valid) => {
      if (valid.data) {
        this.isChangePwd(loginResult);
        UserStoreAction.updateUserLoginStatus(this.props.dispatch, {
          isLogin: true,
        });
        const systemId = getStorage('systemId');
        if (systemId && systemId !== 'undefined') {
          this.props.dispatch(get_settings(systemId));
        }
      } else {
        message.error(this.props.t('common.two_factor_error'));
      }
    });
  };

  render() {
    const { loginGoogleOtp } = {
      loginGoogleOtp: false,
    };
    const { loginStatus, loginResult } = this.state;
    switch (loginStatus) {
      case 4:
        return (
          <div className="admin-login-table">
            <p className="title">
              <QrcodeOutlined /> {this.props.t('common.two_factor')}
            </p>
            <Form layout="inline" onFinish={this.handle2FASubmit}>
              <Form.Item
                style={{ height: 'auto' }}
                extra={
                 this.props.t('common.scan_two_factor')
                }
              >
                {loginResult.firstTimeLogin && (
                  <Image
                    src={loginResult.googleAuthKey}
                    style={{ padding: 50 }}
                  />
                )}
              </Form.Item>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    pattern: /^\d{6}$/,
                    message: '請輸入二階段驗證碼',
                  },
                ]}
              >
                <Input
                  maxLength={6}
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={this.props.t('common.authenticator_code')}
                />
              </Form.Item>

              <Form.Item>
                <div className="admin-login-submit">
                  <Button type="primary" htmlType="submit">
                    {this.props.t('common.confirm')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        );

      default:
        return (
          <div className="admin-login-table">
            <p className="title">{this.props.t('login.login_your_account')}</p>
            <Form layout="inline" onFinish={this.handleSubmit}>
              <Form.Item
                // name="operatorName"
                name="username"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {loginGoogleOtp ? (
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Google Auth Code!',
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Google Auth Code"
                  />
                </Form.Item>
              ) : (
                ''
              )}

              <Form.Item>
                <Dropdown overlay={() => <LangMenu />} arrow={true}>
                  <Button>{this.props.t('lang.language')}</Button>
                </Dropdown>
                <div className="admin-login-submit">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    {this.props.t('login.login')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        );
    }
  }
}

export default withTranslation()(LoginForm);
