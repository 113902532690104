import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { getStorage, setStorage } from '../lib/webStorage';

import Login from '../containers/login';
import Index from '../containers/index';
import AllChild from './allChild';

import 'antd/dist/antd.min.css';
import '@ant-design/pro-descriptions/dist/descriptions.min.css';
import '@ant-design/pro-table/dist/table.min.css';
import '@ant-design/pro-form/dist/form.min.css';
import '../assets/css/main.less';
import { GlobalStoreMerge } from '../stores/merge';
import { UserStore } from '../stores/user';
import { Menu } from '../modules/core/configs/menus';

const flattenMenu = (menus: any[]): any[] => {
  return menus.reduce(
    (carry, menu) => [...carry, menu, ...flattenMenu(menu.children ?? [])],
    [],
  );
};
const ReduxVersion = '1.1.0';

const RouteMatch = (props: { isLogin: boolean; history: any }) => {
  useEffect(() => {
    // console.log('aaa', props.isLogin);
    const token = getStorage('TOKEN');
    const isLogin = props.isLogin;
    if (!isLogin || !token) {
      props.history.push('/');
    }
    // 檢查 redux 的版本是否一致
    const reduxVersion = getStorage('ReduxVersion');
    console.log('reduxVersion', reduxVersion);
    if (!reduxVersion || reduxVersion !== ReduxVersion) {
      window.sessionStorage.clear();
      window.localStorage.clear();
      setStorage('ReduxVersion', ReduxVersion);
      window.location.href = '/';
    }
    // eslint-disable-next-line
  }, [props.isLogin]);
  return <></>;
};

const Main = () => {
  const flexible = useSelector<GlobalStoreMerge, boolean>(
    (state) => state.menu.leftMenuCollapsed,
  );
  const menuTree: any =
    useSelector<GlobalStoreMerge, Menu[]>((state) => state.menu.treeMenu) || {};
  const user = useSelector<GlobalStoreMerge, UserStore>((state) => state.user);
  const treeMenu = flattenMenu(menuTree ?? []);
  return (
    <>
      <div className={flexible ? 'admin-main on' : 'admin-main'}>
        <div className="admin-body admin-scroll-two">
          {/*{!!spinStatus ? <Loading /> : ""}*/}
          <Route exact path="/" component={Login} />
          <Route exact path="/index" component={Index} />
          {treeMenu
            .filter((x) => x.isPage)
            .map((item: any) => {
              return (
                <Route
                  exact
                  key={item.menuId}
                  path={`/${item.i18nLabel.en_US
                    .split(' ')
                    .join('')
                    .replace(/^\S/, (s: any) => s.toLowerCase())}/:id`}
                  rank="error"
                  component={AllChild}
                />
              );
            })}
          <Route
            path="*"
            component={(props: any) => (
              <RouteMatch {...props} isLogin={user.isLogin} />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default Main;
