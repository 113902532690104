import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';

const CopyToClipboard = require('react-copy-to-clipboard');

export default function HexLabel({
  hex,
  size = 'normal',
}: {
  hex: string;
  size?: 'small' | 'normal';
}) {
  // const [displayLabel, setDisplayLabel] = useState(hex);
  // useEffect(() => {
  //   setDisplayLabel(
  //     size === 'normal' || !hex
  //       ? hex
  //       : hex.substr(0, 4) + '.....' + hex.substr(-4, 4),
  //   );
  // }, [size, hex]);
  return (
    <>
      {!!hex && (
        <Popover content={hex} trigger={'hover'}>
          <CopyToClipboard text={hex}>
            <Button
              style={{ color: '#3498db' }}
              icon={<CopyTwoTone />}
              size={'small'}
            >
              <span style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '35px',
                verticalAlign: 'top',
              }}>{hex}</span>
              <span>..{hex.substr(-4, 4)}</span>
            </Button>
          </CopyToClipboard>
        </Popover>
      )}
    </>
  );
}
