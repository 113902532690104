export class CollectType {
  static CollectOrderStatus = {
    OrderCreated : "歸集訂單建立",
    PayerFundInSufficient : "手續費資金不足",
    SourceFundSufficient : "來源資金充足",
    SourceFundInSufficient : "來源資金不足",
    NetworkFeeTxnCreated : "礦工費轉帳建立",
    NetworkFeeTxnSuccess : "礦工費轉帳完成",
    NetworkFeeTxnFail : "礦工費轉帳失敗",
    NetworkFeeSufficient : "礦工費充足",
    TokenTxnCreated : "歸集任務建立",
    TokenTxnSuccess : "歸集完成",
    TokenTxnFail : "歸集失敗",
  };

  static CollectWalletStatusLabel = {
    Collecting: '歸集中',
    UnCollect: '尚未歸集',
  };
  
  static CollectWalletStatus = {
    Collecting: 'Collecting',
    UnCollect: 'UnCollect',
  };
}
