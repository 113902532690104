import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';

export interface RevenueItem {
  reportDate: Date,
  systemId: string,
  agentType: string,
  depositAmount: number,
  depositFee: number,
  payoutAmount: number,
  payoutFee: number,
  totalFee: number,
  payforUsdtFee: number,
  commissionAmount: number,
  profitAmount: number,
  createdAt: Date,
  updatedAt: Date,
}
export interface AgentRefundItem {
  reportDate: Date,
  systemId: string,
  parentSystem: string,
  profitAmount: number,
}
export interface TotalItem {
  overallFee: number,
  overallCommissionAmount: number,
  overallProfitAmount: number,
}
export class ChartProvider {
  static async getRevenueList(params: any) {
    const res = await adminRequest.get<AdminListResponse<any>>(
      ReportUri('revenue'),
      { params: params },
    );
    return res.data;
  }

  static async getSummeryList(params: any) {
    const res = await adminRequest.get<AdminResponse<any>>(
      ReportUri('revenue/summery'),
      { params: params },
    );
    return res.data
  }

  static async getRawRevenue(params: any) {
    const res = await adminRequest.get<AdminResponse<any>>(
      ReportUri('revenue/raw'),
      { params: params },
    );
    return res.data;
  }

  static async getRawAgentRefund(params: any) {
    const res = await adminRequest.get<AdminResponse<any>>(
      ReportUri('revenue/agent-refund-source/raw'),
      { params: params },
    );
    return res.data;
  }

  static async getAgentRefundList(params: any) {
    const res = await adminRequest.get<AdminResponse<any>>(
      ReportUri('revenue/agent-refund-source'),
      { params: params },
    );
    return res.data;
  }
}
