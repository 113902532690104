import * as permissions from '../../permissions';
import * as merchantPermissions from '../../merchant-permissions';
import * as orders from '../../orders';
import * as merchantCenter from '../../merchant-center';
import * as systemSetting from '../../system-setting';
import * as transactionManager from '../../transaction-manager';
import * as mchWalletManager from '../../mch-wallet-manager';
import * as report from '../../report';
import * as agentReport from '../../agent-report';
import * as fundingManager from '../../funding-manager';
import * as abandonWalletManager from '../../abandon-wallet';

/**
 * 手動添加模組清單
 */
// eslint-disable-next-line
export default [
  permissions,
  merchantPermissions,
  merchantCenter,
  orders,
  systemSetting,
  transactionManager,
  mchWalletManager,
  report,
  agentReport,
  fundingManager,
  abandonWalletManager,
  // agents,
];
