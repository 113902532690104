import CoreProTable, {
  CoreProTableState,
} from '../../core/components/CoreProTable';
import {
  TotalUsdtChange,
  TotalUsdtChangeProvider,
} from '../../domain/report-provider/totalUsdtChangeProvider';
import React, { useEffect, useRef } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import { PageHeader } from 'antd';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useTradeCodeTranslate } from '../../domain/trade-code/tradeCodeHook';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';

interface PageOp extends CoreProTableState<TotalUsdtChange> {}

// eslint-disable-next-line
export default function () {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const user = useSelector<GlobalStoreMerge, UserStore>((state) => state.user); // 取得當前使用者資訊
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const tradeCodeEnum = useTradeCodeTranslate();
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  const isMchUser = !!user.systemId;
  return (
    <>
      <PageHeader title={'Usdt 帳變匯總'} subTitle={''} extra={<></>} />
      <CoreProTable<TotalUsdtChange>
        bordered
        rowKey={(record) => `${record.txnId}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        formRef={searchFormRef}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        requestData={async (params: any) => {
          let res;
          if (isMchUser) {
            // this user is mch user, call Mch List Provider
            res = await TotalUsdtChangeProvider.getMchList({
              ...PaginationTransformer.paramTransfer(params),
            }).catch((e) => {
              console.error(e);
              throw e;
            });
          } else {
            // this user is admin user, call Admin List Provider
            res = await TotalUsdtChangeProvider.getAdminList({
              ...PaginationTransformer.paramTransfer(params),
            }).catch((e) => {
              console.error(e);
              throw e;
            });
          }
          return PaginationTransformer.responseTransfer(res);
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('change_log.txn_id'),
            dataIndex: 'txnId',
            fixed: 'left',
          },
          {
            hideInSearch: isMchUser,
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'transactionHash',
            title: t('change_log.txn_hash'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'walletAddress',
            title: t('payment.wallet_address'),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            dataIndex: 'tradeCode',
            valueEnum: tradeCodeEnum,
            title: t('payment.trade_genre'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'availableAmount',
            title: t('change_log.available_balance_change'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'availableAfterBalance',
            title: t('change_log.available_after_balance'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'txnAmount',
            title: t('change_log.txn_balance_change'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'txnAfterBalance',
            title: t('change_log.txn_after_balance'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 200,
            dataIndex: 'comment',
            title: t('common.comment'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'sourceTxnId',
            title: t('change_log.source_txn_id'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'sourceCreatedAt',
            title: t('change_log.source_created_at'),
            render: (_: any, row: TotalUsdtChange) =>
              transfer2LocalTime(new Date(row.sourceCreatedAt)),
          },
        ]}
      />
    </>
  );
}
