import { Form, InputNumber, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypeTransferMode } from '../../usdt-providers/systemProvider';


const TransferModeSettingFrom = (props: { transferMode: string ,staticFee?: number|null ,channel:string, token:string}) => {
  const [transferMode, setTransferMode] = useState<string>(
    props.transferMode ?? TypeTransferMode.Dynamic,
  );
  const [staticFee, setStaticFee] = useState<number|undefined>(
    props.staticFee ?? undefined,
  );
  useEffect(() => {
    setTransferMode(props.transferMode);
  }, [props.transferMode]);
  return (
    <>
      <Form.Item
        name={props.channel+'_'+props.token+'_transferMode'}
        label={'礦工費模式'}
        // rules={[{ required: true, message: '請選擇礦工費模式' }]}
      >
        <Radio.Group
          options={[
            { label: '浮動模式', value: TypeTransferMode.Dynamic },
            { label: '固定模式', value: TypeTransferMode.Static },
          ]}
          onChange={(e) => setTransferMode(e.target.value)}
          optionType="button"
          buttonStyle="solid"
          defaultValue={transferMode}
        />
      </Form.Item>
      {transferMode === TypeTransferMode.Static && (
        <>
          <Form.Item name={props.channel+'_'+props.token+'_staticFee'} label="礦工費">
            <InputNumber precision={6} min={0} step={1} defaultValue={staticFee??0} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default TransferModeSettingFrom;
