import { Card, Form, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';

enum actionType {
  Create,
  Edit,
}

const DepositExpireSettingFrom = (props: {
  depositExpireDefaultTime: number;
  actionType: actionType;
}) => {
  const [depositExpireDefaultTime, setDepositExpireDefaultTime] = useState<number>(
    props.depositExpireDefaultTime ?? 30,
  );
  useEffect(() => {
    setDepositExpireDefaultTime(props.depositExpireDefaultTime);
  }, [props.depositExpireDefaultTime]);
  return (
    <>
      <Card title='訂單過期設定'>
        <Form.Item
          name='depositExpireDefaultTime'
          label='訂單過期時間'
          rules={[{ required: true, message: '請輸入 `預設過期時間`' }]}
        >
          <InputNumber
            precision={0}
            min={0}
            step={1}
          />
        </Form.Item>
      </Card>
    </>
  );
};

export default DepositExpireSettingFrom;
