import { BaseEvent } from '../../../event/baseEvent';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { Button, Descriptions, Form, Input, message, Modal, Statistic } from 'antd';
import { EventProvider } from '../../../event/eventProvider';
import { DollarOutlined, UserOutlined } from '@ant-design/icons';
import { AbandonWalletProvider } from '../../domain/usdt-providers/AbandonWalletProvider';
import HexLabel from '../../core/components/HexLabel';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

export type TransferFormProps = {
  visible: boolean;
  balance: string;
  channel: string;
  token: string;
  systemId: string;
  walletAddress: string;
};

export class TransferOrderCreatedEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      type: TransferOrderCreatedEvent.name,
      eventSymbol: TransferOrderCreatedEvent.symbol,
      payload: null,
    });
  }
}

export class TransferOrderClosedEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      type: TransferOrderClosedEvent.name,
      eventSymbol: TransferOrderClosedEvent.symbol,
      payload: null,
    });
  }
}

export default function TransferForm(props: TransferFormProps) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = React.createRef<FormInstance>();
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const loggger = useOperatorLogger();
  const closeFrom = () => {
    EventProvider.addEvents(new TransferOrderClosedEvent());
  };

  const sendOrder = () => {
    formRef.current?.validateFields().then((data: any) => {
      setConfirmLoading(true);
      const params = {
        channel: props.channel,
        orderAmount: parseFloat(props.balance),
        token: props.token,
        walletAddress: props.walletAddress,
        systemId: props.systemId,
        operatorId: user.userId,
        destAddress: data.destAddress,
        code: data.code
      };
      AbandonWalletProvider.transfer(params)
        .then((res) => {
          loggger.log({
            action: '下發',
            payload: { req: params, res: res.data },
            systemId: params.systemId
          });
          message.success(t('common.add_success'), 2);
          console.log(
            'EventProvider.addEvents(new OrderCreatedEvent(res.data));',
          );
          EventProvider.addEvents(new TransferOrderCreatedEvent());
          closeFrom();
        }).catch(e => {
        loggger.log({
          action: '下發',
          payload: { req: params, res: e },
          systemId: params.systemId
        });
      })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  };

  useEffect(() => {
    formRef.current?.setFieldsValue({
      channel: props.channel,
      token: props.token,
    });
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <Modal
        key={'refund_withdraw_form'}
        visible={props.visible}
        footer={[
          <Button key={2} onClick={() => closeFrom()} loading={confirmLoading}>
            {t('common.cancel')}
          </Button>,
          <Button
            key={1}
            type='primary'
            onClick={() => sendOrder()}
            loading={confirmLoading}
          >
            {t('common.confirm')}
          </Button>,
        ]}
        onCancel={() => closeFrom()}
        maskClosable={false}
        closable={false}
        title={t('transferback.transferback')}
        destroyOnClose
      >
        <Descriptions layout={'horizontal'} column={1}>
          <Descriptions.Item label={t('payment.wallet_address')}>
            {<HexLabel hex={props.walletAddress} />}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment.channel')}>
            {props.channel}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment.token')}>
            {props.token}
          </Descriptions.Item>
        </Descriptions>
        <Statistic
          title={t('payment.txnBalance')}
          value={props.balance}
          precision={6}
          prefix={<DollarOutlined />}
          style={{ margin: '0.2rem' }}
        />
        <Form ref={formRef} layout='vertical'>
          <Form.Item
            name='destAddress'
            label={t('collect.destination')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="code"
            label={t('common.verify_code')}
            rules={[
              {
                required: true,
                pattern: /^\d{6}$/,
                message: '請輸入驗證碼',
              },
            ]}
            extra={
              t('common.scan_two_factor_transfer')
            }
          >
            <Input
              maxLength={6}
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('common.authenticator_code')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
