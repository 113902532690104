import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import request from '../../../config/request';
import { AdminUri } from '../../core/configs/services';
import _module from '../configs/module';
import { DELETE_USERS, WRITE_USERS } from '../configs/scopes';
import { FormInstance } from 'antd/lib/form';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import UserForm, { CloseEvent, UserFormProps } from '../../permissions/components/UserForm';
import { useDispatch, useStore } from 'react-redux';
import { refreshSystemListState } from '../../../stores/system-list/actions';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import { AdminUserProvider, User } from '../../domain/admin-providers/adminUserProvider';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

interface PageOp extends CoreProTableState<User> {
}

const defaulUserFormProps: UserFormProps = {
  isEdit: false,
  visible: false,
};

export default function UserTableList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const systemList = useStore().getState().systemList;
  useEffect(() => {
    dispatch(refreshSystemListState());
  }, [systemList, dispatch]);
  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [userFormState, setUserFormState] = useState<UserFormProps>(
    defaulUserFormProps,
  );
  const refresh = () => {
    pageOp.update((s) => ({ ...s, needRequest: true }));
    tableActionRef.current?.reload();
  };

  const deleteUser = (record: User) => {
    confirm({
      title: `刪除?`,
      content: `確認刪除 ${record?.displayName} (編號: ${record?.id})?`,
      icon: <ExclamationCircleOutlined />,
      okText: '確認',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        request
          .delete(AdminUri(`admin/users/${record?.id}`))
          .then((rs: any) => {
            pageOp.update((s) => ({ ...s, needRequest: true }));
            tableActionRef.current?.reload();
          });
      },
    });
  };

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === CloseEvent.symbol))
      .subscribe((x) => {
        setUserFormState(defaulUserFormProps);
        refresh();
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader
        title={t('permission.UserList')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<User>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={tableActionRef as any}
        rowKey='id'
        columns={[
          {
            width: 50,
            title: '帳號',
            dataIndex: 'username',
            fixed: 'left',
            align: 'center',
          },
          {
            width: 50,
            title: '顯示名稱',
            dataIndex: 'displayName',
            fieldProps: {
              extra: '(關鍵字查詢)',
            },
          },
          {
            width: 100,
            title: '電子信箱',
            dataIndex: 'email',
          },
          {
            width: 50,
            title: '群組',
            dataIndex: 'groupDisplayName',
            filterMultiple: false,
            filters: [],
            fieldProps: {
              extra: '(關鍵字查詢)',
            },
          },
          {
            width: 50,
            hideInSearch: true,
            key: 'operation',
            title: '操作',
            fixed: 'right',
            align: 'center',
            render: (text, record) =>
              <Space>
                <Button
                  size={'small'}
                  key='editable'
                  disabled={!_module.can([WRITE_USERS])}
                  onClick={() =>
                    setUserFormState({
                      isEdit: true,
                      record: record,
                      visible: true,
                    })
                  }
                >
                  編輯
                </Button>
                <Button
                  size={'small'}
                  key='delete'
                  disabled={!_module.can([DELETE_USERS]) || record.id === _module.claims().sub}
                  danger={_module.can([DELETE_USERS]) && record.id !== _module.claims().sub}
                  onClick={(x) => {
                    deleteUser(record);
                  }}
                >
                  刪除
                </Button>
              </Space>,
          },
        ]}
        toolBarRender={() => [
          _module.canRender(
            WRITE_USERS,
            <Button
              key={1}
              type='primary'
              onClick={() => setUserFormState({ isEdit: false, visible: true })}
            >
              <PlusOutlined /> 新增使用者
            </Button>),
        ]}
        requestData={(params) =>
          AdminUserProvider.getList(params).then((res) => {
            return {
              success: res.code === '00',
              data: res.data.items.map((x: User) => ({
                ...x,
                groupDisplayName: x.group.displayName,
              })),
              total: res.data.meta.totalItems,
            };
          })
        }
      />
      <UserForm {...userFormState} />
    </>
  );
};
