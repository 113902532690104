import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { omit, round } from 'lodash';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { TransferBackOrder, TransferBackOrderProvider } from '../../domain/usdt-providers/transferBackOrderProvider';
import { useTransferBackTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { useTranslation } from 'react-i18next';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import ReportDetail from '../../domain/components/order/PayforReportDetail';
import ExcelDownloadFormButton from '../../core/components/ExcelDownloadFormButton';
import TransferBackFrom from '../components/TransferBackFrom';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import SpeedUpForm, {
  SpeedUpFormClosedEvent,
  SpeedUpFormProps,
} from '../../transaction-manager/component/SpeedUpForm';
import { SpeedUpTxnType } from '../../domain/usdt-providers/speedupProvirder';
import { PayforOrderStatus } from '../../domain/order-status/payforOrderTypes';
import { Channel, ChannelValueEnum, MchToken } from '../../domain/channel/channel';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import _module from '../configs/module';
import { ADD_WITHDRAW_ORDER } from '../configs/scopes';

interface PageOp extends CoreProTableState<TransferBackOrder> {
}

const defaulSpeedUpData: SpeedUpFormProps = {
  visible: false,
  orderId: '',
  txnType: SpeedUpTxnType.transferback,
  systemId: '',
};

// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const transferbackStatusEnum = useTransferBackTranslate();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const [speedUpState, setSpeedUpState] = useState<SpeedUpFormProps>(
    defaulSpeedUpData,
  );
  const [issueModalShow, setIssueModalShow] = useState(false);
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });
  const speedup = (row: TransferBackOrder) => {
    setSpeedUpState({
      orderId: row.orderId,
      visible: true,
      txnType: SpeedUpTxnType.transferback,
      systemId: row.systemId,
    });
  };

  const checkSpeedUp = (row: TransferBackOrder) => {
    if (row.status !== PayforOrderStatus.OrderStatus.Pending) {
      return false;
    }
    if (row.channel !== Channel.ETHEREUM) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === SpeedUpFormClosedEvent.symbol))
      .subscribe((x) => {
        setSpeedUpState(defaulSpeedUpData);
      });
  }, []);
  return (
    <>
      <PageHeader
        title={t('transferback.order_list_title')}
        // subTitle={t('transferback.order_list_subtitle')}
        extra={<></>}
      />
      <CoreProTable<TransferBackOrder>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        scroll={{ x: 'fixed' }}
        search={{ layout: 'vertical' }}
        toolBarRender={() => [
          _module.canRender(
            ADD_WITHDRAW_ORDER,
            <Button
              key={1}
              type='primary'
              onClick={() => setIssueModalShow(true)}
            >
              {t('transferback.transferback')}
            </Button>,
          ),
          <ExcelDownloadFormButton
            getExcelFile={(start, end) =>
              TransferBackOrderProvider.getExcel({ start, end })
            }
            filePrefix={'withdraw_order'}
          />,
        ]}
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: false,
            hideInTable: false,
            width: 120,
            align: 'center',
            title: t('payment.order_no'),
            dataIndex: 'orderNo',
            fixed: 'left',
          },
          {
            hideInSearch: true,
            hideInTable: true,
            width: 70,
            align: 'center',
            title: t('payment.system_id'),
            dataIndex: 'systemId',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            valueEnum: ChannelValueEnum,
            title: t('payment.channel'),
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            valueEnum: MchToken,
            title: t('payment.token'),
            dataIndex: 'token',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 70,
            align: 'center',
            title: t('payment.order_status'),
            dataIndex: 'status',
            valueEnum: transferbackStatusEnum,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            align: 'center',
            title: t('payment.mch_custom_order_no'),
            dataIndex: 'customOrderNo',
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: true,
            width: 100,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 70,
            align: 'right',
            title: t('payment.order_amount'),
            dataIndex: 'orderAmount',
            render: (text, row) => round(Number(row.orderAmount), 4).toFixed(4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 70,
            align: 'right',
            title: t('payment.fee'),
            dataIndex: 'fee',
          },
          // {
          //   title: t('payment.transfer_mode'),
          //   dataIndex: 'transferMode',
          //   hideInSearch: true,
          //   width: 70,
          //   align: 'center',
          //   valueEnum: TypeTransferMode,
          // },
          {
            title: t('payment.usdt_fee'),
            hideInSearch: true,
            dataIndex: 'networkFee',
            width: 70,
            align: 'right',
          },
          {
            width: 120,
            ellipsis: true,
            align: 'center',
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
          },
          dateRangeColumn.column,
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text: any, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            title: t('common.operate'),
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 100,
            render: (text: any, row: TransferBackOrder) => [
              <Button
                size={'small'}
                key='editable'
                onClick={(e) => {
                  e.preventDefault();
                  Modal.info({
                    title: '',
                    width: '640px',
                    content: (
                      <ReportDetail
                        title={t('transferback.order_list_title')}
                        order={row}
                        isTransferBackOrder
                        isAdmin={false}
                      />
                    ),
                  });
                }}
              >
                {t('common.view')}
              </Button>,
              checkSpeedUp(row) && (
                <Button
                  key={2}
                  disabled={!_module.can([ADD_WITHDRAW_ORDER])}
                  type={'primary'}
                  size={'small'}
                  onClick={() => speedup(row)}
                >
                  {t('speedup.speedup')}
                </Button>

              ),
            ],
          },
        ]}
        rowKey='key'
        requestData={(params: any, sort, filter) => {
          if (params.status === 'ALL') {
            delete params.status;
          }
          return TransferBackOrderProvider.getTransferBackOrderList({
            page: params.current,
            limit: params.pageSize,
            txHash: params.transactionHash,
            ...omit(params, [
              'current',
              'pageSize',
              'createdAt',
              'transactionHash',
            ]),
            ...dateRangeColumn.getParamAndUpdatePage(),
          }).then((res) => {
            const rs = res;
            return {
              data: rs.data.items.map((el: any, index: number) => ({
                key: `${index}`,
                ...el,
              })),
              success: true,
              total: rs.data.meta.totalItems,
            };
          });
        }}
      />
      <TransferBackFrom
        visible={issueModalShow}
        onClose={() => {
          setIssueModalShow(false);
          pageOp.update((v) => ({ ...v, needRequest: true }));
          actionRef.current?.reload();
        }}
      />
      <SpeedUpForm {...speedUpState} />
    </>
  );
};
