import { Button, Card, Descriptions, Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { TransferBackOrderProvider } from '../../domain/usdt-providers/transferBackOrderProvider';
import { AdminResponse } from '../../domain/models/adminResponse';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { WhiteList, WhiteListProvider } from '../../domain/usdt-providers/whiteListProvider';
import _ from 'lodash';
import WalletProvider, { MchWallet } from '../../domain/usdt-providers/walletProvider';
import ChannelTokenCascader from '../../domain/components/setttings/ChannelTokenCascader';
import { adminClientMessage } from '../../../config/adminRequest';
import { UserOutlined } from '@ant-design/icons';

type Props = { visible: boolean; onClose: () => void };
type TokenId = { channel: string, token: string }

export default function TransferBackFrom(props: Props) {
  const [visible, setVisible] = useState(props.visible);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [whiteList, setWhiteList] = useState<WhiteList[]>([]);
  const [channelToken, setChannelToken] = useState<{channel?:string,token?:string}>({ channel: undefined, token: undefined });
  const [wallets, setWallets] = useState<MchWallet[]>([]);
  const [currentWallet, setCurrentWallet] = useState<MchWallet>();
  const channelWhites = _.chain(whiteList)
    .tap((x) => console.log(x, channelToken, whiteList))
    .filter((x) => {
      return x.channel === channelToken.channel && x.token === channelToken.token;
    })
    .map((x) => ({
      label: `${x.walletAddress} | ${x.comment || '-'}`,
      value: x.walletAddress,
    }))
    .value();

  const { t } = useTranslation();
  const issueFormRef = React.createRef<FormInstance>();

  const closeFrom = () => {
    props.onClose();
    setVisible(false);
  };

  const submitIssueForm = () => {
    issueFormRef.current?.validateFields().then((values) => {
      setConfirmLoading(true);
      TransferBackOrderProvider.createTransferBack({
        destAddress: values.address,
        amount: values.amount,
        channel: channelToken.channel,
        token: channelToken.token,
        code: values.code,
      })
        .then((res) => {
          const rs = res;
          setConfirmLoading(false);
          issueFormRef.current?.resetFields();
          Modal.success({
            content: (
              <>
                <br />
                <div>
                  <strong>{t('payment.order_no')}：</strong>
                  <span>{rs.data.orderNo}</span>
                </div>
              </>
            ),
            onOk: () => {
              closeFrom();
            },
            okText: t('common.confirm'),
          });
        })
        .catch((e) => {
          const err = e as AdminResponse<any>;
          console.error(e);
          Modal.error({
            content: !!e.displayMessage
              ? e.displayMessage
              : adminClientMessage.get(e.clientCode) ?? e.message,
            okText: t('common.confirm'),
          });
        })
        .finally(() => setConfirmLoading(false));
    });
  };

  useEffect(() => {
    setVisible(props.visible);
    if (props.visible) {
      WhiteListProvider.getWhiteList().then((rs) => {
        console.log(rs.data);
        setWhiteList(rs.data);
      });
      WalletProvider.getMchWalletInfo().then((res) => {
        setWallets(res.data);
      });
    }
  }, [props.visible]);

  useEffect(() => {
    const current = _.find(
      wallets,
      (x) => x.channel === channelToken.channel && x.token === channelToken.token,
    );
    setCurrentWallet(current);
  }, [wallets, channelToken]);

  function onCascaderChange(value: { channel?: string, token?: string }) {
    setChannelToken({ channel: value.channel??'', token: value.token??'' });
  }

  return (
    <Modal
      visible={visible}
      footer={[
        <Button key={2} onClick={() => closeFrom()} loading={confirmLoading}>
          {t('common.cancel')}
        </Button>,
        <Button
          key={1}
          type='primary'
          onClick={submitIssueForm}
          loading={confirmLoading}
        >
          {t('common.confirm')}
        </Button>,
      ]}
      onCancel={() => closeFrom()}
      maskClosable={false}
      closable={false}
      destroyOnClose
    >
      <Form
        ref={issueFormRef}
        layout='vertical'
        initialValues={{ amount: 0, channel: channelToken.channel }}
      >
        <Card style={{ marginBottom: '0.5rem' }}>
          <Descriptions column={1} title={t('payment.mch_walletInfo_title')}>
            <Descriptions.Item label={t('payment.channel')}>
              {currentWallet?.channel}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.token')}>
              {currentWallet?.token}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.availableBalance')}>
              {currentWallet?.availableBalance ?? 0}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Form.Item
          name='channel'
          label={t('payment.channel')}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <ChannelTokenCascader onChange={onCascaderChange} option={{ channel: '', token: '' }} />
          {/*<Select*/}
          {/*  options={ChannelSelect}*/}
          {/*  onChange={(value) => setChannel(value as string)}*/}
          {/*/>*/}
        </Form.Item>
        <Form.Item
          name='address'
          label={t('transferback.destination')}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <Select
            options={channelWhites}
            showSearch
            placeholder={t('transferback.choose_wallet_address')}
          />
        </Form.Item>
        <Form.Item name='amount' label={t('transferback.amount')}>
          <InputNumber min={0} step={1} precision={6}/>
        </Form.Item>
        <Form.Item
          name="code"
          label={t('common.verify_code')}
          rules={[
            {
              required: true,
              pattern: /^\d{6}$/,
              message: '請輸入驗證碼',
            },
          ]}
          extra={
            t('common.scan_two_factor_transfer')
          }
        >
          <Input
            maxLength={6}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('common.authenticator_code')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
