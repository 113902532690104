import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';

import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import { Button, Form, Modal, PageHeader, Select } from 'antd';
import i18next from 'i18next';
import { Channel, ChannelValueEnum, Token } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { SystemListStore } from '../../../stores/system-list';

import {
  WalletNetworkFeeTrackerProvider,
  WalletNetworkFeeTrackerType,
} from '../../domain/usdt-providers/walletNetworkFeeTrackProvider';
import PaginationTransformer from '../../core/utils/paginationTransformer';

interface PageOp extends CoreProTableState<WalletNetworkFeeTrackerType> {
}

export default function MchWalletTrxWhiteList(props: {}) {
  const { t } = useTranslation();
  const [formVisible, setFormVisible] = useState(false);
  const editForm = React.createRef<FormInstance>();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const systemList = useSelector<GlobalStoreMerge, SystemListStore>(
    (x) => x.systemList,
  );
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 20,
      current: 1,
    },
    needRequest: true,
  });
  const refreshDataCommand = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
  };
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  const deleteCommand = (item: WalletNetworkFeeTrackerType) => {
    Modal.confirm({
      content: `${t('common.delete_confirm')} ${item.address} ?`,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        WalletNetworkFeeTrackerProvider.delete(item.systemId, item.channel, item.token)
          .then((res) => {
            Modal.success({
              content: `${t('common.delete_success')}`,
              okText: t('common.confirm'),
            });
          })
          .catch((e) => {
            Modal.error({
              content: e.message,
              okText: t('common.confirm'),
            });
          })
          .finally(() => {
            refreshDataCommand();
          });
      },
    });
  };

  const addCommand = async (systemId: string, channel: string, token: string) => {
    const res = await WalletNetworkFeeTrackerProvider.add(systemId, channel, token);
    refreshDataCommand();
    return res.data;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);

  return (
    <>
      <PageHeader
        title={t('credit.mch_wallet_trx_white_list')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<WalletNetworkFeeTrackerType>
        bordered={true}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        toolBarRender={() => [
          <Button
            key={1}
            type='primary'
            onClick={(event) => {
              event.stopPropagation();
              setFormVisible(true);
            }}
          >
            {i18next.t('common.add')}
          </Button>,
        ]}
        requestData={(params) =>
          WalletNetworkFeeTrackerProvider.getList(PaginationTransformer.paramTransfer(params))
            .then((res) => {
              return PaginationTransformer.responseTransfer(res);
            })
            .catch((e) => {
              console.error(e);
              return {
                success: false,
                data: [],
                total: 0,
              };
            })
        }
        columns={[
          {
            title: '商戶代號',
            dataIndex: 'systemId',
            align: 'center',
            width: 200,
            renderFormItem: (_, row, form) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
            fieldProps: {
              showSearch: true,
            },
          },
          {
            align: 'center',
            title: t('payment.channel'),
            hideInSearch: true,
            hideInTable: true,
            dataIndex: 'channel',
            valueEnum: ChannelValueEnum,
          },
          {
            align: 'center',
            title: t('payment.token'),
            hideInSearch: true,
            hideInTable: true,
            dataIndex: 'token',
          },
          {
            align: 'center',
            title: t('payment.wallet_address'),
            hideInSearch: true,
            dataIndex: 'address',
            render: (node, row) => <HexLabel hex={row.address} size={'small'} />,
          },
          {
            align: 'center',
            title: t('common.operate'),
            hideInSearch: true,
            dataIndex: 'address',
            key: '_action',

            render: (text, row) => [
              <Button
                key={2}
                size='small'
                disabled={false}
                danger={true}
                onClick={() => deleteCommand(row)}
              >
                {t('common.delete')}
              </Button>,
            ],
          },
        ]}
      />

      <Modal
        destroyOnClose
        title={t('common.add') + t('credit.mch_wallet_trx_white_list')}
        visible={formVisible}
        okText={t('common.add_confirm')}
        cancelText={i18next.t('common.cancel')}
        onOk={() => {
          editForm.current?.validateFields().then((values) => {
            addCommand(values.systemId, Channel.TRON, Token.TRX).then(async (res) => {
              Modal.success({
                content: `${values.systemId} ${t('common.add_success')}`,
                okText: t('common.confirm'),
              });
              refreshDataCommand();
              setFormVisible(false);
            });
          });
        }}
        onCancel={() => setFormVisible(false)}
      >
        <Form ref={editForm}>
          <Form.Item
            name='systemId'
            label='商戶代號'
            rules={[{ required: true, message: '請輸入商戶代號' }]}
          >
            <Select
              showSearch
              allowClear
              options={systemList.systems.map((s: any) => ({
                label: s.systemId,
                value: s.systemId,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
