import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePayforStatusTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { DateRangeStateType, DateRangeType } from '../../core/utils/tableUtilType';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { AbandonWalletProvider, AbandonWalletTransferOrder } from '../../domain/usdt-providers/AbandonWalletProvider';
import { PageHeader } from 'antd';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import HexLabel from '../../core/components/HexLabel';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

interface PageOp extends CoreProTableState<AbandonWalletTransferOrder> {}

export default function WithdrawOrder(props: {}) {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const payforStatusTranslateEnum = usePayforStatusTranslate();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const loggger = useOperatorLogger();
  const searchOp = usePageState<DateRangeType>('SEARCH_PARAM', {});
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp: searchOp,
    state: dateRangeState,
  });

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader title={'下發任務'} subTitle={''} extra={<></>} />
      <CoreProTable<AbandonWalletTransferOrder>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        scroll={{ x: 'fixed' }}
        rowKey="orderNo"
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        requestData={(params) => {
          const param = {
            ...PaginationTransformer.paramTransfer(params),
            ...dateRangeColumn.getParamAndUpdatePage(),
          };
          const res = AbandonWalletProvider.getTransferOrderList(param).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: param },
              systemId: params.systemId,
            });
            return PaginationTransformer.responseTransfer(rs);
          });
          return res;
        }
        }

        columns={[
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '訂單編號',
            dataIndex: 'orderId',
            fixed: 'left',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
          },
          {
            width: 100,
            dataIndex: 'systemId',
            hideInSearch: false,
            align: 'center',
            title: '商戶名稱',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '訂單狀態',
            dataIndex: 'status',
            valueEnum: payforStatusTranslateEnum,
          },
          {
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.wallet_address'),
            dataIndex: 'walletAddress',
            render: (n, row) => (
              <HexLabel hex={row.walletAddress} size={'small'} />
            ),
          },
          {
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '目標錢包地址',
            dataIndex: 'toAddress',
            render: (n, row) => <HexLabel hex={row.toAddress} size={'small'} />,
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: false,
            width: 120,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'right',
            title: '訂單金額',
            dataIndex: 'orderAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '交易創建時間',
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          dateRangeColumn.column,
          // {
          //   title: '操作',
          //   key: '_action',
          //   align: 'center',
          //   fixed: 'right',
          //   valueType: 'option',
          //   width: 100,
          //   render: (text: any, row) =>
          //     // 加入是否通知，如果是以完成通知的狀態要再確認一次
          //     [],
          // },
        ]}
      />
    </>
  );
}
