import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UserStoreAction from '../../stores/user/action';
import { useDispatch } from 'react-redux';

export function LangMenu(props?: {}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Menu
      onClick={(e) => {
        const lang = e.key.toString();
        i18n
          .changeLanguage(lang)
          .then(() => {
            UserStoreAction.updateUserLang(dispatch, { lang });
          })
          .catch((e) => console.error('changeLanguage', e));
      }}
    >
      <Menu.Item key="en">{t('lang.en')}</Menu.Item>
      <Menu.Item key="zh">{t('lang.zh')}</Menu.Item>
      <Menu.Item key="vi">{'Tiếng Việt'}</Menu.Item>
    </Menu>
  );
}
