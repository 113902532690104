import { UsdtUri } from '../../core/configs/services';
import { RequestData } from '@ant-design/pro-table';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { AdminResponse } from '../models/adminResponse';
import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { CreditRecord } from '../../orders/types/creditRecordListResponse';
import { getStorage } from '../../../lib/webStorage';
import _ from 'lodash';

export interface CreditOrder {
  orderId: string;
  orderNo: string;
  tradeCode: string;
  systemId: string;
  status: string;
  depositMode: number;
  transactionHash: string;
  walletAddress: string;
  orderAmount: number;
  fromAmount: number;
  fromCurrency: string;
  exchangeRate: number;
  sourceType: string;
  fee: number;
  token: string;
  channel: string;
  merchantUserId: string;
  actualAmount: string;
  customOrderNo: string;
  customMessage: string;
  notifyResponse: string;
  lastCreditTime: string;
  notifyStatus: string;
  notifyCount: number;
  notifyAt: string;
  createdAt: string;
  paymentUrl: string;
}

export interface AgentCreditReport {
  orderNo: string;
  tradeCode: string;
  systemId: string;
  status: string;
  transactionHash: string;
  walletAddress: string;
  orderAmount: number;
  fee: number;
  vcKind: string;
  merchantOrderNo: string;
  merchantMessage: string;
  createdAt: string;
}

export interface DepositPageQuery {
  page: number;
  limit: number;
  start?: string;
  end?: string;
  txHash?: string;
  status?: string;
  orderNo?: string;
  merchantUserId?: string;
  sourceType?: string;
}

export class DepositOrderProvider {
  static async getAdminCreditOrder(
    params: any,
  ): Promise<RequestData<CreditOrder>> {
    params = _.cloneDeep(params);
    if (!!params.transactionHash) {
      params.txHash = params.transactionHash;
      delete params.transactionHash;
    }
    const rs = await adminRequest.get<AdminListResponse<any>>(
      UsdtUri('admin/payment/deposit-order'),
      {
        params: {
          ...PaginationTransformer.paramTransfer(params),
          childSystemId: params.systemId,
        },
      },
    );
    return PaginationTransformer.responseTransfer(rs.data);
  }

  static async triggerReNotify(params: { systemId: string; orderId: string }) {
    const rs = await adminRequest.post<AdminResponse<CreditOrder>>(
      UsdtUri(`admin/payment/trigger/notify/credit/${params.orderId}`),
      { systemId: params.systemId },
    );
    return rs.data.data;
  }

  static async getCreditOrderList(params: DepositPageQuery) {
    const res = await adminRequest.get<AdminListResponse<CreditRecord>>(
      UsdtUri('/merchant/payment/deposit-order'),
      { params: params },
    );
    return res.data;
  }

  static async getExcel(params: Omit<DepositPageQuery, 'page' | 'limit'>) {
    const res = await adminRequest.get<any>(
      UsdtUri('/merchant/payment/deposit-order/xlsx'),
      { params: params, responseType: 'blob' },
    );
    return res.data;
  }

  static async fixNotMappingCreditOrder(
    params: Pick<CreditOrder, 'orderNo' | 'customOrderNo'>,
  ) {
    const res = await adminRequest.patch<
      AdminResponse<{
        orderNo: string;
        status: string;
        customOrderNo: string;
      }>
    >(
      UsdtUri(
        `/merchant/payment/deposit-order/fix-mappingStatus/${params.orderNo}`,
      ),
      {
        customOrderNo: params.customOrderNo,
      },
    );
    return res.data;
  }

  static async getMerchantCreditOrder(
    params: any,
  ): Promise<RequestData<AgentCreditReport>> {
    const systemId = getStorage('systemId');
    const rs = await adminRequest.get(
      UsdtUri('merchant/payment/deposit-order'),
      {
        params: {
          ...PaginationTransformer.paramTransfer(params),
          childSystemId: params.systemId,
        },
        headers: {
          SYSTEM_ID: systemId,
        },
      },
    );
    return PaginationTransformer.responseTransfer(rs.data);
  }

  static async getDailySummary(param: {
    dataDate: Date;
    channel?: string;
    token?: string;
  }) {
    const res = await adminRequest.get<
      AdminResponse<{
        totalOrderAmount: string;
        orderCount: number;
        totalFee: string;
      }>
    >(UsdtUri('merchant/payment/daily-report/deposit'), {
      params: { ...param },
    });
    return res.data;
  }

  static async changePollingOrder(data: {
    changedOrderNo: string;
    sourceOrderNo: string;
    systemId?: string;
  }) {
    if (!!data.systemId) {
      const res = await adminRequest.patch<AdminResponse<CreditOrder[]>>(
        UsdtUri('/admin/payment/deposit-order/change-order'),
        data,
      );
      return res.data;
    }
    const res = await adminRequest.patch<AdminResponse<CreditOrder[]>>(
      UsdtUri('/merchant/payment/deposit-order/fix-wrong-amount'),
      data,
    );
    return res.data;
  }

  static async getNotifyDetail(orderId: string, isAdmin: boolean) {
    let url: string;
    if (isAdmin) {
      url = UsdtUri(`admin/payment/deposit-order/notify-info/${orderId}`);
    } else {
      url = UsdtUri(`merchant/payment/deposit-order/notify-info/${orderId}`);
    }
    const res = await adminRequest.get<AdminResponse<string>>(url, {});
    return res.data;
  }

  static async checkDepositTxn(param: {
    transactionHash: string;
    channel: string;
    token: string;
  }) {
    await adminRequest.post(UsdtUri('admin/wallet/restore-txn'), param);
    return param;
  }

  static async getDepositTxnTempList(params: { page: number; limit: number }) {
    const res = await adminRequest.get<AdminListResponse<any>>(
      UsdtUri('admin/payment/deposit-txn-temp/getPageList'),
      { params: params },
    );
    return res.data;
  }
}
