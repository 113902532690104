import { Button, Form, Input, Modal, Select } from 'antd';
import { ChannelSelect, TokenSelect } from '../../domain/channel/channel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { DepositOrderProvider } from '../../domain/usdt-providers/depositOrderProvider';

type Props = { visible: boolean; onClose: (txnHash?: string) => void };

export function CheckDepositHashFrom(props: Props) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = React.createRef<FormInstance>();

  const close = (txnHash?: string) => {
    props.onClose(txnHash);
  };

  const submit = async () => {
    setConfirmLoading(true);
    try {
      const value = await formRef.current?.validateFields();
      if (!value) {
        return;
      }
      const data: {
        transactionHash: string;
        channel: string;
        token: string;
      } = value;
      await DepositOrderProvider.checkDepositTxn(data);
      close(data.transactionHash);
    } catch (e) {
      console.error('CheckDepositHashFrom submit', e);
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <>
      <Modal
        visible={props.visible}
        footer={[
          <Button key={2} onClick={() => close()} loading={confirmLoading}>
            {t('common.cancel')}
          </Button>,
          <Button
            key={1}
            type="primary"
            onClick={submit}
            loading={confirmLoading}
          >
            {t('common.confirm')}
          </Button>,
        ]}
        onCancel={() => close()}
        maskClosable={false}
        closable={false}
        destroyOnClose
      >
        <Form ref={formRef} layout="vertical">
          <Form.Item
            name="channel"
            label={t('payment.channel')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select options={ChannelSelect} />
          </Form.Item>
          <Form.Item
            name="token"
            label={t('payment.token')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select options={TokenSelect} />
          </Form.Item>
          <Form.Item
            name="transactionHash"
            label={t('payment.blockchain_hash')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
