import React, { ReactNode } from 'react';
import { Drawer, Space } from 'antd';

type EventType = React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement | HTMLButtonElement>;

interface props {
    zIndex?: number;
    width: number;
    title: string;
    visible: boolean;
    destroyOnClose?: boolean;
    onClose: (e: EventType) => void;
    footer?: React.ReactNode;
    footerbuttons: Array<React.ReactNode>;
    children: ReactNode,
}

const DefaultWidth = 480;

export default function CoreDrawer(props: props) {
    return <Drawer
        {...props}
        width={Math.max(props.width, DefaultWidth)}
        footer={<Space size="small" direction="horizontal">
            {props.footerbuttons.filter(b => !!b)}
        </Space>}
    />
}

CoreDrawer.defaultProps = {
    zIndex: 1000,
    width: DefaultWidth,
    title: '',
    visible: false,
    destroyOnClose: true,
    onClose: (e: EventType) => {},
    footerbuttons: [],
}