import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminListResponse } from '../models/adminListResponse';

export interface WalletNetworkFeeTrackerType {
  systemId: string;
  channel: string;
  token: string;
  address: string;
  type: TrackerEnum;
}

export enum TrackerEnum {
  MCH = 'MCH',
  USER = 'USER',
}

export class WalletNetworkFeeTrackerProvider {
  static async getList(param: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<WalletNetworkFeeTrackerType>>(
      UsdtUri('admin/wallet/network-fee-tracker'),
      {
        params: { ...param },
      },
    );
    return res.data;
  }

  static async add(systemId: string, channel: string, token: string) {
    const res = await adminRequest.post(UsdtUri('admin/wallet/network-fee-tracker'), {
      systemId,
      channel,
      token,
      type: TrackerEnum.MCH,
    });
    return res.data;
  }

  static async delete(systemId: string, channel: string, token: string) {
    const res = await adminRequest.delete(
      UsdtUri(`admin/wallet/network-fee-tracker/${systemId}`),
      {
        params: {
          channel: channel,
          token: token,
        },
      },
    );
    return res.data;
  }

}
