import { History, LocationState, UnregisterCallback } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';

import './core.css';

import modules from './configs/modules';
import template from '../../lib/importModel';
import { getStorage, setStorage } from '../../lib/webStorage';
import defaultMenu, { Menu } from './configs/menus';
import { moduleInit } from './base/moduleInit';
import reduxMerge from '../../stores/merge';
import { IPage, Page } from './configs/types';
import { Environment } from './configs/services';

const reduceObj = modules.reduce(
  (c, m) => ({ ...c, [m.module.moduleIdentity]: m.reducer }),
  {} as any,
);

const setSessionStorageMiddleware = (store: any) => (next: any) => (
  action: any,
) => {
  if (Environment() === 'develop') console.log('start action', action);
  const result = next(action);
  setSessionDb('redux-store', store.getState());
  if (Environment() === 'develop')
    console.log('end action', result, store.getState());
  return result;
};

// const persistReduxFrom

export const buildStore = () => {
  const exist = getSessionDb('redux-store');
  if (!!exist) {
    return createStore(
      combineReducers({
        ...reduxMerge,
        ...reduceObj,
      }),
      exist,
      applyMiddleware(thunkMiddleware, setSessionStorageMiddleware),
    );
  }
  return createStore(
    combineReducers({
      ...reduxMerge,
      ...reduceObj,
    }),
    applyMiddleware(thunkMiddleware, setSessionStorageMiddleware),
  );
};

export const lang: any = { zhCN: zhCN, enUS: enUS };
export const defaultLang = zhCN;

const LOCAL_PERSISTENT = 0;
const SESSION_STORAGE = 1;
export const setLocalDb = (code: string, value: any) =>
  setStorage(code, value, LOCAL_PERSISTENT);
export const getLocalDb = (code: string) => getStorage(code, LOCAL_PERSISTENT);
export const setSessionDb = (code: string, value: any) =>
  setStorage(code, value, SESSION_STORAGE);
export const getSessionDb = (code: string) => getStorage(code, SESSION_STORAGE);

//设置默认语言
if (!getLocalDb('lang')) {
  setLocalDb('lang', 'zhCN');
}

declare global {
  interface Window {
    store: any;
  }
}

window.store = buildStore;

modules.forEach((m) => {
  m.module.bootstrap(buildStore);
});

export const pages: { [id: number]: any } = modules.reduce(
  (c, m) => ({
    ...c,
    ...m.module.pages.reduce((cc, p) => ({ ...cc, [p.id]: p.component }), {}),
  }),
  template || {},
);
export const flattenPages: { [id: number]: IPage } = modules.reduce(
  (c, m) => ({
    ...c,
    ...m.module.pages.reduce((cc, p) => ({ ...cc, [p.id]: p }), {}),
  }),
  {},
);

function processAllMenuItem(module: moduleInit, _menu: Menu) {
  const menu = { ..._menu };
  menu.isPage = menu.menuId instanceof Page;
  if (menu.isPage) {
    const page = menu.menuId as IPage;
    menu.menuId = page.id;
    // if (!page.scopes.some((scope) => module.can(scope))) {
    //   console.warn(`not found this scope menu`, module);
    //   return null;
    // }
  }
  if (menu.children && menu.children.length > 0) {
    menu.children = menu.children.reduce((c: Menu[], m) => {
      const nm = processAllMenuItem(module, m);
      return nm ? [...c, nm] : c;
    }, []);
    
    if (menu.children.length < 1) {
      return null;
    }
  }
  return menu;
}

function processMenuItem(module: moduleInit, _menu: Menu) {
  const menu = { ..._menu };
  menu.isPage = menu.menuId instanceof Page;
  if (menu.isPage) {
    const page = menu.menuId as IPage;
    menu.menuId = page.id;
    if (!page.scopes.some((scope) => module.can(scope))) {
      console.warn(`not found this scope menu`, module);
      return null;
    }
  }
  if (menu.children && menu.children.length > 0) {
    menu.children = menu.children.reduce((c: Menu[], m) => {
      const nm = processMenuItem(module, m);
      return nm ? [...c, nm] : c;
    }, []);

    if (menu.children.length < 1) {
      return null;
    }
  }
  return menu;
}

export const generateMenus = () =>
  modules.reduce(
    (c, _m) =>
      _m.module.menus?.reduce((cc, om) => {
        const m = processMenuItem(_m.module, om);
        if (!m) return cc;
        if (m.parentSymbol) {
          // find parent and append into its children
          cc.some((cm) => {
            if (cm.symbol === m.parentSymbol) {
              cm.children = cm.children ?? [];
              if (m.order) {
                cm.children = [...cm.children, m];
              } else {
                cm.children = [...cm.children, m];
              }
              return true;
            }
            return false;
          });
          return cc;
        }
        // just append into menus
        if (m.order) {
          return [...cc, m];
        }
        return [...cc, m];
      }, c) ?? c,
    defaultMenu,
  );


export const generateAllMenus = () =>
  modules.reduce(
    (c, _m) =>
      _m.module.menus?.reduce((cc, om) => {
        const m = processAllMenuItem(_m.module, om);
        if (!m) return cc;
        if (m.parentSymbol) {
          // find parent and append into its children
          cc.some((cm) => {
            if (cm.symbol === m.parentSymbol) {
              cm.children = cm.children ?? [];
              if (m.order) {
                cm.children = [...cm.children, m];
              } else {
                cm.children = [...cm.children, m];
              }
              return true;
            }
            return false;
          });
          return cc;
        }
        // just append into menus
        if (m.order) {
          return [...cc, m];
        }
        return [...cc, m];
      }, c) ?? c,
    defaultMenu,
  );

export const hookHistory = (
  history: History<LocationState>,
  fn: () => void,
): UnregisterCallback => {
  return history.listen((location, action) => {
    // console.log('hhhh', location, action);
    // scopes[(location.state as any).children]
    fn();
  });
};
