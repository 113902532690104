const env = require('../../../config');
const processUri = (host: string, path: string) => {
  const slashHost = host.endsWith('/');
  const slashPath = path.startsWith('/');
  if (slashHost && slashPath) {
    return `${host}${path.substr(1)}`;
  }
  if (!slashHost && !slashPath) {
    return `${host}/${path}`;
  }
  return `${host}${path}`;
};
export const UsdtUri = (uri: string) => processUri(env.USDT_SERVICE, uri);
export const AdminUri = (uri: string) => processUri(env.ADMIN_SERVICE, uri);
export const ReportUri = (uri: string) => processUri(env.REPORT_SERVICE, uri);
export const TwoFactoryOpen = (): boolean => env.TWO_FACTORY_VALIDATE;
export const Environment = (): string => env.ENV;
