import { getStorage } from '../../../lib/webStorage';
import { moduleInit } from '../base/moduleInit';
import UserStoreAction from '../../../stores/user/action';
import { generateMenus } from '../bootstrap';
import MenuStoreAction from '../../../stores/menu/action';

const decodeToken = (token: string) => atob(token.split('.')[1]);

export interface UserClaims {
  sub: string;
  role: string;
  scopes: string;
  exp: number;
}

export const defaultUserClaims: UserClaims = {
  role: "",
  scopes: '',
  sub: '',
  exp: 0
};

const worker = new window.Worker(
  window.URL.createObjectURL(
    new Blob(
      [
        `onmessage = function (oEvent) {
            console.log(oEvent);
            setTimeout(function () { postMessage(Object.assign({}, oEvent.data, { end: Date.now() })); }, oEvent.data.duration); }`,
      ],
      { type: 'application/javascript' },
    ),
  ),
);

class Authorize {
  private modules: Array<moduleInit> = [];
  private claims: UserClaims = defaultUserClaims;

  private static getClaims(): UserClaims {
    try {
      const token = getStorage('token');
      return JSON.parse(decodeToken(token));
    } catch {
      return defaultUserClaims;
    }
  }

  public registerModule(_module: moduleInit) {
    this.claims = Authorize.getClaims();
    this.modules.push(_module);
    this.process();
  }

  public async authRefresh(dispatch: any) {
    this.claims = Authorize.getClaims();
    this.process();
    await UserStoreAction.refreshToken(dispatch);
    const treeMenu = generateMenus();
    MenuStoreAction.reset(dispatch, treeMenu);
  }

  public autoAuthRefresh(dispatch: any) {
    this.claims = Authorize.getClaims();
    this.process();

    // 持續登入
    if (worker && this.claims.exp > 0) {
      const duration = Math.max(
        this.claims.exp * 1000 - Date.now() - 60000,
        60000,
      );
      console.info(
        `send message to worker: duration (${duration})`,
        this.claims.exp,
      );
      worker.postMessage({
        duration: duration,
        start: Date.now(),
      });
      worker.onmessage = async (event) => {
        console.info(`receive message from worker`, event);
        // refresh token and menu
        await UserStoreAction.refreshToken(dispatch);
        this.autoAuthRefresh(dispatch);
      };
    }
  }

  private process() {
    if (this.claims.exp === defaultUserClaims.exp) return;

    // 1. check claims exp is expired?
    if (Date.now() > this.claims.exp * 1000) {
      window.sessionStorage.clear();
      window.location.href = '/';
      this.claims = defaultUserClaims;
    }

    // 2. check scope
    this.modules = this.modules.map((_module) => {
      _module.can = (scope, module = undefined) =>
        (typeof scope === 'string' && scope === '*') ||
        !(typeof scope === 'string' ? [scope] : scope).some(
          () =>
            !new RegExp(
              `${module ?? _module.moduleIdentity}:(.+,)?${scope}([,;]|$)`,
            ).test(this.claims.scopes),
        );
      _module.claims = () => this.claims;
      return _module;
    });
  }
}

const authorize = new Authorize();
export default authorize;
