const defaultState = {};

// eslint-disable-next-line
export default (
  state = defaultState,
  action: { type: string; payload: { data: any; updatedAt: number } },
) => {
  switch (action.type) {
    default:
      return state;
  }
};
