import { AdminListResponse } from '../models/adminListResponse';

export interface PayforRecord {
  orderId: string;
  orderNo: string;
  tradeCode: string;
  systemId: string;
  status: string;
  transactionHash: string;
  walletAddress: string;
  orderAmount: string;
  fee: string;
  token: string;
  channel: string;
  customOrderNo: string;
  customMessage: string;
  fromAmount: number;
  fromCurrency: string;
  exchangeRate: number;
  notifyStatus: string;
  notifyCount: number;
  notifyAt: Date;
  notifyResponse: {
    code?: string;
    data?: string;
  };
  createdAt: string;
  failReason?: string;
}

export interface PayforOrderTypes extends AdminListResponse<PayforRecord> {}

export class PayforOrderStatus {
  static OrderStatus = {
    Draft: 'Draft',
    Pending: 'Pending',
    Success: 'Success',
    Fail: 'Fail',
  };

  static NotifyStatusTypes = {
    NotNotify: 'NotNotify',
    // retry notify client
    Notifying: 'Notifying',
    // notify client success
    NotifySuccess: 'NotifySuccess',
  };
}
