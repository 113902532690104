import React from 'react';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Endpoint } from '../types/endpoint';
import { Button, Space } from 'antd';

// 取得所有模組及權限 供選擇

// const form = () => {}

// eslint-disable-next-line
export default () => {
    const data: Array<Endpoint> = [
        {
            id: 1,
            name: 'FETCH_PERMISSION',
            moduleIdentity: 'PERMISSIONS',
            scope: 'READ_PERMISSIONS',
            urlPattern: '',
            rewriteUrl: '',
        },
    ];
    const columns: ProColumns[] = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            align: 'center',
        },
        {
            key: 'moduleIdentity',
            dataIndex: 'moduleIdentity',
            title: 'moduleIdentity',
            align: 'center',
        },
        {
            key: 'scope',
            dataIndex: 'scope',
            title: 'scope',
            align: 'center',
        },
        {
            key: 'urlPattern',
            dataIndex: 'urlPattern',
            title: 'urlPattern',
            align: 'center',
        },
        {
            key: 'operators',
            title: 'Operator',
            align: 'center',
            render: dom => (<Space>
                <Button type="primary">編輯</Button>
                <Button danger>刪除</Button>
            </Space>)
        }
    ];
    return (<>
        <ProTable
            dataSource={data}
            columns={columns}
        />
    </>);
}
