import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { refreshSystemListState } from '../../../../stores/system-list/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../../stores/merge';
import { UserStore } from '../../../../stores/user';

// eslint-disable-next-line
export default (props: {
  onChange: (value: string) => any;
  systemId?: string;
}) => {
  const dispatch = useDispatch();
  // first init data
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);

  const [system, setSystem] = useState<string | undefined>(props.systemId!);
  const systems = useSelector((state) => (state as any).systemList);
  const Option = Select.Option;
  useEffect(() => {
    setSystem(props?.systemId);
  }, [props.systemId]);

  useEffect(() => {
    dispatch(refreshSystemListState({ systemId: user.systemId, force: false }));
  });

  return (
    <Select
      showSearch
      allowClear
      onChange={(value) => {
        const sys = value as string;
        setSystem(sys);
        props.onChange(sys);
      }}
      value={system}
      placeholder="请输入"
    >
      {systems?.systems.map((x: any) => (
        <Option key={x.systemId} value={x.systemId}>
          {x.systemId}
        </Option>
      ))}
    </Select>
  );
};
