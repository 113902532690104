import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import UserInfo from './userInfo';

import './index.less';
import TapMenu from './tapMenuNew';
import { generateAllMenus } from '../../modules/core/bootstrap';

interface props extends RouteComponentProps {
  menuList: any;
  dispatch: any;
}

let countClick = 0;

class Header extends React.Component<props> {
  render() {
    return (
      <div className="admin-header">
        <div
          className="admin-header-info"
          onClick={() => {
            countClick++;
            if (countClick > 5) {
              console.log(generateAllMenus());
            }
          }}
        >
          {/*<Marquee />*/}
          <UserInfo />
        </div>
        <TapMenu />
      </div>
    );
  }
}

function headerState(state: any) {
  return { menuList: state.menu };
}

export default withRouter(connect(headerState)(Header));
