import { moduleInit } from '../../core/base/moduleInit';
import { IPage, Page } from '../../core/configs/types';
import React from 'react';
import { READ_MCH_WALLET, READ_MCH_WALLET_TXN, READ_USER_WALLET, READ_USER_WALLET_TXN } from './scopes';
import MchWalletList from '../pages/MchWalletPage';
import UserWalletList from '../pages/UserWalletPage';
import AdminMchWalletChangeLogTable from '../../domain/components/change-log/AdminMchWalletChangeLogTable';
import AdminUserWalletChangeLogTable from '../../domain/components/change-log/AdminUserWalletChangeLogTable';

export const ModuleName = 'MerchantWalletManager';
export const ModuleIdentity = 'MCH-WALLET-MANAGER';
// ModuleIdentity => 需等於權限管理的模組代號
const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

const pages: IPage[] = Page.createInstances([
  {
    id: 90001,
    uri: 'AdminMchWalletList',
    component: () => <MchWalletList title="商戶錢包查詢" subTitle="" />,
    scopes: [READ_MCH_WALLET],
  },
  {
    id: 90002,
    uri: 'AdminUserWalletList',
    component: () => <UserWalletList title="用戶錢包查詢" subTitle="" />,
    scopes: [READ_USER_WALLET],
  },
  {
    id: 90003,
    uri: 'AdminMchWalletChangeLog',
    component: () => <AdminMchWalletChangeLogTable isAdmin={true} />,
    scopes: [READ_MCH_WALLET_TXN],
  },
  {
    id: 90004,
    uri: 'AdminUserWalletTxnList',
    component: () => <AdminUserWalletChangeLogTable isAdmin={true} />,
    scopes: [READ_USER_WALLET_TXN],
  },
]);

_module.addPages(pages);
_module.setMenu([
  {
    symbol: Symbol(),
    label: '商戶錢包管理',
    menuId: 90,
    order: 90,
    iconId: 18,
    i18nLabel: { en_US: 'Wallet Management', zh_CN: '錢包管理',vi_VN:'錢包管理' },
    children: [
      {
        label: '商戶錢包查詢',
        menuId: pages[0],
        i18nLabel: { en_US: 'Merchant Wallet List', zh_CN: '商戶錢包查詢',vi_VN:'商戶錢包查詢' },
      },
      {
        label: '用戶錢包查詢',
        menuId: pages[1],
        i18nLabel: { en_US: 'User Wallet List', zh_CN: '用戶錢包查詢',vi_VN:'用戶錢包查詢' },
      },
      {
        label: '商戶錢包帳變',
        menuId: pages[2],
        i18nLabel: {
          en_US: 'Merchant Wallet Change Log',
          zh_CN: '商戶錢包帳變',vi_VN:'商戶錢包帳變'
        },
      },
      {
        label: '用戶錢包帳變',
        menuId: pages[3],
        i18nLabel: {
          en_US: 'Merchant User Wallet txn List',
          zh_CN: '用戶錢包帳變',vi_VN:'用戶錢包帳變'
        },
      },
    ],
  },
]);
