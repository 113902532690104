import adminRequest from '../../../config/adminRequest';
import { AdminResponse } from '../models/adminResponse';
import { UsdtUri } from '../../core/configs/services';

export interface TaskInfo {
  txHash: string;
  status: string;
}

export class TransferTaskProvider {
  static async getTaskList(orderId: string) {
    const res = await adminRequest.get<AdminResponse<TaskInfo[]>>(
      UsdtUri('vc-wallet-txn'),
      { params: { orderId: orderId } },
    );
    return res.data;
  }
}
