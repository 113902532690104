import CoreDrawer from '../../core/components/CoreDrawer';
import { Button, Form, Input, Spin, Transfer } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import { Group } from '../../permissions/types';
import { AdminUserGroupProvider } from '../../domain/admin-providers/adminUserGroupProvider';
import { AdminUserRoleProvider, Role } from '../../domain/admin-providers/adminUserRoleProvider';

const { TextArea } = Input;

export type GroupFormProps = {
  group?: Group;
  visible: boolean;
  isEdit: boolean;
};

export class CloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: CloseEvent.symbol,
      payload: undefined,
      type: CloseEvent.name,
    });
  }
}

export default function GroupForm(props: GroupFormProps) {
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<{
    isEdit: boolean;
    group?: Group;
  }>({
    isEdit: props.isEdit,
    group: undefined,
  });
  const [roleSelectTransfer, setRoleSelectTransfer] = useState<Array<Role>>([]);
  const [roleList, setRoleList] = useState<Array<Role>>([]);
  const [curRoleList, setCurRoleList] = useState<Array<Role>>([]);
  const close = () => {
    EventProvider.addEvents(new CloseEvent());
  };
  const refreshGroupData = async (groupId: number) => {
    const res = await AdminUserGroupProvider.getOne(groupId);
    setFormState({ ...formState, group: res.data, isEdit: true });
    setCurRoleList(res.data.roles);
  };
  const save = () => {
    formRef.current?.validateFields().then((values: any) => {
      if (formState.isEdit && !!formState.group) {
        // 編輯
        const payload: Partial<Group> = {
          id: formState.group?.id,
          name: values.name,
          displayName: values.displayName,
          description: values.description,
          roles: curRoleList,
        };
        AdminUserGroupProvider.edit(formState.group?.id, payload).then((rs: any) => {
          console.log('after update: ', rs);
          close();
        });
      } else {
        // 新增
        const payload: Partial<Group> = {
          name: values.name,
          displayName: values.displayName,
          description: values.description,
        };
        AdminUserGroupProvider.create(payload).then(async (rs) => {
          console.log('after create', rs.data);
          // NOTE: save and continue editing
          await refreshGroupData(rs.data.id);

        });
      }
    });
  };

  useEffect(() => {
    setFormState({ group: props.group, isEdit: props.isEdit });
    if (!props.visible) {
      return;
    }
    const pArr: Promise<any>[] = [];
    pArr.push(AdminUserRoleProvider.getAll().then((roles) => setRoleList(roles)));
    if (!!props.group?.id) {
      pArr.push(refreshGroupData(props.group.id));
    }
    setLoading(true);
    Promise.all(pArr)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.group, props.visible]);

  useEffect(() => {
    if (!formState.group || !formRef.current) {
      return;
    }
    const group = formState.group;
    formRef.current?.setFieldsValue({
      name: group.name,
      displayName: group.displayName,
      description: group.description,
    });
  }, [formState, formRef]);

  return (
    <CoreDrawer
      width={640}
      title={formState.isEdit ? `編輯群組(id: ${formState.group?.id})` : `新增群組`}
      visible={props.visible}
      onClose={() => close()}
      footerbuttons={[
        <Button
          key={1}
          type='primary'
          onClick={() => save()}
        >
          {formState.isEdit ? '儲存' : '新增並開始設定角色'}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form ref={formRef} layout='vertical' name='role_form'>
          <Form.Item
            name='name'
            label='群組代碼'
            rules={[{ required: true, message: '請輸入群組代碼' }]}
          >
            <Input allowClear bordered={!formState.isEdit} readOnly={formState.isEdit} />
          </Form.Item>
          <Form.Item
            name='displayName'
            label='群組顯示名稱'
            rules={[{ required: true, message: '請輸入群組顯示名稱' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item name='description' label='群組說明'>
            <TextArea allowClear />
          </Form.Item>
          {(formState.isEdit) && (
            <Form.Item label='指定角色'>
              <Transfer
                dataSource={roleList.map((role) => ({
                  key: role.id.toString(),
                  title: role.name,
                }))}
                listStyle={{
                  width: 250,
                  height: 300,
                }}
                titles={['尚未指定', '已經指定']}
                className='tree-transfer'
                targetKeys={curRoleList.map((x) => x.id.toString())}
                selectedKeys={roleSelectTransfer.map((x) =>
                  x.id.toString(),
                )}
                render={(item) => item.title ?? ''}
                onChange={(keys) => {
                  setCurRoleList(
                    keys.map(
                      (k) =>
                        roleList.filter(
                          (role) => role.id?.toString() === k,
                        )[0],
                    ),
                  );
                }}
                onSelectChange={(selectKeys, targetKeys) => {
                  setRoleSelectTransfer(
                    [...selectKeys, ...targetKeys].map(
                      (k) =>
                        roleList.filter(
                          (role) => role.id?.toString() === k,
                        )[0],
                    ),
                  );
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </CoreDrawer>
  );
}

