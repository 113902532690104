import { Card, Form, InputNumber, Radio } from 'antd';
import React from 'react';
import { FeeSetting } from '../../usdt-providers/systemProvider';
import * as _ from 'lodash';
import TransferModeSettingFrom from './TransferModeSettingFrom';
import { NetworkSetting } from '../../usdt-providers/payforSettingProvider';

const PayforTokenSettingForm = (props: {
  channel: string;
  data: any;
  feeRange: FeeSetting;
  networkFeeSetting: NetworkSetting;
}) => {

  return (
    <>
      {
        _.map(props.data, (tokenObj, token) => {
          let max = 1;
          let min = 0;
          _.map(props.feeRange, (feeChannelObj, channel) => {
            if (props.channel === channel) {
              _.map(feeChannelObj, (tokenObj, feeToken) => {
                if (feeToken === token) {
                  max = tokenObj.max;
                  min = tokenObj.min;
                }
              });
            }
          });

          if (tokenObj.active) {
            return (

              <Card key={props.channel + '_' + token} title={token + '設定'} style={{ marginBottom: 16 }}>

                <Form.Item
                  key={props.channel + '_' + token + '_feeRate'}
                  name={props.channel + '_' + token + '_feeRate'}
                  label='代付費率'
                  rules={[{ required: tokenObj.payoutOption?.indexOf('rate') > -1, message: '請輸入 `代付費率`' }]}
                  initialValue={tokenObj.feeRate}
                >
                  <InputNumber
                    precision={4}
                    min={min}
                    max={max}
                    defaultValue={tokenObj.feeRate}
                    step={0.001}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_payoutFee'}
                  name={props.channel + '_' + token + '_payoutFee'}
                  label='代付費(单笔)'
                  rules={[{ required: tokenObj.payoutOption?.indexOf('fee') > -1, message: '請輸入 `代付費`' }]}
                  initialValue={tokenObj.payoutFee}
                >
                  <InputNumber
                    precision={4}
                    min={0}
                    defaultValue={tokenObj.payoutFee}
                    step={1}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_payoutOption'}
                  name={props.channel + '_' + token + '_payoutOption'}
                  label="费率模式"
                  initialValue={tokenObj.payoutOption}>
                  <Radio.Group
                    options={[
                      { label: '单笔', value: 'fee' },
                      { label: '費率', value: 'rate' },
                      { label: '单笔+費率', value: 'fee+rate' },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                    defaultValue={tokenObj.payoutOption}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_minimumAmount'}
                  name={props.channel + '_' + token + '_minimumAmount'}
                  label='最小代付金額'
                  rules={[{ required: true, message: '請輸入 `最小代付金額`' }]}
                  initialValue={tokenObj.minimumAmount}
                >
                  <InputNumber precision={6} defaultValue={tokenObj.minimumAmount} min={0} step={1} />
                </Form.Item>
                <TransferModeSettingFrom
                  transferMode={props.networkFeeSetting[props.channel][token].transferMode}
                  staticFee ={props.networkFeeSetting[props.channel][token].staticFee}
                  channel={props.channel}
                  token={token}/>
              </Card>);
          }
        })
      }
    </>
  );
};

export default PayforTokenSettingForm;

