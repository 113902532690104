import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProCoreActionType } from '@ant-design/pro-utils';
import _module from '../configs/module';
import { FormInstance } from 'antd/lib/form';
import { DELETE_MERCHANT_GROUPS, WRITE_MERCHANT_GROUPS } from '../configs/scopes';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { Group, MchGroupProvider } from '../../domain/admin-providers/mchGroupProvider';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import GroupFrom, { CloseEvent, GroupFormProps } from '../components/GroupFrom';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { useTranslation } from 'react-i18next';

interface PageOp extends CoreProTableState<Group> {
}

const defualFormState: GroupFormProps = {
  isEdit: false,
  visible: false,
};

// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [formState, setFormState] = useState<GroupFormProps>(defualFormState);
  const loggger = useOperatorLogger();

  const refresh = () => {
    pageOp.update((s) => ({ ...s, needRequest: true }));
    tableActionRef.current?.reload();
  };

  const deleteRow = (record: Group) => {
    Modal.confirm({
      title: `刪除?`,
      content: `確認刪除 ${record?.displayName} (編號: ${record?.id})?`,
      icon: <ExclamationCircleOutlined />,
      okText: '確認',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        MchGroupProvider.delete(record!.id)
          .then((rs) => {
            loggger.log({
              action: '刪除',
              payload: { req: record, res: rs.data },
            });
            refresh();
          })
          .catch((e) => {
            loggger.log({ action: '刪除', payload: { req: record, res: e } });
          });
      },
    });
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === CloseEvent.symbol))
      .subscribe(() => {
        setFormState(defualFormState);
        refresh();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader
        title={t('mch_permission.GroupList')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<Group>
        // manualRequest={!systemId}
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={tableActionRef as any}
        rowKey='id'
        columns={[
          {
            width: 50,
            hideInSearch: true,
            title: '群組編號',
            dataIndex: 'id',
            fixed: 'left',
            align: 'center',
          },
          {
            width: 100,
            title: '群組代碼',
            dataIndex: 'name',
            fixed: 'left',
          },
          {
            width: 100,
            title: '群組名稱',
            dataIndex: 'displayName',
            fixed: 'left',
            fieldProps: {
              extra: '(關鍵字查詢)',
            },
          },
          {
            width: 100,
            hideInSearch: true,
            title: '群組說明',
            dataIndex: 'description',
            fixed: 'left',
          },
          {
            width: 100,
            hideInSearch: true,
            key: 'operation',
            title: '操作',
            fixed: 'right',
            align: 'center',
            render: (text, record) =>
              <Space>
                <Button
                  size={'small'}
                  key='editable'
                  disabled={!_module.can([WRITE_MERCHANT_GROUPS])}
                  onClick={(x) => {
                    setFormState({
                      ...formState,
                      isEdit: true,
                      record: record,
                      visible: true,
                    });
                  }}
                >
                  編輯
                </Button>
                <Button
                  size={'small'}
                  key='delete'
                  disabled={!_module.can([DELETE_MERCHANT_GROUPS])}
                  danger={_module.can([DELETE_MERCHANT_GROUPS])}
                  onClick={(x) => {
                    deleteRow(record);
                  }}
                >
                  刪除
                </Button>
              </Space>,
          },
        ]}
        toolBarRender={() => [
          _module.canRender(
            WRITE_MERCHANT_GROUPS,
            <Button
              key={1}
              type='primary'
              onClick={() =>
                setFormState({ isEdit: false, record: undefined, visible: true })
              }
            >
              新增群組
            </Button>),
        ]}
        requestData={(params) =>
          MchGroupProvider.getList(PaginationTransformer.paramTransfer(params)).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params },
            });
            return PaginationTransformer.responseTransfer(rs);
          })
        }
      />
      <GroupFrom {...formState} />
    </>
  );
};
