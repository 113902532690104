import React, { useEffect, useRef, useState } from 'react';
import { Observable } from 'rxjs';
import { ExchangeRateProvider } from '../domain/usdt-providers/exchangeRateProvider';
import moment from 'moment';
import { Line, LineOptions } from '@antv/g2plot';
import { PageHeader } from 'antd';
import { transfer2LocalMinTime } from '../core/utils/timeFormat';
import { useTranslation } from 'react-i18next';

const config: LineOptions = {
  data: [],
  padding: 'auto',
  xField: 'time',
  yField: 'avgBuyingPrice',
  xAxis: {
    type: 'cat',
    label: {
      autoRotate: false,
      formatter: (v) => {
        return transfer2LocalMinTime(v);
      },
    },
  },
  yAxis: {
    minLimit: 1300,
  },
};

export default function CurrentUsdt2EthChart(props: {}) {
  // const { t } = useTranslation();
  const ref = useRef<any>();
  const chartRef = useRef<Line>();
  const [refreshEvent] = useState(
    new Observable<Date>((subscriber) => {
      subscriber.next(new Date());
      setTimeout(() => {
        subscriber.next(new Date());
      }, 60000);
    }),
  );
  const { t } = useTranslation();
  useEffect(() => {
    refreshEvent.subscribe(async (value) => {
      const start = moment().subtract(7, 'day').startOf('day').toDate();
      const end = moment().endOf('minute').toDate();
      const res = await ExchangeRateProvider.getRawList({
        from: 'USDT',
        to: 'ETH',
        start,
        end,
      });
      chartRef.current?.changeData(res.data);
    });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const line = new Line(ref.current, config);
    chartRef.current = line;
    line.render();
  }, []);

  return (
    <div>
      <PageHeader title={t('payment.usdt_to_eth_exchange_rate')} subTitle="" extra={<></>} />
      <div ref={ref} />
    </div>
  );
}
