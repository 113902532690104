import ProDescriptions from '@ant-design/pro-descriptions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreditOrder,
  DepositOrderProvider,
} from '../../usdt-providers/depositOrderProvider';
import {
  useCreditStatusTranslate,
  useNotifyStatusTranslate,
} from '../../order-status/orderStatusTranslateHook';
import { transfer2LocalTime } from '../../../core/utils/timeFormat';

export default function DepositOrderDetail(props: {
  order: CreditOrder;
  isAdmin: boolean;
}) {
  const { t } = useTranslation();
  const creditStatusEnum = useCreditStatusTranslate();
  const notifyStatusEnum = useNotifyStatusTranslate();
  const order = props.order;
  const [notifyRes, setNotifyRes] = useState<string>('');
  useEffect(() => {
    const orderId = props.order.orderId;
    const isAdmin = props.isAdmin;
    DepositOrderProvider.getNotifyDetail(orderId, isAdmin).then((res) => {
      const data = JSON.parse(res.data);
      const notifyDetail = {
        req: IsJsonString(data.req) ? JSON.parse(data.req) : data.req,
        res: IsJsonString(data.res) ? JSON.parse(data.res) : data.res,
        notifyTime: data.notifyTime,
      };
      setNotifyRes(JSON.stringify(notifyDetail));
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ProDescriptions
        column={2}
        title={t('credit.deposit_order')}
        dataSource={{
          ...order,
          notifyResponse: notifyRes,
        }}
      >
        <ProDescriptions.Item
          label={t('payment.order_no')}
          span={2}
          dataIndex={'orderNo'}
        />
        {props.isAdmin && (
          <ProDescriptions.Item
            label={t('payment.system_id')}
            dataIndex={'systemId'}
          />
        )}
        <ProDescriptions.Item
          label={t('payment.mch_custom_order_no')}
          dataIndex={'customOrderNo'}
        />
        <ProDescriptions.Item
          label={t('payment.mch_custom_message')}
          dataIndex={'customMessage'}
        />
        <ProDescriptions.Item
          label={t('payment.mch_user_id')}
          dataIndex={'merchantUserId'}
        />
        <ProDescriptions.Item
          label={t('payment.order_status')}
          valueEnum={creditStatusEnum}
          dataIndex={'status'}
        />
        <ProDescriptions.Item
          label={t('payment.order_notify_status')}
          valueEnum={notifyStatusEnum}
          dataIndex={'notifyStatus'}
        />
        <ProDescriptions.Item
          label={t('payment.order_amount')}
          dataIndex={'orderAmount'}
        />
        <ProDescriptions.Item
          label={t('payment.actual_amount')}
          dataIndex={'actualAmount'}
        />
        <ProDescriptions.Item
          label={t('credit.from_amount')}
          dataIndex={'fromAmount'}
        />
        <ProDescriptions.Item
          label={t('credit.from_currency')}
          dataIndex={'fromCurrency'}
        />
        <ProDescriptions.Item
          label={t('credit.exchange_rate')}
          dataIndex={'exchangeRate'}
        />
        <ProDescriptions.Item label={t('payment.fee')} dataIndex={'fee'} />
        <ProDescriptions.Item label={t('common.createdAt')}>
          {transfer2LocalTime(new Date(order.createdAt))}
        </ProDescriptions.Item>
        <ProDescriptions.Item
          span={2}
          label={t('payment.blockchain_hash')}
          dataIndex={'transactionHash'}
        />
        {props.isAdmin && (
          <>
            <ProDescriptions.Item
              span={2}
              valueType={'jsonCode'}
              label={t('payment.notify_info')}
              dataIndex={'notifyResponse'}
              contentStyle={{ height: '200px', overflowX: 'auto' }}
            />
          </>
        )}
      </ProDescriptions>
    </>
  );
}

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
