import ProDescriptions from '@ant-design/pro-descriptions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Log } from '../../admin-providers/logProvider';

export default function ApiLogDetail(props: { log: Log; payload: string; title: string }) {
  const { t } = useTranslation();
  const log = props.log;
  log.payload = props.payload
  if (props.log.systemId === 'NULL') {
    log.systemId = '';
  }

  return (
    <>
      <ProDescriptions
        column={1}
        title={props.title}
        dataSource={log}
        layout={'vertical'}
      >
        <ProDescriptions.Item
          label={t('payment.system_id')}
          dataIndex={'systemId'}
        />
        <ProDescriptions.Item
          label={t('api_log.payload')}
          valueType={'jsonCode'}
          dataIndex={'payload'}
          contentStyle={{ height: '400px', overflowX: 'auto' }}
        />
      </ProDescriptions>
    </>
  );
}
