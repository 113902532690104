import { Card, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { DomainProvider } from '../../usdt-providers/domainProvider';
import { ProFormSwitch } from '@ant-design/pro-form';

const CreditSettingFrom = (props: {
  notifyChannelFlag: boolean;
  notifyTokenFlag: boolean;
}) => {

  const [domainList, setDomainList] = useState<{ label: string; value: any }[]>(
    [],
  );

  useEffect(() => {
    DomainProvider.getDomain({
      params: { limit: 100, page: 1 },
    }).then((rs) => {
      setDomainList(
        rs.data?.items?.map((x) => ({
          label: x.name,
          value: x.domain,
        })),
      );
    });
  }, []);

  return (
    <>
      <Form.Item
        name='creditOrderDomain'
        label='網域'
        rules={[{ required: true, message: '請選擇 `網域`' }]}
      >
        <Select showSearch options={domainList} />
      </Form.Item>
      <ProFormSwitch
        name='autoMatchPolling'
        label='自動匹配'
        tooltip='自動匹配功能'
      />
      <Card title='版本設定' style={{ marginBottom: 16 }}>
        <ProFormSwitch
          disabled={props.notifyChannelFlag}
          name='notifyChannelFlag'
          label='新版充值通知'
          tooltip={'舊版充值通知已棄用請協助商戶調整'}
        />
        <ProFormSwitch
          disabled={props.notifyTokenFlag}
          name='notifyTokenFlag'
          label='回調包含代幣(Token)'
          tooltip={'請協助商戶開啟'}
        />
      </Card>
    </>
  );
};

export default CreditSettingFrom;
