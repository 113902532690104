import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri } from '../../core/configs/services';

export interface RevenueItem {
  reportDate: Date,
  systemId: string,
  agentType: string,
  depositAmount: number,
  depositFee: number,
  payoutAmount: number,
  payoutFee: number,
  totalFee: number,
  payforUsdtFee: number,
  commissionAmount: number,
  profitAmount: number,
  createdAt: Date,
  updatedAt: Date,
}
export interface AgentRefundItem {
  reportDate: Date,
  systemId: string,
  parentSystem: string,
  profitAmount: number,
}
export interface TotalItem {
  overallFee: number,
  overallCommissionAmount: number,
  overallProfitAmount: number,
}
export class NetworkFeeDepositExpendProvider {
  static async getPageList(params: {
    startDate: Date;
    endDate: Date;
    systemId?: string;
    token?: string;
  }) {
    const res = await adminRequest.get<AdminListResponse<any>>(
      ReportUri('/network-fee/daily'),
      { params: params },
    );
    return res.data;
  }
}
