import { PaginationQuery } from '../../core/utils/paginationTransformer';
import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminListResponse } from '../models/adminListResponse';
import { BoSignHelper } from '../../../core/providers/helper/boSign-helper';

export type AbandonWallet = {
  walletAddress: string;
  channel: string;
  token: string;
  systemId: string;
  available: boolean;
  txnBalance: number | string;
  availableBalance: number | string;
};

export type AbandonWalletChangeLog = {
  txnId: string;
  txnHash: string;
  walletAddress: string;
  systemId: string;
  orderNo: string;
  channel: string;
  token: string;
  availableAmount: number;
  txnAmount: number;
  availableBeforeBalance: number;
  availableAfterBalance: number;
  txnBeforeBalance: number;
  txnAfterBalance: number;
  comment: string;
  createdBy: string;
};

export type TransferReq = {
  token: string;
  channel: string;
  destAddress: string;
  orderAmount: number;
  walletAddress: string;
  operatorId: string;
  systemId: string;
  code: number;
};

export type AbandonWalletTransferOrder = {
  orderId: string;
  orderNo: string;
  systemId: string;
  status: string;
  transactionHash: string;
  transferTaskId: string;
  walletAddress: string;
  toAddress: string;
  orderAmount: number;
  channel: string;
  token: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
};

export class AbandonWalletProvider {
  static async getList(param: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<AbandonWallet>>(
      UsdtUri('admin/abandon/wallet'),
      { params: param },
    );
    return res.data;
  }

  static async getChangeLogList(param: PaginationQuery) {
    const res = await adminRequest.get<
      AdminListResponse<AbandonWalletChangeLog>
    >(UsdtUri('admin/abandon/wallet-txn'), { params: param });
    return res.data;
  }

  static async getTransferOrderList(param: PaginationQuery) {
    const res = await adminRequest.get<
      AdminListResponse<AbandonWalletTransferOrder>
    >(UsdtUri('admin/abandon/order'), { params: param });
    return res.data;
  }

  static async transfer(data: TransferReq) {
    const boSign = BoSignHelper.generate(data);
    const res = await adminRequest.post(
      UsdtUri('admin/abandon/wallet/transfer'),
      {
        ...data,
        boSign,
      },
    );
    return res.data;
  }
}
