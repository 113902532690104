import { moduleInit } from '../../core/base/moduleInit';
import { Page } from '../../core/configs/types';
import React from 'react';
import RptChart from '../pages/Revenue';
import DepositOrderSuccessRate from '../pages/DepositOrderSuccessRate';
import MchDailyIncome from '../pages/DailyIncome';
import MchTotalUsdtChange from '../pages/TotalUsdtChange';
import DailyAdvanceFund from '../pages/DailyAdvanceFund';
import DailyAgentRefund from '../pages/DailyAgentRefund';
import NetworkFeeDepositExpend from '../pages/NetworkFeeDepositExpend';


export const ModuleName = 'REPORT-MANAGE';
export const ModuleIdentity = 'REPORT-MANAGE';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_USDT_REPORT = Symbol();

const Scope = {
  READ_REPORT: 'READ_REPORT',
  AGENT_READ_REPORT: 'AGENT_READ_REPORT',
};

const DailyIncomePage = Page.createInstance({
  id: 50001,
  uri: '',
  component: () => <MchDailyIncome />,
  scopes: [Scope.READ_REPORT, Scope.AGENT_READ_REPORT],
  onClose: () => {
  },
});
const TotalUsdtChangePage = Page.createInstance({
  id: 50002,
  uri: '',
  component: () => <MchTotalUsdtChange />,
  scopes: [Scope.READ_REPORT, Scope.AGENT_READ_REPORT],
  onClose: () => {
  },
});
const RptChartPage = Page.createInstance({
  id: 50003,
  uri: '',
  component: () => <RptChart />,
  scopes: [Scope.READ_REPORT],
  onClose: () => {
  },
});
const DailyDepositOrderSuccessRatePage = Page.createInstance({
  id: 50004,
  uri: '',
  component: () => <DepositOrderSuccessRate />,
  scopes: [Scope.READ_REPORT, Scope.AGENT_READ_REPORT],
  onClose: () => {
  },
});
const DailyAdvanceFundPage = Page.createInstance({
  id: 50005,
  uri: '',
  component: () => <DailyAdvanceFund />,
  scopes: [Scope.READ_REPORT],
  onClose: () => {
  },
});
const DailyAgentRefundPage = Page.createInstance({
  id: 50006,
  uri: '',
  component: () => <DailyAgentRefund />,
  scopes: [Scope.READ_REPORT],
  onClose: () => {
  },
});
const NetworkFeeDepositExpendPage = Page.createInstance({
  id: 50007,
  uri: '',
  component: () => <NetworkFeeDepositExpend />,
  scopes: [Scope.READ_REPORT, Scope.AGENT_READ_REPORT],
  onClose: () => {
  },
});

_module.addPages([DailyIncomePage, TotalUsdtChangePage, RptChartPage, DailyDepositOrderSuccessRatePage, DailyAdvanceFundPage, DailyAgentRefundPage, NetworkFeeDepositExpendPage]);
_module.setMenu([
  {
    symbol: MENU_SYMBOL_USDT_REPORT,
    label: 'Summary Report',
    menuId: 1000,
    order: 30,
    iconId: 20,
    i18nLabel: { en_US: 'Summary Report', zh_CN: '報表管理',vi_VN:'報表管理' },
    children: [
      {
        label: '日結算表',
        menuId: DailyIncomePage,
        i18nLabel: { en_US: 'Daily Income Report', zh_CN: '日結算表' ,vi_VN:'日結算表'},
      },
      {
        label: 'Usdt 總帳變',
        menuId: TotalUsdtChangePage,
        i18nLabel: { en_US: 'Total Usdt Change Report', zh_CN: 'Usdt 帳變匯總' ,vi_VN:'Usdt 帳變匯總'},
      },
      {
        label: '公司營收日報表',
        menuId: RptChartPage,
        i18nLabel: { en_US: 'Company Daily Profit', zh_CN: '公司營收日報表' ,vi_VN:'Báo cáo doanh thu hàng ngày của công ty'},
      },
      {
        label: '佣金來源與應付佣金表',
        menuId: DailyAgentRefundPage,
        i18nLabel: { en_US: 'Daily Agent Refund Report', zh_CN: '佣金來源與應付佣金表',vi_VN:'佣金來源與應付佣金表' },
      },
      {
        label: '充值訂單成功率表',
        menuId: DailyDepositOrderSuccessRatePage,
        i18nLabel: { en_US: 'Daily Deposit Order Success Rate Report', zh_CN: '充值訂單成功率表',vi_VN:'充值訂單成功率表' },
      },
      {
        label: '平台USDT資產統計報表',
        menuId: DailyAdvanceFundPage,
        i18nLabel: { en_US: 'Daily Advance Fund Report', zh_CN: '平台USDT資產統計報表',vi_VN:'平台USDT資產統計報表' },
      },
      {
        label: '礦工費充值與消耗報表',
        menuId: NetworkFeeDepositExpendPage,
        i18nLabel: { en_US: 'Network Fee deposit/expend Report', zh_CN: '礦工費充值與消耗報表',vi_VN:'礦工費充值與消耗報表' },
      },
    ],
  },
]);
