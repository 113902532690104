import { FETCH_SETTINGS } from '../types/types';

export const STATE_MERCHANT_CENTER = 'merchant-center';

const defaultState = {
  [STATE_MERCHANT_CENTER]: {
    settings: {
      systemName: '',
      creditNotifyUrl: '',
      payforNotifyUrl: '',
      transferBackNotifyUrl: '',
      creditFeeRate: '',
      payforFeeRate: '',
      transferBackFeeRate: '',
      payforMinAmount: '',
      transferBackMinAmount: '',
      collectMinAmount: '',
      collectTargetAddress: '',
    },
  },
};

// eslint-disable-next-line
export default (state = defaultState, action: any) => {
  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state[STATE_MERCHANT_CENTER],
        ...action.payload,
      };
    default:
      return state;
  }
};
