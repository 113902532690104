import CoreDrawer from '../../core/components/CoreDrawer';
import { Button, Form, Input, Modal, Spin, Transfer } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  MchRoleProvider,
  Role,
} from '../../domain/admin-providers/mchRoleProvider';
import { BaseEvent } from '../../../event/baseEvent';
import TextArea from 'antd/es/input/TextArea';
import {
  Group,
  MchGroupProvider,
} from '../../domain/admin-providers/mchGroupProvider';
import { EventProvider } from '../../../event/eventProvider';
import { FormInstance } from 'antd/lib/form';
import { UserGroupDuplicate } from '../exceptions';
import _ from 'lodash';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

export type GroupFormProps = {
  record?: Group;
  visible: boolean;
  isEdit: boolean;
};

export class CloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: CloseEvent.symbol,
      payload: undefined,
      type: CloseEvent.name,
    });
  }
}

export default function GroupFrom(props: GroupFormProps) {
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<{
    isEdit: boolean;
    group?: Group;
  }>({
    isEdit: props.isEdit,
    group: undefined,
  });
  const [roleSelectTransfer, setRoleSelectTransfer] = useState<Array<Role>>([]);
  const [roleList, setRoleList] = useState<Array<Role>>([]);
  const [curRoleList, setCurRoleList] = useState<Array<Role>>([]);
  const logger = useOperatorLogger();
  const close = () => {
    EventProvider.addEvents(new CloseEvent());
  };

  const refreshGroupData = async (groupId: number) => {
    const res = await MchGroupProvider.get(groupId);
    setFormState({ ...formState, group: res.data, isEdit: true });
    setCurRoleList(res.data.roles);
  };

  const save = async (formRef: React.RefObject<FormInstance>) => {
    formRef.current?.validateFields().then((values: any) => {
      if (formState.isEdit && !!formState.group) {
        // 編輯
        const payload: Partial<Group> = {
          systemId: values.systemId,
          id: formState.group.id,
          name: values.name,
          displayName: values.displayName,
          description: values.description,
          roles: curRoleList,
        };
        MchGroupProvider.patch(formState.group!.id, payload)
          .then((res) => {
            logger.log({
              action: '編輯',
              payload: { req: payload, res: res },
            });
          })
          .catch((e) => {
            console.error(e);
            logger.log({
              action: '編輯',
              payload: { req: payload, res: e },
            });
          });
        close();
      } else {
        // 新增
        const payload: Partial<Group> = {
          name: `${values.name}`,
          displayName: values.displayName,
          description: values.description,
        };
        MchGroupProvider.create(payload)
          .then((res) => {
            logger.log({
              action: '新增群組',
              payload: { req: payload, res: res },
            });
            refreshGroupData(res.data.id);
          })
          .catch((e) => {
            const { data } = e.response;
            const { clientCode } = data;
            if (clientCode === UserGroupDuplicate.clientCode) {
              Modal.error({ content: '請勿新增重複的群組', okText: '確認' });
            }
            logger.log({
              action: '新增群組',
              payload: { req: payload, res: e },
            });
          });
      }
    });
  };

  useEffect(() => {
    setFormState({ group: props.record, isEdit: props.isEdit });
    if (!props.visible) {
      return;
    }
    const pArr: Promise<any>[] = [];
    pArr.push(MchRoleProvider.getAll().then((roles) => setRoleList(roles)));
    if (!!props.record?.id) {
      pArr.push(refreshGroupData(props.record.id));
    }
    setLoading(true);
    Promise.all(pArr)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.record, props.visible]);

  useEffect(() => {
    if (!formState.group || !formRef.current) {
      return;
    }
    const group = formState.group;
    formRef.current?.setFieldsValue({
      name: group.name,
      displayName: group.displayName,
      description: group.description,
    });
  }, [formState, formRef]);

  return (
    <CoreDrawer
      width={640}
      title={
        formState.isEdit ? `編輯群組(id: ${formState.group?.id})` : `新增群組`
      }
      visible={props.visible}
      onClose={() => close()}
      footerbuttons={[
        <Button key={1} type="primary" onClick={() => save(formRef)}>
          {formState.isEdit ? '儲存' : '新增並開始設定角色'}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form ref={formRef} layout="vertical" name="role_form">
          <Form.Item
            name="name"
            label="群組名稱"
            rules={[{ required: true, message: '請輸入群組名稱' }]}
          >
            <Input
              allowClear
              bordered={!formState.isEdit}
              readOnly={formState.isEdit}
            />
          </Form.Item>
          <Form.Item
            name="displayName"
            label="群組顯示名稱"
            rules={[{ required: true, message: '請輸入群組顯示名稱' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item name="description" label="群組說明">
            <TextArea allowClear />
          </Form.Item>
          {formState.isEdit && (
            <Form.Item label="指定角色">
              <Transfer
                dataSource={roleList.map((role) => ({
                  key: role.id.toString(),
                  title: role.name,
                }))}
                listStyle={{
                  width: 250,
                  height: 300,
                }}
                titles={['尚未指定', '已經指定']}
                className="tree-transfer"
                targetKeys={curRoleList.map((x) => x.id.toString())}
                selectedKeys={roleSelectTransfer.map((x) => x.id.toString())}
                render={(item) => item.title ?? ''}
                onChange={(keys) => {
                  const curRoles = _.chain(keys)
                    .filter(
                      (key) =>
                        !!_.find(roleList, (x) => x.id.toString() === key),
                    )
                    .map(
                      (key) =>
                        _.find(
                          roleList,
                          (x) => x.id.toString() === key,
                        ) as Role,
                    )
                    .value();
                  setCurRoleList(curRoles);
                }}
                onSelectChange={(selectKeys, targetKeys) => {
                  setRoleSelectTransfer(
                    [...selectKeys, ...targetKeys].map(
                      (k) =>
                        roleList.filter((role) => role.id?.toString() === k)[0],
                    ),
                  );
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </CoreDrawer>
  );
}
