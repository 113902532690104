import { FETCH_SETTINGS } from '../types/types';
import { setStorage } from '../../../lib/webStorage';
import SystemProvider from '../../domain/usdt-providers/systemProvider';

export const get_settings = (systemId: string) => (dispatch: any) =>
  SystemProvider.getSystemVar(systemId).then((res) => {
    setStorage('systemSettings', res);
    dispatch({
      type: FETCH_SETTINGS,
      payload: res,
    });
  });
