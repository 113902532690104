import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import { Button, Descriptions, Form, Input, InputNumber, Modal } from 'antd';
import HexLabel from '../../core/components/HexLabel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import WalletProvider from '../../domain/usdt-providers/walletProvider';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

export class FormCloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: FormCloseEvent.symbol,
      payload: undefined,
      type: FormCloseEvent.name,
    });
  }
}

export type ChangeAccountProps = {
  walletAddress: string;
  systemId: string;
  visible: boolean;
  txnBalance: number;
  availableBalance: number;
  channel: string;
  token: string;
  walletType:string;
}

export default function ChangeAccountForm(props: ChangeAccountProps) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = React.createRef<FormInstance>();
  const maxOrderAmount: number = 2000;
  const walletType = props.walletType;
  const loggger = useOperatorLogger();
  const closeFrom = () => {
    EventProvider.addEvents(new FormCloseEvent());
  };

  const sendOrder = async () => {
    setConfirmLoading(true);
    try {
      const formData = await formRef.current?.validateFields();
      const params = {
        ...props,
        availableBalanceChange: formData.amount,
        reason: formData.reason,
      };
      switch (walletType)
      {
        case 'Mch':
        {
          await WalletProvider.addAvailableBalance(params).then(res => {
            loggger.log({
              action: '調帳',
              payload: { req: params, res: res.data },
              systemId: params.systemId,
            });
            Modal.success({
              title: t('common.success'),
            });
            closeFrom();
          }).catch(e => {
            loggger.log({
              action: '調帳',
              payload: { req: params, res: e },
              systemId: params.systemId,
            });
          });
          break;
        }
        case 'User':
        {
          await WalletProvider.addUserAvailableBalance(params).then(res => {
            loggger.log({
              action: '調帳',
              payload: { req: params, res: res.data },
              systemId: params.systemId,
            });
            Modal.success({
              title: t('common.success'),
            });
            closeFrom();
          }).catch(e => {
            loggger.log({
              action: '調帳',
              payload: { req: params, res: e },
              systemId: params.systemId,
            });
          });
          break;
        }
      }
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    // console.log('useEffect', props);
    formRef.current?.setFieldsValue(props);
    // eslint-disable-next-line
  }, [props]);

  return <>
    <Modal
      key={'change_account_form'}
      visible={props.visible}
      footer={[
        <Button key={2} onClick={() => closeFrom()} loading={confirmLoading}>
          {t('common.cancel')}
        </Button>,
        <Button
          key={1}
          type='primary'
          onClick={() => sendOrder()}
          loading={confirmLoading}
        >
          {t('common.confirm')}
        </Button>,
      ]}
      onCancel={() => closeFrom()}
      maskClosable={false}
      closable={false}
      title={'調帳'}
      destroyOnClose
    >
      <Descriptions layout={'horizontal'} column={1}>
        <Descriptions.Item label={t('payment.wallet_address')}>
          {<HexLabel hex={props.walletAddress} />}
        </Descriptions.Item>
        <Descriptions.Item label={t('payment.channel')}>
          {props.channel}
        </Descriptions.Item>
        <Descriptions.Item label={t('payment.token')}>
          {props.token}
        </Descriptions.Item>
        <Descriptions.Item label={t('payment.system_id')}>
          {props.systemId}
        </Descriptions.Item>
      </Descriptions>
      <Form ref={formRef} layout='vertical'>
        <Form.Item
          name='amount'
          label={t('payment.order_amount')}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <InputNumber max={maxOrderAmount} />
        </Form.Item>
        <Form.Item
          name='reason'
          label={t('common.reason')}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  </>;
}
