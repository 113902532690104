export class DomainConstants {
  static merchantType = {
    NORMAL: 'NORMAL',
    AGENT: 'AGENT',
  };
  static languageType = {
    zh: 'zh',
    en: 'en',
    vi: 'vi',
  };
  static BOSignSecret = 'Qdc59aQZiiu';
}
