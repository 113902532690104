import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import React, { useEffect, useRef } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import { PageHeader } from 'antd';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import {
  AgentFundingWalletChangeLog,
  AgentSourceWalletProvider,
} from '../../domain/report-provider/agentSourceWalletProvider';

interface PageOp extends CoreProTableState<AgentFundingWalletChangeLog> {}

const useAgentTradeCodeTranslate = (): any => {
  const { t } = useTranslation();
  return {
    "Withdraw": t('agentTradeCode.Withdraw'),
    "Credit": t('agentTradeCode.Credit'),
    "RevertWithdraw": t('agentTradeCode.RevertWithdraw'),
    "WithdrawFinish": t('agentTradeCode.WithdrawFinish'),
    "NetworkFee":t('agentTradeCode.NetworkFee'),
    "WithdrawRetry": t('agentTradeCode.WithdrawRetry'),
  };
};

export default function AgentFundingTxnPage(props: {}) {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const agentTradeCodeTranslate = useAgentTradeCodeTranslate();

  const loggger = useOperatorLogger();
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader
        title={'資金池資金流水'}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<AgentFundingWalletChangeLog>
        bordered
        rowKey={(row) => `${row.txnId}`}
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        onReset={() => {
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        requestData={(params) =>
          AgentSourceWalletProvider.getChangeLogList(
            PaginationTransformer.paramTransfer(params),
          ).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params },
              systemId: params.systemId
            });
            return PaginationTransformer.responseTransfer(rs);
          })
        }
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            fixed: 'left',
            title: t('change_log.txn_no'),
            dataIndex: 'txnId',
            width: 60,
          },
          {
            hideInSearch: true,
            align: 'center',
            title: t('payment.trade_genre'),
            dataIndex: 'tradeCode',
            valueType: 'select',
            valueEnum: agentTradeCodeTranslate,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            valueType: 'select',
            valueEnum: Token,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            ellipsis: true,
            title: t('代理資金錢包'),
            dataIndex: 'walletAddress',
            width: 120,
            render: (n, r) => <HexLabel hex={r.walletAddress} size={'small'} />,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.blockchain_hash'),
            dataIndex: 'txnHash',
            width: 120,
            render: (n, r) => <HexLabel hex={r.txnHash} size={'small'} />,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: true,
            title: t('change_log.available_balance_change'),
            dataIndex: 'availableAmount',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: true,
            title: t('change_log.available_before_balance'),
            dataIndex: 'availableBeforeBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: true,
            title: t('change_log.available_after_balance'),
            dataIndex: 'availableAfterBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.txn_before_balance'),
            dataIndex: 'txnBeforeBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('交易金額'),
            dataIndex: 'txnAmount',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.txn_after_balance'),
            dataIndex: 'txnAfterBalance',
            width: 100,
          },
          {
            align: 'left',
            hideInSearch: true,
            hideInTable: false,
            title: t('common.comment'),
            dataIndex: 'comment',
            width: 250,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
        ]}
      />
    </>
  );
}
