import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminUri } from '../../core/configs/services';
import { Permission } from '../../permissions/types';
import { AdminResponse } from '../models/adminResponse';
import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';

export interface RoleBase {
  id?: number;
  name: string;
  description: string;
}

export interface Role extends RoleBase {
  id: number;
  name: string;
  description: string;
  scopes: Array<Permission>;
}

export class MchRoleProvider {
  static async getList(params: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<Role>>(
      AdminUri(`admin/merchant/user/roles`),
      {
        params: {
          ...params
        },
      },
    );
    return res.data;
  }

  static async getAll(param?: PaginationQuery) {
    return await PaginationTransformer.getAllFromPagination<Role, null>(
      (qruery) => MchRoleProvider.getList(qruery),
      null,
    );
  }

  static async getOne(id: number) {
    const res = await adminRequest.get<AdminResponse<Role>>(
      AdminUri(`admin/merchant/user/roles/${id}`),
    );
    return res.data;
  }

  static async add(props: Partial<Role>) {
    const res = await adminRequest.post<AdminResponse<Role>>(
      AdminUri(`admin/merchant/user/roles`),
      props,
    );
    return res.data;
  }

  static async patch(props: Role) {
    const res = await adminRequest.patch(
      AdminUri(`admin/merchant/user/roles/${props.id}`),
      props,
    );
    return res.data;
  }

  static async delete(props: Partial<Role>) {
    const res = await adminRequest.delete<AdminResponse<any>>(
      AdminUri(`admin/merchant/user/roles/${props?.id}`),
    );
    return res.data;
  }
}
