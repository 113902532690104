import { sha256Encrypt } from "./crypto.helper";
import { DomainConstants } from "../../../modules/domain/domainConstants";
import * as _ from 'lodash';

export class BoSignHelper {
  static generate(obj: any): string {
    let objStr = _.chain(obj)
      .toPairs()
      .sortBy((x) => x[0])
      .reduce((str, [key, value]) => {
        return str + `&${key}=${value}`;
      }, '')
      .value();
    objStr += `&SECRET=${DomainConstants.BOSignSecret}`;
  
    const encode = Buffer.from(objStr).toString('base64');
    let sign: string;
    sign = sha256Encrypt(encode).toUpperCase();
    return sign;
  }
}