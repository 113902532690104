import adminRequest from '../../config/adminRequest';
import {AdminUri} from '../../modules/core/configs/services';
import {AdminResponse} from "../../modules/domain/models/adminResponse";

export class TwoFactoryProvider {
  static async validate(code: string) {
    const res = await adminRequest.get<AdminResponse<boolean>>(AdminUri(`admin/auth/s/v`), {
      params: {token: code},
    });
    return res.data
  }
  
  static async generate() {
    const res = await adminRequest.get<AdminResponse<{ qrcode: string }>>(AdminUri('admin/auth/s/q'))
    return res.data;
  }
}
