import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import { PayforRecord } from '../../domain/order-status/payforOrderTypes';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { useTranslation } from 'react-i18next';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType, DateRangeType } from '../../core/utils/tableUtilType';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { PayforCreateFailListProvider } from '../../domain/usdt-providers/payforCreateFailListProvider';

interface PageOp extends CoreProTableState<PayforRecord> {
}


export default (props: { title: string; subTitle: string }) => {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const loggger = useOperatorLogger();
  const searchOp = usePageState<DateRangeType>('SEARCH_PARAM', {});
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp: searchOp,
    state: dateRangeState,
  });

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader title={props.title} subTitle={props.subTitle} extra={<></>} />
      <CoreProTable<PayforRecord>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        scroll={{ x: 'fixed' }}
        rowKey='orderNo'
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        requestData={(params) =>
          PayforCreateFailListProvider.getAdminCreateFailList({
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
            txHash: params.transactionHash,
          }).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params },
              systemId: params.systemId,
            });
            return rs;
          })
        }
        columns={[
          {
            width: 100,
            dataIndex: 'systemId',
            hideInSearch: false,
            align: 'center',
            title: '商戶名稱',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.channel'),
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.token'),
            valueEnum: Token,
            dataIndex: 'token',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '商戶訂單編號',
            dataIndex: 'customOrderNo',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 50,
            align: 'right',
            title: '訂單金額',
            dataIndex: 'orderAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 150,
            align: 'center',
            title: '失敗原因',
            dataIndex: 'failReason',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '交易創建時間',
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          dateRangeColumn.column,
        ]}
      />
    </>
  );
};