import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri, UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';
import { PaginationQuery } from '../../core/utils/paginationTransformer';

export type ChangeLogType = {
  txnId: string;
  token: string;
  systemId: string;
  orderId: string;
  txnType: string;
  txnAfterBalance: number;
  txnAmount: number;
  comment: string;
  createdAt: Date;
  updatedAt: Date;
};

export type WithdrawOrderType = {
  orderId: string;
  systemId: string;
  txnType: string;
  orderNo: string;
  orderAmount: number;
  toAddress: string;
  status: string;
  channel: string;
  token: string;
  transactionHash: string;
  verifiedUserId: string;
  transferStatus: string;
};

export type ChangeLogQuery = {
  start?: string;
  end?: string;
  txnType?: string;
};

export type WithdrawOrderQuery = {
  start?: string;
  end?: string;
  orderNo?: string;
  transactionHash?: string;
};

export type AgentWalletInfo = {
  systemId: string;
  token:string;
  available: boolean;
  balance: number;
};

export class AgentWalletProvider {
  static async getChangeLogList(params: ChangeLogQuery & PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<ChangeLogType>>(
      ReportUri('agent-wallet/change-log'),
      { params },
    );
    return res.data;
  }

  static async getChangeLogExcel(params: ChangeLogQuery) {
    const res = await adminRequest.get<AdminListResponse<ChangeLogType>>(
      ReportUri('agent-wallet/change-log/xlsx'),
      { params, responseType: 'blob' },
    );
    return res.data;
  }

  static async getWithdrawOrderList(params: WithdrawOrderQuery) {
    const res = await adminRequest.get<AdminListResponse<WithdrawOrderType>>(
      ReportUri('agent-wallet/withdraw-order'),
      { params },
    );
    return res.data;
  }

  static async getCurrentBalance(params: { systemId: string }) {
    const res = await adminRequest.get<AdminResponse<AgentWalletInfo[]>>(
      ReportUri(`agent-wallet/${params.systemId}`),
      {
        transformResponse: (data, headers) => {
          if (data?.code === '1000') {
            data.code = '00';
            data.balance = 0;
            return data;
          }
          return data;
        },
      },
    );
    return res.data.data
  }

  static async createWithdrawOrder(data: {
    systemId: string;
    token:string;
    toAddress: string;
    operatorId: string;
    channel: string;
    balance: number;
  }) {
    const res = await adminRequest.post<AdminResponse<WithdrawOrderType>>(
      UsdtUri('agent-wallet/withdraw-order'),
      data,
    );
    return res.data;
  }

  static async agreeWithdraw(data: { orderId: string; operatorId: string; walletAddress:string }) {
    const res = await adminRequest.post<AdminResponse<WithdrawOrderType>>(
      ReportUri('agent-wallet/withdraw-order/agree'),
      data,
    );
    return res.data;
  }

  static async rejectWithdraw(data: { orderId: string; operatorId: string }) {
    const res = await adminRequest.post<AdminResponse<WithdrawOrderType>>(
      ReportUri('agent-wallet/withdraw-order/reject'),
      data,
    );
    return res.data;
  }

  static async retryWithdraw(data: { orderId: string; operatorId: string }) {
    const res = await adminRequest.post<AdminResponse<WithdrawOrderType>>(
      ReportUri('agent-wallet/withdraw-order/retry'),
      data,
    );
    return res.data;
  }

  static async successWithdraw(data: {
    orderId: string;
    operatorId: string;
    txnHash: string;
  }) {
    const res = await adminRequest.post<AdminResponse<WithdrawOrderType>>(
      ReportUri('agent-wallet/withdraw-order/success'),
      data,
    );
    return res.data;
  }
}
