import React, { useEffect, useState } from 'react';
import { Col, PageHeader, Row } from 'antd';
import {
  AFWallet,
  AgentSourceWalletProvider,
} from '../../domain/report-provider/agentSourceWalletProvider';
import AFWalletInfo from '../components/AFWalletInfo';

// eslint-disable-next-line
export default () => {
  const [wallets, setWallets] = useState<AFWallet[]>([]);

  const refreshData = async () => {
    const res = await AgentSourceWalletProvider.getFundingWalletList({
      channel: '',
    });
    setWallets(res.data);
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      refreshData().catch((e) => console.error(e));
    }, 60000);
    refreshData().catch((e) => console.error(e));
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageHeader title={'代理資金池'} />
      <Row gutter={[24, 24]} style={{ margin: '0.2rem' }}>
        {/* eslint-disable-next-line array-callback-return */}
        {wallets.map((wallet) => {
          return (
            <Col span={11} style={{ margin: '1rem' }}>
              <AFWalletInfo wallet={wallet} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
