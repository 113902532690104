import { AgentWalletProvider, WithdrawOrderType } from '../../domain/report-provider/agentWalletProvider';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { Button, Form, message, Modal, Select, Tooltip, Typography } from 'antd';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import _ from 'lodash';
import { AgentSourceWalletProvider } from '../../domain/report-provider/agentSourceWalletProvider';
import BigNumber from 'bignumber.js';
import { Token } from '../../domain/channel/channel';


type Props = {
  visible: boolean;
  order: WithdrawOrderType;
};

export class WithdrawAgreeEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: WithdrawAgreeEvent.symbol,
      payload: null,
      type: WithdrawAgreeEvent.name,
    });
  }
}

export default function WithdrawAgreeForm(props: Props) {
  const [visible, setVisible] = useState(props.visible);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = React.createRef<FormInstance>();
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const closeFrom = () => {
    EventProvider.addEvents(new WithdrawAgreeEvent());
    setSelectWallet({
      walletAddress: '',
      coin: 'ETH / TRX',
      tokenBalance: 0,
      coinBalance: 0,
      token:'USDT',
    });
    setVisible(false);
  };
  const checkSelectedWallet = (selectWallet: {
    walletAddress: string,
    coin: string,
    tokenBalance: number,
    coinBalance: number,
    token: string,
  }) => {
    const applyBalance = props.order.orderAmount;
    const {
      coin,
      walletAddress,
      tokenBalance,
      coinBalance,
      token,
    } = selectWallet;
    if (walletAddress === '') {
      throw new Error(t('funding.message.wallet_not_selected'));
    }
    if (new BigNumber(coinBalance).toNumber() === 0 && coin === 'ETH') {
      throw new Error(t('funding.message.eth_not_enough'));
    }
    if (new BigNumber(coinBalance).toNumber() === 0 && coin === 'TRX') {
      throw new Error(t('funding.message.trx_not_enough'));
    }
    if (new BigNumber(coinBalance).toNumber() === 0 && coin === 'BSC') {
      throw new Error(t('funding.message.bnb_not_enough'));
    }
    if (new BigNumber(tokenBalance).toNumber() < new BigNumber(applyBalance).toNumber()) {
      switch (token)
      {
        case Token.USDT:{
          throw new Error(t('funding.message.usdt_not_enough'));
          break;
        }
        case Token.BUSD:{
          throw new Error(t('funding.message.busd_not_enough'));
          break;
        }
        case Token.USDC:{
          throw new Error(t('funding.message.usdc_not_enough'));
          break;
        }
      }

    }
  };

  const agreeTransfer = async () => {
    formRef.current
      ?.validateFields()
      .then(async () => {
        checkSelectedWallet(selectWallet); // selectWallet balance is enough
        await AgentWalletProvider.agreeWithdraw({
          operatorId: user.userId,
          orderId: props.order.orderId,
          walletAddress: selectWallet.walletAddress,
        });
        EventProvider.addEvents(new WithdrawAgreeEvent());
        message.success(t('common.success'), 2);
      })
      .catch((err) => {
        Modal.error({
          content: err.message,
          okText: '確認',
        });
      })
      .finally(() => setConfirmLoading(false));
  };

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible, user]);
  const [selectWallet, setSelectWallet] = useState<{
    walletAddress: string,
    coin: string,
    tokenBalance: number,
    coinBalance: number,
    token: string,
  }>({
    walletAddress: '',
    coin: 'ETH / TRX',
    tokenBalance: 0,
    coinBalance: 0,
    token:'USDT',
  });

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible, user]);
  const [sourceWalletList, setSourceWalletList] = useState<{
    channel: string;
    token: string;
    walletAddress: string;
    systemId: string;
    txnBalance: number;
    availableBalance: number;
  }[]>([]);
  useEffect(() => {
    setVisible(props.visible);
    if (props.visible) {
      const order = props.order;
      AgentSourceWalletProvider.getFundingWalletList({
        channel: order.channel,
      }).then((rs) => {
        setSourceWalletList(rs.data);
      });
    }
    // eslint-disable-next-line
  }, [props.visible]);

  const channelWhites = _.chain(sourceWalletList)
    .tap((x) => console.log(x))
    .map((x) => ({
      label: x.walletAddress,
      value: x.walletAddress,
    }))
    .value();
  return (
    <>
      <Modal
        visible={visible}
        footer={[
          <Button key={2} onClick={() => closeFrom()} loading={confirmLoading}>
            {t('common.cancel')}
          </Button>,
          <Button
            key={1}
            type='primary'
            onClick={agreeTransfer}
            loading={confirmLoading}
          >
            {t('common.confirm')}
          </Button>,
        ]}
        onCancel={() => closeFrom()}
        maskClosable={false}
        closable={false}
        title={t('funding.withdraw_action.agree')}
        destroyOnClose
      >
        <Form
          ref={formRef}
          layout='vertical'
        >
          <Form.Item
            name='address'
            label={t('funding.funding_wallet')}
          >
            <Select
              options={_.uniqBy(channelWhites, 'value')}
              placeholder={t('transferback.choose_wallet_address')}
              onSelect={(walletAddress) => {


                const walletCoinIdx = sourceWalletList.filter(i => {
                  return i.walletAddress === walletAddress;
                });
                const walletTokenIdx = sourceWalletList.filter(i => {
                  return i.walletAddress === walletAddress && i.token === props.order.token;
                });
                let displayCoinGenre;
                if (walletCoinIdx[0].channel === 'ETHEREUM') {
                  displayCoinGenre = 'ETH';
                }
                if (walletCoinIdx[0].channel === 'TRON') {
                  displayCoinGenre = 'TRX';
                }
                if (walletCoinIdx[0].channel === 'SOLANA') {
                  displayCoinGenre = 'SOL';
                }
                if (walletCoinIdx[0].channel === 'BSC') {
                  displayCoinGenre = 'BNB';
                }
                setSelectWallet({
                  walletAddress: walletAddress.toString(),
                  coin: displayCoinGenre || '礦工費 (ETH/TRX/BNB)',
                  tokenBalance: walletTokenIdx[0].availableBalance,
                  coinBalance: walletCoinIdx[0].availableBalance,
                  token: walletTokenIdx[0].token,
                });
                return walletAddress;
              }}
            />
            <Tooltip title='Useful information'>
              <Typography.Link>
                {`${selectWallet.token} : ${_.round(new BigNumber(selectWallet.tokenBalance).toNumber(), 6)}`}
                <br />
                {selectWallet.coin === 'SOL' ? '' : selectWallet.coin + ' : '+_.round(new BigNumber(selectWallet.coinBalance).toNumber(), 6)}
              </Typography.Link>
            </Tooltip>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
