import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { MenuStore } from '../../../stores/menu';
import { LogProvider } from '../../domain/admin-providers/logProvider';
import _ from 'lodash';

export function useOperatorLogger() {
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const userId = user.userId;
  const menu = useSelector<GlobalStoreMerge, MenuStore>((x) => x.menu);
  const menuId = _.get(menu, ['current', 'now', 0], '');
  return {
    log: (data: { action: string; payload: any; systemId?: string }) => {
      const systemId = data.systemId ?? user.systemId ?? 'NULL';
      LogProvider.recordLog({
        action: data.action,
        function: menuId,
        payload: JSON.stringify(data.payload),
        systemId: systemId,
        userId: userId,
      }).catch((e) => console.error(e));
    },
  };
}
