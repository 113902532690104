import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import * as serviceWorker from './serviceWorker';

import './assets/css/index.less';

import { device } from './lib/device';
import { buildStore } from './modules/core/bootstrap';

import Main from './app/main';
import Outer from './app/outer';
import './i18n';
import { GlobalStoreMerge } from './stores/merge';
import { UserStore } from './stores/user';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import viVN from 'antd/es/locale/vi_VN';

const Index = () => {
  const user = useSelector<GlobalStoreMerge, UserStore>((state) => state.user);
  let locale = zhCN
  switch(user.lang){
    case'zh':{
      locale = zhCN
      break;
    }
    case'en':{
      locale = enUS
      break;
    }
    case'vi':{
      locale = viVN
      break;
    }
    default:{
      locale = zhCN
      break;
    }
  }
  return (
    <ConfigProvider locale={locale}>
      <HashRouter basename="/">
        <Main />
        <Outer />
      </HashRouter>
    </ConfigProvider>
  );
};

device(document.getElementById('root'));
ReactDOM.render(
  <Provider store={buildStore()}>
    <Index />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
