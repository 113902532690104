import React, { useEffect, useRef, useState } from 'react';
import { Collapse, FormInstance, PageHeader, Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChartProvider, RevenueItem } from '../../domain/report-provider/chartProvider';
import { RevenueChartProps } from '../components/chart/RevenueChart';
import { usePageState } from '../../core/hooks/usePageState';
import { transfer2LocalDate } from '../../core/utils/timeFormat';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ProCoreActionType } from '@ant-design/pro-utils';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { roundPrecision } from '../../core/utils/precisionFormat';
import { state } from '@antv/g2plot/lib/adaptor/common';
import AgentRefundChart, { AgentRefundChartProps } from '../components/chart/AgentRefundChart';
import MchAgentRefundChart from '../components/chart/MchAgentRefundChart';
import { leepayFormat } from '../../core/utils/leepayFormat';


interface PageOp extends CoreProTableState<RevenueItem> {
}

const defaultRevenueChartProps: AgentRefundChartProps = {
  dataSet: [],
};
// eslint-disable-next-line
export default function() {
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState<any>({});
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();

  const revenueState = usePageState<AgentRefundChartProps>(
    'RevenueChartProps',
    defaultRevenueChartProps,
  );

  const searchState = usePageState<{
    queryStr: string;
  }>('RevenueSearchState', {
    queryStr: '',
  });
  const revenueTotalState = usePageState<{
    totalFee: number;
    payforUsdtFee: number;
    profitAmount: number;
  }>('RevenueTotalState', {
    totalFee: 0,
    payforUsdtFee: 0,
    profitAmount: 0,
  });
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const checkedState = usePageState<{
    checked: boolean;
  }>('CheckedState', {
    checked: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    defaultDateRange: 30,
    title: t('report.reportDate'),
    pageOp,
    state: dateRangeState,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  useEffect(()=>{
    pageOp.update((v) => {
      const start = (v as any).start;
      const end = (v as any).end;
      const systemId = v.params.systemId;
      const searchQueryStr = `${start}:${end}:${systemId}`;

      searchState.update({
        queryStr: searchQueryStr,
      });

      return { ...v, ...state };
    });
  },[queryData])
  return (
    <>
      <PageHeader
        title={t('report.agent_refund_source')}
        subTitle={'此報表僅顯示有代理階層之一般商戶。'}
        extra={<></>}
      />
      <Tabs
        type='card'
        style={{
          border: '0.5px',
          padding: 24,
          margin: 0,
        }}
      >
        <Tabs.TabPane
          key={1}
          tab={
            <Tooltip title='檢視每個代理的獲利來源主要為哪個一般商戶'>
              <span>代理佣金來源比較</span>
            </Tooltip>
          }
        >
          <AgentRefundChart {...revenueState.get()} />
        </Tabs.TabPane>

        <Tabs.TabPane
          key={2}
          tab={
            <Tooltip title='檢視哪一個一般般商戶讓代理賺最多'>
              <span>商戶應付佣金比較</span>
            </Tooltip>
          }
        >
          <MchAgentRefundChart {...revenueState.get()} />
        </Tabs.TabPane>

      </Tabs>
      <CoreProTable<RevenueItem>
        bordered
        rowKey={(record) => `${record.reportDate}:${record.systemId}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}

        formRef={searchFormRef}
        updateTableState={(state: any) => {
          const earlySearchQueryStr = searchState.get().queryStr;
          // pageOp.update((v) => ({ ...v, ...state }));
          pageOp.update((v) => {
            const start = (v as any).start;
            const end = (v as any).end;
            const systemId = v.params.systemId;

            const searchQueryStr = `${start}:${end}:${systemId}`;

            searchState.update({
              queryStr: searchQueryStr,
            });

            if (earlySearchQueryStr !== searchQueryStr) {
              ChartProvider.getRawAgentRefund({
                systemId,
                start: dateRangeColumn.getParamAndUpdatePage().start,
                end: dateRangeColumn.getParamAndUpdatePage().end,
                token:'USDT'
              })
                .then((res) => {
                  revenueState.update({ dataSet: res.data });
                })
                .catch((e) => {
                  console.error(e);
                  throw e;
                });
            }

            return { ...v, ...state };
          });
        }}
        requestData={async (params: any) => {
          let res;
          const param = PaginationTransformer.paramTransfer(params);
          const queryObj = {
            ...param,
            start: dateRangeColumn.getParamAndUpdatePage().start,
            end: dateRangeColumn.getParamAndUpdatePage().end,
            token:'USDT'
          };
          res = await ChartProvider.getAgentRefundList(queryObj).catch((e) => {
            console.error(e);
            throw e;
          });
          setQueryData({
            ...param,
            start: dateRangeColumn.getParamAndUpdatePage().start,
            end: dateRangeColumn.getParamAndUpdatePage().end,
          })
          // const pageSummary = {
          //   totalFee: 0,
          //   payforUsdtFee: 0,
          //   profitAmount: 0,
          // };
          // const itemList = res.data.items;
          // for (const item of itemList) {
          //   pageSummary.totalFee = new BigNumber(pageSummary.totalFee)
          //     .plus(item.totalFee)
          //     .toNumber();
          //   pageSummary.payforUsdtFee = new BigNumber(pageSummary.payforUsdtFee)
          //     .plus(item.payforUsdtFee)
          //     .toNumber();
          //   pageSummary.profitAmount = new BigNumber(pageSummary.profitAmount)
          //     .plus(item.profitAmount)
          //     .toNumber();
          // }
          // revenueTotalState.update(pageSummary);


          return PaginationTransformer.responseTransfer(res);
        }}
        onReset={() => {
          dateRangeColumn.reset();
          checkedState.update({
            checked: true,
          });
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('report.date'),
            dataIndex: 'reportDate',
            fixed: 'left',
            render: (_: any, row: RevenueItem) =>
              transfer2LocalDate(new Date(row.reportDate)),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'token',
            title: t('payment.token'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'depositAmount',
            title: t('report.depositAmount'),
            tooltip: '商戶之充值金額加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.depositAmount, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'depositFee',
            title: t('report.depositFee'),
            tooltip: '商戶之充值手續費加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.depositFee, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'payoutAmount',
            title: t('report.payoutAmount'),
            tooltip: '商戶之代付金額加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.payoutAmount, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'payoutFee',
            title: t('report.payoutFee'),
            tooltip: '商戶之代付手續費加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.payoutFee, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'totalFee',
            title: t('report.total_fee'),
            tooltip: '={總收款金額+總出款金額}',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.totalFee, 2),
          },
          {
            hideInSearch: true,
            hideInTable: true,
            width: 120,
            dataIndex: 'payforUsdtFee',
            title: t('report.payforUsdtFee'),
            tooltip: '代付中所花費之轉帳費用',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.payforUsdtFee, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            ellipsis: true,
            dataIndex: 'parentSystem',
            title: '代理商戶名稱',
            tooltip: '代理商戶名稱',
            render: (text, row: any) =>
              leepayFormat(row.parentSystem),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'profitAmount',
            title: '應付代理佣金',
            tooltip: '該代理商戶(或平台)獲得的佣金',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.profitAmount, 2),
          },
          dateRangeColumn.column,
        ]}
        // summary={() => (
        //   <Table.Summary.Row style={{ backgroundColor: '#d9d9d9' }}>
        //     <Table.Summary.Cell index={1} align={'center'}>
        //       Total
        //     </Table.Summary.Cell>
        //     <Table.Summary.Cell index={2}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={3}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={4}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={5}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={6}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={7}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={8}>
        //       {revenueTotalState.get().totalFee}
        //     </Table.Summary.Cell>
        //     <Table.Summary.Cell index={9}></Table.Summary.Cell>
        //     <Table.Summary.Cell index={10}>
        //       {revenueTotalState.get().profitAmount}
        //     </Table.Summary.Cell>
        //   </Table.Summary.Row>
        // )}
      />
    </>
  );
}
