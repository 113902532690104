import React, { useEffect, useRef, useState } from 'react';
import { Card, Checkbox, Col, Collapse, FormInstance, PageHeader, Row, Statistic, Table, Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChartProvider, RevenueItem } from '../../domain/report-provider/chartProvider';
import RevenueChart, { RevenueChartProps } from '../components/chart/RevenueChart';
import { usePageState } from '../../core/hooks/usePageState';
import { transfer2LocalDate } from '../../core/utils/timeFormat';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ProCoreActionType } from '@ant-design/pro-utils';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import BigNumber from 'bignumber.js';
import { roundPrecision } from '../../core/utils/precisionFormat';
import FeeRefundTrendencyChart from '../components/chart/FeeRefundTrendencyChart';
import NormalMchFeeChart from '../components/chart/NormalMchFeeChart';
import NormalMchFeePercentChart from '../components/chart/NormalMchFeePercentChart';
import { state } from '@antv/g2plot/lib/adaptor/common';
import TotalFeeWeeklyChart from '../components/chart/TotalFeeWeekly';
import CommsionAmountChart from '../components/chart/CommsiomAmountChart';


interface PageOp extends CoreProTableState<RevenueItem> {
}

interface Summary {
  overallFee: number
  overallCommissionAmount: number,
  overallProfitAmount: number,
}

const initialSummary: Summary = {
  'overallFee': 0,
  'overallCommissionAmount': 0,
  'overallProfitAmount': 0,
};

const defaultRevenueChartProps: RevenueChartProps = {
  dataSet: [],
};
// eslint-disable-next-line
export default function() {
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState<any>({});
  const [overall, setOverall] = useState(initialSummary);
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const agentTypeEnum = {
    NORMAL: t('system.normal_mch_type'),
    AGENT: t('system.agent_mch_type'),
  };
  const revenueState = usePageState<RevenueChartProps>(
    'RevenueChartProps',
    defaultRevenueChartProps,
  );

  const searchState = usePageState<{
    queryStr: string;
  }>('RevenueSearchState', {
    queryStr: '',
  });
  const revenueTotalState = usePageState<{
    totalFee: number;
    payforUsdtFee: number;
    commissionAmount: number;
    profitAmount: number;
  }>('RevenueTotalState', {
    totalFee: 0,
    payforUsdtFee: 0,
    commissionAmount: 0,
    profitAmount: 0,
  });
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const checkedState = usePageState<{
    checked: boolean;
  }>('CheckedState', {
    checked: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    defaultDateRange: 30,
    title: t('report.reportDate'),
    pageOp,
    state: dateRangeState,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  useEffect(()=>{
    pageOp.update((v) => {
      const start = (v as any).start;
      const end = (v as any).end;
      const systemId = v.params.systemId;
      const agentType = v.params.agentType;
      const profitAmountMoreThan = (v.params as any).profitAmountMoreThan;
      let totalFeeMoreThan = (v.params as any).totalFeeMoreThan;
      const hideLessZeroFlag = checkedState.get().checked;
      const searchQueryStr = `${start}:${end}:${systemId}:${agentType}:${profitAmountMoreThan}:${totalFeeMoreThan}:${hideLessZeroFlag}`;

      searchState.update({
        queryStr: searchQueryStr,
      });

      const result = ChartProvider.getSummeryList({
        systemId,
        agentType,
        profitAmountMoreThan,
        totalFeeMoreThan,
        startDate: dateRangeColumn.getParamAndUpdatePage().start,
        endDate: dateRangeColumn.getParamAndUpdatePage().end,
        hideLessZero: hideLessZeroFlag,
      }).then((rs) => {
        setOverall({
          overallFee: rs.data?.overallFee,
          overallCommissionAmount: rs.data?.overallCommissionAmount,
          overallProfitAmount: rs.data?.overallProfitAmount,
        });
      })
        .catch((e) => {
          console.error(e);
          throw e;
        });
      return { ...v, ...state };
    });
  },[queryData])
  return (
    <>
      <PageHeader
        title={t('report.company_profit')}
        subTitle={''}
        extra={<></>}
      />
      <Tabs
        type='card'
        style={{
          border: '0.5px',
          padding: 24,
          margin: 0,
        }}
      >
        <Tabs.TabPane
          key={1}
          tab={
            <Tooltip title='統計每日{公司獲利金額加總÷總手續費加總}，公司獲利金額為{總手續費-應付代理佣金}'>
              <span>{t('report.revenue_chart')}</span>
            </Tooltip>
          }
        >
          <RevenueChart {...revenueState.get()} />
        </Tabs.TabPane>

        <Tabs.TabPane
          key={2}
          tab={
            <Tooltip title='統計每日{總手續費加總}與{應付代理佣金加總}'>
              <span>{t('report.feeAndRefundTrendency')}</span>
            </Tooltip>
          }
        >
          <FeeRefundTrendencyChart {...revenueState.get()} />
        </Tabs.TabPane>

        <Tabs.TabPane
          key={3}
          tab={
            <Tooltip title='統計每個商戶每日{總手續費加總}。此圖表僅顯示一般商戶'>
              <span>{t('report.MchFeeTrendency')}</span>
            </Tooltip>
          }
        >
          <NormalMchFeeChart {...revenueState.get()} />
        </Tabs.TabPane>

        <Tabs.TabPane
          key={4}
          tab={
            <Tooltip title='統計每個商戶每日{總手續費加總}，並轉換為佔比。此圖表僅顯示一般商戶'>
              <span>{t('report.MchFeePercentTrendency')}</span>
            </Tooltip>
          }
        >
          <NormalMchFeePercentChart {...revenueState.get()} />
        </Tabs.TabPane>

        <Tabs.TabPane
          key={5}
          tab={
            <Tooltip title='每星期與每週之手續費比較'>
              <span>{t('report.totalFeeWeek')}</span>
            </Tooltip>
          }
        >
          <TotalFeeWeeklyChart {...revenueState.get()} />
        </Tabs.TabPane>

        <Tabs.TabPane
          key={6}
          tab={
            <Tooltip title='比較每個代理商戶與平台的的佣金收益'>
              <span>{t('report.AgentMCHCommissionAmountCompare')}</span>
            </Tooltip>
          }
        >
          <CommsionAmountChart {...revenueState.get()} />
        </Tabs.TabPane>
      </Tabs>
      <CoreProTable<RevenueItem>
        bordered
        rowKey={(record) => `${record.reportDate}:${record.systemId}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        title={() =>
          <Collapse defaultActiveKey={['1']}>
            <Panel header={'查詢統計'} key={1}>
              <Row gutter={[24, 24]} style={{ margin: '0.2rem' }}>
                <Col span={6}>
                </Col>
                <Col span={6}>
                  <Card loading={loading}>
                    <Statistic
                      title={'總手續費'}
                      // value={overall.overallFee??0}
                      value={revenueTotalState.get().totalFee + revenueTotalState.get().payforUsdtFee}
                      precision={6}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card loading={loading}>
                    <Statistic
                      title={'應付代理佣金'}
                      value={overall.overallCommissionAmount??0}
                      precision={6}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card loading={loading}>
                    <Statistic
                      title={'公司獲利金額'}
                      // value={overall.overallProfitAmount??0}
                      value={revenueTotalState.get().profitAmount + revenueTotalState.get().payforUsdtFee}
                      precision={6}
                    />
                  </Card>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        }
        formRef={searchFormRef}
        updateTableState={(state: any) => {
          const earlySearchQueryStr = searchState.get().queryStr;
          // pageOp.update((v) => ({ ...v, ...state }));
          pageOp.update((v) => {
            const start = (v as any).start;
            const end = (v as any).end;
            const systemId = v.params.systemId;
            const agentType = v.params.agentType;
            const profitAmountMoreThan = (v.params as any).profitAmountMoreThan;
            let totalFeeMoreThan = (v.params as any).totalFeeMoreThan;
            const hideLessZeroFlag = checkedState.get().checked;
            const searchQueryStr = `${start}:${end}:${systemId}:${agentType}:${profitAmountMoreThan}:${totalFeeMoreThan}:${hideLessZeroFlag}`;

            searchState.update({
              queryStr: searchQueryStr,
            });

            if (earlySearchQueryStr !== searchQueryStr) {
              ChartProvider.getRawRevenue({
                systemId,
                agentType,
                profitAmountMoreThan,
                totalFeeMoreThan,
                startDate: dateRangeColumn.getParamAndUpdatePage().start,
                endDate: dateRangeColumn.getParamAndUpdatePage().end,
                hideLessZero: hideLessZeroFlag,
              })
                .then((res) => {
                  revenueState.update({ dataSet: res.data });
                })
                .catch((e) => {
                  console.error(e);
                  throw e;
                });
            }

            const result = ChartProvider.getSummeryList({
              systemId,
              agentType,
              profitAmountMoreThan,
              totalFeeMoreThan,
              startDate: dateRangeColumn.getParamAndUpdatePage().start,
              endDate: dateRangeColumn.getParamAndUpdatePage().end,
              hideLessZero: hideLessZeroFlag,
            }).then((rs) => {
              setOverall({
                overallFee: rs.data?.overallFee,
                overallCommissionAmount: rs.data?.overallCommissionAmount,
                overallProfitAmount: rs.data?.overallProfitAmount,
              });
            })
              .catch((e) => {
                console.error(e);
                throw e;
              });

            return { ...v, ...state };
          });
        }}
        requestData={async (params: any) => {
          let res;
          const param = PaginationTransformer.paramTransfer(params);
          const queryObj = {
            ...param,
            startDate: dateRangeColumn.getParamAndUpdatePage().start,
            endDate: dateRangeColumn.getParamAndUpdatePage().end,
            hideLessZero: checkedState.get().checked,
          };
          res = await ChartProvider.getRevenueList(queryObj).catch((e) => {
            console.error(e);
            throw e;
          });
          setQueryData({
            ...param,
            startDate: dateRangeColumn.getParamAndUpdatePage().start,
            endDate: dateRangeColumn.getParamAndUpdatePage().end,
            hideLessZero: checkedState.get().checked,
          })
          const pageSummary = {
            totalFee: 0,
            payforUsdtFee: 0,
            commissionAmount: 0,
            profitAmount: 0,
          };
          const itemList = res.data.items;
          for (const item of itemList) {
            pageSummary.totalFee = new BigNumber(pageSummary.totalFee)
              .plus(item.totalFee)
              .toNumber();
            pageSummary.payforUsdtFee = new BigNumber(pageSummary.payforUsdtFee)
              .plus(item.payforUsdtFee)
              .toNumber();
            pageSummary.commissionAmount = new BigNumber(
              pageSummary.commissionAmount,
            )
              .plus(item.commissionAmount)
              .toNumber();
            pageSummary.profitAmount = new BigNumber(pageSummary.profitAmount)
              .plus(item.profitAmount)
              .toNumber();
          }
          revenueTotalState.update(pageSummary);
          // setRevenueTotalData(pageSummary);
          // const rawData = await ChartProvider.getRawRevenue({
          //   ...param,
          //   startDate: dateRangeColumn.getParamAndUpdatePage().start,
          //   endDate: dateRangeColumn.getParamAndUpdatePage().end,
          // }).catch((e) => {
          //   console.error(e);
          //   throw e;
          // });
          // revenueState.update({ dataSet: rawData.data });
          return PaginationTransformer.responseTransfer(res);
        }}
        onReset={() => {
          dateRangeColumn.reset();
          checkedState.update({
            checked: true,
          });
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: false,
            hideInTable: true,
            width: 80,
            ellipsis: true,
            dataIndex: 'hideLessZero',
            title: '隱藏手續費與應付代理傭金同時小於1的商戶',
            renderFormItem: (value) => (
              <Checkbox
                onChange={(value) => {
                  checkedState.update({
                    checked: value.target.checked,
                  });
                  return;
                }}
                checked={checkedState.get().checked}
              />
            ),
          },
          {
            hideInSearch: false,
            hideInTable: true,
            width: 80,
            ellipsis: true,
            dataIndex: 'profitAmountMoreThan',
            title: t('report.profitAmountMoreThan'),
          },
          {
            hideInSearch: false,
            hideInTable: true,
            width: 80,
            ellipsis: true,
            dataIndex: 'totalFeeMoreThan',
            title: t('report.totalFeeMoreThan'), //'手續費大於',
            tooltip: `篩選表身總手續費(註:若篩選手續費，因代理商戶無手續費而會被排除，故獲利率圖表會不扣除應付代理佣金)`,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('report.date'),
            dataIndex: 'reportDate',
            fixed: 'left',
            render: (_: any, row: RevenueItem) =>
              transfer2LocalDate(new Date(row.reportDate)),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'token',
            title: t('payment.token'),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'agentType',
            valueEnum: agentTypeEnum,
            title: t('report.agentType'),
            tooltip: '分為一般商戶與代理商戶',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'depositAmount',
            title: t('report.depositAmount'),
            tooltip: '商戶之充值金額加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.depositAmount, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'depositFee',
            title: t('report.depositFee'),
            tooltip: '商戶之充值手續費加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.depositFee, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'payoutAmount',
            title: t('report.payoutAmount'),
            tooltip: '商戶之代付金額加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.payoutAmount, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'payoutFee',
            title: t('report.payoutFee'),
            tooltip: '商戶之代付手續費和礦工費加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(parseFloat(`${row.payoutFee}`) + parseFloat(`${row.payforUsdtFee}`), 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            dataIndex: 'totalFee',
            title: t('report.total_fee'),
            tooltip: '={總收款金額+總出款金額}',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(parseFloat(`${row.totalFee}`) + parseFloat(`${row.payforUsdtFee}`), 2),
          },
          {
            hideInSearch: true,
            hideInTable: true,
            width: 120,
            dataIndex: 'payforUsdtFee',
            title: t('report.payforUsdtFee'),
            tooltip: '代付中所花費之轉帳費用',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.payforUsdtFee, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'commissionAmount',
            title: t('report.commissionAmount'),
            tooltip: '公司應付給此代理商戶之佣金加總',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(row.commissionAmount, 2),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'profitAmount',
            title: t('report.profitAmount'),
            tooltip: '={總手續費-應付代理佣金}',
            render: (_: any, row: RevenueItem) =>
              roundPrecision(parseFloat(`${row.profitAmount}`) + parseFloat(`${row.payforUsdtFee}`), 2),
          },
          dateRangeColumn.column,
        ]}
        summary={() => (
          <Table.Summary.Row style={{ backgroundColor: '#d9d9d9' }}>
            <Table.Summary.Cell index={1} align={'center'}>
              Total
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={3}></Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}></Table.Summary.Cell>
            <Table.Summary.Cell index={6}></Table.Summary.Cell>
            <Table.Summary.Cell index={7}></Table.Summary.Cell>
            <Table.Summary.Cell index={8}></Table.Summary.Cell>
            <Table.Summary.Cell index={9}>
              {revenueTotalState.get().totalFee + revenueTotalState.get().payforUsdtFee}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10}>
              {revenueTotalState.get().commissionAmount}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={11}>
              {revenueTotalState.get().profitAmount + revenueTotalState.get().payforUsdtFee}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    </>
  );
}
