import { Card, Form, Input, InputNumber } from 'antd';
import React from 'react';
import * as _ from 'lodash';
import { NetworkSetting, TokenSetting } from '../../usdt-providers/collectSettingProvider';
import TransferModeSettingFrom from './TransferModeSettingFrom';

const CollectTokenSettingForm = (props: {
  channel: string;
  data: any;
  tokenSetting: TokenSetting;
  networkFeeSetting: NetworkSetting;
}) => {
  return (
    <>
      {
        _.map(props.data, (tokenObj, token) => {
          if (tokenObj.active) {
            return (

              <Card key={props.channel + '_' + token} title={token + '設定'} style={{ marginBottom: 16 }}>
                <Form.Item
                  key={props.channel + '_' + token + '_collectDest'}
                  name={props.channel + '_' + token + '_collectDest'}
                  label='歸集地址'
                >
                  <Input
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_minimumAmount'}
                  name={props.channel + '_' + token + '_minimumAmount'}
                  label='最小歸集金額'
                  rules={[{ required: true, message: '請輸入 `最小歸集金額`' }]}
                >
                  <InputNumber precision={6} min={0} step={1} />
                </Form.Item>
                <TransferModeSettingFrom
                  transferMode={props.networkFeeSetting[props.channel][token].transferMode}
                  staticFee ={props.networkFeeSetting[props.channel][token].staticFee}
                  channel={props.channel}
                  token={token}/>
              </Card>);
          }
        })
      }
    </>
  );
};

export default CollectTokenSettingForm;

