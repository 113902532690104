import { adminClientMessage, AdminResErrorType } from '../../../config/adminRequest';
import { Button, Form, Input, Modal, Radio, Select, Spin, Tree } from 'antd';
import React, { RefObject, useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import SystemProvider, { SystemSettingResponseV2 } from '../../domain/usdt-providers/systemProvider';
import CoreDrawer from '../../core/components/CoreDrawer';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DomainConstants } from '../../domain/domainConstants';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { refreshSystemListState } from '../../../stores/system-list/actions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import DepositModeFrom from '../../domain/components/setttings/DepositModeFrom';
import { CreditSettingProvider } from '../../domain/usdt-providers/creditSettingProvider';

export enum SystemSettingActionType {
  AddAgent,
  AddNormal,
  Extend,
  Edit,
}

const merchantType = {
  NORMAL: {
    type: 'NORMAL',
    text: '一般商戶',
  },
  AGENT: { type: 'AGENT', text: '代理商戶' },
};

const fromEditErrorModel = (
  err: AdminResErrorType,
  state: { type: string },
) => {
  Modal.error({
    title: state.type,
    content: (
      <div>
        <ul>
          <li>原因: {adminClientMessage.get(err.clientCode) ?? err.message}</li>
          {err.clientCode === 'feeRate.check.error' ? (
            <>
              <li>欄位: {err.data.colName}</li>
              <li>最小值: {err.data.min}</li>
              <li>最大值: {err.data.max}</li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    ),
    okText: '確認',
  });
};

const initialSystemSettingResponse: SystemSettingResponseV2 = {
  parentSystem: 'ROOT',
  systemId: '',
  systemName: '',
  depositMode: 4,
  merchantType: DomainConstants.merchantType.NORMAL,
  lang: DomainConstants.languageType.zh,
  activeToken: {
    ETHEREUM: {
      ETH: false,
      USDT: false,
    },
    TRON: {
      USDT: false,
    },
    SOLANA: {
      USDT: false,
    },
    BSC: {
      BUSD: false,
      USDT: false,
      USDC: false,
    },
  },
};

const initMetaData = {
  curSystemId: undefined,
  parentSystem: undefined,
  title: '新增商戶',
};

enum ActionType {
  Closed,
}

function useLanglist() {
  return [
    {
      label: '中文',
      value: DomainConstants.languageType.zh,
    },
    {
      label: '英文',
      value: DomainConstants.languageType.en,
    },
    {
      label: '越南文',
      value: DomainConstants.languageType.vi,
    },
  ];
}

const initTreeData = [
  {
    title: 'Ethereum',
    key: 'Ethereum',
    disableCheckbox: false,
    children: [
      {
        title: 'USDT',
        key: 'Ethereum-USDT',
        disableCheckbox: false,
      },
      {
        title: 'ETH',
        key: 'Ethereum-ETH',
        disableCheckbox: false,
      },
    ],
  },
  {
    title: 'Tron',
    key: 'Tron',
    disableCheckbox: false,
    children: [
      {
        title: 'USDT',
        key: 'Tron-USDT',
        disableCheckbox: false,
      },
    ],
  },
  {
    title: 'Solana',
    key: 'Solana',
    disableCheckbox: false,
    children: [
      {
        title: 'USDT',
        key: 'Solana-USDT',
        disableCheckbox: false,
      },
    ],
  },
  {
    title: 'BSC',
    key: 'BSC',
    disableCheckbox: false,
    children: [
      {
        title: 'BUSD',
        key: 'BSC-BUSD',
        disableCheckbox: false,
      },
      {
        title: 'USDT',
        key: 'BSC-USDT',
        disableCheckbox: false,
      },
      {
        title: 'USDC',
        key: 'BSC-USDC',
        disableCheckbox: false,
      },
    ],
  },
];

const formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

const SystemSettingForm = (props: {
  type: SystemSettingActionType;
  visible: boolean;
  closeForm: () => void;
  systemId: string;
}) => {
  const languageList = useLanglist();
  const dispatch = useDispatch();
  const [treeData, setTreeData] = useState(initTreeData);
  const [curScopes, setCurScopes] = useState<Array<string>>([]);
  const [actionState] = useState(new Subject<ActionType>());

  const [actionType, setActionType] = useState<SystemSettingActionType>(
    props.type,
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SystemSettingResponseV2>(
    initialSystemSettingResponse,
  );
  const [metaData, setMetaData] = useState<{
    curSystemId: string | undefined;
    parentSystem: string | undefined;
    title: string;
  }>(initMetaData);

  const opLogger = useOperatorLogger();

  const successHandle = () => {
    if (formRef.current) formRef.current.resetFields();
    actionState.next(ActionType.Closed);
  };

  const formSave = () => {
    if (formRef.current) {
      switch (actionType) {
        case SystemSettingActionType.Edit:
          if (metaData.curSystemId)
            formRef.current.validateFields().then((value) => {
              value.activeToken = {
                ETHEREUM: {
                  ETH: !!_.find(curScopes, (x) => x === 'Ethereum-ETH'),
                  USDT: !!_.find(curScopes, (x) => x === 'Ethereum-USDT'),
                },
                TRON: {
                  USDT: !!_.find(curScopes, (x) => x === 'Tron-USDT'),
                },
                SOLANA: {
                  USDT: !!_.find(curScopes, (x) => x === 'Solana-USDT'),
                },
                BSC: {
                  BUSD: !!_.find(curScopes, (x) => x === 'BSC-BUSD'),
                  USDT: !!_.find(curScopes, (x) => x === 'BSC-USDT'),
                  USDC: !!_.find(curScopes, (x) => x === 'BSC-USDC'),
                },
              };

              if (value.systemKey) {
                Modal.confirm({
                  title: `商戶鑰匙修改`,
                  content: `確認修改商戶鑰匙?`,
                  okText: '確認',
                  okType: 'danger',
                  cancelText: '取消',
                  onOk: () => {
                    const d = JSON.parse(JSON.stringify(data));
                    const v = JSON.parse(JSON.stringify(value));
                    const req = { ...d, ...v };
                    SystemProvider.adminUpdateSystemVar(
                      { ...d, ...v },
                      metaData.curSystemId!,
                    ).then(
                      (rss) => {
                        Modal.success({
                          content: '修改成功',
                          okText: '確認',
                          onOk: () => successHandle(),
                        });
                        opLogger.log({
                          action: '修改',
                          payload: {
                            req: req,
                            res: rss,
                          },
                          systemId: metaData.curSystemId,
                        });
                      },
                      (err) => {
                        fromEditErrorModel(err as any, { type: '更新失敗' });
                        opLogger.log({
                          action: '修改',
                          payload: {
                            req: req,
                            res: err,
                          },
                          systemId: metaData.curSystemId,
                        });
                      },
                    );
                  },
                });
              } else {
                const d = JSON.parse(JSON.stringify(data));
                const v = JSON.parse(JSON.stringify(value));
                const req = { ...d, ...v };
                SystemProvider.adminUpdateSystemVar(
                  { ...d, ...v },
                  metaData.curSystemId!,
                ).then(
                  (rss) => {
                    Modal.success({
                      content: '修改成功',
                      okText: '確認',
                      onOk: () => successHandle(),
                    });
                    opLogger.log({
                      action: '修改',
                      payload: {
                        req: req,
                        res: rss,
                      },
                      systemId: metaData.curSystemId,
                    });
                  },
                  (err) => {
                    fromEditErrorModel(err as any, { type: '更新失敗' });
                    opLogger.log({
                      action: '修改',
                      payload: {
                        req: req,
                        res: err,
                      },
                      systemId: metaData.curSystemId,
                    });
                  },
                );
              }
            });
          break;
        default:
          formRef.current.validateFields().then((value) => {
            const v: SystemSettingResponseV2 = value;
            v.activeToken = {
              ETHEREUM: {
                ETH: !!_.find(curScopes, (x) => x === 'Ethereum-ETH'),
                USDT: !!_.find(curScopes, (x) => x === 'Ethereum-USDT'),
              },
              TRON: {
                USDT: !!_.find(curScopes, (x) => x === 'Tron-USDT'),
              },
              SOLANA: {
                USDT: !!_.find(curScopes, (x) => x === 'Solana-USDT'),
              },
              BSC: {
                BUSD: !!_.find(curScopes, (x) => x === 'BSC-BUSD'),
                USDT: !!_.find(curScopes, (x) => x === 'BSC-USDT'),
                USDC: !!_.find(curScopes, (x) => x === 'BSC-USDC'),
              },
            };
            if (v.depositMode === 5) {
              v.activeToken = {
                ETHEREUM: {
                  ETH: false,
                  USDT: false,
                },
                TRON: {
                  USDT: !!_.find(curScopes, (x) => x === 'Tron-USDT'),
                },
                SOLANA: {
                  USDT: false,
                },
                BSC: {
                  BUSD: false,
                  USDT: false,
                  USDC: false,
                },
              };
            }

            const req = {
              ...data,
              ...value,
            };
            SystemProvider.createSystem(req, metaData.parentSystem)
              .then((res) => {
                Modal.success({
                  content: '新增成功',
                  okText: '確認',
                });
                successHandle();
                opLogger.log({
                  action: '新增商戶',
                  payload: {
                    req: { req, parentSystem: metaData.parentSystem },
                    res: res,
                  },
                  systemId: metaData.curSystemId,
                });

                //更新商戶下拉
                dispatch(refreshSystemListState({ force: true }));
              })
              .catch((err) => {
                fromEditErrorModel(err, { type: '新增失敗' });
                opLogger.log({
                  action: '新增商戶',
                  payload: {
                    req: { req, parentSystem: metaData.parentSystem },
                    res: err,
                  },
                  systemId: metaData.curSystemId,
                });
              });
          });
          break;
      }
    }
  };

  function onDepositModeChange(value: { depositMode: number }) {
    if (value.depositMode === 5) {
      setTreeData([
        {
          title: 'Tron',
          key: 'Tron',
          disableCheckbox: false,
          children: [
            {
              title: 'USDT',
              key: 'Tron-USDT',
              disableCheckbox: false,
            },
          ],
        },
      ]);
    } else {
      setTreeData(initTreeData);
    }
  }

  useEffect(() => {
    if (!data.activeToken) {
      data.activeToken = initialSystemSettingResponse.activeToken;
    }
    const treeArr: Array<string> = [];
    if (data.activeToken.ETHEREUM.ETH) {
      treeArr.push('Ethereum-ETH');
    }
    if (data.activeToken.ETHEREUM.USDT) {
      treeArr.push('Ethereum-USDT');
    }
    if (data.activeToken.TRON.USDT) {
      treeArr.push('Tron-USDT');
    }
    if (data.activeToken.SOLANA.USDT) {
      treeArr.push('Solana-USDT');
    }
    if (data.activeToken.BSC.BUSD) {
      treeArr.push('BSC-BUSD');
    }
    if (data.activeToken.BSC.USDT) {
      treeArr.push('BSC-USDT');
    }
    if (data.activeToken.BSC.USDC) {
      treeArr.push('BSC-USDC');
    }
    setCurScopes(treeArr);
  }, [data]);

  useEffect(() => {
    setActionType(props.type);
  }, [props.type]);

  useEffect(() => {
    const curSystemId =
      actionType === SystemSettingActionType.Edit ? props.systemId : undefined;
    const parentSystem =
      actionType === SystemSettingActionType.Extend
        ? props.systemId
        : undefined;

    const title =
      actionType === SystemSettingActionType.AddAgent ||
      actionType === SystemSettingActionType.AddNormal
        ? `新增商戶`
        : actionType === SystemSettingActionType.Edit
          ? `編輯商戶 ${curSystemId}`
          : `新增 ${parentSystem} 子商戶`;
    setMetaData({ curSystemId, parentSystem, title });
    // console.log('setMetaData', metaData, props);
  }, [props.systemId, actionType]);

  useEffect(() => {
    if (metaData.curSystemId && props.visible) {
      const syd = metaData.curSystemId;
      setLoading(true);
      CreditSettingProvider.getOneCreditSetting(metaData.curSystemId).then(
        (res) => {
          setData({ ...data, depositMode: res.data.data.depositMode });
          const checkTreeData = _.cloneDeep(initTreeData);
          if (res.data.data.depositMode === 5) {
            _.map(checkTreeData, (parent) => {
              if (parent.title !== 'Tron') {
                parent.disableCheckbox = true;
                _.map(parent.children, (children) => {
                  children.disableCheckbox = true;
                });
              }
            });
            setTreeData(checkTreeData);
          } else {
            _.map(checkTreeData, (parent) => {
              parent.disableCheckbox = false;
              _.map(parent.children, (children) => {
                children.disableCheckbox = false;
              });
            });
            setTreeData(checkTreeData);
          }
          SystemProvider.getSystemAdminVar(syd)
            .then((data) => {
              if (
                actionType === SystemSettingActionType.Edit &&
                formRef.current
              ) {
                formRef.current.setFieldsValue(data);
                setData(data);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        },
      );
    }
    // eslint-disable-next-line
  }, [metaData, actionType, props.visible]);

  useEffect(() => {
    actionState.pipe(filter((x) => x === ActionType.Closed)).subscribe((x) => {
      setData(initialSystemSettingResponse);
      setMetaData(initMetaData);
      props.closeForm();
    });
    // eslint-disable-next-line
  }, [actionState]);

  return (
    <>
      <CoreDrawer
        title={metaData.title}
        width={640}
        visible={props.visible}
        onClose={() => {
          actionState.next(ActionType.Closed);
        }}
        destroyOnClose={true}
        footerbuttons={[
          <Button key={1} onClick={formSave}>
            送出
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            ref={formRef}
            layout='vertical'
            name='systemSettingForm'
            initialValues={data}
          >
            {actionType !== SystemSettingActionType.Edit ? (
              <>
                <Form.Item
                  name='merchantType'
                  label='商戶類型'
                  rules={[{ required: true, message: '請選擇 `商戶類型`' }]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      if (actionType === SystemSettingActionType.Extend) {
                      } else
                        setActionType(
                          (e.target.value = merchantType.AGENT
                            ? SystemSettingActionType.AddAgent
                            : SystemSettingActionType.AddNormal),
                        );
                    }}
                    options={Object.keys(merchantType).map((t) => ({
                      label: (merchantType as any)[t].text,
                      value: t,
                    }))}
                    optionType='button'
                    buttonStyle='solid'
                  />
                </Form.Item>
                <Form.Item
                  name='systemId'
                  label='商戶代號'
                  rules={[{ required: true, message: '請輸入 `商戶代號`' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='systemKey'
                  label='商戶鑰匙'
                  rules={[{ required: true, message: '請輸入 `商戶鑰匙`' }]}
                >
                  <Input />
                </Form.Item>
              </>
            ) : null}
            <Form.Item
              name='systemName'
              label='商戶名稱'
              rules={[{ required: true, message: '請輸入 `商戶名稱`' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='lang'
              label='商戶語系'
              rules={[{ required: true, message: '請選擇 `商戶語系`' }]}
            >
              <Select showSearch options={languageList} />
            </Form.Item>
            {actionType === SystemSettingActionType.Edit ? (
              <>
                <Form.Item
                  name='systemKey'
                  label='商戶鑰匙'
                  extra={`如不修改，請留空`}
                  rules={[{ required: false, message: '請輸入 `商戶鑰匙`' }]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item hidden name='enabled' label='啟用狀態'>
                  <Radio.Group buttonStyle='solid' size='small'>
                    <Radio.Button value={1}>啟用</Radio.Button>
                    <Radio.Button value={0}>禁用</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            ) : null}

            <Form.Item label='開啟的渠道與代幣'>
              <Tree
                blockNode
                checkable
                defaultExpandAll
                selectable={false}
                treeData={treeData}
                onCheck={(keys) => {
                  const keysArr = keys as string[];
                  if (data.activeToken.ETHEREUM.ETH) {
                    keysArr.push('Ethereum-ETH');
                  }
                  if (data.activeToken.ETHEREUM.USDT) {
                    keysArr.push('Ethereum-USDT');
                  }
                  if (data.activeToken.TRON.USDT) {
                    keysArr.push('Tron-USDT');
                  }
                  if (data.activeToken.SOLANA.USDT) {
                    keysArr.push('Solana-USDT');
                  }
                  if (data.activeToken.BSC.BUSD) {
                    keysArr.push('BSC-BUSD');
                  }
                  if (data.activeToken.BSC.USDT) {
                    keysArr.push('BSC-USDT');
                  }
                  if (data.activeToken.BSC.USDC) {
                    keysArr.push('BSC-USDC');
                  }
                  setCurScopes(keysArr);
                }}
                checkedKeys={curScopes.map((x) => x.toString())}
              />
            </Form.Item>
            {actionType !== SystemSettingActionType.Edit ? (
              <DepositModeFrom
                depositMode={data.depositMode}
                onChange={onDepositModeChange}
              />
            ) : null}
          </Form>
        </Spin>
      </CoreDrawer>
    </>
  );
};

export default SystemSettingForm;
