import moment from 'moment';

export const transfer2LocalMinTime = (date: Date | string) => {
  return moment(date).format('MM-DD HH:mm');
};

export const transfer2LocalTime = (date: Date | string) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const transfer2LocalDate = (date: Date | string) => {
  return moment(date).format('YYYY-MM-DD');
};
