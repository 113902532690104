import { moduleInit } from '../../core/base/moduleInit';
import { IPage, Page } from '../../core/configs/types';
import userTableList from '../pages/UserTableList';
import {
  READ_GROUPS,
  READ_GROUPS_ADMIN,
  READ_PERMISSION,
  READ_PROXY,
  READ_ROLES,
  READ_USERS,
  READ_USERS_ADMIN,
} from './scopes';
import groupTableList from '../pages/GroupTableList';
import roleTableList from '../pages/RoleTableList';
import scopeTableList from '../pages/ScopeTableList';
import proxyList from '../pages/ProxyList';

export const ModuleName = 'Permission';
export const ModuleIdentity = 'PERMISSIONS';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_PERMISSION = Symbol();

const PAGE_USER: IPage = Page.createInstance({
  id: 10201,
  uri: '',
  component: userTableList,
  scopes: [READ_USERS, READ_USERS_ADMIN],
});
const PAGE_GROUP: IPage = Page.createInstance({
  id: 10202,
  uri: '',
  component: groupTableList,
  scopes: [READ_GROUPS, READ_GROUPS_ADMIN],
});
const PAGE_ROLE: IPage = Page.createInstance({
  id: 10001,
  uri: '',
  component: roleTableList,
  scopes: [READ_ROLES],
});
const PAGE_PERMISSION: IPage = Page.createInstance({
  id: 10101,
  uri: '',
  component: scopeTableList,
  scopes: [READ_PERMISSION],
});
const PAGE_PROXY: IPage = Page.createInstance({
  id: 10104,
  uri: '',
  component: proxyList,
  scopes: [READ_PROXY],
});

_module.addPages([
  PAGE_USER,
  PAGE_GROUP,
  PAGE_ROLE,
  PAGE_PERMISSION,
  PAGE_PROXY,
]);
_module.setMenu([
  {
    symbol: MENU_SYMBOL_PERMISSION,
    label: '系統權限',
    menuId: 20,
    order: 20,
    children: [
      {
        label: '使用者',
        menuId: PAGE_USER,
        i18nLabel: { en_US: 'Users', zh_CN: '使用者清單', vi_VN:'Danh sách người dùng' },
      },
      {
        label: '群組',
        menuId: PAGE_GROUP,
        i18nLabel: {
          en_US: 'Groups',
          zh_CN: '群組清單',
          vi_VN:'Danh sách nhóm'
        },
      },
      {
        label: '角色',
        menuId: PAGE_ROLE,
        i18nLabel: { en_US: 'Roles', zh_CN: '角色清單',
          vi_VN:'Danh sách vai trò' },
      },
      {
        label: '權限',
        menuId: PAGE_PERMISSION,
        i18nLabel: {
          en_US: 'Permissions',
          zh_CN: '權限管理',
          vi_VN:'Danh sách quyền hạn',
        },
      },
      // {
      //     label: "代理",
      //     menuId: PAGE_PROXY,
      //     i18nLabel: { en_US: "Proxies", zh_CN: "代理管理" },
      // },
    ],
    i18nLabel: { en_US: 'Permission', zh_CN: '權限管控', vi_VN:'權限管控' },
  },
]);
