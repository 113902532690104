import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';
import adminRequest from '../../../config/adminRequest';

export type TokenRowType = {
  channel: string,
  token: string,
  creditFeeRate: number,
  innerDepositRate: number,
  creditAmountPrecision: number
}

export interface TokenSettingType {
  active: boolean;
  creditFeeRate: number;
  innerDepositRate: number;
  creditAmountPrecision: number;
  creditMinFee: number;
}

export type TokenSetting = {
  [channel: string]: {
    [token: string]: TokenSettingType;
  };
}

export interface DepositSetting {
  systemId: string;
  depositMode: number;
  notifyChannelFlag: boolean;
  notifyUrl: string;
  notifyTokenFlag: boolean;
  notifyFailUrl: string;
  notifySuccessUrl: string;
  changeOrderEnable: boolean;
  creditOrderDomain: string;
  depositNotifyChannelFlag: boolean;
  depositExpireDefaultTime: number;
  autoMatchPolling: boolean;
  tokenSetting: TokenSetting;
}

export interface FeeSetting {
  ETHEREUM: {
    USDT: {
      max: number,
      min: number,
    },
    ETH: {
      max: number,
      min: number,
    },
  },
  TRON: {
    USDT: {
      max: number,
      min: number,
    },
  },
  SOLANA: {
    USDT: {
      max: number,
      min: number,
    },
  },
  BSC: {
    BUSD: {
      max: number,
      min: number,
    },
    USDT: {
      max: number,
      min: number,
    },
    USDC: {
      max: number,
      min: number,
    }
  },
}

export default class DepositSettingProvider {

  static async getSetting(systemId: string) {
    const result = await adminRequest.get<AdminResponse<DepositSetting>>(
      UsdtUri(`admin/credit/setting/${systemId}`),
      {
        headers: {
          SYSTEM_ID: systemId,
        },
      },
    );
    return result.data.data;
  }

  static async updateSetting(data: DepositSetting) {
    const result = await adminRequest.put<AdminResponse<DepositSetting>>(
      UsdtUri(`admin/credit/setting/`),
      data,
    );
    return result.data.data;
  }

  static async getFeeRateRange(
    systemId?: string,
  ): Promise<FeeSetting> {
    const res = await adminRequest.get<AdminResponse<any>>(UsdtUri(`admin/credit/setting/range/`), {
      params: { systemId },
    });
    return res.data.data;
  }

}
