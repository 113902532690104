import { Dropdown, Menu, Modal, PageHeader, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import WalletProvider, { MchWallet } from '../../domain/usdt-providers/walletProvider';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import { useTranslation } from 'react-i18next';
import ProDescriptions from '@ant-design/pro-descriptions';
import ChangeAccountForm, { ChangeAccountProps, FormCloseEvent } from '../components/ChangeAccountForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import { SystemStatusEnum } from '../../domain/system-status';
import * as profitForm from '../components/ProfitWithdrawFrom';
import _ from 'lodash';
import { ADJUST_PAYMENT, CHANGE_WALLET, WRITE_PROFIT_WITHDRAW } from '../configs/scopes';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import _module from '../configs/module';

interface PageOp extends CoreProTableState<MchWallet> {
}

const defaultChangeAccount: ChangeAccountProps = {
  availableBalance: 0,
  txnBalance: 0,
  channel: '',
  token: '',
  systemId: '',
  visible: false,
  walletAddress: '',
  walletType: 'Mch',
};

const defaultProfitProps: profitForm.ProfitWithdrawProps = {
  balance: 0,
  channel: '',
  fromAddress: '',
  systemId: '',
  token: '',
  visible: false,
};

// eslint-disable-next-line
export default (props: { title: string; subTitle: string }) => {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  // const [currentRow, setCurrentRow] = useState<MchWallet>();
  // const [restoreFormVisible, setRestoreFormVisible] = useState(false);
  const [changeAccount, setChangeAccount] = useState<ChangeAccountProps>(
    defaultChangeAccount,
  );
  const [profit, setProfit] = useState<profitForm.ProfitWithdrawProps>(
    defaultProfitProps,
  );

  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const loggger = useOperatorLogger();

  const refresh = () => {
    pageOp.update((v) => {
      v.needRequest = true;
      return v;
    });
    actionRef.current?.reload();
  };

  const replaceWallet = async (param: {
    systemId: string;
    channel: string;
  }) => {
    Modal.confirm({
      title: '更換商戶錢包',
      content: '請確認是否要更換商戶錢包',
      onOk: async () => {
        await WalletProvider.replaceWallet(param)
          .then((res) => {
            loggger.log({
              action: '更換商戶錢包',
              payload: { req: param, res: res.data },
              systemId: param.systemId,
            });
            Modal.success({
              title: t('common.success'),
              content: (
                <ProDescriptions column={1}>
                  <ProDescriptions.Item label={t('payment.wallet_address')}>
                    {res.data.walletAddress}
                  </ProDescriptions.Item>
                </ProDescriptions>
              ),
            });
            refresh();
          })
          .catch((e) => {
            loggger.log({
              action: '更換商戶錢包',
              payload: { req: param, res: e },
              systemId: param.systemId,
            });
          });
      },
    });
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === FormCloseEvent.symbol))
      .subscribe(() => {
        setChangeAccount(defaultChangeAccount);
      });
    EventProvider.subject
      .pipe(
        filter(
          (x) =>
            x.eventSymbol === FormCloseEvent.symbol ||
            x.eventSymbol === profitForm.FormClosedEvent.symbol,
        ),
      )
      .subscribe(() => {
        refresh();
      });
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === profitForm.FormClosedEvent.symbol))
      .subscribe(() => {
        setProfit(defaultProfitProps);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageHeader title={props.title} subTitle={''} extra={<></>} />
      <CoreProTable<MchWallet>
        bordered
        rowKey={(row) =>
          `${row.systemId}:${row.channel}:${row.token}:${row.walletAddress}`
        }
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        // requestData={async (params) => {
        //   const res = await WalletProvider.getMchWalletPage(
        //     PaginationTransformer.paramTransfer(params),
        //   );
        //   return PaginationTransformer.responseTransfer(res);
        // }}

        requestData={(params) =>
          WalletProvider.getMchWalletPage(
            PaginationTransformer.paramTransfer({ ...params, isAdmin: true }),
          ).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params, res: rs },
              systemId: params.systemId,
            });
            return PaginationTransformer.responseTransfer(rs);
          })
        }
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            fixed: 'left',
            hideInSearch: false,
            width: 100,
            align: 'center',
            title: '商戶名稱',
            dataIndex: 'systemId',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            valueType: 'select',
            valueEnum: Token,
            onFilter: true,
            width: 70,
          },
          {
            hideInSearch: false,
            width: 100,
            align: 'center',
            title: '錢包地址',
            ellipsis: true,
            dataIndex: 'walletAddress',
          },
          {
            hideInSearch: true,
            width: 80,
            align: 'right',
            title: '餘額',
            dataIndex: 'balance',
          },
          {
            hideInSearch: true,
            width: 100,
            align: 'center',
            title: '更新時間',
            dataIndex: 'balanceUpdatedAt',
            render: (text: any, row: any) =>
              transfer2LocalTime(new Date(row.balanceUpdatedAt)),
          },
          {
            hideInSearch: true,
            width: 80,
            align: 'right',
            title: '帳變餘額',
            dataIndex: 'txnBalance',
          },
          {
            hideInSearch: true,
            width: 80,
            align: 'right',
            title: '可用餘額',
            dataIndex: 'availableBalance',
          },
          {
            title: '操作',
            width: 100,
            key: 'actions',
            valueType: 'option',
            fixed: 'right',
            align: 'center',
            render: (dom, row: MchWallet) => (
              <Space>
                {/*<Button*/}
                {/*  key={1}*/}
                {/*  hidden={row.token !== 'USDT'}*/}
                {/*  size={'small'}*/}
                {/*  disabled={!_module.can([SYNC_MCH_BLOCKCHAIN])}*/}
                {/*  type="primary"*/}
                {/*  onClick={() => {*/}
                {/*    setCurrentRow(row);*/}
                {/*    setRestoreFormVisible(true);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  回補充值交易*/}
                {/*</Button>*/}
                {row.token === Token.USDT && (
                  <Dropdown.Button
                    key={2}
                    size={'small'}
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          disabled={
                            row.systemStatus === SystemStatusEnum.Activated ||
                            !_module.can([CHANGE_WALLET])
                          }
                          key={1}
                          onClick={(x) => {
                            replaceWallet({
                              systemId: row.systemId,
                              channel: row.channel,
                            }).catch((e) => console.error(e));
                          }}
                        >
                          更換商戶錢包
                        </Menu.Item>
                        <Menu.Item
                          key={2}
                          disabled={
                            parseFloat(row.txnBalance as any) === 0 ||
                            !_module.can([ADJUST_PAYMENT])
                          }
                          onClick={(x) =>
                            setChangeAccount({
                              systemId: row.systemId,
                              visible: true,
                              walletAddress: row.walletAddress,
                              channel: row.channel,
                              token: row.token,
                              availableBalance: parseFloat(
                                row.availableBalance,
                              ),
                              txnBalance: parseFloat(row.txnBalance),
                              walletType: 'Mch',
                            })
                          }
                        >
                          調帳
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <div style={{ fontSize: '12px' }}>其它</div>
                  </Dropdown.Button>
                )}
              </Space>
            ),
          },
        ]}
      />
      {/*<MchDepositTxRestoreFrom*/}
      {/*  systemId={currentRow?.systemId!}*/}
      {/*  visible={restoreFormVisible}*/}
      {/*  onClose={() => {*/}
      {/*    setRestoreFormVisible(false);*/}
      {/*  }}*/}
      {/*/>*/}
      <ChangeAccountForm {...changeAccount} />
      <profitForm.ProfitWithdrawFrom {...profit} />
    </>
  );
};
