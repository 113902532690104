import {
  SpeedupProvirder,
  SpeedUpRef,
  SpeedupRes,
  SpeedUpTxnType,
} from '../../domain/usdt-providers/speedupProvirder';
import { Card, Col, Form, Modal, notification, Row, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import _ from 'lodash';
import { SmileOutlined } from '@ant-design/icons';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

export type SpeedUpFormProps = {
  orderId: string;
  visible: boolean;
  txnType: SpeedUpTxnType;
  systemId: string;
};

type State = {
  gasPrice: number;
  esTxnFee: number;
};

export class SpeedUpFormClosedEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: SpeedUpFormClosedEvent.symbol,
      payload: undefined,
      type: SpeedUpFormClosedEvent.name,
    });
  }
}

const calTxnFee = (gasPrice: number, limit: number, eth2USdtfeeRate: number) =>
  _.round(((gasPrice * limit) / 1000000000) * eth2USdtfeeRate, 2);

export default function SpeedUpForm(props: SpeedUpFormProps) {
  const { t } = useTranslation();
  const formRef = React.createRef<FormInstance>();
  const [refState, setRefState] = useState<
    SpeedUpRef & { loading: boolean; maxPirce: number }
  >({
    curGasPriceInfo: { avg: 5, high: 50 },
    gaslimit: 0,
    orderGasPrice: 0,
    loading: true,
    maxPirce: 0,
    ethToUsdt: 0,
  });
  const loggger = useOperatorLogger();
  const [state, setState] = useState<State>({
    gasPrice: 0,
    esTxnFee: 0,
  });
  const step = 5;

  const close = () => {
    EventProvider.addEvents(new SpeedUpFormClosedEvent());
  };
  const onchange = (value: any) => {
    if (_.isNaN(value)) return;
    const price = _.parseInt(value);
    const esTxnFee = calTxnFee(price, refState.gaslimit, refState.ethToUsdt);
    setState({ gasPrice: price, esTxnFee: esTxnFee });
  };
  const getMarks = () => {
    if (refState.curGasPriceInfo.avg === 0) {
      return {};
    }
    const cur = refState.curGasPriceInfo;
    return {
      [cur.avg]: t('speedup.avg_speed'),
      [refState.maxPirce]: t('speedup.high_speed'),
    };
  };

  const successNotify = (data: SpeedupRes) => {
    notification.success({
      message: `${t('speedup.speedup')}-${t('common.success')}`,
      description: (
        <div>
          <p>{`${t('payment.order_no')}: ${data.orderNo}`}</p>
          <p>{`${t('payment.blockchain_hash')}: ${data.txnHash}`}</p>
        </div>
      ),
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

  const speedUp = () => {
    const params = {
      gasPrice: state.gasPrice,
      orderId: props.orderId,
      type: props.txnType,
      systemId: props.systemId,
    }
    SpeedupProvirder.speedUp(params)
      .then((res) => {
        loggger.log({
          action: '加速',
          payload: { req: params, res: res.data },
          systemId: params.systemId,
        });
        successNotify(res.data);
        EventProvider.addEvents(new SpeedUpFormClosedEvent());
      })
      .catch((e) => {
        loggger.log({
          action: '加速',
          payload: { req: params, res: e },
          systemId: params.systemId,
        });
        console.error(e.message);
      });
  };

  useEffect(() => {
    if (!props.orderId) return;
    SpeedupProvirder.getSpeedUpRef({ orderId: props.orderId }).then((data) => {
      formRef.current?.setFieldsValue(data);
      const stepPrice =
        _.ceil(
          (data.curGasPriceInfo.high - data.curGasPriceInfo.avg) / step + 5,
        ) * step;
      console.log(stepPrice);
      setRefState({
        ...data,
        loading: false,
        maxPirce: stepPrice + data.curGasPriceInfo.avg,
      });
    });
    // eslint-disable-next-line
  }, [props.orderId]);
  return (
    <div>
      <Modal
        destroyOnClose
        title={t('speedup.speedup')}
        visible={props.visible}
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
        onOk={() => speedUp()}
        onCancel={() => close()}
      >
        <Form ref={formRef}>
          <Form.Item>
            <Slider
              onChange={onchange}
              min={refState.curGasPriceInfo.avg}
              max={refState.maxPirce}
              step={step}
              marks={getMarks()}
            />
          </Form.Item>
        </Form>
        <Row>
          <Col span={12} style={{ padding: '0.2rem' }}>
            <Card
              size={'small'}
              title={t('speedup.speedup')}
              style={{ color: 'blue' }}
              loading={refState.loading}
            >
              <div>GasPrice: {state.gasPrice} (Gwei)</div>
              <div>
                {t('speedup.es_transfer_fee')}: {state.esTxnFee} (USDT)
              </div>
            </Card>
          </Col>
          <Col span={12} style={{ padding: '0.2rem' }}>
            <Card
              size={'small'}
              title={t('speedup.order_info')}
              loading={refState.loading}
            >
              <div>GasPrice: {refState.orderGasPrice} (Gwei)</div>
              <div>
                {t('speedup.es_transfer_fee')}:
                {' ' +
                  calTxnFee(
                    refState.orderGasPrice,
                    refState.gaslimit,
                    refState.ethToUsdt,
                  )}{' '}
                (USDT)
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <p style={{ color: 'red', marginTop: '0.2rem' }}>
            {t('speedup.remind_text')}
          </p>
        </Row>
      </Modal>
    </div>
  );
}
