import React from "react";
import { Modal } from "antd";
import moment from "moment";
import i18next from "i18next";


interface props {
  homeList: any;
}

interface stae {
  item: any;
  show: boolean;
}


class PlatformInfo extends React.Component<props, stae> {
  state = {
    item: {},
    show: false,
  };
  showAnnouncement = (item: any) => {
    this.setState({ item, show: true });
  };
  handleCancel = () => {
    this.setState({ item: {}, show: false });
  };
  render() {
    const { homeList = [] } = this.props;
    const { show } = this.state;
    const item: any = this.state.item;
    const announcement = homeList.announcement || [];
    return (
      <div className="admin-content-platform">
        <p className="platform-title">{i18next.t('common.proclamation')}</p>
        <ul className="platform-main admin-scroll-two">
          {announcement.map((item: any) => (
            <li
              key={item.announcementId}
              onClick={() => this.showAnnouncement(item)}
            >
              <p>{item.cnSummary}</p>
              <span>{moment(item.createTime).format("MM-DD HH:mm")} 发布</span>
            </li>
          ))}
        </ul>
        {show ? (
          <Modal
            visible={true}
            onCancel={this.handleCancel}
            footer={null}
          >
            <div dangerouslySetInnerHTML={{ __html: `${item.cnContent}` }}></div>

          </Modal>
        ) : (
            ""
          )}
      </div>
    );
  }
}

export default PlatformInfo;
