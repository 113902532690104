import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: require('./locales/en.json'),
  },
  zh: {
    translation: require('./locales/zh.json'),
  },
  vi: {
    translation: require('./locales/vi.json'),
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['main'],
    lng: 'zh',
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
