import { Form, Input, Modal } from 'antd';
import { CreditOrder, DepositOrderProvider } from '../../domain/usdt-providers/depositOrderProvider';
import ProDescriptions from '@ant-design/pro-descriptions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

export class ChangeOrderFinishEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      type: ChangeOrderFinishEvent.name,
      eventSymbol: ChangeOrderFinishEvent.symbol,
      payload: null,
    });
  }
}

export type ChangeOrderProps = {
  visible: boolean;
  order?: CreditOrder;
  isAdmin?: boolean;
};

export default function ChangeOrderFrom(props: ChangeOrderProps) {
  const { t } = useTranslation();
  const [state, setState] = useState<ChangeOrderProps>(props);
  const editCustomerFrom = React.createRef<FormInstance>();
  const finish = () => {
    EventProvider.addEvents(new ChangeOrderFinishEvent());
    setState({ visible: false, order: undefined });
  };
  const loggger = useOperatorLogger();

  const changeOrder = () => {
    editCustomerFrom.current?.validateFields().then((data) => {
      const params = {
        ...data,
        ...(state.isAdmin && { systemId: state.order?.systemId }),
      }
      DepositOrderProvider.changePollingOrder(params)
        .then((res) => {
          loggger.log({
            action: '調帳',
            payload: { req: params, res: res.data },
            systemId: params.systemId,
          });
          Modal.success({
            title: t('common.update_success'),
            content: t('common.update_success'),
            onOk: () => {
              finish();
            },
          });
        })
        .catch((err) => {
          loggger.log({
            action: '調帳',
            payload: { req: params, res: err },
            systemId: params.systemId,
          });
          Modal.error({
            title: t('common.update_fail'),
            content: (
              <ProDescriptions>
                <ProDescriptions.Item label={t('common.error_cause')}>
                  {err.message}
                </ProDescriptions.Item>
              </ProDescriptions>
            ),
          });
        });
    });
  };
  useEffect(() => {
    setState(props);
  }, [props]);
  return (
    <Modal
      destroyOnClose
      title={t('credit.change_order')}
      visible={state.visible}
      okText={t('common.confirm')}
      cancelText={t('common.cancel')}
      onOk={() => changeOrder()}
      onCancel={() => finish()}
    >
      <Form
        ref={editCustomerFrom}
        initialValues={{ changedOrderNo: props.order?.orderNo }}
      >
        <Form.Item
          name="sourceOrderNo"
          label={`${t('credit.status.mapping_fail')}-${t('payment.order_no')}`}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="changedOrderNo"
          label={t('payment.order_no')}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
