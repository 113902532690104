import { Popover, Space, Table, Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  TaskInfo,
  TransferTaskProvider,
} from '../../domain/usdt-providers/transferTaskProvider';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../domain/channel/channel';
import { Environment } from '../../core/configs/services';

type Props = {
  showText: React.ReactNode;
  orderId: string;
  channel: string;
};
export type dataType = {
  status: string;
  txnHash: string;
};

const tagColor = {
  success: 'success',
  processing: 'processing',
  error: 'error',
};

const TaskStatus = {
  Draft: 'Draft',
  Start: 'Start',
  Pending: 'Pending',
  Success: 'Success',
  Fail: 'Fail',
};

type LinkType = { txnHash: string };

function TronscanLink(props: LinkType) {
  const env = Environment() ?? 'develop';
  let url = _.find(['prod', 'stg'], (x) => x === env)
    ? 'https://tronscan.org/#/transaction/'
    : 'https://shasta.tronscan.org/#/transaction/';

  return (
    <>
      <span>
        { /*eslint-disable-next-line */ }
        <a href={url + props.txnHash} target="_blank">
          {props.txnHash}
        </a>
      </span>
    </>
  );
}

function EtherscanLink(props: LinkType) {
  const env = Environment() ?? 'develop';
  let url = _.find(['prod', 'stg'], (x) => x === env)
    ? 'https://etherscan.io/tx/'
    : 'https://ropsten.etherscan.io/tx/';
  return (
    <>
      <span>
        { /*eslint-disable-next-line */ }
        <a href={url + props.txnHash} target="_blank">
          {props.txnHash}
        </a>
      </span>
    </>
  );
}

function SolanaLink(props: LinkType) {
  const env = Environment() ?? 'develop';
  const host = 'https://solscan.io/tx';

  const cluster = _.find(['prod', 'stg'], (x) => x === env)
    ? '' 
    : 'cluster=devnet';
  return (
    <>
      <span>
        { /*eslint-disable-next-line */ }
        <a href={host + props.txnHash + cluster} target="_blank">
          {props.txnHash}
        </a>
      </span>
    </>
  );
}

export function TxnHashPoper(props: Props) {
  const { t } = useTranslation();
  const [color, setColor] = useState<string>(tagColor.processing);
  const [tasks, setTasks] = useState<TaskInfo[]>();

  const processColorFromStatus = (status: string) => {
    switch (status) {
      case TaskStatus.Draft:
      case TaskStatus.Start:
      case TaskStatus.Pending:
        return tagColor.processing;
      case TaskStatus.Fail:
        return tagColor.error;
      case TaskStatus.Success:
        return tagColor.success;
    }
  };

  useEffect(() => {
    TransferTaskProvider.getTaskList(props.orderId).then((res) => {
      const data: TaskInfo[] = res.data;
      setTasks(data);
    });
  }, [props.orderId]);

  useEffect(() => {
    setColor(
      _.find(
        tasks,
        (x) =>
          x.status === TaskStatus.Draft ||
          x.status === TaskStatus.Start ||
          x.status === TaskStatus.Pending,
      )
        ? tagColor.processing
        : _.find(tasks, (x) => x.status === TaskStatus.Success)
        ? tagColor.success
        : _.find(
            tasks,
            (x) =>
              x.status === TaskStatus.Fail,
          )
        ? tagColor.error
        : tagColor.processing,
    );
  }, [tasks]);

  return (
    <>
      <Space>
        {props.showText}
        <Popover
          placement="right"
          title={'轉帳狀態列表'}
          content={
            <Table<TaskInfo>
              scroll={{ x: 'fixed', y: 'fixed' }}
              rowKey={'txnHash'}
              style={{ width: 550 }}
              dataSource={tasks}
              pagination={false}
              columns={[
                {
                  fixed: 'left',
                  align: 'center',
                  ellipsis: true,
                  title: t('common.status'),
                  dataIndex: 'stauts',
                  width: 70,
                  render: (node, row) => {
                    return <Tag color={processColorFromStatus(row.status)}>
                      {t(`transferTaskStatus.${row.status}`)}
                    </Tag>
                  },
                },
                {
                  ellipsis: true,
                  title: t('payment.blockchain_hash'),
                  dataIndex: 'txnHash',
                  width: 70,
                  render: (node, row) => {
                    return props.channel === Channel.ETHEREUM ? (
                      <EtherscanLink txnHash={row.txHash} />
                    ) : props.channel === Channel.TRON ? (
                      <TronscanLink txnHash={row.txHash} />
                    ) : props.channel === Channel.SOLANA ? (
                      <SolanaLink txnHash={row.txHash} />
                    ) : null
                  }
                  ,
                },
              ]}
            />
          }
          arrowPointAtCenter
        >
          <Tag
            style={{ margin: 'auto' }}
            icon={
              color === tagColor.processing ? (
                <SyncOutlined spin />
              ) : color === tagColor.success ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
            color={color}
          >
            轉帳狀態
          </Tag>
        </Popover>
      </Space>
    </>
  );
}
