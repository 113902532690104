import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Header from './header';
import LeftMenu from './leftMenu';
import { UserStore } from '../stores/user';
import { GlobalStoreMerge } from '../stores/merge';
import { useTranslation } from 'react-i18next';
import { Menu } from '../modules/core/configs/menus';

const Outer = () => {
  const user = useSelector<GlobalStoreMerge, UserStore>((state) => state.user);
  const flexible = useSelector<GlobalStoreMerge, boolean>(
    (state) => state.menu.leftMenuCollapsed,
  );
  const { i18n } = useTranslation();
  const menu = useSelector<GlobalStoreMerge, Menu[]>(
    (state) => state.menu.treeMenu,
  );
  useEffect(() => {
    const curLang = i18n.language;
    if (curLang !== user.lang) {
      i18n.changeLanguage(user.lang).catch((e) => console.error(e));
    }
  }, [user, i18n]);
  const isLogin = user.isLogin;
  return isLogin ? (
    <div className={flexible ? 'admin-outer on' : 'admin-outer'}>
      <Header />
      <LeftMenu menu={menu} />
    </div>
  ) : (
    <></>
  );
};

export default Outer;
