import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminUri } from '../../core/configs/services';
import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminResponse } from '../models/adminResponse';
import { Role } from './mchRoleProvider';

export interface GroupBase {
  id?: number;
  groupDescription?: string;
  groupDisplayName?: string;
  groupName?: string;
  description: string;
  displayName: string;
  name: string;
}

export interface Group extends GroupBase {
  id: number;
  roles: Array<Role>;
  systemId: string;
}

export class MchGroupProvider {
  static async getList(params: PaginationQuery, systemId?: string) {
    const res = await adminRequest.get<AdminListResponse<Group>>(
      AdminUri('admin/merchant/user/groups'),
      { params: { ...params, systemId } },
    );
    return res.data;
  }

  static async getAll() {
    const res = PaginationTransformer.getAllFromPagination<Group, null>(
      (query) => MchGroupProvider.getList(query),
      null,
    );
    return res;
  }

  static async get(groupId: string | number) {
    const res = await adminRequest.get<AdminResponse<Group>>(
      AdminUri(`admin/merchant/user/groups/${groupId}`),
    );
    return res.data;
  }

  static async patch(groupId: string | number, payload: Partial<Group>) {
    const res = await adminRequest.patch<AdminResponse<any>>(
      AdminUri(`admin/merchant/user/groups/${groupId}`),
      payload,
    );
    return res.data;
  }

  static async create(payload: Partial<Group>) {
    const res = await adminRequest.post<AdminResponse<Group>>(
      AdminUri(`admin/merchant/user/groups`),
      payload,
    );
    return res.data;
  }

  static async delete(groupId?: string | number) {
    const res = await adminRequest.delete<AdminResponse<any>>(
      AdminUri(`admin/merchant/user/groups/${groupId}`),
    );
    return res.data;
  }
}
