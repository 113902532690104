import { ProCoreActionType } from '@ant-design/pro-utils';
import { Button, Modal, PageHeader } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import CollectOrderDetail from '../../domain/components/order/CollectOrderDetail';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { CollectOrder, CollectTaskProvider } from '../../domain/usdt-providers/collectTaskProvider';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import * as _ from 'lodash';
import { parseErrorCause } from '../../core/utils/errorParse';
import { useCollectStatusTranslate } from '../../domain/order-status/orderStatusTranslateHook';

interface PageOp extends CoreProTableState<CollectOrder> {
}

// eslint-disable-next-line
export default (props: { title: string; subTitle: string }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const collectStatusEnum = useCollectStatusTranslate();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });
  const logger = useOperatorLogger();
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader title={props.title} subTitle={props.subTitle} extra={<></>} />
      <CoreProTable<CollectOrder>
        bordered
        rowKey='taskId'
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        formRef={searchFormRef}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        // requestData={(params: any) => {
        //   console.log(params);
        //   return CollectTaskProvider.getAdminCollectOrder({
        //     ...params,
        //     ...dateRangeColumn.getParamAndUpdatePage(),
        //   });
        // }}

        requestData={(params) => {
          const param = {
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
          };

          const res = CollectTaskProvider.getAdminCollectOrder(param).then((rs) => {
            logger.log({
              action: '查詢',
              payload: { req: param },
              systemId: params.systemId,
            });
            return rs;
          });
          return res;
        }
        }

        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 150,
            align: 'center',
            title: '任務編號',
            dataIndex: 'taskId',
            fixed: 'left',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.channel'),
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.token'),
            valueEnum: Token,
            dataIndex: 'token',
          },
          {
            width: 70,
            dataIndex: 'systemId',
            hideInSearch: false,
            title: '商戶名稱',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            align: 'center',
            title: '用戶編號',
            dataIndex: 'merchantUserId',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            align: 'center',
            title: '充值錢包地址',
            dataIndex: 'srcAddress',
            // render: (node, row) => <HexLabel hex={row.srcAddress} size={"small"}/>,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            align: 'center',
            title: '目標地址',
            dataIndex: 'targetAddress',
            // render: (node, row) => <HexLabel hex={row.targetAddress} size={"small"}/>,
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 60,
            align: 'center',
            title: '任務狀態',
            dataIndex: 'status',
            valueEnum: collectStatusEnum,
            render: (n, row) => {
              let statusText = t('collect.status.'+row.status.toLowerCase());

              // if (row.status.toLowerCase() != 'ordercreated'
              //   ||row.status.toLowerCase() != 'payerfundinsufficient'
              //   ||row.status.toLowerCase() != 'networkfeetxncreated'
              //   ||row.status.toLowerCase() != 'networkfeetxnsuccess'
              //   ||row.status.toLowerCase() != 'networkfeesufficient'
              //   ||row.status.toLowerCase() != 'tokentxncreated'
              //   ||row.status.toLowerCase() != 'tokentxnsuccess'
              // ) {
              if (row.status.toLowerCase() === 'tokentxnfail' ||
              row.status.toLowerCase() === 'networkfeetxnfail'
              ) {
                if (!_.isNil(row.failReason)) {
                  statusText += parseErrorCause(row.failReason);
                }
              }
              return statusText;
            },
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: '歸集前帳變餘額',
            dataIndex: 'txnAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: '歸集前可用餘額',
            dataIndex: 'availableAmount',
          },
          {
            title: '轉帳費用',
            hideInSearch: true,
            dataIndex: 'networkFee',
            width: 100,
            align: 'center',
          },
          dateRangeColumn.column,
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: '建立時間',
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            title: '操作',
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 80,
            render: (text: any, row: CollectOrder) => (
              <Button
                size={'small'}
                key='editable'
                onClick={(e) => {
                  e.preventDefault();
                  logger.log({
                    action: '查看',
                    payload: { data: row },
                    systemId: row.systemId,
                  });
                  Modal.info({
                    title: '',
                    width: '640px',
                    content: (
                      <CollectOrderDetail
                        title={'歸集訂單'}
                        order={row}
                        isAdmin={true}
                      />
                    ),
                  });
                }}
              >
                {t('common.view')}
              </Button>
            ),
          },
        ]}
      />
    </>
  );
};
