import React, { useEffect, useRef } from 'react';
import { Area, AreaOptions } from '@antv/g2plot';
import { transfer2LocalDate } from '../../../core/utils/timeFormat';
import BigNumber from 'bignumber.js';
import { RevenueItem } from '../../../domain/report-provider/chartProvider';
import _ from 'lodash';
import moment from 'moment';

export type RevenueChartProps = {
  dataSet: Array<RevenueItem>;
};

const RevenueChart = (props: RevenueChartProps) => {
  const ref = useRef<any>();
  const chartRef = useRef<Area>();

  const changeData = (data: Array<RevenueItem>) => {
    chartRef.current?.changeData(calculateChartData(data));
  };

  useEffect(() => {
    const rawData = props.dataSet;
    const chartData = calculateChartData(rawData);
    const config: AreaOptions = {
      data: chartData,
      padding: 'auto',
      xField: 'date',
      yField: 'rate',
      seriesField: 'yFieldName',
      xAxis: {
        type: 'cat',
        label: {
          autoRotate: false,
          formatter: (v) => {
            return transfer2LocalDate(v);
          },
        },
      },
      yAxis: {
        label: {
          formatter: (v) => {
            return v + '%';
          },
        },
      },
      // isPercent: true,
      areaStyle: () => {
        return {
          fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
        };
      },
    };
    // config.data = props.dataSet
    const line = new Area(ref.current, config);
    chartRef.current = line;
    line.render();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeData(props.dataSet);
  }, [props.dataSet]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default RevenueChart;

function calculateChartData(rawData: RevenueItem[]) {
  const sumDictionary: any = {};
  for (const item of rawData) {
    const date = transfer2LocalDate(item.reportDate);
    const profitAmount = item.profitAmount;
    const totalFee = item.totalFee;
    if (!sumDictionary[date.toString()]) {
      sumDictionary[date.toString()] = {
        summeryProfit: 0,
        summeryTotalFee: 0,
      };
      sumDictionary[date.toString()].summeryProfit = new BigNumber(
        profitAmount,
      ).toNumber();
      sumDictionary[date.toString()].summeryTotalFee = new BigNumber(
        totalFee,
      ).toNumber();
    } else {
      sumDictionary[date.toString()].summeryProfit = new BigNumber(
        sumDictionary[date.toString()].summeryProfit,
      )
        .plus(profitAmount)
        .toNumber();
      sumDictionary[date.toString()].summeryTotalFee = new BigNumber(
        sumDictionary[date.toString()].summeryTotalFee,
      )
        .plus(totalFee)
        .toNumber();
    }
  }
  const result = [];
  for (const i in sumDictionary) {
    let rate: number;
    if (sumDictionary[i].summeryTotalFee === 0) {
      rate = 0;
    } else {
      rate = _.round(
        new BigNumber(sumDictionary[i].summeryProfit)
          .dividedBy(sumDictionary[i].summeryTotalFee)
          .multipliedBy(100)
          .toNumber(),
        2,
      );
    }
    result.push({
      yFieldName: '獲利率',
      timestamp: moment(i).valueOf(),
      date: i,
      rate: rate,
    });
  }
  return _.sortBy(result, (item) => item.timestamp);
}
