import { READ_MERCHANT_GROUPS, READ_MERCHANT_ROLES, READ_MERCHANT_USERS, READ_SYSTEM } from '../configs/scopes';
import { IPage, Page } from '../../core/configs/types';
import UserTableList from './UserTableList';
import GroupTableList from './GroupTableList';
import RoleTableList from './RoleTableList';
import SystemList from './SystemList';
import DepositSettingPage from './DepositSettingPage';
import PayforSettingPage from './PayforSettingPage';
import WithdrawSettingPage from './WithdrawSettingPage';
import CollectSettingPage from './CollectSettingPage';

export const PAGE_USER: IPage = Page.createInstance({
  id: 10401,
  uri: '',
  component: UserTableList,
  scopes: [READ_MERCHANT_USERS],
});
export const PAGE_GROUP: IPage = Page.createInstance({
  id: 10402,
  uri: '',
  component: GroupTableList,
  scopes: [READ_MERCHANT_GROUPS],
});
export const PAGE_ROLE: IPage = Page.createInstance({
  id: 10403,
  uri: '',
  component: RoleTableList,
  scopes: [READ_MERCHANT_ROLES],
});
export const PAGE_SYSTEM: IPage = Page.createInstance({
  id: 10404,
  uri: '',
  component: SystemList,
  scopes: [READ_SYSTEM],
});
export const DEPOSIT_SETTING: IPage = Page.createInstance({
  id: 10405,
  uri: '',
  component: DepositSettingPage,
  scopes: [READ_SYSTEM],
});
export const PAYOUT_SETTING: IPage = Page.createInstance({
  id: 10406,
  uri: '',
  component: PayforSettingPage,
  scopes: [READ_SYSTEM],
});
export const COLLECT_SETTING: IPage = Page.createInstance({
  id: 10407,
  uri: '',
  component: CollectSettingPage,
  scopes: [READ_SYSTEM],
});
export const WITHDRAW_SETTING: IPage = Page.createInstance({
  id: 10408,
  uri: '',
  component: WithdrawSettingPage,
  scopes: [READ_SYSTEM],
});
// eslint-disable-next-line
export default [PAGE_USER, PAGE_GROUP, PAGE_ROLE, PAGE_SYSTEM, DEPOSIT_SETTING, PAYOUT_SETTING, COLLECT_SETTING, WITHDRAW_SETTING];
