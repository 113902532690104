export const READ_USERS = 'READ_USERS';
export const READ_USERS_ADMIN = 'READ_USERS_ADMIN';
export const WRITE_USERS = 'WRITE_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const READ_GROUPS = 'READ_GROUPS';
export const READ_GROUPS_ADMIN = 'READ_GROUPS_ADMIN';
export const WRITE_GROUPS = 'WRITE_GROUPS';
export const DELETE_GROUPS = 'DELETE_GROUPS';
export const READ_ROLES = 'READ_ROLES';
export const WRITE_ROLES = 'WRITE_ROLES';
export const DELETE_ROLES = 'DELETE_ROLES';
export const READ_PERMISSION = 'READ_PERMISSION';
export const WRITE_PERMISSION = 'WRITE_PERMISSION';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const READ_PROXY = 'READ_PROXY';
export const WRITE_PROXY = 'WRITE_PROXY';
export const DELETE_PROXY = 'DELETE_PROXY';
