import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import { UsdtUri } from '../../core/configs/services';
import { RequestData } from '@ant-design/pro-table';
import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';

export interface MchWallet {
  systemId: string;
  walletAddress: string;
  balance: number;
  balanceUpdatedAt: string;
  txnBalance: string;
  availableBalance: string;
  ethBalance: number;
  systemStatus: string;
  token: string;
  channel: string;
}

export interface DepositWallet {
  walletAddress: string;
  systemId: string;
  token: string;
  channel: string;
  userId: string;
  available: boolean;
  sign: string;
  balance: string;
  balanceUpdatedAt: string;
  txnBalance: string;
  availableBalance: string;
  collectCount: number;
  collectStatus: string;
}

export interface MchWalletChangeLog {
  txnId: number;
  txnHash: string;
  walletAddress: string;
  systemId: string;
  orderNo: string;
  tradeCode: string;
  channel: string;
  token: string;
  availableAmount: number;
  txnAmount: number;
  availableBeforeBalance: number;
  availableAfterBalance: number;
  txnBeforeBalance: number;
  txnAfterBalance: number;
  comment: string;
  usdtFee: number;
  createdAt: Date;
  createdBy: string;
}

export type MchChangeLogFilter = {
  excluedTradeCode?: string[];
  start?: string;
  end?: string;
};

export default class WalletProvider {
  static async getMchWalletPage(
    params: any & {
      isAdmin?: boolean;
    },
  ): Promise<AdminListResponse<MchWallet>> {


    let url = UsdtUri(`/merchant/payment/merchant/wallet`);
    if (params.isAdmin) {
      url = UsdtUri('/admin/payment/merchant/wallet');
    }

    const res = await adminRequest.get<AdminListResponse<MchWallet>>(
      url,
      {
        params: {
          ...params,
        },
      },
    );
    return res.data;
  }

  static async getMchWalletInfo() {
    const res = await adminRequest.get<AdminResponse<MchWallet[]>>(
      UsdtUri('merchant/payment/merchant-wallet-info'),
    );
    return res.data;
  }

  static async getExcel(params?:
    MchChangeLogFilter & {
    start: string;
    end: string;
    systemId?: string;
    isAdmin?: boolean;
    token?: string;
    isCollectRecord?: boolean;
    isAvailableBalanceChanged?: boolean;
  },) {

    let filter = { ...params };
    filter.isAvailableBalanceChanged = false;
    filter.excluedTradeCode = ['1006', '1007', '3004', '4003'];
    filter.token = params?.token;
    if ( !params?.isCollectRecord ) {
      filter.excluedTradeCode.push('2001');
      filter.excluedTradeCode.push('2002');
    }

    const res = await adminRequest.get<any>(
      UsdtUri('/merchant/payment/txn/merchant-wallet/xlsx'),
      { params: filter, responseType: 'blob' },
    );
    return res.data;
  }

  static async getMchWalletChangeLogPagination(
    params?: PaginationQuery &
      MchChangeLogFilter & {
      systemId?: string;
      isAdmin?: boolean;
      token?: string;
      isCollectRecord?: boolean;
      isAvailableBalanceChanged?: boolean;
    },
  ) {
    let url = UsdtUri('merchant/payment/txn/merchant-wallet');
    let filter = { ...params };
    delete filter.isAdmin;
    if (params?.isAdmin) {
      url = UsdtUri(`/admin/payment/txn/merchant-wallet`);
      filter.excluedTradeCode = [];
    } else {
      filter.isAvailableBalanceChanged = false;
      filter.excluedTradeCode = ['1006', '1007', '3004', '4003'];
      filter.token = params?.token;
      if ( !params?.isCollectRecord ) {
        filter.excluedTradeCode.push('2001');
        filter.excluedTradeCode.push('2002');
      }
    }
    const res = await adminRequest.get<AdminListResponse<MchWalletChangeLog>>(
      url,
      {
        params: filter,
      },
    );
    return res.data;
    // return res.data;
  }

  static async getUserWalletTxnPagination(
    params: any  & {
      isAvailableBalanceChanged?: boolean;
    },
    option?: { systemId?: string },
  ): Promise<RequestData<any>> {
    let filter = { ...params };

    let url = UsdtUri(`/admin/payment/txn/user-wallet`);
    if (!!option?.systemId) {
      url = UsdtUri('merchant/payment/txn/user-wallet');
      filter.isAvailableBalanceChanged = false;
    }

    const res = await adminRequest.get<AdminListResponse<any>>(url, {
      params: {
        ...PaginationTransformer.paramTransfer({
          ...filter,
        }),
      },
    });
    return PaginationTransformer.responseTransfer(res.data);
    // return res.data;
  }

  static async restoreDepositTx(data: {
    transactionHash: string;
    channel: string;
    systemId: string;
  }) {
    const res = await adminRequest.post<AdminResponse<MchWalletChangeLog>>(
      UsdtUri('admin/mch-wallet/restore-txn'),
      data,
    );
    return res.data;
  }

  static async replaceWallet(param: { systemId: string; channel: string }) {
    const res = await adminRequest.post<AdminResponse<{
      systemId: string;
      channel: string;
      walletAddress: string;
    }>>(UsdtUri('admin/payment/merchant/replace-wallet'), param);
    return res.data;
  }

  static async addAvailableBalance(req: {
    systemId: string;
    walletAddress: string;
    channel: string;
    token: string;
    availableBalanceChange: number;
    reason: string;
  }) {
    const res = await adminRequest.post<AdminResponse<boolean>>(
      UsdtUri('admin/mch-wallet/add-available'),
      req,
    );
    return res.data;
  }
  static async addUserAvailableBalance(req: {
    systemId: string;
    walletAddress: string;
    channel: string;
    token: string;
    availableBalanceChange: number;
    reason: string;
  }) {
    const res = await adminRequest.post<AdminResponse<boolean>>(
      UsdtUri('admin/payment/deposit-wallet/add-available'),
      req,
    );
    return res.data;
  }
}
