import { RequestData } from '@ant-design/pro-table';
import { omit } from 'lodash';
import { UsdtUri } from '../../core/configs/services';
import { PayforRecord } from '../order-status/payforOrderTypes';
import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';

export class PayforCreateFailListProvider {
  static async getAdminCreateFailList(params?: {
    pageSize?: number;
    current?: number;
    systemId?: string;
    start?: Date;
    end?: Date;
  }): Promise<RequestData<PayforRecord>> {
    const rs = await adminRequest.get<AdminListResponse<any>>(
      UsdtUri(`/admin/payment/create-fail-list/payfor`),
      {
        params: {
          ...omit(params, ['current', 'pageSize']),
          page: params?.current,
          limit: params?.pageSize,
        },
      },
    );
    return {
      success: true,
      data: rs.data.data.items,
      total: rs.data.data.meta.totalItems,
    };
  }
}
