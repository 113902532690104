import { moduleInit } from '../../core/base/moduleInit';
import { Page } from '../../core/configs/types';
import RefundOrders from '../pages/RefundOrders';
import { AgentReportProvider } from '../../domain/report-provider/agentReportProvider';
import i18next from 'i18next';
import { useDepositRefundResStatus, useDepositRefundSearchStatus, usePayoutRefundStatus } from '../hook/orderStatus';
import RefundDaily from '../pages/RefundDaily';
import { AgentReportScope } from './scopes';

export const ModuleName = 'Agent-Report';
export const ModuleIdentity = 'AGENT-REPORT';
export const ReadOrder = 'READ_ORDER';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_AGENT_REPORT = Symbol();

const mchDepositPage = Page.createInstance({
  id: 21000,
  uri: '',
  component: () => (
    <RefundOrders
      resStatusMapping={useDepositRefundResStatus}
      reqStatusEnum={useDepositRefundSearchStatus}
      title={i18next.t('agent.mch_deposit_record')}
      request={(param) => AgentReportProvider.getDepositOrder(param)}
    />
  ),
  scopes: [AgentReportScope.READ_ORDER],
  onClose: () => null,
});

const mchPayoutPage = Page.createInstance({
  id: 21001,
  uri: '',
  component: () => (
    <RefundOrders
      resStatusMapping={usePayoutRefundStatus}
      reqStatusEnum={usePayoutRefundStatus}
      title={i18next.t('agent.mch_payout_record')}
      request={(param) => AgentReportProvider.getPayoutOrder(param)}
    />
  ),
  scopes: [AgentReportScope.READ_ORDER],
  onClose: () => null,
});

const depositRefundDailyPage = Page.createInstance({
  id: 21002,
  uri: '',
  component: () => (
    <RefundDaily
      resStatusMapping={useDepositRefundResStatus}
      reqStatusEnum={useDepositRefundSearchStatus}
      title={i18next.t('agent.mch_deposit_daily')}
      request={(param) => AgentReportProvider.getDepositDaily(param)}
      requestExcel={(param) => AgentReportProvider.getDepositDailyExcel(param)}
    />
  ),
  scopes: [AgentReportScope.READ_ORDER],
  onClose: () => null,
});

const payoutRefundDailyPage = Page.createInstance({
  id: 21003,
  uri: '',
  component: () => (
    <RefundDaily
      resStatusMapping={usePayoutRefundStatus}
      reqStatusEnum={usePayoutRefundStatus}
      title={i18next.t('agent.mch_payout_daily')}
      request={(param) => AgentReportProvider.getPayoutDaily(param)}
      requestExcel={(param) => AgentReportProvider.getPayoutDailyExcel(param)}
    />
  ),
  scopes: [AgentReportScope.READ_ORDER],
  onClose: () => null,
});

_module.addPages([
  mchDepositPage,
  mchPayoutPage,
  depositRefundDailyPage,
  payoutRefundDailyPage,
]);
_module.setMenu([
  {
    symbol: MENU_SYMBOL_AGENT_REPORT,
    label: '代理管理',
    menuId: 210,
    order: 50,
    iconId: 14,
    i18nLabel: { en_US: 'Agent Management', zh_CN: '代理管理',vi_VN:'Quản lý Đại Lý' },
    children: [
      {
        label: '商戶代收紀錄',
        menuId: mchDepositPage,
        i18nLabel: { en_US: 'Merchant Deposit', zh_CN: '商戶代收紀錄',vi_VN:'Nguồn nạp từ thương nhân' },
      },
      {
        label: '商戶代付紀錄',
        menuId: mchPayoutPage,
        i18nLabel: { en_US: 'Merchant Payout', zh_CN: '商戶代付紀錄',vi_VN:'Lịch sử thanh toán hộ của Thương nhân' },
      },
      {
        label: '商戶代收統計',
        menuId: depositRefundDailyPage,
        i18nLabel: {
          en_US: 'Merchant Deposit Daily',
          zh_CN: '商戶代收統計',vi_VN:'Thống kê thu hộ ví Thương Nhân'
        },
      },
      {
        label: '商戶代付統計',
        menuId: payoutRefundDailyPage,
        i18nLabel: {
          en_US: 'Merchant Payout Daily',
          zh_CN: '商戶代付統計',vi_VN:'Tổng kết thanh toán hộ của Thương nhân'
        },
      },
    ],
  },
]);
