import { Subject } from 'rxjs';

export type MenuEvent = {
  type: MenuEventEnum;
  payload?: any;
};

export enum MenuEventEnum {
  Opened,
  Closed,
}

export class MenuEventManager {
  static subject = new Subject<MenuEvent>();

  static publish(e: MenuEvent) {
    this.subject.next(e);
  }

  static publishAll(...events: MenuEvent[]) {
    for (const e of events) {
      this.subject.next(e);
    }
  }
}
