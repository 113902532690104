import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { omit } from 'lodash';
import { CollectRecord } from '../types/collectRecordListResponse';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { CollectTaskProvider } from '../../domain/usdt-providers/collectTaskProvider';
import { useTranslation } from 'react-i18next';
import { useCollectStatusTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import CollectOrderDetail from '../../domain/components/order/CollectOrderDetail';
import ExcelDownloadFormButton from '../../core/components/ExcelDownloadFormButton';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import { ChannelValueEnum, MchToken } from '../../domain/channel/channel';

interface PageOp extends CoreProTableState<CollectRecord> {
}

// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const collectStatusTranslateEnum = useCollectStatusTranslate();
  // const dispatch = useDispatch();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader
        title={t('collect.order_list_title')}
        // subTitle={t('collect.order_list_subtitle')}
        extra={<></>}
      />
      <CoreProTable<CollectRecord>
        formRef={searchFormRef}
        scroll={{ x: 'fixed' }}
        search={{ layout: 'vertical' }}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        toolBarRender={() => [
          <ExcelDownloadFormButton
            getExcelFile={(start, end) =>
              CollectTaskProvider.getExcel({ start, end })
            }
            filePrefix={'collect_usdt'}
          />,
        ]}
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 150,
            align: 'center',
            title: t('collect.task_id'),
            dataIndex: 'taskId',
            fixed: 'left',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            valueEnum: ChannelValueEnum,
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            valueEnum: MchToken,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            align: 'center',
            title: t('payment.merchant_user_id'),
            dataIndex: 'merchantUserId',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            align: 'center',
            title: t('payment.wallet_address'),
            dataIndex: 'srcAddress',
            render: (node, row) => <HexLabel hex={row.srcAddress} size={'small'} />,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            align: 'center',
            title: t('collect.destination'),
            dataIndex: 'targetAddress',
            render: (n, row) => <HexLabel hex={row.targetAddress} size={'small'} />,
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 60,
            align: 'center',
            title: t('collect.task_status'),
            dataIndex: 'status',
            valueEnum: collectStatusTranslateEnum,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('collect.available_before_balance'),
            dataIndex: 'availableAmount',
          },
          {
            title: t('payment.usdt_fee'),
            hideInSearch: true,
            dataIndex: 'networkFee',
            width: 100,
            align: 'center',
          },
          dateRangeColumn.column,
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          // {title: '操作', key: '_action', dataIndex: 'id', valueType: 'option', width: 200, render: (text, row) => [
          //     <Button key={1}>加速歸集</Button>
          // ]},
          {
            title: t('common.operate'),
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 80,
            render: (text: any, row: CollectRecord) => (
              <Button
                size={'small'}
                key='editable'
                onClick={(e) => {
                  e.preventDefault();
                  Modal.info({
                    title: '',
                    width: '640px',
                    content: (
                      <CollectOrderDetail
                        title={t('collect.order_list_title')}
                        order={row}
                        isAdmin={false}
                      />
                    ),
                  });
                }}
              >
                {t('common.view')}
              </Button>
            ),
          },
        ]}
        rowKey='key'
        requestData={(params: any, sort, filter) => {
          if (params.status === 'ALL') {
            delete params.status;
          }
          return CollectTaskProvider.getCollectTaskList({
            page: params.current,
            limit: params.pageSize,
            walletAddress: params.fromAddress,
            ...omit(params, [
              'current',
              'pageSize',
              'createdAt',
              'fromAddress',
            ]),
            ...dateRangeColumn.getParamAndUpdatePage(),
          }).then((res) => {
            const rs = res;
            return {
              data: rs.data.items.map((el: any, index: number) => ({
                key: `${index}`,
                ...el,
              })),
              success: true,
              total: rs.data.meta.totalItems,
            };
          });
        }}
      />
    </>
  );
};
