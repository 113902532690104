import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const fileDownload = require('js-file-download');

export default function ExcelDowloadButton(props: {
  getExcel: () => Promise<any>;
  filePrefix: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Button
        key={1}
        type="primary"
        onClick={() => {
          props.getExcel().then((data) => {
            fileDownload(
              data,
              `${props.filePrefix}_${new Date().getTime()}.xlsx`,
            );
          });
        }}
      >
        <DownloadOutlined />
        {t('common.export_excel')}
      </Button>
    </>
  );
}
