import React, { useEffect, useRef } from 'react';
import { Area, AreaOptions } from '@antv/g2plot';
import { transfer2LocalDate } from '../../../core/utils/timeFormat';
import BigNumber from 'bignumber.js';
import { RevenueItem } from '../../../domain/report-provider/chartProvider';
import _ from 'lodash';
import moment from 'moment';

export type RevenueChartProps = {
  dataSet: Array<RevenueItem>;
};

const FeeRefundTrendencyChart = (props: RevenueChartProps) => {
  const ref = useRef<any>();
  const chartRef = useRef<Area>();

  const changeData = (data: Array<RevenueItem>) => {
    chartRef.current?.changeData(calculateChartData(data));
  };

  useEffect(() => {
    const rawData = props.dataSet;
    const chartData = calculateChartData(rawData);
    const config: AreaOptions = {
      data: chartData,
      padding: 'auto',
      xField: 'date',
      yField: 'value',
      seriesField: 'genre',
      color: ['#e56262d9', '#85db8cd9'],
      isStack: false,
      xAxis: {
        type: 'cat',
        label: {
          autoRotate: false,
          formatter: (v) => {
            return transfer2LocalDate(v);
          },
        },
      },
      yAxis: {
        // label: {
        //   formatter: (v) => {
        //     return v + '%';
        //   },
        // }
      },
      legend: {
        position: 'left-top',
      },
      smooth: true,
    };
    // config.data = props.dataSet
    const line = new Area(ref.current, config);
    chartRef.current = line;
    line.render();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeData(props.dataSet);
  }, [props.dataSet]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default FeeRefundTrendencyChart;

function calculateChartData(rawData: RevenueItem[]) {
  const sumDictionary: any = {};
  for (const item of rawData) {
    const date = transfer2LocalDate(item.reportDate);
    const commissionAmountAmount = item.commissionAmount;
    // const totalFee = item.totalFee;
    const totalFee = new BigNumber(item.totalFee)
      .plus(new BigNumber(item.payforUsdtFee))
      .toNumber();
    if (!sumDictionary[date.toString()]) {
      sumDictionary[date.toString()] = {
        summeryCommissionAmountAmount: 0,
        summeryTotalFee: 0,
      };
      sumDictionary[
        date.toString()
      ].summeryCommissionAmountAmount = new BigNumber(
        commissionAmountAmount,
      ).toNumber();
      sumDictionary[date.toString()].summeryTotalFee = new BigNumber(
        totalFee,
      ).toNumber();
    } else {
      sumDictionary[
        date.toString()
      ].summeryCommissionAmountAmount = new BigNumber(
        sumDictionary[date.toString()].summeryCommissionAmountAmount,
      )
        .plus(commissionAmountAmount)
        .toNumber();
      sumDictionary[date.toString()].summeryTotalFee = new BigNumber(
        sumDictionary[date.toString()].summeryTotalFee,
      )
        .plus(totalFee)
        .toNumber();
    }
  }
  const result = [];
  for (const i in sumDictionary) {
    result.push({
      genre: '手續費',
      timestamp: moment(i).valueOf(),
      date: i,
      value: sumDictionary[i].summeryTotalFee,
    });
    result.push({
      genre: '佣金',
      timestamp: moment(i).valueOf(),
      date: i,
      value: sumDictionary[i].summeryCommissionAmountAmount,
    });
  }
  return _.sortBy(result, (item) => item.timestamp);
}
