import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import { PayforOrderStatus, PayforRecord } from '../../domain/order-status/payforOrderTypes';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { PayforOrderProvider } from '../../domain/usdt-providers/payforOrderProvider';
import {
  useCreditStatusTranslate,
  useNotifyStatusTranslate,
  usePayforStatusTranslate,
} from '../../domain/order-status/orderStatusTranslateHook';
import { useTranslation } from 'react-i18next';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import ReportDetail from '../../domain/components/order/PayforReportDetail';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType, DateRangeType } from '../../core/utils/tableUtilType';
import SpeedUpForm, { SpeedUpFormClosedEvent, SpeedUpFormProps } from '../component/SpeedUpForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import { Channel, ChannelValueEnum, Token } from '../../domain/channel/channel';
import { SpeedUpTxnType } from '../../domain/usdt-providers/speedupProvirder';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { OPERATION_PAYFOR_RETRY_NOTIFY, OPERATION_PAYFOR_SPEEDUP } from '../configs/scopes';
import _module from '../configs/module';
import * as _ from 'lodash';
import { parseErrorCause } from '../../core/utils/errorParse';

interface PageOp extends CoreProTableState<PayforRecord> {
}

const defaulSpeedUpData: SpeedUpFormProps = {
  visible: false,
  orderId: '',
  txnType: SpeedUpTxnType.payfor,
  systemId: '',
};

// eslint-disable-next-line
export default (props: { title: string; subTitle: string }) => {
  // const dispatch = useDispatch();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const payforStatusTranslateEnum = usePayforStatusTranslate();
  const notifyStatusTranslateEnum = useNotifyStatusTranslate();
  const notifyStatusEnum = useCreditStatusTranslate();
  const [speedUpState, setSpeedUpState] = useState<SpeedUpFormProps>(
    defaulSpeedUpData,
  );
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const loggger = useOperatorLogger();
  const searchOp = usePageState<DateRangeType>('SEARCH_PARAM', {});
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp: searchOp,
    state: dateRangeState,
  });
  const retryNotify = (row: PayforRecord) => {
    Modal.confirm({
      title: '警吿',
      content: '請確認是否要立即發送充值通知',
      okText: '確認',
      onOk: async () => {
        if (
          row.notifyStatus === PayforOrderStatus.NotifyStatusTypes.NotifySuccess
        ) {
          Modal.confirm({
            title: '警吿',
            content:
              '訂單已通知成功，可能導致對方系統錯誤，請確認是否要重新發送',
            okText: '確認',
            onOk: async () => {
              await resendNotify(row);
            },
          });
        } else {
          await resendNotify(row);
        }
      },
    });
  };

  const retryTransfer = (row: PayforRecord) => {
    Modal.confirm({
      title: '警吿',
      content: '請確認是否要重新嘗試轉帳',
      okText: '確認',
      onOk: async () => {
        await PayforOrderProvider.retryFailOrderTransfer({
          systemId: row.systemId,
          orderNo: row.orderNo,
        })
          .then((rs) => {
            loggger.log({
              action: '重試',
              payload: { req: row, res: rs },
              systemId: row.systemId,
            });
            Modal.success({
              title: '成功通知',
              content: '已重新建立轉帳任務',
              okText: '確認',
            });
          })
          .catch((e) => {
            loggger.log({
              action: '重試',
              payload: { req: row, res: e },
              systemId: row.systemId,
            });
          });
      },
    });
  };

  const resendNotify = async (row: PayforRecord) => {
    await PayforOrderProvider.triggerReNotify(row).then(
      (rs) => {
        loggger.log({
          action: '通知',
          payload: { req: row, res: rs },
          systemId: row.systemId,
        });
        Modal.success({
          title: '成功通知',
          content: (
            <ul>
              <li>status: {(notifyStatusEnum as any)[rs.notifyStatus]}</li>
              <li>response: {rs.notifyResponse}</li>
            </ul>
          ),
          okText: '確認',
        });
      },
      (e) => {
        loggger.log({
          action: '通知',
          payload: { req: row, res: e },
          systemId: row.systemId,
        });
      },
    );
  };

  const speedup = (row: PayforRecord) => {
    setSpeedUpState({
      orderId: row.orderId,
      visible: true,
      txnType: SpeedUpTxnType.payfor,
      systemId: row.systemId,
    });
  };

  const checkSpeedUp = (row: PayforRecord) => {
    if (row.status !== PayforOrderStatus.OrderStatus.Pending) {
      return false;
    }
    if (row.channel !== Channel.ETHEREUM) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === SpeedUpFormClosedEvent.symbol))
      .subscribe((x) => {
        setSpeedUpState(defaulSpeedUpData);
      });
  }, []);
  return (
    <>
      <PageHeader title={props.title} subTitle={props.subTitle} extra={<></>} />
      <CoreProTable<PayforRecord>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        scroll={{ x: 'fixed' }}
        rowKey='orderNo'
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        requestData={(params) =>
          PayforOrderProvider.getAdminPayforOrder({
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
            txHash: params.transactionHash,
          }).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params },
              systemId: params.systemId,
            });
            return rs;
          })
        }
        columns={[
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '訂單編號',
            dataIndex: 'orderNo',
            fixed: 'left',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.channel'),
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            align: 'center',
            title: t('payment.token'),
            valueEnum: Token,
            dataIndex: 'token',
          },
          {
            width: 100,
            dataIndex: 'systemId',
            hideInSearch: false,
            align: 'center',
            title: '商戶名稱',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '商戶訂單編號',
            dataIndex: 'customOrderNo',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '訂單狀態',
            dataIndex: 'status',
            valueEnum: payforStatusTranslateEnum,
            render: (n, row) => {
              let statusText = t('payfor.status.' + row.status.toLowerCase());
              if (row.status === 'Fail') {
                if (!_.isNil(row.failReason)) {
                  statusText +=  parseErrorCause(row.failReason);
                }
              }
              return statusText;
            },
          },
          {
            dataIndex: 'notifyStatus',
            hideInSearch: true,
            width: 100,
            align: 'center',
            title: '通知狀態',
            valueEnum: notifyStatusTranslateEnum,
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: false,
            width: 120,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'right',
            title: '訂單金額',
            dataIndex: 'orderAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 60,
            align: 'right',
            title: t('credit.from_amount'),
            dataIndex: 'fromAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 60,
            align: 'center',
            title: t('credit.from_currency'),
            dataIndex: 'fromCurrency',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 60,
            align: 'right',
            title: t('credit.exchange_rate'),
            dataIndex: 'exchangeRate',
          },
          {
            hideInSearch: true,
            width: 60,
            align: 'right',
            title: t('payment.fee'),
            dataIndex: 'fee',
          },
          // {
          //   title: '轉帳模式',
          //   dataIndex: 'transferMode',
          //   hideInSearch: true,
          //   width: 100,
          //   align: 'center',
          //   valueEnum: TypeTransferMode,
          // },
          {
            hideInSearch: true,
            title: '轉帳費用',
            dataIndex: 'networkFee',
            width: 100,
            align: 'right',
          },
          // {
          //   width: 350,
          //   dataIndex: 'notifyResponse',
          //   hideInSearch: true,
          //   title: '通知結果',
          //   render: (_: any, row: PayforRecord) =>
          //     ` 最後通知時間: ${transfer2LocalTime(
          //       new Date(row.notifyAt),
          //     )} ; 通知次數: ${row.notifyCount ?? '-'} ; 結果: ${
          //       row.notifyResponse
          //     }`,
          // },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: '交易創建時間',
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          dateRangeColumn.column,
          {
            title: '操作',
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 200,
            render: (text: any, row: PayforRecord) =>
              // 加入是否通知，如果是以完成通知的狀態要再確認一次
              [
                <Button
                  size={'small'}
                  key='editable'
                  onClick={(e) => {
                    e.preventDefault();
                    loggger.log({
                      action: '查看',
                      payload: { data: row },
                      systemId: row.systemId,
                    });
                    Modal.info({
                      title: '',
                      width: '640px',
                      content: (
                        <ReportDetail
                          title={'代付訂單'}
                          order={row}
                          isAdmin={true}
                        />
                      ),
                    });
                  }}
                >
                  {t('common.view')}
                </Button>,
                <Button
                  key={1}
                  disabled={!_module.can([OPERATION_PAYFOR_RETRY_NOTIFY])}
                  danger={_module.can([OPERATION_PAYFOR_RETRY_NOTIFY])}
                  size={'small'}
                  onClick={() => retryNotify(row)}
                >
                  通知
                </Button>,
                row.status === PayforOrderStatus.OrderStatus.Fail && (
                  <Button
                    key={2}
                    type={'primary'}
                    disabled={!_module.can([OPERATION_PAYFOR_RETRY_NOTIFY])}
                    size={'small'}
                    onClick={() => retryTransfer(row)}
                  >
                    重試
                  </Button>
                ),
                checkSpeedUp(row) && (
                  <Button
                    key={2}
                    type={'primary'}
                    disabled={!_module.can([OPERATION_PAYFOR_SPEEDUP])}
                    size={'small'}
                    onClick={() => speedup(row)}
                  >
                    {t('speedup.speedup')}
                  </Button>
                ),
              ],
          },
        ]}
      />
      <SpeedUpForm {...speedUpState} />
    </>
  );
};