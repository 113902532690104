import React from 'react';

export interface IPage {
    id: number;
    uri: string;
    component: any;
    scopes: Array<string>,
    onOpen?: (...arg: any[]) => any;
    onClose?: (...arg: any[]) => any,
}
export class Page implements IPage {
    component: any;
    id: number;
    scopes: Array<string>;
    uri: string;
    onOpen?: (...arg: any[]) => any;
    onClose?: (...arg: any[]) => any;
    constructor(data: IPage) {
        this.id = data.id;
        this.scopes = data.scopes;
        this.uri = data.uri;
        this.component = data.component;
        this.onOpen = (dispatch: any) => {
            dispatch({ type: 'SETUP_PAGE', payload: { id: data.id } });
            return data.onOpen && data.onOpen(dispatch)
        };
        this.onClose = (dispatch: any) => {
            dispatch({ type: 'CLEAR_PAGE', payload: { id: data.id } })
            return data.onClose && data.onClose(dispatch)
        };
    }
    public static createInstance(data: IPage): IPage {
        return new Page(data);
    }
    public static createInstances(data: IPage[]): IPage[] {
        return data.map(x => Page.createInstance(x));
    }
}
export const NULL_KEY_ELEMENT = <span key={`rand-${Date.now()}`}/>;
