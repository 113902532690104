import ProDescriptions from '@ant-design/pro-descriptions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { transfer2LocalTime } from '../../../core/utils/timeFormat';
import { CollectOrder } from '../../usdt-providers/collectTaskProvider';

export default function CollectOrderDetail(props: {
  order: CollectOrder;
  isAdmin: boolean;
  title: string;
}) {
  const { t } = useTranslation();
  const order = props.order;
  return (
    <>
      <ProDescriptions column={2} title={props.title} dataSource={order}>
        {[
          { label: t('collect.task_id'), dataIndex: 'taskId', hide: false, span: 2 }, // prettier-ignore
          { label: t('payment.channel'), dataIndex: 'channel' },
          { label: t('payment.token'), dataIndex: 'token' },
          { label: t('payment.system_id'), dataIndex: 'systemId', hide: !props.isAdmin },
          { label: t('payment.mch_user_id'), dataIndex: 'merchantUserId' },
          { label: t('collect.task_status'), dataIndex: 'status' },
          { label: t('collect.available_before_balance'), dataIndex: 'availableAmount', span: 2 },
          { label: t('payment.usdt_fee'), dataIndex: 'networkFee', span: 2 },
          { label: t('collect.collect_wallet_title'), dataIndex: 'srcAddress', span: 2 },
          { label: t('collect.destination'), dataIndex: 'targetAddress', span: 2 },
          { label: t('common.createdAt'), children: transfer2LocalTime(new Date(order.createdAt)), span: 2 }, // prettier-ignore
        ]
          .filter((el) => el.hide !== true)
          .map((el, index) => {
            return (
              <ProDescriptions.Item
                key={index}
                label={el.label}
                span={el.span}
                dataIndex={el.dataIndex}
                children={el.children}
              />
            );
          })}
      </ProDescriptions>
    </>
  );
}
