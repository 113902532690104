import { Button, Card, Descriptions, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { AdminResponse } from '../../domain/models/adminResponse';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import { ProfitProvider } from '../../domain/usdt-providers/profitProvider';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { UserOutlined } from '@ant-design/icons';

export class FormClosedEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: FormClosedEvent.symbol,
      payload: null,
      type: FormClosedEvent.name,
    });
  }
}

export type ProfitWithdrawProps = {
  visible: boolean;
  token: string;
  channel: string;
  fromAddress: string;
  systemId: string;
  balance: number;
};

export function ProfitWithdrawFrom(props: ProfitWithdrawProps) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [whiteList, setWhiteList] = useState<{ value: string; label: string }[]>([]);
  const loggger = useOperatorLogger();
  const { t } = useTranslation();
  const issueFormRef = React.createRef<FormInstance>();
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const closeFrom = () => {
    EventProvider.addEvents(new FormClosedEvent());
  };

  const submitIssueForm = () => {
    issueFormRef.current?.validateFields().then((values) => {
      setConfirmLoading(true);
      const params = {
        destAddress: values.address,
        operatorId: user.userId,
        orderAmount: values.amount,
        systemId: props.systemId,
        token: props.token,
        walletAddress: props.fromAddress,
        channel: props.channel,
        code: values.code,
      };
      ProfitProvider.createOrder(params)
        .then((res) => {
          loggger.log({
            action: '利潤下發',
            payload: { req: params, res: res.data },
            systemId: params.systemId,
          });
          message.success({
            content: t('common.success'),
          });
          closeFrom();
        })
        .catch((e) => {

          loggger.log({
            action: '利潤下發',
            payload: { req: params, res: e },
            systemId: params.systemId,
          });

          const err = e as AdminResponse<any>;
          console.error(e);
          Modal.error({
            content: err.message,
            okText: t('common.confirm'),
          });
        })
        .finally(() => setConfirmLoading(false));
    });
  };

  useEffect(() => {
    ProfitProvider.getWhiteList({ channel: props.channel })
      .then((list) => {
        const channelWhites = _.chain(list)
          .tap((x) => console.log(x, whiteList))
          .filter((x) => x.channel === props.channel)
          .map((x) => ({
            label: x.walletAddress,
            value: x.walletAddress,
          }))
          .value();
        setWhiteList(channelWhites);
      })
      .catch((e) => {
        console.error('get white list fail', e);
        closeFrom();
      });
    // eslint-disable-next-line
  }, [props.channel]);

  return (
    <Modal
      visible={props.visible}
      footer={[
        <Button key={2} onClick={() => closeFrom()} loading={confirmLoading}>
          {t('common.cancel')}
        </Button>,
        <Button
          key={1}
          type='primary'
          onClick={submitIssueForm}
          loading={confirmLoading}
        >
          {t('common.confirm')}
        </Button>,
      ]}
      onCancel={() => closeFrom()}
      maskClosable={false}
      closable={false}
      destroyOnClose
    >
      <Form ref={issueFormRef} layout='vertical' initialValues={{ amount: 0 }}>
        <Card style={{ marginBottom: '0.5rem' }}>
          <Descriptions column={1} title={t('payment.mch_walletInfo_title')}>
            <Descriptions.Item label={t('payment.channel')}>
              {props.channel}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.token')}>
              {props.token}
            </Descriptions.Item>
            <Descriptions.Item label={'可下發餘額'}>
              {props.balance}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Form.Item
          name='address'
          label={t('transferback.destination')}
          rules={[
            { required: true, message: t('common.must_fill_required_field') },
          ]}
        >
          <Select
            options={whiteList}
            placeholder={t('transferback.choose_wallet_address')}
          />
        </Form.Item>
        <Form.Item name='amount' label={t('transferback.amount')}>
          <InputNumber min={0} step={100} max={props.balance} precision={6} />
        </Form.Item>
        <Form.Item
          name="code"
          label={t('common.verify_code')}
          rules={[
            {
              required: true,
              pattern: /^\d{6}$/,
              message: '請輸入驗證碼',
            },
          ]}
          extra={
            t('common.scan_two_factor_transfer')
          }
        >
          <Input
            maxLength={6}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('common.authenticator_code')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
