import { moduleInit } from '../../core/base/moduleInit';
import { Page } from '../../core/configs/types';
import AbandonWalletPage from '../pages/AbandonWalletPage';
import AbandonWalletChangeLogPage from '../pages/AbandonWalletChangeLogPage';
import WithdrawOrder from '../pages/WithdrawOrder';
import { READ_ABANDON_ORDER, READ_ABANDON_WALLET, READ_ABANDON_WALLET_TXN } from './scopes';

export const ModuleName = 'ABANDON-WALLET';
export const ModuleIdentity = 'ABANDON-WALLET';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_AGENT_REPORT = Symbol();

const walletPage = Page.createInstance({
  id: 21200,
  uri: '',
  component: () => <AbandonWalletPage />,
  scopes: [READ_ABANDON_WALLET],
  onClose: () => null,
});
const changeLogPage = Page.createInstance({
  id: 21201,
  uri: '',
  component: () => <AbandonWalletChangeLogPage />,
  scopes: [READ_ABANDON_WALLET_TXN],
  onClose: () => null,
});
const withdrawOrderPage = Page.createInstance({
  id: 21202,
  uri: '',
  component: () => <WithdrawOrder />,
  scopes: [READ_ABANDON_ORDER],
  onClose: () => null,
});
_module.addPages([walletPage, changeLogPage, withdrawOrderPage]);

_module.setMenu([
  {
    symbol: MENU_SYMBOL_AGENT_REPORT,
    label: 'Abandon Wallet Management',
    menuId: 212,
    order: 50,
    iconId: 16,
    i18nLabel: { en_US: 'Abandon Wallet Management', zh_CN: '棄用錢包管理',vi_VN:'棄用錢包管理' },
    children: [
      {
        label: 'Abandon Wallet',
        menuId: walletPage,
        i18nLabel: { en_US: 'Abandon Wallet', zh_CN: '棄用錢包',vi_VN:'Ví không dùng' },
      },
      {
        label: 'Abandon Wallet',
        menuId: changeLogPage,
        i18nLabel: {
          en_US: 'Transaction Change Log',
          zh_CN: '資金流水',vi_VN:'資金流水'
        },
      },
      {
        label: 'Withdraw Order',
        menuId: withdrawOrderPage,
        i18nLabel: {
          en_US: 'Withdraw Order',
          zh_CN: '下發任務',vi_VN:'下發任務'
        },
      },
    ],
  },
]);
