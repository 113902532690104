import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, PageHeader } from 'antd';
import ProForm from '@ant-design/pro-form';
import { Content } from 'antd/lib/layout/layout';
import { CreateLibProps, CryptoLibProvider } from '../../domain/report-provider/createCryptoLibProvider';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';

export default function CryptoLibCreatePage() {
  const [form] = ProForm.useForm();
  const { t } = useTranslation();
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const [formState, setFormState] = useState({ loading: false });

  const handleSubmit = async (values: CreateLibProps) => {
    setFormState({ ...formState, loading: true });
    await CryptoLibProvider.getNewCryptoLib({
      ...values,
      token: user.token!,
    })
      .catch(async (rs) => {
        const jsonRes = JSON.parse(await rs.response.data.text());
        Modal.error({
          content: jsonRes.message,
          okText: '確認',
        });
      })
      .finally(() => setFormState({ ...formState, loading: false }));
  };

  return (
    <>
      <PageHeader
        title={t('crypto_lib.create_crypto_lib_label')}
        subTitle={''}
        extra={<></>}
      />
      <Content
        style={{
          padding: 24,
          margin: 0,
        }}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          onFinish={handleSubmit}
        >
          <Form.Item name="iv" label="新IV" rules={[{ required: true }]}>
            <Input allowClear style={{ width: 360 }} />
          </Form.Item>

          <Form.Item name="key" label="新Key" rules={[{ required: true }]}>
            <Input allowClear style={{ width: 360 }} />
          </Form.Item>

          <Button
            key={1}
            type="primary"
            htmlType="submit"
            onClick={() => {}}
            loading={formState.loading}
          >
            <DownloadOutlined />
            {'產生套件'}
          </Button>
        </Form>
      </Content>
    </>
  );
}
