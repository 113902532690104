import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri } from '../../core/configs/services';
import { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminResponse } from '../models/adminResponse';

export type WalletListFilter = {
  channel?: string;
};

export interface Wallet {
  walletAddress: string;
  channel: string;
  token: string;
  description: string;
  createdAt: Date;
  userId: string;
}

export interface AFWallet {
  walletAddress: string;
  balance: number;
  balanceUpdatedAt: string;
  txnBalance: string;
  availableBalance: string;
  token: string;
  channel: string;
}

export type AgentFundingWalletChangeLog = {
  txnId: string;
  txnHash: string;
  walletAddress: string;
  systemId: string;
  orderNo: string;
  channel: string;
  tradeCode: string;
  token: string;
  availableAmount: number;
  txnAmount: number;
  availableBeforeBalance: number;
  availableAfterBalance: number;
  txnBeforeBalance: number;
  txnAfterBalance: number;
  comment: string;
  createdBy: string;
};

export class AgentSourceWalletProvider {

  static async getWalletListPage(query?: PaginationQuery | WalletListFilter): Promise<AdminListResponse<Wallet>> {

    const res = await adminRequest.get<AdminListResponse<Wallet>>(
      ReportUri('funding-wallet'),
      {
        params: { ...query },
      },
    );

    return res.data;
  }

  static async addWallet(payload?: Partial<Wallet>) {
    await adminRequest.post<AdminResponse<Wallet>>(
      ReportUri('funding-wallet/agent-funding-wallet/issue'),
      payload,
      {
        headers: {
          operatorId: payload?.userId,
        },
      },
    );
    return;
  }

  static async editWallet(payload?: Partial<Wallet>) {
    const res = await adminRequest.patch<AdminResponse<Wallet>>(
      ReportUri(`funding-wallet/agent-funding-wallet/${payload?.walletAddress}`),
      payload,
    );
    return res.data;
  }

  static async getChangeLogList(param: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<AgentFundingWalletChangeLog>>(ReportUri('funding-wallet-txn/walletLog'), { params: param });
    return res.data;
  }

  static async getFundingWalletList(filter: { channel: string }) {

    const res = await adminRequest.get<AdminResponse<any>>(
      ReportUri(`funding-wallet/all`),
      {
        params: { ...filter },
      },
    );
    return res.data;
  }
}
