import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';


export interface Domain {
  domain: 'string';
  name: 'string';
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface DomainResponse extends AdminListResponse<Domain> {}

export class DomainProvider {
  static async createDomain(data: {}) {
    const res = await adminRequest.post<AdminResponse<Domain>>(
      UsdtUri(`admin/domain`),
      data
    );
    return res.data;
  }

  static async getDomain(params: any) {
    const res = await adminRequest.get<AdminListResponse<Domain>>(
      UsdtUri('admin/domain'),
      {
        params,
      },
    );
    return res.data;
  }

  static async updateDomain(formRow: string, data: {}) {
    const res = await adminRequest.put<AdminResponse<Domain>>(
      UsdtUri(`admin/domain/${formRow}`),
      data
    );
    return res.data;
  }

  static async deleteDomain(formRow: any) {
    const res = await adminRequest.delete<AdminResponse<Domain>>(
      UsdtUri(`admin/domain/${formRow}`),
    );
    return res.data;
  }
}
