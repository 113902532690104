import ProDescriptions from '@ant-design/pro-descriptions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transfer2LocalTime } from '../../../core/utils/timeFormat';
import { PayforRecord } from '../../order-status/payforOrderTypes';
import { PayforOrderProvider } from '../../usdt-providers/payforOrderProvider';
import { TransferBackOrder } from '../../usdt-providers/transferBackOrderProvider';

export default function ReportDetail(props: {
  order: PayforRecord | TransferBackOrder;
  isAdmin: boolean;
  isTransferBackOrder?: boolean;
  title: string;
}) {
  const { t } = useTranslation();
  const [notifyRes, setNotifyRes] = useState<string>('');
  const order = props.order;
  useEffect(() => {
    const orderId = props.order.orderId;
    const isAdmin = props.isAdmin;
    if(props.isTransferBackOrder) {
      setNotifyRes(JSON.stringify(null));
    }else{
      PayforOrderProvider.getNotifyDetail(orderId, isAdmin).then((res) => {
        const data = JSON.parse(res.data);
        const notifyDetail = {
          req: IsJsonString(data.req) ? JSON.parse(data.req) : data.req,
          res: IsJsonString(data.res) ? JSON.parse(data.res) : data.res,
          notifyTime: data.notifyTime,
        };
        setNotifyRes(JSON.stringify(notifyDetail));
      })
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ProDescriptions column={2} title={props.title} dataSource={{
          ...order,
          notifyResponse: notifyRes,
        }}>
        {[
          {
            label: t('payment.order_no'),
            dataIndex: 'orderNo',
            hide: false,
            span: 2,
          },
          {
            label: t('payment.mch_custom_order_no'),
            dataIndex: 'customOrderNo',
            span: 2,
          },
          { label: t('payment.channel'), dataIndex: 'channel' },
          { label: t('payment.token'), dataIndex: 'token' },
          {
            label: t('payment.system_id'),
            dataIndex: 'systemId',
            hide: !props.isAdmin,
          },
          { label: t('payment.order_status'), dataIndex: 'status' },
          {
            label: t('payment.order_notify_status'),
            dataIndex: 'notifyStatus',
          },
          { label: t('payment.order_amount'), dataIndex: 'orderAmount' },
          { label: t('credit.from_amount'), dataIndex: 'fromAmount' },
          { label: t('credit.from_currency'), dataIndex: 'fromCurrency' },
          { label: t('credit.exchange_rate'), dataIndex: 'exchangeRate' },
          // { label: t('payment.transfer_mode'), dataIndex: 'transferMode' },
          { label: t('payment.usdt_fee'), dataIndex: 'networkFee', span: 2 },
          { label: t("common.createdAt"), children: transfer2LocalTime(new Date(order.createdAt)), span: 2 }, // prettier-ignore
          {
            label: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            span: 2,
          },
          {
            label: t("payment.notify_info"),
            dataIndex: "notifyResponse",
            valueType: "jsonCode",
            span: 2,
            hide: !props.isAdmin || props.isTransferBackOrder
          }, // prettier-ignore
        ]
          .filter((el) => el.hide !== true)
          .map((el, index) => {
            return (
              <ProDescriptions.Item
                valueType={!!el.valueType ? (el.valueType as any) : 'text'}
                key={index}
                label={el.label}
                span={el.span}
                dataIndex={el.dataIndex}
                children={el.children}
                contentStyle={el.dataIndex === "notifyResponse" ? { height: '200px', overflowX: 'auto' } : undefined}
              />
            );
          })}
      </ProDescriptions>
    </>
  );
}

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
