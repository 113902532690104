import { moduleInit } from '../../core/base/moduleInit';
import { IPage, Page } from '../../core/configs/types';
import {
  CREATE_CRYPTO_LIB_MANAGER,
  READ_CRYPTO_LIB_MANAGER,
  READ_DOMAIN_SETTING,
  READ_PROFIT_WHITELIST,
  READ_SYSTEM_SETTING,
  READ_USER_LOG,
} from './scopes';
import DomainManager from '../pages/DomainManager';
import GlobalSettingManager from '../pages/GlobalSettingManager';
import React from 'react';
import CryptoLibCreatePage from '../pages/CryptoLibCreatePage';
import CryptoLibUpdatePage from '../pages/CryptoLibUpdatePage';
import ProfitWithdrawWhiteListPage from '../pages/ProfitWithdrawWhiteListPage';
import ApiLogPage from '../pages/ApiLogPage';
import OPDepositSourceList from '../pages/OPDepositSourceList';
import MchWalletTrxWhiteList from '../pages/MchWalletTrxWhiteList';

export const ModuleName = 'SystemSetting';
export const ModuleIdentity = 'SYSTEM-SETTING';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_SYSTEM_SETTING = Symbol();

const pages: IPage[] = Page.createInstances([
  {
    id: 40341,
    uri: '',
    component: DomainManager,
    scopes: [READ_DOMAIN_SETTING],
    onClose: () => {},
  },
  {
    id: 40342,
    uri: '',
    component: () => <GlobalSettingManager title="全域設定管理" />,
    scopes: [READ_SYSTEM_SETTING],
    onClose: () => {},
  },
  {
    id: 40345,
    uri: '',
    component: () => <CryptoLibCreatePage />,
    scopes: [CREATE_CRYPTO_LIB_MANAGER],
    onClose: () => {},
  },
  {
    id: 40346,
    uri: '',
    component: () => <CryptoLibUpdatePage />,
    scopes: [READ_CRYPTO_LIB_MANAGER],
    onClose: () => {},
  },
  {
    id: 40347,
    uri: '',
    component: () => <ApiLogPage />,
    scopes: [READ_USER_LOG],
    onClose: () => {},
  },
  {
    id: 40343,
    uri: '',
    component: () => <ProfitWithdrawWhiteListPage />,
    scopes: [READ_PROFIT_WHITELIST],
    onClose: () => {},
  },
]);
const OPDepositSourcePage = Page.createInstance({
  id: 40348,
  uri: '',
  component: OPDepositSourceList,
  scopes: [READ_PROFIT_WHITELIST],
  onClose: () => null,
});
const MchWalletTrxWhiteListPage = Page.createInstance({
  id: 40349,
  uri: '',
  component: MchWalletTrxWhiteList,
  scopes: [READ_PROFIT_WHITELIST],
  onClose: () => null,
});

_module.addPages([...pages,OPDepositSourcePage,MchWalletTrxWhiteListPage]);
_module.setMenu([
  {
    symbol: MENU_SYMBOL_SYSTEM_SETTING,
    label: '平台設置',
    menuId: 40,
    order: 30,
    iconId: 20,
    i18nLabel: { en_US: 'System Setting', zh_CN: '平台設置',vi_VN:'平台設置' },
    children: [
      {
        label: '域名管理',
        menuId: pages[0],
        i18nLabel: { en_US: 'Domain Management', zh_CN: '域名管理',vi_VN:'域名管理' },
      },
      {
        label: '全域設定管理',
        menuId: pages[1],
        i18nLabel: { en_US: 'Global Setting Management', zh_CN: '全域設定管理',vi_VN:'全域設定管理' },
      },
      {
        label: '利潤下發白名單',
        menuId: pages[5],
        i18nLabel: {
          en_US: 'Profit Withdraw Whitelist',
          zh_CN: '利潤下發白名單',vi_VN:'利潤下發白名單'
        },
      },
      {
        label: '維運充值來源',
        menuId: OPDepositSourcePage,
        i18nLabel: { en_US: 'OP Deposit Source', zh_CN: '維運充值來源',vi_VN:'維運充值來源' },
      },
      {
        label: '商戶錢包充TRX白名單',
        menuId: MchWalletTrxWhiteListPage,
        i18nLabel: { en_US: 'Mch Wallet TRX White List', zh_CN: '商戶錢包充TRX白名單',vi_VN:'商戶錢包充TRX白名單' },
      },
      {
        label: '產生加密套件',
        menuId: pages[2],
        i18nLabel: { en_US: 'Create Crypto Lib', zh_CN: '產生加密套件',vi_VN:'產生加密套件' },
      },
      {
        label: '更換加密套件',
        menuId: pages[3],
        i18nLabel: { en_US: 'Update Crypto Lib', zh_CN: '更換加密套件',vi_VN:'Cập nhật bộ tiền mã hóa' },
      },
      {
        label: '操作紀錄查詢',
        menuId: pages[4],
        i18nLabel: { en_US: 'Api Log', zh_CN: '操作紀錄查詢',vi_VN:'操作紀錄查詢' },
      },
    ],
  },
]);
