import { useTranslation } from 'react-i18next';

export const useTradeCodeTranslate = (): any => {
  const { t } = useTranslation();
  return {
    '1001': t('tradeCode.1001'),
    '1003': t('tradeCode.1003'),
    '1004': t('tradeCode.1004'),
    '1005': t('tradeCode.1005'),
    '1006': t('tradeCode.1006'),
    '1007': t('tradeCode.1007'),
    '1008': t('tradeCode.1008'),
    '2001': t('tradeCode.2001'),
    '2002': t('tradeCode.2002'),
    '3001': t('tradeCode.3001'),
    '3002': t('tradeCode.3002'),
    '3004': t('tradeCode.3004'),
    '4001': t('tradeCode.4001'),
    '4002': t('tradeCode.4002'),
    '4003': t('tradeCode.4003'),
    '5000': t('tradeCode.5000'),
    '6000': t('tradeCode.6000'),
  };
};
