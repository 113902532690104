import { ProColumns } from '@ant-design/pro-table';
import { DatePicker } from 'antd';
import React from 'react';
import moment from 'moment';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { DateRangePickerProps, DateRangePickerType, DateRangeType } from './tableUtilType';
import { PageStateType } from '../hooks/usePageState';
import _ from 'lodash';

const dateRangeColumnName = 'TableUtilColumn-DateRangeColumn';

type DateRangeStateType = PageStateType<DateRangeType>;

export class TableUtilColumn {
  static getDateRangeColumn<TRow = any>(
    props: DateRangePickerProps,
  ): DateRangePickerType<TRow> {
    const setState = props.state[1];
    const op: DateRangeStateType = props.pageOp;
    const column: ProColumns<TRow> = {
      title: !!props.title ? props.title : '時間區間',
      hideInTable: true,
      hideInSearch: false,
      dataIndex: dateRangeColumnName,
      renderFormItem: (_: any, row: any, form: FormInstance<any>) => {
        const { start, end } = getDatePickerFromState(props);
        return (
          <DatePicker.RangePicker
            defaultValue={[start, end]}
            value={[start, end]}
            onChange={(values) => {
              if (values) {
                setState((v) => ({
                  start: values![0]!.startOf('day'),
                  end: values![1]!.endOf('day'),
                }));
              }
            }}
          />
        );
      },
    };
    const getParam = (): DateRangeType => {
      const { start, end } = getDatePickerFromState(props);
      const startIso = start.startOf('day').toISOString();
      const endIso = end.endOf('day').toISOString();
      op.update((v) => {
        v.start = startIso;
        v.end = endIso;
        return v;
      });
      return {
        start: startIso,
        end: endIso,
      };
    };
    const reset = () => {
      op.update((v) => ({ ...v, start: undefined, end: undefined }));
      setState(() => undefined);
      setState(() => ({ start: undefined as any, end: undefined as any }));
    };
    return { column, getParamAndUpdatePage: getParam, reset };
  }
}

function getDatePickerFromState(props: DateRangePickerProps) {
  const setState = props.state[1];
  const state = props.state[0];
  const op: DateRangeStateType = props.pageOp;
  let start: moment.Moment;
  let end: moment.Moment;
  if (!!state?.start) {
    start = moment(state?.start);
    end = moment(state?.end);
  } else if (!!op.get().start) {
    start = moment(op.get().start);
    end = moment(op.get().end);
  } else {
    const range = _.isNumber(props.defaultDateRange)
      ? props.defaultDateRange
      : 7;
    const endDate = _.isNumber(props.defaultDate) ? props.defaultDate : 0;

    start = moment().subtract(range+endDate, 'day');
    end = end = moment().subtract(endDate, 'day');
    setState((v) => ({ start, end }));
  }
  return {
    start,
    end,
  };
}
