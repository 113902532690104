import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import CreditOrderStatusType from '../../domain/order-status/creditOrderType';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { CreditOrder, DepositOrderProvider } from '../../domain/usdt-providers/depositOrderProvider';
import { filter } from 'rxjs/operators';
import {
  useCreditStatusTranslate,
  useNotifyStatusTranslate,
} from '../../domain/order-status/orderStatusTranslateHook';
import { useTranslation } from 'react-i18next';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { CreditSettingProvider } from '../../domain/usdt-providers/creditSettingProvider';
import _ from 'lodash';
import DepositOrderDetail from '../../domain/components/order/DepositOrderDetail';
import ExcelDownloadFormButton from '../../core/components/ExcelDownloadFormButton';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import ChangeOrderFrom, { ChangeOrderFinishEvent } from '../../transaction-manager/component/ChangeOrderFrom';
import { EventProvider } from '../../../event/eventProvider';
import _module from '../configs/module';
import { WRITE_ORDERS_CREDIT_RECORD } from '../configs/scopes';
import { PlusOutlined } from '@ant-design/icons';
import { ChannelValueEnum, MchToken } from '../../domain/channel/channel';
import { CheckDepositHashFrom } from '../components/CheckDepositHashFrom';

interface PageOp extends CoreProTableState<CreditOrder> {}

// eslint-disable-next-line
export default () => {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const creditStatusEnum = useCreditStatusTranslate();
  const notifyStatusEnum = useNotifyStatusTranslate();
  const userInfo = useSelector<GlobalStoreMerge, UserStore>(
    (store) => store.user,
  );
  const [systemInfo, setSystemInfo] = useState<{
    changeOrderEnable: boolean;
  }>();
  const [checkFormVisible, setCheckFormVisible] = useState<boolean>(false);

  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {
      sourceType: 'USER'
    },
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [changeOrder, setChangeOrder] = useState<{
    visible: boolean;
    order?: CreditOrder;
  }>({
    visible: false,
    order: undefined,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });

  const refresh = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    // reload
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === ChangeOrderFinishEvent.symbol))
      .subscribe((x) => {
        setChangeOrder({ visible: false, order: undefined });
        refresh();
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    CreditSettingProvider.getCreditSetting({
      systemId: userInfo.systemId,
    }).then((data) => {
      const setting = _.get(data, ['data', 'items', 0]);
      if (setting) {
        setSystemInfo({ changeOrderEnable: setting.changeOrderEnable });
      }
    });
    // eslint-disable-next-line
  }, []);
  const changeButtonVisible = (row: CreditOrder): boolean => {
    if (row.depositMode < 3) {
      return false;
    }
    if (!systemInfo?.changeOrderEnable) {
      return false;
    }
    return (
      row.status === CreditOrderStatusType.OrderStatusTypes.Pending ||
      row.status === CreditOrderStatusType.OrderStatusTypes.Expired
    );
  };

  return (
    <>
      <PageHeader
        title={t('credit.order_list_title')}
        // subTitle={t('credit.order_list_subtitle')}
        extra={<></>}
      />
      <CoreProTable<CreditOrder>
        bordered
        formRef={searchFormRef}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        search={{ layout: 'vertical' }}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        toolBarRender={() => [
          <ExcelDownloadFormButton
            filePrefix={'deposit_order'}
            getExcelFile={(start, end) =>
              DepositOrderProvider.getExcel({
                start: start.toISOString(),
                end: end.toISOString(),
              })
            }
          />,
          <Button
            key={1}
            type="primary"
            onClick={() => setCheckFormVisible(true)}
          >
            <PlusOutlined />
            {t('credit.check_deposit_hash')}
          </Button>,
        ]}
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        rowKey="orderNo"
        requestData={(params: any, sort, filter) => {
          if (params.status === 'ALL') {
            delete params.status;
          }
          return DepositOrderProvider.getCreditOrderList({
            ...PaginationTransformer.paramTransfer(params),
            txHash: params.transactionHash,
            status: params.status,
            orderNo: params.orderNo,
            merchantUserId: params.merchantUserId,
            sourceType: 'USER',
            ...dateRangeColumn.getParamAndUpdatePage(),
          }).then((res) => {
            const rs = res;
            return {
              data: rs.data.items.map((el: any, index: number) => ({
                key: `${index}`,
                ...el,
              })),
              success: true,
              total: rs.data.meta.totalItems,
            };
          });
        }}
        columns={[
          {
            title: t('payment.order_no'),
            dataIndex: 'orderNo',
            width: 120,
            align: 'center',
          },
          {
            title: t('payment.channel'),
            dataIndex: 'channel',
            width: 80,
            align: 'center',
            valueEnum: ChannelValueEnum,
            hideInSearch: false,
          },
          {
            title: t('payment.token'),
            dataIndex: 'token',
            width: 100,
            valueEnum: MchToken,
            align: 'center',
            hideInSearch: false,
          },
          {
            title: t('payment.mch_custom_order_no'),
            dataIndex: 'customOrderNo',
            width: 120,
            align: 'center',
            hideInSearch: false,
          },
          {
            title: t('payment.mch_user_id'),
            dataIndex: 'merchantUserId',
            hideInSearch: false,
            width: 70,
            align: 'center',
          },
          {
            title: t('payment.order_status'),
            valueEnum: creditStatusEnum,
            dataIndex: 'status',
            hideInSearch: false,
            width: 70,
            align: 'center',
          },
          {
            title: t('payment.order_notify_status'),
            valueEnum: notifyStatusEnum,
            dataIndex: 'notifyStatus',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: false,
            width: 80,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            width: 110,
            dataIndex: 'walletAddress',
            hideInSearch: true,
            align: 'right',
            title: t('payment.wallet_address'),
            render: (n, row) => (
              <HexLabel hex={row.walletAddress} size={'small'} />
            ),
          },
          {
            title: t('payment.order_amount'),
            dataIndex: 'orderAmount',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: t('payment.actual_amount'),
            dataIndex: 'actualAmount',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: t('credit.from_amount'),
            dataIndex: 'fromAmount',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: t('credit.from_currency'),
            dataIndex: 'fromCurrency',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: t('credit.exchange_rate'),
            dataIndex: 'exchangeRate',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          {
            title: t('payment.fee'),
            dataIndex: 'fee',
            hideInSearch: true,
            width: 70,
            align: 'center',
          },
          dateRangeColumn.column,
          {
            hideInSearch: true,
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            width: 100,
            align: 'center',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            title: t('common.operate'),
            key: '_action',
            fixed: 'right',
            align: 'center',
            dataIndex: 'id',
            valueType: 'option',
            width: 200,
            render: (text: any, row: CreditOrder) =>
              // 加入是否通知，如果是以完成通知的狀態要再確認一次
              [
                <Button
                  size={'small'}
                  onClick={(e) => {
                    e.preventDefault();
                    Modal.info({
                      title: '',
                      width: '640px',
                      content: (
                        <DepositOrderDetail order={row} isAdmin={false} />
                      ),
                    });
                  }}
                >
                  {t('common.view')}
                </Button>,
                changeButtonVisible(row) && (
                  <Button
                    key={2}
                    type="primary"
                    disabled={!_module.can([WRITE_ORDERS_CREDIT_RECORD])}
                    size={'small'}
                    onClick={() => {
                      setChangeOrder({ visible: true, order: row });
                    }}
                  >
                    {t('credit.change_order')}
                  </Button>
                ),
              ],
          },
        ]}
      />
      <ChangeOrderFrom
        visible={changeOrder.visible}
        order={changeOrder.order}
      />
      <CheckDepositHashFrom
        visible={checkFormVisible}
        onClose={(hash?: string) => {
          setCheckFormVisible(false);
          if (!!hash) {
            pageOp.update((v) => {
              v.params.transactionHash = hash;
              return v;
            });
          }
          refresh();
        }}
      />
    </>
  );
};
