import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { omit } from 'lodash';
import { PayforOrderStatus, PayforRecord } from '../../domain/order-status/payforOrderTypes';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { PayforOrderProvider } from '../../domain/usdt-providers/payforOrderProvider';
import {
  useNotifyStatusTranslate,
  usePayforStatusTranslate,
} from '../../domain/order-status/orderStatusTranslateHook';
import { useTranslation } from 'react-i18next';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import ReportDetail from '../../domain/components/order/PayforReportDetail';
import ExcelDownloadFormButton from '../../core/components/ExcelDownloadFormButton';
import HexLabel from '../../core/components/HexLabel';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import SpeedUpForm, {
  SpeedUpFormClosedEvent,
  SpeedUpFormProps,
} from '../../transaction-manager/component/SpeedUpForm';
import { SpeedUpTxnType } from '../../domain/usdt-providers/speedupProvirder';
import { Channel, ChannelValueEnum, MchToken } from '../../domain/channel/channel';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import _module from '../configs/module';
import { OPERATOR_SEPEEDUP_PAYOUT } from '../configs/scopes';

interface PageOp extends CoreProTableState<PayforRecord> {}

const defaulSpeedUpData: SpeedUpFormProps = {
  visible: false,
  orderId: '',
  txnType: SpeedUpTxnType.payfor,
  systemId: '',
};

// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const payforStatusTranslateEnum = usePayforStatusTranslate();
  const notifyStatusTranslateEnum = useNotifyStatusTranslate();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [speedUpState, setSpeedUpState] = useState<SpeedUpFormProps>(
    defaulSpeedUpData,
  );
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('common.createdAt'),
    pageOp,
    state: dateRangeState,
  });

  const speedup = (row: PayforRecord) => {
    setSpeedUpState({
      orderId: row.orderId,
      visible: true,
      txnType: SpeedUpTxnType.payfor,
      systemId: row.systemId,
    });
  };

  const checkSpeedUp = (row: PayforRecord) => {
    if (row.status !== PayforOrderStatus.OrderStatus.Pending) {
      return false;
    }
    if (row.channel !== Channel.ETHEREUM) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === SpeedUpFormClosedEvent.symbol))
      .subscribe((x) => {
        setSpeedUpState(defaulSpeedUpData);
      });
  }, []);

  return (
    <>
      <PageHeader
        title={t('payfor.order_list_title')}
        // subTitle={t('payfor.order_list_subtitle')}
        extra={<></>}
      />
      <CoreProTable<PayforRecord>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        search={{ layout: 'vertical' }}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        toolBarRender={() => [
          <ExcelDownloadFormButton
            getExcelFile={(start, end) =>
              PayforOrderProvider.getExcel({ start, end })
            }
            filePrefix={'payout_order'}
          />,
        ]}
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        rowKey="orderNo"
        requestData={(params: any, sort, filter) => {
          if (params.status === 'ALL') {
            delete params.status;
          }
          return PayforOrderProvider.getPayforOrderList({
            page: params.current,
            limit: params.pageSize,
            ...omit(params, ['current', 'pageSize', 'createdAt']),
            ...dateRangeColumn.getParamAndUpdatePage(),
          }).then((res) => {
            const rs = res;
            return {
              data: rs.data.items.map((el: any, index: number) => ({
                key: `${index}`,
                ...el,
              })),
              success: true,
              total: rs.data.meta.totalItems,
            };
          });
        }}
        columns={[
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.order_no'),
            dataIndex: 'orderNo',
            fixed: 'left',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 70,
            align: 'center',
            title: t('payment.channel'),
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            valueEnum: MchToken,
            title: t('payment.token'),
            dataIndex: 'token',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.mch_custom_order_no'),
            dataIndex: 'customOrderNo',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 60,
            align: 'center',
            title: t('payment.order_status'),
            dataIndex: 'status',
            valueEnum: payforStatusTranslateEnum,
          },
          {
            dataIndex: 'notifyStatus',
            hideInSearch: true,
            width: 60,
            align: 'center',
            title: t('payment.order_notify_status'),
            valueEnum: notifyStatusTranslateEnum,
          },
          {
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            hideInSearch: true,
            width: 100,
            align: 'center',
            render: (n, row) => (
              <HexLabel hex={row.transactionHash} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'right',
            title: t('payment.order_amount'),
            dataIndex: 'orderAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            align: 'right',
            title: t('credit.from_amount'),
            dataIndex: 'fromAmount',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            align: 'center',
            width: 60,
            title: t('credit.from_currency'),
            dataIndex: 'fromCurrency',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 60,
            align: 'right',
            title: t('credit.exchange_rate'),
            dataIndex: 'exchangeRate',
          },
          {
            hideInSearch: true,
            width: 60,
            align: 'right',
            title: t('payment.fee'),
            dataIndex: 'fee',
          },
          // {
          //   hideInSearch: true,
          //   title: t('payment.transfer_mode'),
          //   dataIndex: 'transferMode',
          //   width: 60,
          //   align: 'center',
          //   valueEnum: TypeTransferMode,
          // },
          {
            hideInSearch: true,
            title: t('payment.usdt_fee'),
            dataIndex: 'networkFee',
            width: 60,
            align: 'right',
          },
          {
            hideInSearch: false,
            hideInTable: true,
            width: 100,
            align: 'center',
            ellipsis: true,
            title: t('payment.blockchain_hash'),
            dataIndex: 'transactionHash',
            key: 'txHash',
          },
          dateRangeColumn.column,
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
          // {title: '訂單時間', dataIndex: 'updated', hideInTable: true },
          // {title: '功能', key: '_action', dataIndex: 'id', valueType: 'option', width: 100, render: (text, row) => []},
          {
            title: t('common.operate'),
            key: '_action',
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            width: 100,
            render: (text: any, row: PayforRecord) => [
              <Button
                size={'small'}
                key="editable"
                onClick={(e) => {
                  e.preventDefault();
                  Modal.info({
                    title: '',
                    width: '640px',
                    content: (
                      <ReportDetail
                        title={t('payfor.order_list_title')}
                        order={row}
                        isAdmin={false}
                      />
                    ),
                  });
                }}
              >
                {t('common.view')}
              </Button>,
              checkSpeedUp(row) && (
                <Button
                  key={2}
                  disabled={!_module.can([OPERATOR_SEPEEDUP_PAYOUT])}
                  type={'primary'}
                  size={'small'}
                  onClick={() => speedup(row)}
                >
                  {t('speedup.speedup')}
                </Button>
              ),
            ],
          },
        ]}
      />
      <SpeedUpForm {...speedUpState} />
    </>
  );
};
