export const READ_ORDERS_CREDIT_RECORD = 'READ_ORDERS_CREDIT_RECORD';
export const WRITE_ORDERS_CREDIT_RECORD = 'WRITE_ORDERS_CREDIT_RECORD';
export const READ_POLLING_WALLET = 'READ_POLLING_WALLET';
export const ADD_POLLING_WALLET = 'ADD_POLLING_WALLET';
export const READ_MCH_DEPOSIT_SOURCE = 'READ_MCH_DEPOSIT_SOURCE';
export const WRITE_MCH_DEPOSIT_SOURCE = 'WRITE_MCH_DEPOSIT_SOURCE';
export const READ_ORDERS_PAY_RECORD = 'READ_ORDERS_PAY_RECORD';
export const OPERATOR_SEPEEDUP_PAYOUT = 'OPERATOR_SEPEEDUP_PAYOUT';
export const READ_COLLOECT_DASHBOARD = 'READ_COLLOECT_DASHBOARD';
export const READ_COLLOECT_ORDER = 'READ_COLLOECT_ORDER';
export const READ_COLLOECT_WALLET = 'READ_COLLOECT_WALLET';
export const OPERATOR_COLLOECT = 'OPERATOR_COLLOECT';
export const READ_WITHDRAW_ORDER = 'READ_WITHDRAW_ORDER';
export const ADD_WITHDRAW_ORDER = 'ADD_WITHDRAW_ORDER';
export const READ_WITHDRAW_WHITLIST = 'READ_WITHDRAW_WHITLIST';
export const ADD_WITHDRAW_WHITLIST = 'ADD_WITHDRAW_WHITLIST';
