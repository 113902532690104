import React, { useEffect, useRef } from 'react';
import { Button, Modal, PageHeader } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { CollectTaskProvider } from '../../domain/usdt-providers/collectTaskProvider';
import { UserWallet, UserWalletProvider } from '../../domain/usdt-providers/userWalletProvider';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useUserWalletCollectStatusTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { useTranslation } from 'react-i18next';
import { ChannelValueEnum, MchToken } from '../../domain/channel/channel';
import _module from '../configs/module';
import { OPERATOR_COLLOECT } from '../configs/scopes';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { getStorage } from '../../../lib/webStorage';

interface PageOp extends CoreProTableState<UserWallet> {}

// eslint-disable-next-line
export default () => {
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const userWalletCollectStatusTranslateEnum = useUserWalletCollectStatusTranslate();

  const { t } = useTranslation();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);

  const doCollect = (row: UserWallet) => {
    Modal.confirm({
      title: t('collect.collect_description.title'),
      content: t('collect.collect_description.content'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      centered: true,
      onOk: () => {
        return CollectTaskProvider.createCollectTask({
          userId: user.userId,
          srcAddress: row.walletAddress,
          channel: row.channel,
          token: row.token,
        })
          .then((res) => {
            const rs = res;
            Modal.success({
              title: t('collect.success'),
              content: (
                <>
                  <br />
                  <div key={1}>
                    <strong>{t('payment.wallet_address')}：</strong>
                    {rs.data.srcAddress}
                  </div>
                  <div key={2}>
                    <strong>{t('collect.task_id')}：</strong>
                    {rs.data.taskId}
                  </div>
                </>
              ),
              okText: t('common.confirm'),
            });
          })
          .finally(() => {
            pageOp.update((v) => ({ ...v, needRequest: true }));
            actionRef.current?.reload();
          });
      },
    });
  };
  const settings = getStorage('systemSettings');
  const hiddenTxnBalance = settings.depositMode !== 5 ? true : false;
  return (
    <>
      <PageHeader
        title={t('collect.collect_wallet_title')}
        // subTitle={t('collect.collect_wallet_subtitle')}
        extra={<></>}
      />
      <CoreProTable<UserWallet>
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={actionRef as any}
        toolBarRender={false}
        search={{ layout: 'vertical' }}
        rowKey="walletAddress"
        requestData={(params: any, sort, filter) => {
          if (params.status === 'ALL') {
            delete params.status;
          }
          return UserWalletProvider.mchGetUserWalletList({
            page: params.current,
            limit: params.pageSize,
            uid: params.userId,
            walletAddress: params.walletAddress,
            channel: params.channel,
            token: params.token,
          }).then((res) => {
            return PaginationTransformer.responseTransfer(res);
          });
        }}
        columns={[
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.merchant_user_id'),
            dataIndex: 'userId',
          },
          {
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 80,
            valueEnum: MchToken,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.wallet_address'),
            dataIndex: 'walletAddress',
          },
          {
            hideInSearch: true,
            hideInTable: hiddenTxnBalance,
            width: 100,
            align: 'center',
            title: t('payment.txnBalance'),
            dataIndex: 'txnBalance',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.availableBalance'),
            dataIndex: 'availableBalance',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('collect.user_wallet_collect_status'),
            dataIndex: 'collectStatus',
            valueEnum: userWalletCollectStatusTranslateEnum,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('collect.user_wallet_collect_count'),
            dataIndex: 'collectCount',
          },
          // {
          //   hideInSearch: true,
          //   hideInTable: true,
          //   width: 100,
          //   align: 'center',
          //   title: '建立時間',
          //   dataIndex: 'balanceUpdatedAt',
          //   renderFormItem: TableColumnRangePicker,
          //   render: (text: any, row: any) =>
          //     new Date(row.balanceUpdatedAt).toLocaleString(),
          // },
          {
            title: t('common.operate'),
            key: '_action',
            align: 'center',
            dataIndex: 'id',
            valueType: 'option',
            width: 100,
            render: (text, row) =>
              !row.collectStatus || row.collectStatus === 'UnCollect'
                ? [
                    <Button
                      key={1}
                      disabled={!_module.can([OPERATOR_COLLOECT])}
                      type="primary"
                      onClick={() => doCollect(row)}
                    >
                      {t('collect.collect')}
                    </Button>,
                  ]
                : null,
          },
        ]}
      />
    </>
  );
};
