import { AdminUri } from '../../core/configs/services';
import { Permission } from '../../permissions/types';
import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import { RoleBase } from './mchRoleProvider';
import { AdminResponse } from '../models/adminResponse';

export interface Role extends RoleBase {
  id: number;
  name: string;
  description: string;
  scopes: Array<Permission>;
}

export class AdminUserRoleProvider {

  static async getAll(param?: PaginationQuery) {
    return await PaginationTransformer.getAllFromPagination<Role, null>(
      (qruery) => AdminUserRoleProvider.getList(qruery),
      null,
    );
  }

  static async getList(params: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<Role>>(
      AdminUri(`admin/user/roles`),
      {
        params: {
          ...params,
        },
      },
    );
    return res.data;
  }

  static async delete(id?: number) {
    await adminRequest.delete(AdminUri(`admin/user/roles/${id}`));
  }

  static async edit(id?: number, payload?: Partial<Role>) {
    await adminRequest.patch(AdminUri(`admin/user/roles/${id}`), payload);
  }

  static async create(payload?: Partial<Role>) {
    const res = await adminRequest.post<AdminResponse<Role>>(
      AdminUri(`admin/user/roles`),
      payload,
    );
    return res.data;
  }

  static async getOne(id: number) {
    const res = await adminRequest.get<AdminResponse<Role>>(
      AdminUri(`admin/user/roles/${id}`),
    );
    return res.data;
  }

  static async add(props: Partial<Role>) {
    const res = await adminRequest.post<AdminResponse<Role>>(
      AdminUri(`admin/user/roles`),
      props,
    );
    return res.data;
  }

  static async patch(props: Role) {
    const res = await adminRequest.patch(
      AdminUri(`admin/user/roles/${props.id}`),
      props,
    );
    return res.data;
  }
}
