import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';
import { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminListResponse } from '../models/adminListResponse';
import { BoSignHelper } from '../../../core/providers/helper/boSign-helper';

export interface ProfitOrderType {
  orderId: string;
  systemId: string;
  status: string;
  transactionHash: string;
  walletAddress: string;
  toAddress: string;
  fromAmount: number;
  orderAmount: number;
  channel: string;
  token: string;
  transferTaskId?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export interface ProfitWithdrawReq {
  walletAddress: string;
  destAddress: string;
  channel: string;
  token: string;
  orderAmount: number;
  operatorId: string;
  systemId: string;
  code: number;
}

export interface ProfitWhiteList {
  walletAddress: string;
  channel: string;
  token: string;
  createdAt: Date;
}

export type WhiteListFilter = {
  channel?: string;
};

export class ProfitProvider {
  static async createOrder(req: ProfitWithdrawReq) {
    const boSign = BoSignHelper.generate(req);
    const res = await adminRequest.post<AdminResponse<any>>(
      UsdtUri('admin/profit/order/withdraw'),
      {
        ...req,
        boSign,
      },
    );
    return res.data;
  }

  static async getWhiteListPage(query?: PaginationQuery | WhiteListFilter) {
    const res = await adminRequest.get<AdminListResponse<ProfitWhiteList>>(
      UsdtUri('admin/profit/white-list'),
      {
        params: { ...query },
      },
    );
    return res.data;
  }

  static async getWhiteList(
    query?: WhiteListFilter,
  ): Promise<ProfitWhiteList[]> {
    const res = await this.getWhiteListPage({ page: 1, limit: 100, ...query });
    return res.data.items;
  }

  static async addWhitList(req: {
    channel: string;
    walletAddress: string;
    opId: string;
  }) {
    const res = await adminRequest.post<AdminResponse<ProfitWhiteList>>(
      UsdtUri('admin/profit/white-list'),
      req,
      {
        headers: {
          operatorId: req.opId,
        },
      },
    );
    return res.data;
  }

  static async deleteWhitList(req: { walletAddress: string; opId: string }) {
    const res = await adminRequest.delete<AdminResponse<ProfitWhiteList>>(
      UsdtUri(`admin/profit/white-list/${req.walletAddress}`),
      {
        headers: {
          operatorId: req.opId,
        },
      },
    );
    return res.data;
  }

  static async getTransferOrderList(param: PaginationQuery) {
    const res = await adminRequest.get<
      AdminListResponse<ProfitOrderType>
      >(UsdtUri('admin/profit/order'), { params: param });
    return res.data;
  }
}
