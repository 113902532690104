import HttpErrorType from '../core/types/errorHttpType';

export const UserDuplicate: HttpErrorType = {
  clientCode: 'mch.user.duplicate',
  code: '10200',
  httpStatus: 400,
  message: 'mch.user.duplicate',
};

export const UserLoginError: HttpErrorType = {
  clientCode: 'mch.user.login.error',
  code: '10201',
  httpStatus: 400,
  message: 'mch.user.login.error',
};

export const UserGroupDuplicate: HttpErrorType = {
  clientCode: 'mch.user.group.duplicate',
  code: '10202',
  httpStatus: 400,
  message: 'mch.user.group.duplicate',
};

export const UserGroupCascade: HttpErrorType = {
  clientCode: 'mch.user.group.cascade',
  code: '10203',
  httpStatus: 400,
  message: 'mch.user.group.cascade',
};

export const GroupRoleCascade: HttpErrorType = {
  clientCode: 'mch.group.role.cascade',
  code: '10206',
  httpStatus: 400,
  message: 'mch.group.role.cascade',
};
