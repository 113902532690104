import { createHash } from 'crypto';

/**
 * @param {string} algorithm
 * @param {any} content
 *  @return {string}
 */

export const encrypt = (algorithm: string, content: any) => {
  const hash = createHash(algorithm);
  hash.update(content);
  return hash.digest('hex');
};

/**
 * @param {any} content
 *  @return {string}
 */
export const sha1Encrypt = (content: any) => encrypt('sha1', content);

/**
 * @param {any} content
 *  @return {string}
 */
export const sha256Encrypt = (content: any) => encrypt('sha256', content);

export const htmlRenderTokenEncrypt = (key: string, content: string) => {
  return sha1Encrypt(`key=${key}&content=${content}`);
};
