import { useTranslation } from 'react-i18next';

export const useDepositRefundResStatus = () => {
  const { t } = useTranslation();
  return {
    Pending: t('credit.status.pending'),
    Success: t('credit.status.success'),
    MappingSuccess: t('credit.status.success'),
    Expired: t('credit.status.order_close'),
  };
};
export const useDepositRefundSearchStatus = () => {
  const { t } = useTranslation();
  return {
    Pending: t('credit.status.pending'),
    Success: t('credit.status.success'),
    Expired: t('credit.status.order_close'),
  };
};
export const usePayoutRefundStatus = () => {
  const { t } = useTranslation();
  return {
    Pending: t('payfor.status.pending'),
    Success: t('payfor.status.success'),
    Fail: t('payfor.status.fail'),
  };
};
export const useTxnType = () => {
  const { t } = useTranslation();
  return {
    Deposit: t('txn_type.deposit'),
    Payout: t('txn_type.payout'),
    Withdraw: t('txn_type.withdraw'),
    WithdrawBack: t('txn_type.withdrawBack'),
  };
};
