import { UPDATE_SYSTEM_LIST, UPDATE_TIME } from './actions';
import _ from 'lodash';

export type SystemType = {
  enabled: number;
  merchantType: string;
  parentSystem: string;
  systemId: string;
};

export type SystemListStore = {
  systems: SystemType[];
  systemsUpdatedAt: number;
}

const defaultState: {
  systems: SystemType[];
  systemsUpdatedAt: number;
} = {
  systems: [],
  systemsUpdatedAt: 0,
};

// eslint-disable-next-line
const systemList = (
  state = defaultState,
  action: { type: string; payload: { data: any; updatedAt: number } },
) => {
  switch (action.type) {
    case UPDATE_TIME:
      return {
        ...state,
        systemsUpdatedAt: action.payload?.updatedAt + 3600,
      };
    case UPDATE_SYSTEM_LIST:
      const data = _.get(action, ['payload', 'data']);
      if (!data) {
        return state;
      }
      return {
        ...state,
        systems: data,
      };
    default:
      return state;
  }
};

export default systemList;
