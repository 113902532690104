import { Card, Col, Row, Statistic, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DollarOutlined } from '@ant-design/icons';
import { transfer2LocalDate } from '../../core/utils/timeFormat';
import moment from 'moment';

const { Title } = Typography;

export type advanceNumbersType = {
  lpwBalanceAmount: number, lpwAvailableAmount: number, mchAvailableAmount: number
}

export default function AdvanceFundCard(props: advanceNumbersType) {
  const { t } = useTranslation();
  const [balance, setBalance] = useState<advanceNumbersType>({
    lpwBalanceAmount: props.lpwBalanceAmount,
    lpwAvailableAmount: props.lpwAvailableAmount,
    mchAvailableAmount: props.mchAvailableAmount,
  });

  useEffect(() => {
    setBalance({
      lpwBalanceAmount: props.lpwBalanceAmount,
      lpwAvailableAmount: props.lpwAvailableAmount,
      mchAvailableAmount: props.mchAvailableAmount,
    });
  }, [props]);

  return (
    <>
      <Card
        title={<Title
          level={5}>{transfer2LocalDate(moment().subtract(1, 'day').toDate()) + ' ' + t('report.yesterdayDailySettlement')}</Title>}
      >
        <Row gutter={24}>
          <Col span={5}>
            <Card
              key={'refund_wallet_card'}
              style={{
                margin: '0.5rem',
                width: '100%',
              }}
            >
              <Statistic
                key={'refund_balance'}
                title={t('report.lpwBalanceAmount')}
                value={balance.lpwBalanceAmount}
                precision={3}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card
              key={'refund_wallet_card'}
              style={{
                margin: '0.5rem',
                width: '100%',
              }}
            >
              <Statistic
                key={'refund_balance'}
                title={t('report.lpwAvailableAmount')}
                value={balance.lpwAvailableAmount}
                precision={3}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card
              key={'refund_wallet_card'}
              style={{
                margin: '0.5rem',
                width: '100%',
              }}
            >
              <Statistic
                key={'refund_balance'}
                title={t('report.mchAvailableAmount')}
                value={balance.mchAvailableAmount}
                precision={3}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
}
