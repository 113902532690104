import { ReactElement } from 'react';
import authorize, { defaultUserClaims, UserClaims } from '../libs/auth';
import { Menu } from '../configs/menus';
import { IPage, NULL_KEY_ELEMENT } from '../configs/types';

declare type CanFunc = (scope: string | string[], module?: string | undefined) => boolean;
declare type ClaimsFunc = () => UserClaims;
declare type Element = JSX.Element | ReactElement;

export class moduleInit {
    public readonly moduleName: string;
    public readonly moduleIdentity: string;
    public pages: IPage[] = [];
    public menus?: Menu[] | undefined = undefined;
    public readonly bootstrap: (...args: any[]) => void;

    can: CanFunc = () => false;
    claims: ClaimsFunc = () => defaultUserClaims;

    constructor(moduleName: string, moduleIdentity: string);
    constructor(moduleName: string, moduleIdentity: string, bootstrap: (...args: any[]) => void);
    constructor(moduleName: string, moduleIdentity: string, bootstrap?: (...args: any[]) => void) {

        this.moduleName = moduleName;
        this.moduleIdentity = moduleIdentity;

        authorize.registerModule(this);

        this.bootstrap = bootstrap ?? (() => {})
    }

    public addPages(pages: IPage[]) {
        this.pages = pages;
    }

    public setMenu(menus: Menu[]) {
        this.menus = menus;
    }

    public canRender (scope: () => boolean, component: Element): Element;
    public canRender (scope: string, component: Element): Element;
    public canRender (scope: string[], component: Element): Element;
    public canRender (scope: string, module: string, component: Element): Element;
    public canRender (scope: string[], module: string, component: Element): Element;
    public canRender (scope: string | string[] | (() => boolean), module: string | Element, component?: Element): Element {
        if (typeof scope === 'function' && scope.call(this)) {
            return component as Element;
        }
        if (typeof module === 'string' && this.can(scope as string | string[], module)) {
            return component as Element;
        }
        if (this.can(scope as string | string[])) {
            return module as unknown as Element;
        }
        return NULL_KEY_ELEMENT;
    }
}
