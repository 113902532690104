import { Button, Form, Input, Modal, PageHeader, Select, Space } from 'antd';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { usePageState } from '../../core/hooks/usePageState';
import { ChannelSelect, ChannelValueEnum } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';
import { AgentSourceWalletProvider, Wallet } from '../../domain/report-provider/agentSourceWalletProvider';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { Subject } from 'rxjs';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { PlusOutlined } from '@ant-design/icons';
import CoreDrawer from '../../core/components/CoreDrawer';

export type WalletFormProps = {
  record?: Wallet;
  visible: boolean;
  isEdit: boolean;
};

const defualFormState: WalletFormProps = {
  isEdit: false,
  visible: false,
};

enum ActionType {
  Closed,
}

const formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

interface PageOp extends CoreProTableState<Wallet> {
}

export default function AgentFundingWalletPage() {
  const { t } = useTranslation();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [actionState] = useState(new Subject<ActionType>());
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const [formState, setFormState] = useState<{
    isEdit: boolean;
    wallet?: Wallet;
    visible: boolean;
  }>({
    isEdit: false,
    wallet: undefined,
    visible: false,
  });

  const successHandle = () => {
    if (formRef.current) formRef.current.resetFields();
    actionState.next(ActionType.Closed);
  };

  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const refresh = () => {
    pageOp.update((s) => ({ ...s, needRequest: true }));
    setFormState(defualFormState);
    actionRef.current?.reload();
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);

  useEffect(() => {
    if (!formState.wallet) {
      return;
    }
    const wallet = formState.wallet;
    formRef.current?.setFieldsValue({
      channel: wallet.channel,
      description: wallet.description,
    });
    // eslint-disable-next-line
  }, [formState, formRef]);

  const save = () => {
    if (formRef.current) {
      formRef.current.validateFields().then((value) => {
        const v = JSON.parse(JSON.stringify(value));

        if (formState.isEdit && !!formState.wallet) {
          // 編輯
          const payload: Partial<Wallet> = {
            walletAddress: formState.wallet?.walletAddress,
            channel: formState.wallet?.channel,
            token: formState.wallet?.token,
            description: v.description,
            userId: user.userId,
          };
          AgentSourceWalletProvider.editWallet(payload).then((rs: any) => {
            console.log('after update: ', rs);
            Modal.success({
              content: '修改成功',
              okText: '確認',
              onOk: () => successHandle(),
            });
            refresh();
          });
        } else {
          // 新增
          const payload: Partial<Wallet> = {
            description: v.description,
            channel: v.channel,
            userId: user.userId,
          };
          AgentSourceWalletProvider.addWallet(payload).then((rs: any) => {
            Modal.success({
              content: '新增成功',
              okText: '確認',
              onOk: () => successHandle(),
            });
            refresh();
          });
          refresh();
        }
      });
    }
  };

  return (
    <>
      <PageHeader title={'代理資金池'} subTitle={''} extra={<></>} />
      <CoreProTable<Wallet>
        bordered={true}
        formRef={searchFormRef}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        toolBarRender={() => [

          <Button
            key={1}
            type='primary'
            onClick={() =>
              setFormState({
                isEdit: false,
                wallet: undefined,
                visible: true,
              })
            }
          >
            <PlusOutlined />
            {i18next.t('common.add')}
          </Button>,

        ]}
        requestData={async (params) => {
          const res = await AgentSourceWalletProvider.getWalletListPage(
            PaginationTransformer.paramTransfer(params),
          );
          return PaginationTransformer.responseTransfer(res);
        }}
        columns={[
          {
            fixed: 'left',
            align: 'center',
            width: 50,
            title: t('payment.channel'),
            hideInSearch: false,
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          {
            fixed: 'left',
            align: 'center',
            width: 50,
            title: t('payment.token'),
            hideInSearch: true,
            dataIndex: 'token',
          },
          {
            align: 'center',
            width: 100,
            title: t('payment.wallet_address'),
            hideInSearch: false,
            dataIndex: 'walletAddress',
            render: (node, row) => (
              <HexLabel hex={row.walletAddress} size={'small'} />
            ),
          },
          {
            align: 'center',
            width: 50,
            title: t('payment.balance'),
            hideInSearch: true,
            dataIndex: 'balance',
          },
          {
            align: 'center',
            width: 50,
            title: t('更新時間'),
            hideInSearch: true,
            dataIndex: 'balanceUpdatedAt',
            render: (text: any, row: any) =>
              transfer2LocalTime(new Date(row.balanceUpdatedAt)),
          },
          {
            align: 'center',
            width: 50,
            title: t('payment.txnBalance'),
            hideInSearch: true,
            dataIndex: 'txnBalance',
          },
          {
            align: 'center',
            width: 100,
            title: t('描述'),
            hideInSearch: true,
            dataIndex: 'description',
          },

          {
            key: 'actions',
            valueType: 'option',
            width: 30,
            fixed: 'right',
            align: 'center',
            render: (dom, row) => (
              <Space>
                <Button
                  key={3}
                  onClick={(x) => {
                    setFormState({
                      ...formState,
                      isEdit: true,
                      wallet: row,
                      visible: true,
                    });
                  }}
                >
                  編輯
                </Button>,

              </Space>
            ),
          },
        ]}
      />
      <CoreDrawer
        title={formState.isEdit ? `編輯代理資金錢包` : `新增代理資金錢包`}
        visible={formState.visible}
        onClose={() => {
          refresh();
        }}
        destroyOnClose={true}
        footerbuttons={[
          <Button key={1} onClick={() => save()}>
            送出
          </Button>,
        ]}
      >
        <Form
          ref={formRef}
          layout='vertical'
          name='systemSettingForm'
          initialValues={formState.wallet}
        >
          <Form.Item
            name='channel'
            label={t('payment.channel')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select disabled={formState.isEdit} options={ChannelSelect} />
          </Form.Item>

          <Form.Item name='description' label={t('描述')}>
            <Input />
          </Form.Item>
        </Form>
      </CoreDrawer>
    </>
  );
}
