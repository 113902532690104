import { UsdtUri } from '../../core/configs/services';
import axios from 'axios';
import fileDownload from 'js-file-download';

export interface CreateLibProps {
  iv: string;
  key: string;
  token: string;
}

export class CryptoLibProvider {
  static async getNewCryptoLib(param: CreateLibProps) {
    const data = new FormData();
    data.append('iv', param.iv);
    data.append('key', param.key);
    await axios
      .create({responseType:'blob'})
      .post(UsdtUri('change-crypto-lib/build'), data, {
        headers: { Authorization: 'Bearer ' + param.token },
      })
      .then((res: any) => {
        fileDownload(res.data, `${new Date().getTime()}.node`);
        return;
      });
  }
}
