import { Card, Form, InputNumber, Radio } from 'antd';
import React from 'react';
import { FeeSetting } from '../../usdt-providers/systemProvider';
import * as _ from 'lodash';
import { NetworkSetting, TokenSetting } from '../../usdt-providers/withdrawSettingProvider';
import TransferModeSettingFrom from './TransferModeSettingFrom';

const WithdrawTokenSettingFrom = (props: {
  channel: string;
  data: any;
  feeRange: FeeSetting;
  tokenSetting: TokenSetting;
  networkFeeSetting: NetworkSetting;
}) => {
  return (
    <>
      {
        _.map(props.data, (tokenObj, token) => {
          let max = 1;
          let min = 0;
          _.map(props.feeRange, (feeChannelObj, channel) => {
            if (props.channel === channel) {
              _.map(feeChannelObj, (tokenObj, feeToken) => {
                if (feeToken === token) {
                  max = tokenObj.max;
                  min = tokenObj.min;
                }
              });
            }
          });

          if (tokenObj.active) {
            return (

              <Card key={props.channel + '_' + token} title={token + '設定'} style={{ marginBottom: 16 }}>

                <Form.Item
                  key={props.channel + '_' + token + '_feeRate'}
                  name={props.channel + '_' + token + '_feeRate'}
                  label='下發費率'
                  rules={[{ required: tokenObj.withdrawOption?.indexOf('fee') > -1, message: '請輸入 `下發費率`' }]}
                >
                  <InputNumber
                    precision={4}
                    min={min}
                    max={max}
                    step={0.001}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_withdrawFee'}
                  name={props.channel + '_' + token + '_withdrawFee'}
                  label='下發費(单笔)'
                  rules={[{ required: tokenObj.withdrawOption?.indexOf('fee') > -1, message: '請輸入 `下發費`' }]}
                  initialValue={tokenObj.withdrawFee}
                >
                  <InputNumber
                    precision={4}
                    min={0}
                    defaultValue={tokenObj.withdrawFee}
                    step={1}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_withdrawOption'}
                  name={props.channel + '_' + token + '_withdrawOption'}
                  label="费率模式"
                  initialValue={tokenObj.withdrawOption}>
                  <Radio.Group
                    options={[
                      { label: '单笔', value: 'fee' },
                      { label: '費率', value: 'rate' },
                      { label: '单笔+費率', value: 'fee+rate' },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                    defaultValue={tokenObj.withdrawOption}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_minimumAmount'}
                  name={props.channel + '_' + token + '_minimumAmount'}
                  label='最小下發金額'
                  rules={[{ required: true, message: '請輸入 `最小下發金額`' }]}
                >
                  <InputNumber precision={6} min={0} step={1} />
                </Form.Item>
                <TransferModeSettingFrom
                  transferMode={props.networkFeeSetting[props.channel][token].transferMode}
                  staticFee ={props.networkFeeSetting[props.channel][token].staticFee}
                  channel={props.channel}
                  token={token}/>
              </Card>);
          }
        })
      }
    </>
  );
};

export default WithdrawTokenSettingFrom;

