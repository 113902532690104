import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';
import { RequestData } from '@ant-design/pro-table';
import { omit } from 'lodash';
import * as _ from 'lodash';
import { BoSignHelper } from '../../../core/providers/helper/boSign-helper';

interface CreateTransferback {
  orderId: string;
  orderNo: string;
  // nonce: number;
  // gasPrice: number;
  // contractAddress: string;
}

export interface TransferBackOrder {
  orderId: string;
  orderNo: string;
  tradeCode: string;
  systemId: string;
  status: string;
  transactionHash: string;
  walletAddress: string;
  orderAmount: string;
  fee: string;
  channel: string;
  token: string;
  customOrderNo: string;
  customMessage: string;
  notifyResponse: string;
  createdAt: string;
  transferMode: string;
  usdtFee: string;
  failReason: string;
}

export class TransferBackOrderProvider {
  static async createTransferBack(req: {
    destAddress: string;
    amount: number;
    code: number;
    channel?: string;
    token?:string;
  }) {
    const boSign = BoSignHelper.generate(req);
    const res = await adminRequest.post<AdminResponse<CreateTransferback>>(
      UsdtUri('merchant/payment/transfer-back'),
      {
        ...req,
        boSign,
      },
    );
    return res.data;
  }

  static async getTransferBackOrderList(params: {
    page: number;
    limit: number;
    channel?:string;
    token?:string;
    start?: string;
    end?: string;
    txHash?: string;
    status?: string;
    orderNo?: string;
  }) {
    const res = await adminRequest.get<AdminListResponse<TransferBackOrder>>(
      UsdtUri('/merchant/payment/order/transfer-back'),
      { params },
    );
    return res.data;
  }

  static async getAdminTransferBackOrder(
    params: any,
  ): Promise<RequestData<TransferBackOrder>> {
    if (params.status === 'ALL') {
      delete params.status;
    }
    const rs = await adminRequest.get<AdminListResponse<TransferBackOrder>>(
      UsdtUri(`/admin/payment/order/transfer-back`),
      {
        params: {
          ...omit(params, [
            'current',
            'pageSize',
            'createdAt',
            'transactionHash',
          ]),
          page: params.current,
          limit: params.pageSize,
          txHash: params.transactionHash,
        },
      },
    );
    return {
      success: true,
      data: rs.data.data.items,
      total: rs.data.data.meta.totalItems,
    };
  }

  static async getExcel(
    params: Omit<{ start: Date; end: Date }, 'page' | 'limit'>,
  ) {
    const res = await adminRequest.get<any>(
      UsdtUri('merchant/payment/order/transfer-back/xlsx'),
      { params: params, responseType: 'blob' },
    );
    return res.data;
  }
}
