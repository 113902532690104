/**
 * SCOPE_READ_ADMIN_CREDIT_ORDER admin 充值管理
 *
 * @type {string}
 */
export const READ_MCH_WALLET = 'READ_MCH_WALLET';
export const SYNC_MCH_BLOCKCHAIN = 'SYNC_MCH_BLOCKCHAIN';
export const CHANGE_WALLET = 'CHANGE_WALLET';
export const ADJUST_PAYMENT = 'ADJUST_PAYMENT';
export const WRITE_PROFIT_WITHDRAW = 'WRITE_PROFIT_WITHDRAW';
export const READ_USER_WALLET = 'READ_USER_WALLET';
export const SYNC_USER_BLOCKCHAIN = 'SYNC_USER_BLOCKCHAIN';
export const READ_MCH_WALLET_TXN = 'READ_MCH_WALLET_TXN';
export const READ_USER_WALLET_TXN = 'READ_USER_WALLET_TXN';