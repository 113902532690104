import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import ProForm, { DrawerForm, ProFormText } from '@ant-design/pro-form';
import { omit } from 'lodash';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { AdminResponse } from '../../domain/models/adminResponse';
import _module from '../configs/module';
import { OperationType } from '../../core/types/operationType';
import { AdminListResponse } from '../../domain/models/adminListResponse';
import ProDescriptions from '@ant-design/pro-descriptions';
import { ProColumns } from '@ant-design/pro-table';
import { Domain, DomainProvider, DomainResponse } from '../../domain/usdt-providers/domainProvider';
import { WRITE_DOMAIN_SETTING } from '../configs/scopes';

const EDITOR_EDITABLE: string[] = [];
const EDITOR_DELETABLE: string[] = [];

// Page state
interface PageOp extends CoreProTableState<Domain> {
  formType?: OperationType;
  formPayload?: any;
}

const columns: ProColumns<Domain>[] = [
  {
    dataIndex: 'domain',
    title: '域名',
  },
  {
    hideInSearch: true,
    dataIndex: 'name',
    title: '顯示名稱',
  },
  {
    hideInSearch: true,
    dataIndex: 'createdAt',
    title: '建立日期',
    align: 'center',
    render: (text, row) => new Date(row.createdAt).toLocaleString(),
  },
];

// eslint-disable-next-line
export default () => {
  // Page state
  const [operateType, setOperateType] = useState<OperationType>();
  const [operatePayload, setOperatePayload] = useState<Domain | undefined>(
    undefined,
  );
  const [operateForceRender, setOperateForceRender] = useState(0);
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    collapsed: false,
    needRequest: true,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    params: {},
  });

  // Form state
  const [form] = ProForm.useForm();
  const [formRow, setFormRow] = useState('');
  const [formVisible, setFormVisible] = useState(false);

  // Refs
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();

  /// Effects
  useEffect(() => {
    document.body.classList.add('pro-form-drawer');
  }, []);
  useEffect(() => {
    // Do last action: nothing, add, or edit
    const state = pageOp.get();
    if (typeof state.formType !== 'undefined') {
      execute(state.formType, state.formPayload);
    }
    // Fill search form default value
    if (!!searchFormRef.current?.setFieldsValue)
      searchFormRef.current.setFieldsValue(pageOp.get().params);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // Operate actions
    if (operateType === OperationType.ADD) {
      pageOp.update((prevState) => ({ ...prevState, formType: operateType }));
      setFormVisible(true);
    }
    if (operateType === OperationType.EDIT) {
      if (operatePayload?.domain) {
        pageOp.update((prevState) => ({
          ...prevState,
          formType: operateType,
          formPayload: operatePayload,
        }));
        setFormRow(operatePayload.domain);
        setFormVisible(true);
        // req('get', UsdtUri('admin/domain'), { domain: operatePayload.domain })
        DomainProvider.getDomain({
          domain: operatePayload.domain,
        }).then((rs: AdminListResponse<Domain>) => {
          if (rs.code === '00' && rs.data.items.length === 1) {
            form.setFieldsValue(rs.data.items[0]);
          } else {
            Modal.error({
              content: `錯誤: ${rs.message}`,
              okText: '確認',
            });
          }
        });
      } else {
        execute(OperationType.CLOSE);
      }
    }
    if (operateType === OperationType.SAVE) {
      // eslint-disable-next-line no-unused-expressions
      form.validateFields().then((values) => {
        if (formRow) {
          // req('put', UsdtUri(`admin/domain/${formRow}`), values)
          DomainProvider.updateDomain(formRow, values).then(
            (rs: AdminResponse<Domain>) => {
              if (rs.code === '00') {
                Modal.success({
                  okText: '確認',
                  content: (
                    <>
                      <strong>網域更新成功</strong>
                      <ProDescriptions
                        bordered
                        column={1}
                        size="small"
                        request={() => Promise.resolve(rs)}
                      >
                        <ProDescriptions.Item label="域名" dataIndex="domain" />
                        <ProDescriptions.Item
                          label="顯示名稱"
                          dataIndex="name"
                        />
                        <ProDescriptions.Item
                          label="更新日期"
                          dataIndex="updatedAt"
                          renderText={(text) => new Date(text).toLocaleString()}
                        />
                      </ProDescriptions>
                    </>
                  ),
                  onOk: () => {
                    execute(OperationType.CLOSE);
                  },
                });
              } else {
                Modal.error({
                  content: `錯誤: ${rs.message}`,
                  okText: '確認',
                  onOk: () => {
                    execute(OperationType.CLOSE);
                  },
                });
              }
            },
          );
        } else {
          // req('post', UsdtUri('admin/domain'), {
          //   domainList: [values],
          // })
          DomainProvider.createDomain({
            domainList: [values],
          }).then((rs) => {
            if (rs.code === '00') {
              Modal.success({
                okText: '確認',
                content: (
                  <>
                    <strong>網域建立成功</strong>
                    <ProDescriptions
                      bordered
                      column={1}
                      size="small"
                      request={() =>
                        Promise.resolve({ data: (rs as any).data[0] })
                      }
                    >
                      <ProDescriptions.Item label="域名" dataIndex="domain" />
                      <ProDescriptions.Item label="顯示名稱" dataIndex="name" />
                      <ProDescriptions.Item
                        label="建立日期"
                        dataIndex="createdAt"
                        renderText={(text) => new Date(text).toLocaleString()}
                      />
                    </ProDescriptions>
                  </>
                ),
                onOk: () => {
                  execute(OperationType.CLOSE);
                },
              });
            } else {
              Modal.error({
                content: `錯誤: ${rs.message}`,
                okText: '確認',
                onOk: () => {
                  execute(OperationType.CLOSE);
                },
              });
            }
          });
        }
      });
    }
    if (operateType === OperationType.DELETE) {
      Modal.confirm({
        title: `刪除?`,
        content: `確認刪除 (域名: ${operatePayload?.domain})?`,
        okText: '確認',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          // return req(
          //   'delete',
          //   UsdtUri(`admin/domain/${operatePayload?.domain}`),
          return DomainProvider.deleteDomain(operatePayload?.domain).then(
            (rs: AdminResponse<any>) => {
              if (rs.code === '00') {
                Modal.success({
                  content: `網域 (域名：${operatePayload?.domain}) 刪除成功`,
                  okText: '確認',
                  onOk: () => {
                    execute(OperationType.RELOAD);
                  },
                });
              }
            },
          );
        },
      });
    }
    if (operateType === OperationType.CLOSE) {
      setFormRow('');
      setFormVisible(false);
      form.resetFields();
      pageOp.update((s) => ({
        ...s,
        formType: undefined,
        formPayload: undefined,
      }));
      execute(OperationType.RELOAD);
    }

    if (operateType === OperationType.RELOAD) {
      pageOp.update((s) => ({ ...s, needRequest: true }));
      // eslint-disable-next-line no-unused-expressions
      if (!!actionRef.current?.reload) actionRef.current.reload();
    }
    // eslint-disable-next-line
  }, [operateType, operatePayload, operateForceRender]);

  // Helper functions
  const execute = (type: OperationType, payload?: Domain) => {
    setOperateType(type);
    setOperatePayload(payload);
    if (type === OperationType.SAVE) {
      setOperateForceRender(Date.now());
    }
  };

  return (
    <>
      <PageHeader title="域名管理" subTitle="" extra={<></>} />
      <CoreProTable<Domain>
        bordered
        scroll={{ x: 'fixed' }}
        rowKey="domain"
        columns={[
          ...columns,
          {
            key: 'actions',
            valueType: 'option',
            fixed: 'right',
            align: 'center',
            render: (dom, row) => (
              <Space>
                {_module.canRender(
                  EDITOR_EDITABLE,
                  <Button
                    key={3}
                    disabled={!_module.can([WRITE_DOMAIN_SETTING])}
                    onClick={() => execute(OperationType.EDIT, row)}
                  >
                    編輯
                  </Button>,
                )}
                {_module.canRender(
                  EDITOR_DELETABLE,
                  <Button
                    key={4}
                    disabled={!_module.can([WRITE_DOMAIN_SETTING])}
                    danger={_module.can([WRITE_DOMAIN_SETTING])}
                    onClick={() => execute(OperationType.DELETE, row)}
                  >
                    刪除
                  </Button>,
                )}
              </Space>
            ),
          },
        ]}
        toolBarRender={() => [
          _module.canRender(
            EDITOR_EDITABLE,
            <Button
              key={5}
              disabled={!_module.can([WRITE_DOMAIN_SETTING])}
              type="primary"
              onClick={() => execute(OperationType.ADD)}
            >
              新增
            </Button>,
          ),
        ]}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => pageOp.update((v) => ({ ...v, ...state }))}
        requestData={(params) => {
          // return req('get', UsdtUri('admin/domain'), {
          //   ...omit(params, ['current', 'pageSize']),
          //   page: params.current,
          //   limit: params.pageSize,
          // })
          return DomainProvider.getDomain({
            ...omit(params, ['current', 'pageSize']),
            page: params.current,
            limit: params.pageSize,
          }).then((rs: DomainResponse) => ({
            success: rs.code === '00',
            data: rs.data.items,
          }));
        }}
      />
      <DrawerForm
        visible={formVisible}
        form={form}
        submitter={{
          render: () => (
            <Space>
              <Button
                key={1}
                type="primary"
                onClick={() => execute(OperationType.SAVE)}
              >
                儲存
              </Button>
              <Button key={2} onClick={() => execute(OperationType.CLOSE)}>
                取消
              </Button>
            </Space>
          ),
        }}
        layout="vertical"
        drawerProps={{
          title: !!formRow ? `編輯網域` : `新增網域`,
          width: 480,
          destroyOnClose: true,
          onClose: () => execute(OperationType.CLOSE),
        }}
      >
        <ProFormText
          status="success"
          name="domain"
          label="域名"
          rules={[{ required: true, message: '請輸入域名' }]}
          fieldProps={{
            readOnly: operateType === OperationType.EDIT,
            bordered: operateType !== OperationType.EDIT,
          }}
        />
        <ProFormText status="success" name="name" label="顯示名稱" />
      </DrawerForm>
    </>
  );
};
