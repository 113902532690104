import { Subject } from 'rxjs';
import { EventType } from './types';
import _ from 'lodash';

export class EventProvider {
  static subject = new Subject<EventType>();

  static addEvents(event: EventType | EventType[]) {
    if (_.isArray(event)) {
      _.forEach(event, (e) => this.subject.next(e));
    } else {
      this.subject.next(event);
    }
  }
}
