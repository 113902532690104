import { useDispatch, useStore } from 'react-redux';
import _ from 'lodash';
import { IPageState } from '../../../stores/page-state';
import { useState } from 'react';

export interface PageStateType<S> {
  get: () => S;
  update: (value: Partial<S> | ((prevState: S) => S)) => void;
  set: (key: keyof S, val: any) => void;
}

export function usePageState<S>(
  symbol: string,
  state: S | (() => S),
  hooks?: {
    reduxHook?: (data: S) => S;
  },
): PageStateType<S> {
  const store = useStore();
  const dispatch = useDispatch();
  const defaultValue = _.isFunction(state) ? state() : state;
  const getCurPage = (): S => {
    const pageState: IPageState = store.getState().pageState;
    const id = pageState.current;
    const page = pageState?.pages[id];
    let data = page[symbol] ? page[symbol] : defaultValue;
    if (hooks?.reduxHook && _.isFunction(hooks?.reduxHook)) {
      data = hooks.reduxHook(data);
    }
    return data;
  };
  const [curData, setCurData] = useState<S>(getCurPage());
  // debug
  // useEffect(() => {
  //   console.log('usePageState.curData', curData);
  // }, [curData]);
  const update = (value: Partial<S> | ((prevState: S) => S)) => {
    const curState = getCurPage();
    const nextState = _.isFunction(value) ? value(curState) : value;
    setCurData((v) => ({ ...v, ...nextState }));
    dispatch({
      type: 'SET_PAGE_STATE',
      payload: {
        id: store.getState().pageState.current,
        symbol,
        state: nextState,
      },
    });
    return curData;
  };
  return {
    get: () => curData,
    update,
    set: (key: keyof S, val: any) =>
      update((prevState) => ({ ...prevState, [key]: val })),
  };
}
