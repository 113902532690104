import { RequestData } from '@ant-design/pro-table';
import { omit } from 'lodash';
import { UsdtUri } from '../../core/configs/services';
import { PayforRecord } from '../order-status/payforOrderTypes';
import adminRequest from '../../../config/adminRequest';
import { AdminResponse } from '../models/adminResponse';
import { AdminListResponse } from '../models/adminListResponse';

export class PayforOrderProvider {
  static async getAdminPayforOrder(params?: {
    pageSize?: number;
    current?: number;
    systemId?: string;
    start?: Date;
    end?: Date;
    txHash?: string;
    orderNo?: string;
    status?: string;
  }): Promise<RequestData<PayforRecord>> {
    const rs = await adminRequest.get<AdminListResponse<any>>(
      UsdtUri(`admin/payment/order/payfor`),
      {
        params: {
          ...omit(params, ['current', 'pageSize']),
          page: params?.current,
          limit: params?.pageSize,
        },
      },
    );
    return {
      success: true,
      data: rs.data.data.items,
      total: rs.data.data.meta.totalItems,
    };
  }

  static async triggerReNotify(params: { systemId: string; orderId: string }) {
    const rs = await adminRequest.post<AdminResponse<PayforRecord>>(
      UsdtUri(`admin/payment/trigger/notify/normal/${params.orderId}`),
      { systemId: params.systemId },
    );
    return rs.data.data;
  }

  /**
   * 代付失敗重發
   * @param params
   */
  static async retryFailOrderTransfer(params: {
    systemId: string;
    orderNo: string;
  }) {
    const res = await adminRequest.post<AdminResponse<{
      orderId: string;
      orderNo: string;
      customOrderNo: string;
    }>>(
      UsdtUri('/merchant/payment/order/payfor/retry'),
      {
        orderNo: params.orderNo,
      },
      {
        headers: {
          SYSTEM_ID: params.systemId,
        },
      },
    );
    return res.data;
  }

  static async getPayforOrderList(params: {
    page: number;
    limit: number;
    channel?: string;
    token?: string;
    start?: string;
    end?: string;
    txHash?: string;
    status?: string;
    orderNo?: string;
  }) {
    const res = await adminRequest.get<AdminListResponse<PayforRecord>>(
      UsdtUri('merchant/payment/order/payfor'),
      { params },
    );
    return res.data;
  }

  static async getExcel(
    params: Omit<{ start: Date; end: Date }, 'page' | 'limit'>,
  ) {
    const res = await adminRequest.get<any>(
      UsdtUri('merchant/payment/order/payfor/xlsx'),
      { params: params, responseType: 'blob' },
    );
    return res.data;
  }

  static async getDailySummary(param: { dataDate: Date, channel?: string, token?: string }) {
    const res = await adminRequest.get<AdminResponse<{
      totalOrderAmount: string;
      orderCount: number;
      totalFee: string;
    }>>(UsdtUri('merchant/payment/daily-report/payfor'), {
      params: { ...param },
    });
    return res.data;
  }

  static async getNotifyDetail(orderId: string, isAdmin: boolean) {
    let url: string;
    if (isAdmin) {
      url = UsdtUri(`admin/payment/order/payfor-notify-info/${orderId}`);
    } else {
      url = UsdtUri(`merchant/payment/order/payfor-notify-info/${orderId}`);
    }
    const res = await adminRequest.get<AdminResponse<string>>(
      url,
      {},
    );
    return res.data;
  }
}
