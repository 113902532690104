import SystemProvider from '../../modules/domain/usdt-providers/systemProvider';
export const UPDATE_SYSTEM_LIST = 'UPDATE_SYSTEM_LIST';
export const UPDATE_TIME = 'UPDATE_TIME';

export const refreshSystemListState = (
  payload: { force: boolean; systemId?: string } = { force: false },
) => (dispatch: any, state: any) => {
  const now = ~~(new Date().getTime() / 1000);
  const systemList = state().systemList;
  // if (!systemList) return;
  const updateTime = systemList.systemsUpdatedAt;
  if (payload.force || updateTime < now) {
    dispatch({
      type: UPDATE_TIME,
      payload: now,
    });
    SystemProvider.getSystemList(payload.systemId).then((data) => {
      dispatch({
        type: UPDATE_SYSTEM_LIST,
        payload: {
          data,
          updatedAt: now,
        },
      });
    });
  }
};
