import React, { useEffect, useRef } from 'react';
import { Column, ColumnOptions } from '@antv/g2plot';
import { transfer2LocalDate } from '../../../core/utils/timeFormat';
import BigNumber from 'bignumber.js';
import { RevenueItem } from '../../../domain/report-provider/chartProvider';
import _ from 'lodash';
import moment from 'moment';

export type RevenueChartProps = {
  dataSet: Array<RevenueItem>;
};

const NormalMchFeePercentChart = (props: RevenueChartProps) => {
  const ref = useRef<any>();
  const chartRef = useRef<Column>();

  const changeData = (data: Array<RevenueItem>) => {
    chartRef.current?.changeData(calculateChartData(data));
  };

  useEffect(() => {
    const rawData = props.dataSet;
    const chartData = calculateChartData(rawData);
    const config: ColumnOptions = {
      data: chartData,
      autoFit: true,
      xField: 'date',
      yField: 'totalFee',
      seriesField: 'systemId',
      xAxis: {
        type: 'cat',
        label: {
          autoRotate: false,
          formatter: (v) => {
            return transfer2LocalDate(v);
          },
        },
      },
      yAxis: {
        label: {
          autoRotate: false,
          formatter: (v) => {
            return new BigNumber(v).multipliedBy(100).toString() + '%';
          },
        },
      },
      isPercent: true,
      isStack: true,
      label: {
        position: 'middle',
        content: (item) => {
          let totalFeePercent = new BigNumber(item.totalFee).toNumber();
          totalFeePercent = new BigNumber(totalFeePercent)
            .multipliedBy(100)
            .toNumber();
          totalFeePercent = _.round(totalFeePercent, 2);
          let display: string;
          if (totalFeePercent === 0) {
            display = '';
          } else {
            display = totalFeePercent.toFixed(0) + '%';
          }
          return display;
        },
        style: {
          fill: '#222020d9',
        },
      },
    };
    const line = new Column(ref.current, config);
    chartRef.current = line;
    line.render();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeData(props.dataSet);
  }, [props.dataSet]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default NormalMchFeePercentChart;

function calculateChartData(rawData: RevenueItem[]) {
  const result = [];
  for (const item of rawData) {
    const agentType = item.agentType;
    if (agentType !== 'NORMAL') {
      continue;
    }
    const date = transfer2LocalDate(item.reportDate);
    const systemId = item.systemId;
    const totalFee = new BigNumber(item.totalFee)
      .plus(new BigNumber(item.payforUsdtFee))
      .toNumber();
    result.push({
      timestamp: moment(date).valueOf(),
      date: date,
      totalFee: totalFee,
      systemId: systemId,
    });
  }
  return _.sortBy(result, (item) => item.timestamp);
}
