import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


const fileDownload = require('js-file-download');

export default function ExcelDownloadFormButton(props: {
  getExcelFile: (start: Date, end: Date) => Promise<any>;
  filePrefix: string;
}) {
  const { t } = useTranslation();
  const [range, setRange] = useState({
    start: moment().subtract(7, 'day').startOf('day'),
    end: moment().endOf('day'),
  });
  const [modelVisible, setModelVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadHint, setDownloadHint] = useState('');

  const handleOk = () => {
    setLoading(true);
    setDownloadHint('※ 請求匯出 Excel 中，請勿關閉頁面，最慢約需2分鐘作業時間，完成後將自動下載，請稍後。');
    if(moment(range.start.toDate()).add(31,'day').toDate() < range.end.toDate()){
      Modal.error({
        content: '間隔不得超過31天',
        okText: t('common.confirm'),
      });
      setLoading(false);
      setDownloadHint('');
      return;
    }
    props
      .getExcelFile(range.start.toDate(), range.end.toDate())
      .then((data) => {
        fileDownload(
          data,
          `${props.filePrefix}_${new Date().getTime()}.xlsx`,
        );
        setLoading(false);
        setModelVisible(false);
      })
      // .catch(async err => {
      //   const error = JSON.parse(await err.text());
      //   Modal.error({
      //     content: error.displayMessage || error.message,
      //     okText: t('common.confirm'),
      //   });
      // })
      .finally(()=>{
        setLoading(false);
        setDownloadHint('');
        setModelVisible(false);
      });
  };

  const handleCancel = () => {
    setModelVisible(false);
  };
  return (
    <>
      <Button
        key={1}
        type="primary"
        onClick={() => {
          setModelVisible(true);
        }}
      >
        <DownloadOutlined />
        {t('common.export_excel')}
      </Button>
      <Modal
        title={t('common.export_excel')}
        visible={modelVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            {t('common.confirm')}
          </Button>,
        ]}
        // onOk={() => {
        //   if(moment(range.start.toDate()).add(31,'day').toDate() < range.end.toDate())
        //   {
        //     Modal.error({
        //       content: '間隔不得超過31天',
        //       okText: '確認',
        //     });
        //   }
        //   else {
        //     props
        //       .getExcelFile(range.start.toDate(), range.end.toDate())
        //       .then((data) => {
        //         fileDownload(
        //           data,
        //           `${props.filePrefix}_${new Date().getTime()}.xlsx`,
        //         );
        //         // setModelVisible(true);
        //       }).finally(()=>{
        //         // setModelVisible(false);
        //       });
        //   }
        // }}
        // onCancel={() => setModelVisible(false)}
      >
        <Form>
          <Form.Item label={t('common.createdAt')}>
            <DatePicker.RangePicker
              defaultValue={[range.start, range.end]}
              onChange={(range) => {
                const r = range as moment.Moment[];
                setRange({
                  start: moment(r[0].startOf('day').toDate()),
                  end: moment(r[1].endOf('day').toDate()),
                });
              }}
            />
          </Form.Item>
        </Form>
        <p>{downloadHint}</p>
      </Modal>
    </>
  );
}
