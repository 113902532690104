import React, { useEffect, useState } from 'react';
import { Card, Col, PageHeader, Row, Statistic } from 'antd';
import { UserWalletProvider } from '../../domain/usdt-providers/userWalletProvider';
import { useTranslation } from 'react-i18next';
import { Token } from '../../domain/channel/channel';

interface Summary {
  systemId: string;
  walletNumber: string;
  balance: string;
  txnBalance: string;
  availableBalance: string;
}

const initialSummary: Summary = {
  systemId: '',
  walletNumber: '',
  balance: '',
  txnBalance: '',
  availableBalance: '',
};
// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const [summary, setSummary] = useState(initialSummary);
  const [ethSummary, setEthSummary] = useState(initialSummary);
  useEffect(() => {
    UserWalletProvider.mchGetUserWalletSummery(Token.USDT).then((rs) => {
      setSummary(rs.data);
    });
    UserWalletProvider.mchGetUserWalletSummery(Token.ETH).then((rs) => {
      setEthSummary(rs.data);
    });
  }, []);
  return (
    <>
      <PageHeader
        title={t('collect.collect_dashboard_title')}
        // subTitle={t('collect.collect_dashboard_subtitle')}
        extra={<></>}
      >
        <Card title={t('collect.collect_wallet_total_USDT_amount_title')}>
          <Row gutter={2}>
            {/*<Col span={6}>*/}
            {/*  <Statistic*/}
            {/*    title={t('payment.txnBalance')}*/}
            {/*    value={summary.txnBalance ?? '---'}*/}
            {/*    precision={6}*/}
            {/*  />*/}
            {/*</Col>*/}
            <Col span={6}>
              <Statistic
                title={t('payment.availableBalance')}
                value={summary.availableBalance ?? '---'}
                precision={6}
              />
            </Col>
          </Row>
        </Card>
        <Card title={t('collect.collect_wallet_total_ETH_amount_title')}>
          <Row gutter={2}>
            {/*<Col span={6}>*/}
            {/*  <Statistic*/}
            {/*    title={t('payment.txnBalance')}*/}
            {/*    value={summary.txnBalance ?? '---'}*/}
            {/*    precision={6}*/}
            {/*  />*/}
            {/*</Col>*/}
            <Col span={6}>
              <Statistic
                title={t('payment.availableBalance')}
                value={ethSummary.availableBalance ?? '---'}
                precision={6}
              />
            </Col>
          </Row>
        </Card>
      </PageHeader>
    </>
  );
};
