import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../../core/hooks/usePageState';
import { PageHeader } from 'antd';
import CoreProTable, { CoreProTableState } from '../../../core/components/CoreProTable';
import WalletProvider, { MchWalletChangeLog } from '../../usdt-providers/walletProvider';
import SystemIdSearchList from '../system-search-list/SystemIdSearchList';
import { useTranslation } from 'react-i18next';
import { useTradeCodeTranslate } from '../../trade-code/tradeCodeHook';
import { ChannelValueEnum, Token } from '../../channel/channel';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../../stores/merge';
import { UserStore } from '../../../../stores/user';
import PaginationTransformer from '../../../core/utils/paginationTransformer';
import { useOperatorLogger } from '../../../core/hooks/useOperatorLogger';
import { transfer2LocalTime } from '../../../core/utils/timeFormat';
import { DateRangeStateType } from '../../../core/utils/tableUtilType';
import { TableUtilColumn } from '../../../core/utils/tableUtilColumn';

interface PageOp extends CoreProTableState<MchWalletChangeLog> {}

export default function AdminMchWalletChangeLogTable(props: {
  isAdmin: boolean;
}) {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const tradeCodeTranslate = useTradeCodeTranslate();
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const pageOp = usePageState<PageOp>('AdminMchWalletChangeLogTable', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const loggger = useOperatorLogger();
  const isAdmin = props.isAdmin;

  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    defaultDateRange: 1,
    defaultDate: 0,
    title: t('report.reportDate'),
    pageOp,
    state: dateRangeState,
  });

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader
        title={t('payment.mch_wallet_change_log')}
        subTitle=""
        extra={<></>}
      />
      <CoreProTable
        bordered
        rowKey="txnId"
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        tableState={pageOp.get()}
        requestData={async (params) => {
          const param = {
            systemId: user.systemId,
            ...PaginationTransformer.paramTransfer(params),
            isAdmin,
            start: dateRangeColumn.getParamAndUpdatePage().start,
            end: dateRangeColumn.getParamAndUpdatePage().end,
          };
          const res = await WalletProvider.getMchWalletChangeLogPagination(
            param,
          ).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: param },
              systemId: param.systemId,
            });
            return rs;
          });
          return PaginationTransformer.responseTransfer(res);
        }}
        onReset={() => {
          dateRangeColumn.reset();
        }}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          dateRangeColumn.column,
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            fixed: 'left',
            title: t('change_log.txn_id'),
            dataIndex: 'txnId',
            width: 60,
          },
          {
            align: 'center',
            hideInSearch: !isAdmin,
            hideInTable: !isAdmin,
            title: '商戶代號',
            dataIndex: 'systemId',
            width: 60,
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            title: t('payment.trade_genre'),
            dataIndex: 'tradeCode',
            valueEnum: tradeCodeTranslate,
            width: 80,
          },
          {
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            title: t('payment.token'),
            hideInSearch: !isAdmin,
            dataIndex: 'token',
            valueType: 'select',
            valueEnum: Token,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            hideInSearch: false,
            title: t('payment.blockchain_hash'),
            dataIndex: 'txnHash',
            ellipsis: true,
            width: 120,
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            ellipsis: true,
            title: t('payment.wallet_address'),
            dataIndex: 'walletAddress',
            width: 120,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.available_balance_change'),
            dataIndex: 'availableAmount',
            width: 100,
          },
          // {
          //   align: 'right',
          //   hideInSearch: true,
          //   hideInTable: !isAdmin,
          //   title: t('change_log.txn_balance_change'),
          //   dataIndex: 'txnAmount',
          //   width: 100,
          // },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.available_before_balance'),
            dataIndex: 'availableBeforeBalance',
            width: 100,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('change_log.available_after_balance'),
            dataIndex: 'availableAfterBalance',
            width: 100,
          },
          // {
          //   align: 'right',
          //   hideInSearch: true,
          //   hideInTable: !isAdmin,
          //   title: t('change_log.txn_before_balance'),
          //   dataIndex: 'txnBeforeBalance',
          //   width: 100,
          // },
          // {
          //   align: 'right',
          //   hideInSearch: true,
          //   hideInTable: !isAdmin,
          //   title: t('change_log.txn_after_balance'),
          //   dataIndex: 'txnAfterBalance',
          //   width: 100,
          // },
          {
            align: 'left',
            hideInSearch: true,
            hideInTable: false,
            title: t('common.comment'),
            // render: (text) => commentTranslate.parse(text as string),
            dataIndex: 'comment',
            width: 250,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            width: 120,
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row) => transfer2LocalTime(new Date(row.createdAt)),
          },
        ]}
      />
    </>
  );
}
