import { EventType } from './types';

export class BaseEvent<T = any> implements EventType<T> {
  payload: T;
  type: string;
  eventSymbol: symbol;

  constructor(props: { payload: T; type: string; eventSymbol: symbol }) {
    this.payload = props.payload;
    this.type = props.type;
    this.eventSymbol = props.eventSymbol;
  }
}
