export function device(em: any) {
  const u = navigator.userAgent;
  const mobile = ["Android", "iPhone", "SymbianOS", "Windows Phone"];
  let type = true;
  for (let item of mobile) {
    if (u.indexOf(item) >= 0) {
      type = false;
      break;
    }
  }
  let mobileStatus = "mobile portrait";
  em.className = type ? "web" : mobileStatus;
}

const throttle = function (fn: any, delay: number) {
  let timer: any = null;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn();
    }, delay);
  };
};

window.onresize = function () {
  throttle(device(document.getElementById("root")), 200);
};
