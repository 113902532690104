import { moduleInit } from '../../core/base/moduleInit';
import pages, {
    COLLECT_SETTING,
    DEPOSIT_SETTING,
    PAGE_GROUP,
    PAGE_ROLE,
    PAGE_SYSTEM,
    PAGE_USER,
    PAYOUT_SETTING,
    WITHDRAW_SETTING,
} from '../pages';

export const ModuleName = "MerchantPermission";
export const ModuleIdentity = 'MERCHANT-PERMISSION';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_MERCHANT_PERMISSION = Symbol();

_module.addPages(pages);
_module.setMenu([
    {
        symbol: MENU_SYMBOL_MERCHANT_PERMISSION,
        label: "商戶權限",
        menuId: 22,
        order: 22,
        iconId: 12,
        children: [
            {
                label: "系統",
                menuId: PAGE_SYSTEM,
                i18nLabel: { en_US: "Systems", zh_CN: "商戶清單" ,vi_VN:'商戶清單'},
            },
            {
                label: "充值設定",
                menuId: DEPOSIT_SETTING,
                i18nLabel: { en_US: "Deposit Setting", zh_CN: "充值設定" ,vi_VN:'充值設定'},
            },
            {
                label: "代付設定",
                menuId: PAYOUT_SETTING,
                i18nLabel: { en_US: "Payout Setting", zh_CN: "代付設定" ,vi_VN:'代付設定'},
            },
            {
                label: "歸集設定",
                menuId: COLLECT_SETTING,
                i18nLabel: { en_US: "Collect Setting", zh_CN: "歸集設定" ,vi_VN:'歸集設定'},
            },
            {
                label: "下發設定",
                menuId: WITHDRAW_SETTING,
                i18nLabel: { en_US: "Withdraw Setting", zh_CN: "下發設定" ,vi_VN:'下發設定'},
            },
            {
                label: "商戶使用者",
                menuId: PAGE_USER,
                i18nLabel: { en_US: "Merchant Users", zh_CN: "商戶使用者清單",vi_VN:'Danh sách người dùng trong Cửa hàng' },
            },
            {
                label: "商戶群組",
                menuId: PAGE_GROUP,
                i18nLabel: { en_US: "Merchant Groups", zh_CN: "商戶群組清單",vi_VN:'Danh sách nhóm trong Cửa hàng' },
            },
            {
                label: "商戶角色",
                menuId: PAGE_ROLE,
                i18nLabel: { en_US: "Merchant Roles", zh_CN: "商戶角色清單",vi_VN:'Danh sách vai trò trong Cửa hàng' },
            },
        ],
        i18nLabel: { en_US: "Merchant Permission", zh_CN: "商戶管控",vi_VN:'商戶管控' },
    }
])
