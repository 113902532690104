
export const parseErrorCause = (errorCause: string) => {
  let failReason = '';
  switch(true) {
    case(errorCause.toLowerCase().includes('token') && errorCause.toLowerCase().includes('insufficient')): {
      failReason = ' ： 餘額不足';
      break;
    }
    case(errorCause.toLowerCase().includes('network') && errorCause.toLowerCase().includes('insufficient')): {
      failReason = ' ： 礦工費不足';
      break;
    }
    case(errorCause.toLowerCase().includes('1003')): {
      failReason = ' ： 交易對象錯誤';
      break;
    }
    case(errorCause.toLowerCase().includes('socket hang up')): {
      failReason = ' ： 連線錯誤';
      break;
    }
    case(errorCause.toLowerCase().includes('threshold')): {
      failReason = ' ： 交易門檻限制';
      break;
    }
  }

  return failReason;
}
