import adminRequest from '../../config/adminRequest';
import { AdminUri } from '../../modules/core/configs/services';
import { AdminResponse } from '../../modules/domain/models/adminResponse';
import { LogProvider } from '../../modules/domain/admin-providers/logProvider';
import { FuncionCollection } from '../../modules/core/hooks/menuIdTranslateHook';

const decodeToken = (token: string) => atob(token.split('.')[1]);

export class AuthProvider {
  static async login(data: { username: string; password: string }) {
    const res = await adminRequest
      .post(AdminUri(`admin/auth/login`), data)
      .catch((e) => {
        LogProvider.recordLog({
          action: '登入失敗',
          function: FuncionCollection.login.func,
          payload: JSON.stringify({ req: { username: data.username }, res: e }),
          systemId: '',
          userId: '',
        }).catch((e) => console.error(e));
        throw e;
      });
    let id = '';
    const resData = res.data.data;
    const token = resData.token;
    if (!!token) {
      const claim = JSON.parse(decodeToken(token));
      id = claim.sub;
    }
    LogProvider.recordLog({
      action: '登入成功',
      function: FuncionCollection.login.func,
      payload: JSON.stringify({ req: { username: data.username }, res: res }),
      systemId: resData.systemId,
      userId: id,
      token: 'Bearer ' + token,
    }).catch((e) => console.error(e));
    return res.data;
  }

  static async refreshToken() {
    const res = await adminRequest.post<
      AdminResponse<{ token: { access_token: string } }>
    >(AdminUri(`/admin/auth/refresh`));
    return res.data.data;
  }
}
