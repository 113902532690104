const Channel = {
  ETHEREUM: 'ETHEREUM',
  TRON: 'TRON',
  SOLANA: 'SOLANA',
  BSC: 'BSC',
};

const ChannelValueEnum = {
  ETHEREUM: 'ERC',
  TRON: 'TRC',
  SOLANA: 'SOLANA',
  BSC: 'BSC',
};

const ChannelSelect = [
  {
    label: 'ERC',
    value: Channel.ETHEREUM,
  },
  {
    label: 'TRC',
    value: Channel.TRON,
  },
  {
    label: 'SOLANA',
    value: Channel.SOLANA,
  },
  {
    label: 'BSC',
    value: Channel.BSC,
  },
];

const Token = {
  USDT: 'USDT',
  SOL: 'SOL',
  ETH: 'ETH',
  TRX: 'TRX',
  BNB: 'BNB',
  BUSD: 'BUSD',
  USDC: 'USDC',
};

const MchToken = {
  USDT: 'USDT',
  BUSD: 'BUSD',
  USDC: 'USDC',
  ETH: 'ETH',
};

const AgentToken = [
  {
    label: 'USDT',
    value: Token.USDT,
  },
  {
    label: 'BUSD',
    value: Token.BUSD,
  },
  {
    label: 'USDC',
    value: Token.USDC,
  }];

const CollectToken = {
  USDT: 'USDT',
  BUSD: 'BUSD',
  USDC: 'USDC',
  ETH: 'ETH',
};

const TokenSelect = [
  {
    label: 'USDT',
    value: Token.USDT,
  },
  {
    label: 'ETH',
    value: Token.ETH,
  },
  {
    label: 'TRX',
    value: Token.TRX,
  },
  {
    label: 'SOL',
    value: Token.SOL,
  },
  {
    label: 'BNB',
    value: Token.BNB,
  },
  {
    label: 'BUSD',
    value: Token.BUSD,
  },
  {
    label: 'USDC',
    value: Token.USDC,
  },
];

export { Channel, Token, ChannelSelect, ChannelValueEnum, TokenSelect, CollectToken, MchToken, AgentToken };
