import { Button, Form, Input, Modal, Radio, Select, Spin } from 'antd';
import CoreDrawer from '../../core/components/CoreDrawer';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { MchUserProvider, User } from '../../domain/admin-providers/mchUserProvider';
import { BaseEvent } from '../../../event/baseEvent';
import { Group, MchGroupProvider } from '../../domain/admin-providers/mchGroupProvider';
import { EventProvider } from '../../../event/eventProvider';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { SystemListStore } from '../../../stores/system-list';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

export type UserFormProps = {
  record?: User;
  visible: boolean;
  isEdit: boolean;
};

export class CloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: CloseEvent.symbol,
      payload: undefined,
      type: CloseEvent.name,
    });
  }
}

export default function UserForm(props: UserFormProps) {
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<{
    isEdit: boolean;
    user?: User;
  }>({
    isEdit: props.isEdit,
    user: undefined,
  });
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [formSystemId, setFormSystemId] = useState<string | undefined>(
    undefined,
  );
  const logger = useOperatorLogger();

  const systemList = useSelector<GlobalStoreMerge, SystemListStore>(
    (x) => x.systemList,
  );

  const close = () => {
    EventProvider.addEvents(new CloseEvent());
  };

  const refreshUserData = async (userId: string) => {
    const res = await MchUserProvider.getUser(userId);
    setFormState({ ...formState, user: res, isEdit: true });
  };

  const save = (formRef: React.RefObject<FormInstance>) => {
    formRef.current?.validateFields().then(async (values: any) => {
      if (formState.isEdit) {
        // 編輯
        const payload: Partial<User> = {
          systemId: values.systemId,
          id: formState.user?.id,
          username: values.username,
          displayName: values.displayName,
          email: values.email,
          groupId: values.groupId,
          firstTimeLogin: values.firstTimeLogin,
        };
        if (values.password) {
          payload.password = values.password;
        }
        await MchUserProvider.update(payload)
          .then((res) => {
            logger.log({
              action: '編輯',
              payload: { req: payload, res: res },
              systemId: payload.systemId,
            });
          })
          .catch((e) => {
            logger.log({
              action: '編輯',
              payload: { req: payload, res: e },
              systemId: payload.systemId,
            });
            throw e;
          });
        close();
      } else {
        // 新增
        const payload: Partial<User> = {
          systemId: values.systemId,
          username: `${values.systemId}_${values.username}`,
          password: values.password,
          displayName: values.displayName,
          email: values.email,
          groupId: values.groupId,
          firstTimeLogin: values.firstTimeLogin,
        };
        await MchUserProvider.add(payload).then((res) => {
          logger.log({
            action: '新增使用者',
            payload: { req: payload, res: res },
            systemId: payload.systemId,
          });
          Modal.success({
            content: '新增成功',
            okText: '確認',
          });
        })
          .catch((e) => {
            logger.log({
              action: '新增使用者',
              payload: { req: payload, res: e },
              systemId: payload.systemId,
            });
            throw e;
          });

        close();

      }
    });
  };
  useEffect(() => {
    setFormState({ user: props.record, isEdit: props.isEdit });
    if (!props.visible) {
      return;
    }
    const pArr: Promise<any>[] = [];
    pArr.push(MchGroupProvider.getAll().then((groups) => setGroupList(groups)));
    if (!!props.record?.id) {
      pArr.push(refreshUserData(props.record.id));
    }
    setLoading(true);
    Promise.all(pArr)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.record, props.visible]);

  useEffect(() => {
    if (!formState.user || !formRef.current) {
      return;
    }
    formRef.current?.setFieldsValue({
      ...formState.user,
    });
  }, [formState, formRef]);
  return (
    <CoreDrawer
      width={640}
      title={
        formState.isEdit
          ? `編輯使用者(${formState.user?.displayName})`
          : `新增使用者`
      }
      visible={props.visible}
      onClose={() => close()}
      footerbuttons={[
        <Button key={1} type='primary' onClick={() => save(formRef)}>
          儲存
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          ref={formRef}
          layout='vertical'
          name='user_form'
          onValuesChange={(val) => {
            if (typeof val.password === 'string') {
              setConfirmPasswordVisible(!!val.password);
            }
            if (typeof val.systemId === 'string') {
              setFormSystemId(val.systemId);
            }
          }}
        >
          <Form.Item
            name='systemId'
            label='商戶代號'
            rules={[{ required: true, message: '請輸入商戶代號' }]}
          >
            {formState.isEdit ? (
              <Input readOnly bordered={false} />
            ) : (
              <Select
                showSearch
                allowClear
                options={systemList.systems.map((s: any) => ({
                  label: s.systemId,
                  value: s.systemId,
                }))}
              />
            )}
          </Form.Item>
          <Form.Item
            name='username'
            label='用戶代號'
            rules={[{ required: true, message: '請輸入用戶代號' }]}
          >
            <Input
              allowClear
              bordered={!formState.isEdit}
              readOnly={formState.isEdit}
              addonBefore={
                formState.isEdit || !formSystemId ? '' : `${formSystemId}_`
              }
            />
          </Form.Item>
          <Form.Item
            name='password'
            label='用戶密碼'
            extra={formState.isEdit ? `如不修改，請留空` : ''}
            rules={
              formState.isEdit
                ? []
                : [{ required: true, message: '請輸入用戶密碼' }]
            }
          >
            <Input.Password />
          </Form.Item>
          {formState.isEdit && !confirmPasswordVisible ? null : (
            <Form.Item
              name='confirm-password'
              label='確認用戶密碼'
              rules={[
                { required: true, message: '請輸入正確用戶密碼' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('請確認兩組密碼輸入相同');
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name='displayName'
            label='用戶顯示名稱'
            rules={[{ required: true, message: '請輸入用戶顯示名稱' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name='email'
            label='郵電地址'
            rules={[{ type: 'email', message: '請輸入正確的郵電地址' }]}
          >
            <Input allowClear type='email' />
          </Form.Item>
          {formState.isEdit ? (
            <Form.Item name='firstTimeLogin' label='完成第一次登入'>
              <Radio.Group buttonStyle='solid'>
                <Radio.Button value={true}>未登入</Radio.Button>
                <Radio.Button value={false}>已登入</Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : null}
          <Form.Item
            name='groupId'
            label='指定群組'
            rules={[{ required: true, message: '請指定群組' }]}
          >
            <Select showSearch optionFilterProp='children'>
              {groupList.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {`${x.name} - ${x.displayName}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </CoreDrawer>
  );
}
