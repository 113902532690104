export const STATE_ORDERS = 'orders';

const defaultState = {
  [STATE_ORDERS]: {},
};

// eslint-disable-next-line
export default (state = defaultState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
