import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';
import { UsdtUri } from '../../core/configs/services';
import { CreditSystemSettingResponse } from './systemProvider';

export interface CreditSetting {
  systemId: string;
  depositMode: number;
  changeOrderEnable: boolean;
  creditOrderDomain: string;
  creditNotifyUrl: string;
  creditMappingNotifyUrl: string;
  creditNotMappingNotifyUrl: string;
  creditFeeRate: string;
  creditFeeRateGT500: string;
  creditFeeRateGT1000: string;
  creditFeeRateGT2000: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface IFeeRateRange {
  minFeeRate: number | string;
  maxFeeRate: number | string;
  creditAmountPrecision: number;
}


export class CreditSettingProvider {
  static async getOneCreditSetting(
    systemId: string
  ) {
    const res = adminRequest.get<AdminResponse<CreditSystemSettingResponse>>(
      UsdtUri(`merchant/credit/setting/${systemId}`),
      { headers: { SYSTEM_ID: systemId } },
    )
    return res;
  }

  static async getCreditSetting(params?: {
    systemId?: string;
    [key: string]: any;
  }) {
    const res = await adminRequest.get<AdminListResponse<CreditSetting>>(
      UsdtUri('admin/credit/setting'),
      { params },
    );
    return res.data;
  }

  static async updateCreditSetting(systemId: string, data: any) {
    const res = await adminRequest.post<AdminResponse<CreditSetting>>(
      UsdtUri('admin/credit/setting'),
      { ...data, systemId },
    );
    return res.data;
  }

  static async getCreditSettingRange({
    systemId,
    parentSystemId,
  }: {
    systemId?: string;
    parentSystemId?: string;
  }) {
    const res = await adminRequest.get<AdminResponse<IFeeRateRange>>(
      UsdtUri('admin/credit/setting/range'),
      {
        params: { systemId, parentSystemId: parentSystemId },
      },
    );
    return res.data;
  }
}
