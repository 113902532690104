import { moduleInit } from '../../core/base/moduleInit';
import creditRecordList from '../pages/CreditRecordList';
import payRecordList from '../pages/PayforRecordList';
import collectRecordList from '../pages/CollectRecordList';
import collectDashboard from '../pages/CollectDashboard';
import collectWallets from '../pages/CollectWallets';
import issueRecordList from '../pages/TransferBackRecordList';
import { IPage, Page } from '../../core/configs/types';
import {
  READ_COLLOECT_DASHBOARD,
  READ_COLLOECT_ORDER,
  READ_COLLOECT_WALLET,
  READ_MCH_DEPOSIT_SOURCE,
  READ_ORDERS_CREDIT_RECORD,
  READ_ORDERS_PAY_RECORD,
  READ_POLLING_WALLET,
  READ_WITHDRAW_ORDER,
  READ_WITHDRAW_WHITLIST,
} from './scopes';
import { CollectMultipleWalletPageInfo } from '../pages/CollectMultipleWallet';
import UserWalletList from '../pages/UserWalletList';
import WithdrawWhiteList from '../components/WithdrawWhiteList';
import MchDepositSourceList from '../pages/MchDepositSourceList';

export const ModuleName = 'Orders';
export const ModuleIdentity = 'ORDERS';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_ORDERS_CREDIT = Symbol();
export const MENU_SYMBOL_ORDERS_PAY = Symbol();
export const MENU_SYMBOL_ORDERS_COLLECT = Symbol();
export const MENU_SYMBOL_ORDERS_ISSUE = Symbol();

const pages: IPage[] = Page.createInstances([
  {
    id: 20340,
    uri: '',
    component: creditRecordList,
    scopes: [READ_ORDERS_CREDIT_RECORD],
    onClose: () => {
    },
  },
  {
    id: 20341,
    uri: '',
    component: payRecordList,
    scopes: [READ_ORDERS_PAY_RECORD],
    onClose: () => {
    },
  },
  {
    id: 20362,
    uri: '',
    component: collectRecordList,
    scopes: [READ_COLLOECT_ORDER],
    onClose: () => {
    },
  },
  {
    id: 20363,
    uri: '',
    component: collectDashboard,
    scopes: [READ_COLLOECT_DASHBOARD],
    onClose: () => {
    },
  },
  {
    id: 20364,
    uri: '',
    component: collectWallets,
    scopes: [READ_COLLOECT_WALLET],
    onClose: () => {
    },
  },
  {
    id: 20366,
    uri: '',
    component: issueRecordList,
    scopes: [READ_WITHDRAW_ORDER],
    onClose: () => {
    },
  },
  CollectMultipleWalletPageInfo,
]);

const MchDepositSourcePage = Page.createInstance({
  id: 20343,
  uri: '',
  component: MchDepositSourceList,
  scopes: [READ_MCH_DEPOSIT_SOURCE],
  onClose: () => null,
});

const UserWalletListPage = Page.createInstance({
  id: 20345,
  uri: '',
  component: UserWalletList,
  scopes: [READ_POLLING_WALLET],
  onClose: () => null,
});

const whiteListManagement = Page.createInstance({
  id: 20367,
  uri: 'white-list',
  component: WithdrawWhiteList,
  scopes: [READ_WITHDRAW_WHITLIST],
  onClose: () => null,
});
_module.addPages([
  ...pages,
  UserWalletListPage,
  whiteListManagement,
  MchDepositSourcePage,
]);
_module.setMenu([
  {
    symbol: MENU_SYMBOL_ORDERS_CREDIT,
    label: '充值管理',
    menuId: 30,
    order: 30,
    iconId: 23,
    i18nLabel: { en_US: 'Deposit Management', zh_CN: '充值管理', vi_VN: 'Quản lý tiền nạp' },
    children: [
      {
        label: '充值紀錄',
        menuId: pages[0],
        i18nLabel: { en_US: 'Deposit Orders', zh_CN: '充值紀錄', vi_VN: 'Lịch sử nạp tiền' },
      },
      {
        label: '商戶充值來源',
        menuId: MchDepositSourcePage,
        i18nLabel: { en_US: 'Merchant Deposit Source', zh_CN: '商戶充值來源', vi_VN: 'Nguồn nạp từ thương nhân' },
      },
    ],
  },
  {
    symbol: MENU_SYMBOL_ORDERS_PAY,
    label: '代付訂單',
    menuId: 32,
    order: 32,
    iconId: 24,
    i18nLabel: { en_US: 'Payout Management', zh_CN: '代付管理', vi_VN: 'Quản lý thanh toán hộ' },
    children: [
      {
        label: '代付紀錄',
        menuId: pages[1],
        i18nLabel: { en_US: 'Payout Orders', zh_CN: '代付紀錄', vi_VN: 'Lịch sử thanh toán hộ' },
      },
    ],
  },
  {
    symbol: MENU_SYMBOL_ORDERS_ISSUE,
    label: '下發訂單',
    menuId: 36,
    order: 36,
    iconId: 13,
    i18nLabel: { en_US: 'Withdraw Management', zh_CN: '下發管理', vi_VN: 'Quản lý tiền rút' },
    children: [
      {
        label: '下發紀錄',
        menuId: pages[5],
        i18nLabel: { en_US: 'Withdraw Orders', zh_CN: '下發紀錄', vi_VN: 'Lịch sử rút tiền' },
      },
      {
        label: '下發白名單',
        menuId: whiteListManagement,
        i18nLabel: {
          en_US: 'Withdraw White List Management',
          zh_CN: '下發白名單',
          vi_VN: 'Danh sách trắng địa chỉ rút tiền',
        },
      },
    ],
  },
]);
