import adminRequest from '../../../config/adminRequest';
import { AdminUri } from '../../core/configs/services';
import { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';
import { User } from '../../permissions/types';

export interface Log {
  id: string;
  userId: string;
  systemId: string;
  function: string;
  action: string;
  payload: string;
  createdAt: Date;
}

export interface UserLogInfo extends Log {
  user?: User;
}

export class LogProvider {
  static async getList(param: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<UserLogInfo>>(
      AdminUri('admin/user-log'),
      {
        params: param,
      },
    );
    return res.data;
  }

  static async getPayload(id: string) {
    const res = await adminRequest.get<AdminResponse<any>>(
      AdminUri('admin/user-log/payload'),
      {
        params: { id: id },
      },
    );
    return res;
  }

  static async recordLog(data: {
    userId: string;
    systemId: string;
    function: string;
    action: string;
    payload: string;
    token?: string;
  }) {
    const res = await adminRequest
      .post<AdminResponse<any>>(AdminUri(`admin/user-log`), data, {
        headers: { Authorization: data.token },
      })
      .catch((err) => {
        throw err;
      });
    return res.data;
  }
}
