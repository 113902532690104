import React, { useEffect, useRef } from 'react';
import { Column, ColumnOptions } from '@antv/g2plot';
import { transfer2LocalDate } from '../../../core/utils/timeFormat';
import BigNumber from 'bignumber.js';
import { RevenueItem } from '../../../domain/report-provider/chartProvider';
import _ from 'lodash';
import moment from 'moment';

export type RevenueChartProps = {
  dataSet: Array<RevenueItem>;
};

const CommsionAmountChart = (props: RevenueChartProps) => {
  const ref = useRef<any>();
  const chartRef = useRef<Column>();

  const changeData = (data: Array<RevenueItem>) => {
    chartRef.current?.changeData(calculateChartData(data));
  };

  useEffect(() => {
    const rawData = props.dataSet;
    const chartData = calculateChartData(rawData);
    const config: ColumnOptions = {
      data: chartData,
      isStack: true,
      xField: 'date',
      yField: 'commissionAmount',
      seriesField: 'systemId',
      label: {
        position: 'middle', // 'top', 'bottom', 'middle'
        style:{
          fill: 'black'
        },
        content: (item) => {
          if(item.commissionAmount === 0) {
            return ''
          }
          return _.round(item.commissionAmount, 2).toString();
        },
      },
      interactions: [{ type: 'active-region', enable: false }],
      columnBackground: {
        style: {
          fill: 'rgba(0,0,0,0.1)',
        },
      },
    };
    const line = new Column(ref.current, config);
    chartRef.current = line;
    line.render();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeData(props.dataSet);
  }, [props.dataSet]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default CommsionAmountChart;

function calculateChartData(rawData: RevenueItem[]) {
  const result = [];
  const leepaySummery = [];

  for (const item of rawData) {
    const agentType = item.agentType;
    const date = transfer2LocalDate(item.reportDate);
    const systemId = item.systemId;
    const profitAmount = new BigNumber(item.profitAmount).toNumber();
    leepaySummery.push({
      timestamp: moment(date).valueOf(),
      date: date,
      systemId: 'Leepay',
      commissionAmount: profitAmount
    });
    if (agentType === 'NORMAL') {
      continue;
    }
    const commissionAmount = new BigNumber(item.commissionAmount).toNumber();
    result.push({
      timestamp: moment(date).valueOf(),
      date: date,
      systemId: systemId,
      commissionAmount: commissionAmount
    });
  }
  
  const leepaySummeryList =
  _.chain(leepaySummery)
    .groupBy('date')
    .map((objs, key) => ({
        'timestamp': objs[0].timestamp,
        'date': objs[0].date,
        'systemId': objs[0].systemId,
        'commissionAmount': _.sumBy(objs, 'commissionAmount') }))
    .value();
  return _.sortBy([...result, ...leepaySummeryList], (item) => item.timestamp);
}
