import { MchWallet } from '../../usdt-providers/walletProvider';
import { Button, Card, Col, Descriptions, Row, Statistic } from 'antd';
import React from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import { popOutQRCode } from '../../../../components/qrCode';
import { useTranslation } from 'react-i18next';
import Text from 'antd/es/typography/Text';
// import {CopyToClipboard} from "react-copy-to-clipboard";
const CopyToClipboard = require('react-copy-to-clipboard');

export default function WalletInfo(props: { wallet: MchWallet }) {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <Descriptions column={1}>
          <Descriptions.Item label={t('payment.channel')}>
            {props.wallet.channel}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment.token')}>
            {props.wallet.token}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment.wallet_address')}>
            <QrcodeOutlined
              title={t('payment.wallet_address')}
              onClick={() =>
                popOutQRCode({ address: props.wallet.walletAddress })
              }
            />
            <Text style={{ margin: '0.1rem' }}>
              {props.wallet.walletAddress}
            </Text>
            <CopyToClipboard text={props.wallet.walletAddress}>
              <Button>{t('common.copy')}</Button>
            </CopyToClipboard>
          </Descriptions.Item>
        </Descriptions>
        <Row gutter={2}>
          {/*<Col span={6}>*/}
          {/*  <Statistic*/}
          {/*    title={t('payment.blockBalance')}*/}
          {/*    value={props.wallet.balance}*/}
          {/*    precision={6}*/}
          {/*  />*/}
          {/*</Col>*/}
          {/*<Col span={6}>*/}
          {/*  <Statistic*/}
          {/*    title={t('payment.txnBalance')}*/}
          {/*    value={props.wallet.txnBalance}*/}
          {/*    precision={6}*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col span={6}>
            <Statistic
              title={t('payment.mchAvailableBalance')}
              value={props.wallet.availableBalance}
              precision={6}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
