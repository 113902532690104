import { Card, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DollarOutlined } from '@ant-design/icons';

export default function ReportRefundCard(props: { token: string, balance: number }) {
  const { t } = useTranslation();
  const [withdrawFrom, setWithdrawFrom] = useState(false);
  const [balance, setBalance] = useState<number>(props.balance);
  // const allowWithDraw = !!balance && balance <= 0;

  useEffect(() => {
    setBalance(props.balance);
  }, [props.balance]);

  return (
    <>
      <Card
        key={'refund_wallet_card'}
        style={{
          margin: '0.5rem',
          width: '12rem',
        }}
      >
        <Statistic
          key={'refund_balance'}
          title={props.token + ' ' + t('agent.refund_total')}
          value={balance}
          precision={3}
          // valueStyle={{ color: '#3f8600' }}
          prefix={<DollarOutlined />}
          style={{ margin: '0.2rem' }}
        />
      </Card>
    </>
  );
}
