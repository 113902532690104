import React, { useEffect, useRef, useState } from 'react';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Button, Dropdown, Menu, Modal, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { UsdtUri } from '../../core/configs/services';
import { FormInstance } from 'antd/lib/form';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProCoreActionType } from '@ant-design/pro-utils';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { AdminListResponse } from '../../domain/models/adminListResponse';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { refreshSystemListState } from '../../../stores/system-list/actions';
import adminRequest from '../../../config/adminRequest';
import SystemSettingForm, { SystemSettingActionType } from '../components/SystemSettingForm';
import SystemProvider from '../../domain/usdt-providers/systemProvider';
import { useTranslation } from 'react-i18next';
import { SystemStatusEnum } from '../../domain/system-status';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { READ_MERCHANT_GROUPS, READ_MERCHANT_ROLES, READ_MERCHANT_USERS, WRITE_SYSTEM } from '../configs/scopes';
import _module from '../configs/module';

const merchantType = {
  NORMAL: {
    type: 'NORMAL',
    text: '一般商戶',
  },
  AGENT: { type: 'AGENT', text: '代理商戶' },
};

const rootMerchant = 'ROOT';

interface SystemRow {
  systemId: string;
  systemName: string | null;
  merchantType: string;
  parentSystem: string | 'ROOT';
  status: string;
}

interface FormState {
  systemId?: string;
  visible: boolean;
  type: SystemSettingActionType;
}

interface PageOp extends CoreProTableState<SystemRow> {
  formType?: SystemSettingActionType;
  formPayload?: any;
  expendedRows?: { [key: string]: CoreProTableState<SystemRow> };
}

// eslint-disable-next-line
export default () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const systemListStore = useStore().getState().systemList;
  useEffect(() => {
    dispatch(refreshSystemListState());
  }, [systemListStore, dispatch]);
  const [reloadExpandRow, setReloadExpandRow] = useState<Map<string, { reload: () => any }>>(new Map());

  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const logger = useOperatorLogger();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [formState, setFormState] = useState<FormState>({
    systemId: undefined,
    visible: false,
    type: SystemSettingActionType.AddNormal,
  });

  const StatusTranslateEnum = {
    Activated: { text: t('system.status.activated') },
    Deactivated: { text: t('system.status.deactivated') },
    Banned: { text: t('system.status.banned') },
  };

  const isActive = (status: string) => {
    return status === SystemStatusEnum.Activated;
  };

  const columns: ProColumns<SystemRow>[] = [
    {
      title: '商戶代號',
      dataIndex: 'systemId',
      align: 'center',
      width: 200,
      renderFormItem: (_, row, form) => (
        <SystemIdSearchList
          onChange={(value) =>
            form.setFieldsValue({ [_.dataIndex as string]: value })
          }
          systemId={pageOp.get().params.systemId}
        />
      ),
      fieldProps: {
        showSearch: true,
      },
    },
    {
      title: '商戶名稱',
      dataIndex: 'systemName',
      align: 'center',
      width: 200,
      fieldProps: {
        extra: '(關鍵字查詢)',
      },
    },
    {
      title: '類型',
      dataIndex: 'merchantType',
      valueEnum: merchantType,
      align: 'center',
      width: 100,
    },
    {
      title: '狀態',
      dataIndex: 'status',
      valueEnum: StatusTranslateEnum,
      align: 'center',
      width: 50,
    },
    {
      title: '父商戶',
      dataIndex: 'parentSystem',
      align: 'center',
      width: 200,
      // valueEnum: systemsEnum,
    },
    {
      title: '操作',
      key: '_action',
      align: 'center',
      width: 200,
      valueType: 'option',
      render: (text, row) => [
        ...(row.merchantType?.toUpperCase() === merchantType.AGENT.type
          ? [
            <Button
              key={1}
              size='small'
              disabled={!isActive(row.status) || !_module.can([WRITE_SYSTEM])}
              type='primary'
              ghost
              onClick={() =>
                openSystemForm(SystemSettingActionType.Extend, row)
              }
            >
              新增子商戶
            </Button>,
          ]
          : []),
        <Button
          key={2}
          size='small'
          disabled={!_module.can([WRITE_SYSTEM])}
          onClick={() => openSystemForm(SystemSettingActionType.Edit, row)}
        >
          修改
        </Button>,
        // ...getStatusChangeButton(row),
        ...(isActive(row.status)
          ? [
            <Button
              key={3}
              size='small'
              disabled={!_module.can([WRITE_SYSTEM])}
              danger={_module.can([WRITE_SYSTEM])}
              onClick={() =>
                systemStatusAction({
                  systemId: row.systemId,
                  action: 'deactivate',
                })
              }
            >
              {t('common.deacivate')}
            </Button>,
            <Button
              key={3}
              size='small'
              disabled={!_module.can([WRITE_SYSTEM])}
              danger={_module.can([WRITE_SYSTEM])}
              onClick={() =>
                systemStatusAction({ systemId: row.systemId, action: 'ban' })
              }
            >
              {t('common.ban')}
            </Button>,
          ]
          : [
            <Button
              key={3}
              size='small'
              disabled={!_module.can([WRITE_SYSTEM])}
              onClick={() =>
                systemStatusAction({
                  systemId: row.systemId,
                  action: 'activate',
                })
              }
            >
              {t('common.activate')}
            </Button>,
          ]),
        <Dropdown.Button
          disabled={!isActive(row.status)||!_module.can([READ_MERCHANT_USERS])}
          key={4}
          onClick={(x) => {
            history.push(`/merchantUsers/10401?systemId=${row.systemId}`);
          }}
          size='small'
          overlay={() => (
            <Menu>
              <Menu.Item
                key={1}
                disabled={!_module.can([READ_MERCHANT_GROUPS])}
                onClick={(x) => {
                  history.push(
                    `/merchantGroups/10402?systemId=${row.systemId}`,
                  );
                }}
              >
                群組
              </Menu.Item>
              <Menu.Item
                key={2}
                disabled={!_module.can([READ_MERCHANT_ROLES])}
                onClick={(x) => {
                  history.push(`/merchantRoles/10403?systemId=${row.systemId}`);
                }}
              >
                角色
              </Menu.Item>
            </Menu>
          )}
        >
          <div style={{ fontSize: '12px' }}>使用者</div>
        </Dropdown.Button>,
      ],
    },
  ];

  const systemStatusAction = (param: {
    systemId: string;
    action: 'deactivate' | 'ban' | 'activate';
  }) => {
    let actionName = '';
    switch (param.action) {
      case 'activate':
        actionName = t('common.activate');
        break;
      case 'ban':
        actionName = t('common.ban');
        break;
      case 'deactivate':
        actionName = t('common.deacivate');
        break;
    }
    Modal.confirm({
      title: '確認',
      content: `請確認是否 ${actionName}, 商戶: ${param.systemId}`,
      onOk: async () => {
        switch (param.action) {
          case 'activate':
            try {
              const res = await SystemProvider.activate(param.systemId);
              logger.log({
                action: '啟用',
                payload: { req: param, res },
                systemId: param.systemId,
              });
            } catch (e) {
              logger.log({
                action: '啟用',
                payload: { req: param, res: e },
                systemId: param.systemId,
              });
              throw e;
            }
            break;
          case 'ban':
            try {
              const res = await SystemProvider.ban(param.systemId);
              logger.log({
                action: '禁用',
                payload: { req: param, res },
                systemId: param.systemId,
              });
            } catch (e) {
              logger.log({
                action: '禁用',
                payload: { req: param, e },
                systemId: param.systemId,
              });
              throw e;
            }
            break;
          case 'deactivate':
            try {
              const res = await SystemProvider.deactivate(param.systemId);
              logger.log({
                action: '停用',
                payload: { req: param, res },
                systemId: param.systemId,
              });
            } catch (e) {
              logger.log({
                action: '停用',
                payload: { req: param, e },
                systemId: param.systemId,
              });
              throw e;
            }
            break;
          default:
            throw new Error('not exist action');
        }
        Modal.success({
          title: `${t('common.success')}`,
          content: ``,
          onOk: () => {
            refreshList();
          },
        });
      },
    });
  };

  const refreshList = () => {
    pageOp.update((v) => {
      v.needRequest = true;
      return v;
    });
    if (tableActionRef.current) tableActionRef.current.reload();
    if (reloadExpandRow) reloadExpandRow.forEach((x) => x.reload());
  };

  const openSystemForm = (
    type: SystemSettingActionType,
    payload?: SystemRow,
  ) => {
    console.log('openSystemForm', payload, type);
    pageOp.update((prevState) => ({
      ...prevState,
      formType: type,
      formPayload: payload,
    }));
    if (
      type === SystemSettingActionType.Edit ||
      SystemSettingActionType.Extend
    ) {
      setFormState((s) => ({
        ...s,
        type,
        systemId: payload?.systemId,
        visible: true,
      }));
    } else {
      setFormState((s) => ({
        ...s,
        type,
        visible: true,
      }));
    }
  };
  const closeSystemForm = () => {
    pageOp.update((prevState) => ({
      ...prevState,
      needRequest: true,
      formType: undefined,
      formPayload: undefined,
    }));
    setFormState((s) => ({ ...s, visible: false, systemId: undefined }));
    if (tableActionRef.current) tableActionRef.current.reload();
    if (reloadExpandRow) reloadExpandRow.forEach((x) => x.reload());
  };

  const expandedRow = (row: SystemRow) => {
    return (
      <ProTable<SystemRow>
        actionRef={(ref) => {
          if (reloadExpandRow)
            reloadExpandRow.set(row.systemId, {
              reload: () => {
                if (ref) {
                  ref.reload();
                  console.log(`${row.systemId} reloaded`);
                }
              },
            });
          setReloadExpandRow(reloadExpandRow);
        }}
        rowKey='systemId'
        columns={columns}
        request={(params) =>
          req({ ...params, parentSystem: row.systemId }, {}, {})
        }
        expandable={{
          expandedRowRender: expandedRow,
          rowExpandable: (row) =>
            row.merchantType?.toUpperCase() === merchantType.AGENT.type,
        }}
        // showHeader={false}
        // headerTitle={false}
        search={false}
        options={false}
        pagination={{
          pageSize: 10,
          position: ['bottomCenter'],
          showSizeChanger: true,
        }}
        style={{
          border: '1px dotted #bbbbbb',
          boxShadow: '3px 3px #e6e6e6',
        }}
      />
    );
  };
  const req = (params: any, sort: any, filter: any) => {
    return adminRequest
      .get<AdminListResponse<SystemRow>>(UsdtUri(`admin/payment/merchant`), {
        params: {
          page: params.current,
          limit: params.pageSize,
          ...omit(params, ['current', 'pageSize']),
        },
      })
      .then((rs) => {
        logger.log({
          payload: { req: params },
          systemId: '',
          action: '查詢',
        });
        return PaginationTransformer.responseTransfer(rs.data);
      })
      .catch((e) => {
        Modal.error({ content: '系統錯誤' });
        logger.log({
          payload: { req: params, res: e },
          systemId: '',
          action: '查詢',
        });
        return { data: [], success: false };
      });
  };

  useEffect(() => {
    const state = pageOp.get();
    if (typeof state.formType !== 'undefined') {
      openSystemForm(state.formType, state.formPayload);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (searchFormRef.current)
      searchFormRef.current.setFieldsValue(pageOp.get().params);
    // eslint-disable-next-line
  }, [searchFormRef, pageOp.get()]);

  return (
    <>
      <PageHeader
        title='系統商戶'
        subTitle='系統商戶：一般或代理'
        extra={<></>}
      />
      <CoreProTable<SystemRow>
        bordered
        actionRef={tableActionRef as any}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        columns={columns}
        toolBarRender={() => [
          _module.canRender(
            WRITE_SYSTEM,
          <Button
            key={1}
            type='primary'
            onClick={() => openSystemForm(SystemSettingActionType.AddNormal)}
          >
            <PlusOutlined />
            新增商戶
          </Button>,)
          // <Button key={'qq'} onClick={() => {
          //     console.log(reloadExpandRow)
          //     reloadExpandRow?.forEach(x => x.reload())
          // }}>Reload</Button>
        ]}
        expandable={{
          expandedRowRender: expandedRow,
          rowExpandable: (row) =>
            row.merchantType?.toUpperCase() === merchantType.AGENT.type,
          onExpand: (expanded, record) => {
            if (expanded) {
              pageOp.update((prevState) => ({
                ...prevState,
                expendedRows: {
                  ...pageOp.get().expendedRows,
                  [record.systemId]: {
                    params: {},
                    collapsed: false,
                    pagination: {
                      pageSize: 10,
                      current: 1,
                    },
                    needRequest: true,
                  },
                },
              }));
            } else {
              const expendedRows = pageOp.get().expendedRows;
              if (typeof expendedRows !== 'undefined') {
                delete expendedRows[record.systemId];
                pageOp.update((prevState) => ({ ...prevState, expendedRows }));
              }
            }
          },
        }}
        rowKey='systemId'
        requestData={(params, sort, filter) => {
          return req(
            {
              ...(Object.keys(params).length > 2
                ? {}
                : {
                  parentSystem: rootMerchant,
                }),
              ...params,
            },
            sort,
            filter,
          );
        }}
      />
      <SystemSettingForm
        type={formState.type}
        visible={formState.visible}
        closeForm={closeSystemForm}
        systemId={formState.systemId ?? ''}
      />
    </>
  );
};
