import { AdminUri } from '../../core/configs/services';
import adminRequest from '../../../config/adminRequest';
import { AdminResponse } from '../models/adminResponse';
import { AdminListResponse } from '../models/adminListResponse';
import { Role } from './mchRoleProvider';
import { GroupBase } from './mchGroupProvider';
import PaginationTransformer from '../../core/utils/paginationTransformer';

export interface Group extends GroupBase {
  id: number;
  roles: Array<Role>;
  systemId: string;
}

export class AdminUserGroupProvider {
  static async delete(id?: number) {
    await adminRequest.delete(AdminUri(`admin/user/groups/${id}`));
  }

  static async edit(id?: number, payload?: Partial<Group>) {
    await adminRequest.patch(AdminUri(`admin/user/groups/${id}`), payload);
  }

  static async create(payload?: Partial<Group>) {
    const res = await adminRequest.post<AdminResponse<Group>>(
      AdminUri(`admin/user/groups`),
      payload,
    );
    return res.data;
  }

  static async getInfo(id?: number) {
    return await adminRequest.get<AdminResponse<Group>>(
      AdminUri(`admin/user/groups/${id}`),
    );
  }

  static async getOne(id?: number) {
    const res = await adminRequest.get<AdminResponse<Group>>(
      AdminUri(`admin/user/groups/${id}`),
    );
    return res.data;
  }

  static async getList(params: any) {
    const res = await adminRequest.get<AdminListResponse<Group>>(
      AdminUri('admin/user/groups'),
      {
        params: {
          ...params,
          // ...omit(params, ['current', 'pageSize']),
          // page: params.current,
          // limit: params.pageSize,
        },
      },
    );
    return res.data;
  }

  static async getAll() {
    const res = PaginationTransformer.getAllFromPagination<Group, null>(
      (query) => AdminUserGroupProvider.getList(query),
      null,
    );
    return res;
  }
}
