import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { Checkbox, PageHeader } from 'antd';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { transfer2LocalDate } from '../../core/utils/timeFormat';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { useTranslation } from 'react-i18next';
import {
  DepositOrderSuccessRateProvider,
  DepositOrderSuccessRateType,
} from '../../domain/report-provider/depositOrderSuccessRateProvider';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import CreditOrderStatusType from '../../domain/order-status/creditOrderType';

const OrderBy = {
  DESC: '高到低',
  ASC:'低到高',
};

interface PageOp extends CoreProTableState<DepositOrderSuccessRateType> {
}

export default function() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const checkedState = usePageState<{
    checked: boolean
  }>(
    'CheckedState',
    {
      checked: true
    }
  );
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('payment.report_date'),
    pageOp,
    defaultDateRange:0,
    defaultDate:1,
    state: dateRangeState,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  return (
    <>
      <PageHeader title={'充值訂單成功率表'} subTitle={''} extra={<></>} />
      <CoreProTable<DepositOrderSuccessRateType>
        bordered
        rowKey={(record) => `${record.reportDate}-${record.systemId}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        formRef={searchFormRef}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        requestData={async (params: any) => {
          let res;
          res = await DepositOrderSuccessRateProvider.getList({
            ...PaginationTransformer.paramTransfer(params),
            ...dateRangeColumn.getParamAndUpdatePage(),
            hideNoOrder:checkedState.get().checked,
          }).catch((e) => {
            console.error(e);
            throw e;
          });

          return PaginationTransformer.responseTransfer(res);
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.report_date'),
            dataIndex: 'reportDate',
            render: (text, row) => transfer2LocalDate(text as any),
            fixed: 'left',
          },
          dateRangeColumn.column,
          {
            hideInSearch: false,
            hideInTable: true,
            width: 100,
            dataIndex: 'sortBySuccessRate',
            initialValue: 'DESC',
            title: t('report.sortBySuccessRate'),
            valueEnum: OrderBy,
          },
          {
            hideInSearch: false,
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            width: 100,
            dataIndex: 'depositMode',
            hideInSearch: true,
            title: '充值模式',
            valueEnum: CreditOrderStatusType.DepositMode2Chinese,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'orderCount',
            title: t('report.orderCount'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'successCount',
            title: t('report.successCount'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'expireCount',
            title: t('report.expireCount'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'changedCount',
            title: t('report.changedCount'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'failCount',
            title: t('report.failCount'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'successRate',
            tooltip: '成功訂單數 / 總訂單數',
            title: t('report.successRate'),
            render: (text, row: any) =>
              row.successRate + '%',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'failRate',
            tooltip: '(過期訂單數 + 失敗訂單數 + 已調帳訂單數) / 總訂單數',
            title: t('report.failRate'),
            render: (text, row: any) =>
              row.failRate + '%',
          },
          {
            hideInSearch: false,
            hideInTable: true,
            width: 100,
            dataIndex: 'hideNoOrder',
            ellipsis: true,
            initialValue: true,
            title: t('report.hideNoOrder'),
            renderFormItem: (value) => (
              <Checkbox onChange={(value)=>{
                checkedState.update({
                  checked: value.target.checked
                });
                return;
              }} checked={checkedState.get().checked}/>
            ),
          },
        ]}
      />
    </>
  );
}