import _ from 'lodash';
import { setStorage } from '../../lib/webStorage';

export type UserStore = {
  userId: string;
  isLogin: boolean;
  userName: string;
  systemId: string;
  token?: string;
  googleAuthKey?: string;
  lang: string;
  merchantType?: string;
};

export const USER_STORE = 'USER_STORE';

const defaultState: UserStore = {
  isLogin: false,
  userName: '---',
  systemId: '---',
  lang: 'zh',
  userId: '',
};

const user = (
  state = defaultState,
  action: { type: string; payload: UserStore },
) => {
  switch (action.type) {
    case USER_STORE:
      setStorage('lang', action.payload.lang ?? defaultState.lang);
      return { ..._.cloneDeep(state), ...action.payload };
    default:
      return state;
  }
};

export default user;
