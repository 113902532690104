import React, { useEffect, useState } from 'react';
import { Button, Menu, Tooltip } from 'antd';
import {
  AppstoreOutlined,
  BankOutlined,
  CreditCardOutlined,
  CrownOutlined,
  DashboardOutlined,
  DollarOutlined,
  DotChartOutlined,
  GiftOutlined,
  InboxOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoneyCollectOutlined,
  PayCircleOutlined,
  RedEnvelopeOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  ShopOutlined,
  TableOutlined,
  ToolOutlined,
  TransactionOutlined,
  TrophyOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  VideoCameraOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import './index.less';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { GlobalStoreMerge } from '../../stores/merge';
import MenuStoreAction from '../../stores/menu/action';
import * as configMenu from '../../modules/core/configs/menus';
import { MenuStore } from '../../stores/menu';
import _ from 'lodash';
import { MenuEventEnum, MenuEventManager } from '../../event/menuEventManager';
import { filter } from 'rxjs/operators';

const { SubMenu } = Menu;

interface props {
  menu: configMenu.Menu[];
}

interface state {
  openKeys: Array<any>;
  selectKeys: Array<any>;
}

const menuIcon: any = {
  1: <UserOutlined />,
  2: <CrownOutlined />,
  3: <ShopOutlined />,
  4: <InboxOutlined />,
  5: <AppstoreOutlined />,
  6: <TableOutlined />,
  7: <UserAddOutlined />,
  8: <MoneyCollectOutlined />,
  9: <DotChartOutlined />,
  10: <GiftOutlined />,
  11: <ToolOutlined />,
  12: <RedEnvelopeOutlined />,
  13: <DollarOutlined />,
  14: <UsergroupAddOutlined />,
  15: <TrophyOutlined />,
  16: <SafetyCertificateOutlined />,
  18: <WalletOutlined />,
  17: <VideoCameraOutlined />,
  19: <BankOutlined />,
  20: <SettingOutlined />,
  21: <DashboardOutlined />,
  22: <TransactionOutlined />,
  23: <CreditCardOutlined />,
  24: <PayCircleOutlined />,
};

function getCurOpen(props: {
  menu: configMenu.Menu[];
  menuId: number;
}): { openKeys: string[]; selectKeys: string[] } {
  let openKeys;
  let selectKeys;
  _.find(props.menu, (x, i) => {
    if (!!x.children) {
      const menu = _.find(x.children, (child) => child.menuId === props.menuId);
      if (menu) {
        selectKeys = [props.menuId.toString()];
        openKeys = [`sub-${x.menuId}`];
      }
    }
  });
  return { openKeys: openKeys ?? [], selectKeys: selectKeys ?? [] };
}

function LeftMenu(props: props) {
  const dispatch = useDispatch();
  const store = useStore<GlobalStoreMerge>();
  const lang = useSelector<GlobalStoreMerge, string>(
    (store) => store.user.lang,
  );
  const currentMenu = useSelector<GlobalStoreMerge, MenuStore>(
    (store) => store.menu,
  );
  const [collapse, setCollapse] = useState(currentMenu.leftMenuCollapsed);

  const [state, setState] = useState<state>(() => {
    const cur = getCurOpen({
      menu: props.menu,
      menuId: currentMenu.current.now[1],
    });
    return {
      openKeys: cur.openKeys,
      selectKeys: cur.selectKeys,
    };
  });
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find(
      (key: string) => state.openKeys.indexOf(key) === -1,
    );
    setState((v) => ({ ...v, openKeys: latestOpenKey ? [latestOpenKey] : [] }));
  };
  const onSelectChange = (key: any) => {
    if (!key?.key || store.getState().menu.leftMenuCollapsed) {
      return;
    }
    const data = getCurOpen({ menu: props.menu, menuId: parseInt(key.key) });
    setState((v) => ({
      ...v,
      openKeys: data.openKeys,
      selectKeys: data.selectKeys,
    }));
  };
  const getMenuLabel = (item: any) => {
    switch(lang){
      case'zh':{
        return item.i18nLabel.zh_CN
        break;
      }
      case'en':{
        return item.i18nLabel.en_US
        break;
      }
      case'vi':{
        return item.i18nLabel.vi_VN
        break;
      }
      default:{
        return item.i18nLabel.en_US
        break;
      }
    }
  };
  const collapseMenu = () => {
    const isCollapse = !collapse;
    setCollapse(isCollapse);
    MenuStoreAction.leftMenuCollapse(dispatch, isCollapse);
  };
  useEffect(() => {
    if (collapse)
      setState((v) => {
        v.selectKeys = [];
        return v;
      });
  }, [collapse]);
  useEffect(() => {
    if (currentMenu) {
      const data = getCurOpen({
        menuId: currentMenu.current.now[1] as number,
        menu: props.menu,
      });
      setState((v) => ({
        selectKeys: collapse ? [] : data.selectKeys,
        openKeys: data.openKeys,
      }));
    }
    // eslint-disable-next-line
  }, [currentMenu]);
  useEffect(() => {
    MenuEventManager.subject
      .pipe(filter((x) => x.type === MenuEventEnum.Opened))
      .subscribe((x) => {
        const menuStore = store.getState().menu;
        if (menuStore.leftMenuCollapsed) {
          return;
        }
        const curMenu = menuStore.current;
        const cur = getCurOpen({
          menu: menuStore.treeMenu,
          menuId: curMenu.now[1],
        });
        setState((v) => ({
          selectKeys: cur.selectKeys,
          openKeys: cur.openKeys,
        }));
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="admin-left-menu">
        <div className="admin-menu-title">
          <Button
            className="collapse-button"
            type="primary"
            onClick={() => collapseMenu()}
            style={{
              backgroundColor: '#000',
              borderColor: 'transparent',
            }}
          >
            {collapse ? (
              <MenuUnfoldOutlined style={{ fontSize: '0.8rem' }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: '0.8rem' }} />
            )}
          </Button>
          {collapse ? '' : <span className="logo" />}
        </div>
        <div className="admin-menu-nav admin-scroll-one">
          <Menu
            mode="inline"
            theme="dark"
            // forceSubMenuRender={true}
            selectedKeys={collapse ? undefined : state.selectKeys}
            openKeys={state.openKeys}
            onOpenChange={onOpenChange}
            onSelect={onSelectChange}
            inlineCollapsed={collapse}
          >
            {props.menu.map((item: any) =>
              item.children ? (
                <SubMenu
                  key={`sub-${item.menuId}`}
                  icon={menuIcon[item.iconId ?? item.menuId]}
                  title={getMenuLabel(item)}
                >
                  {item.children.map((rs: any) => (
                    <Menu.Item key={rs.menuId}>
                      <Link
                        to={{
                          pathname: `/${rs.i18nLabel.en_US
                            .split(' ')
                            .join('')
                            .replace(/^\S/, (s: any) => s.toLowerCase())}/${
                            rs.menuId
                          }`,
                          state: { root: item.menuId, children: rs.menuId },
                        }}
                        title={getMenuLabel(rs)}
                      >
                        <Tooltip title={getMenuLabel(rs)}>
                          {getMenuLabel(rs)}
                        </Tooltip>
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={item.menuId}
                  icon={menuIcon[item.iconId ?? item.menuId]}
                >
                  <Link
                    to={{
                      pathname: `/${item.i18nLabel.en_US
                        .split(' ')
                        .join('')
                        .replace(/^\S/, (s: any) => s.toLowerCase())}/${
                        item.menuId
                      }`,
                      state: { root: item.menuId, children: item.menuId },
                    }}
                    title={getMenuLabel(item)}
                  >
                    <Tooltip title={getMenuLabel(item)}>
                      {getMenuLabel(item)}
                    </Tooltip>
                  </Link>
                </Menu.Item>
              ),
            )}
          </Menu>
        </div>
      </div>
    </>
  );
}

export default LeftMenu;
