import { useTranslation } from 'react-i18next';

export const useWithdrawStatus = () => {
  const { t } = useTranslation();
  return {
    Reviewing: t('funding.withdraw_order_status.reviewing'),
    Transferring: t('funding.withdraw_order_status.transferring'),
    Rejected: t('funding.withdraw_order_status.rejected'),
    Success: t('funding.withdraw_order_status.success'),
  };
};
