import React from 'react';

import './index.less';
import LoginForm from './loginForm';
import { connect } from 'react-redux';
import { getStorage } from '../../lib/webStorage';

interface props {
  dispatch: any;
  history: any;
}

interface state {
  forgot: boolean;
}
class Login extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = { forgot: true };
  }
  componentDidMount() {
    const token = getStorage('token');
    if (token) {
      this.props.history.push('/index');
    }
  }
  render() {
    const { history } = this.props;
    const { forgot } = this.state;
    const language = getStorage('lang', 0) ? getStorage('lang', 0) : 'zhCN';
    return (
      <div className="admin-login">
        <div className="admin-login-main admin-scroll-two">
          <div className="admin-login-from">
            {forgot ? (
              <LoginForm
                history={history}
                dispatch={this.props.dispatch}
                lang={language}
              />
            ) : (
              <></>
            )}
            <p className="info-title">
              Copyright © 2020 All Rights Reserved By Pokemon_Pay
            </p>
          </div>
        </div>
      </div>
    );
  }
}
function propertiesState(state: any) {
  return {};
}

export default connect(propertiesState)(Login);
