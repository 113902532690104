import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';

export interface ExchangeRate {
  from: string;
  to: string;
  buyingPrice: number;
  sellingPrice: number;
  bizTime: Date;
}

export class ExchangeRateProvider {
  static async getList(params: {
    from?: string;
    to?: string;
    start: Date;
    end: Date;
  }) {
    const res = await adminRequest.get<AdminListResponse<ExchangeRate>>(
      UsdtUri('exchange-rate'),
      {
        params: {
          ...params,
          start: params.start.toISOString(),
          end: params.end.toISOString(),
          limit: 50,
        },
      },
    );
    res.data.data.items = res.data.data.items.map(
      (x) =>
        ({
          ...x,
          buyingPrice: Number(x.buyingPrice),
          sellingPrice: Number(x.sellingPrice),
        } as ExchangeRate),
    );
    return res.data;
  }

  static async getRawList(params: {
    from: string;
    to: string;
    start: Date;
    end: Date;
  }) {
    const res = await adminRequest.get<AdminResponse<any>>(
      UsdtUri('exchange-rate/group'),
      {
        params: {
          from: params.from,
          to: params.to,
          start: params.start.toISOString(),
          end: params.end.toISOString(),
        },
      },
    );
    return res.data;
  }
}
