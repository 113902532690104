import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { AbandonWallet, AbandonWalletProvider } from '../../domain/usdt-providers/AbandonWalletProvider';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import { Button, PageHeader, Space } from 'antd';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import TransferForm, { TransferFormProps, TransferOrderClosedEvent } from '../component/TransferForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import HexLabel from '../../core/components/HexLabel';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { WRITE_ABANDON_WALLET } from '../configs/scopes';
import _module from '../configs/module';

const defailtTransferState: TransferFormProps = {
  systemId: '',
  visible: false,
  balance: '0',
  channel: '',
  token: '',
  walletAddress: '',
};

interface PageOp extends CoreProTableState<AbandonWallet> {}

export default function AbandonWalletPage(props: {}) {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [transferState, setTransferState] = useState<TransferFormProps>(
    defailtTransferState,
  );
  const loggger = useOperatorLogger();

  const checkTransfer = (row: AbandonWallet) => {
    return row.txnBalance > 0;
  };

  const refresh = () => {
    pageOp.update((v) => {
      v.needRequest = true;
      return v;
    });
    actionRef.current?.reload();
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === TransferOrderClosedEvent.symbol))
      .subscribe((x) => {
        setTransferState(defailtTransferState);
      });
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === TransferOrderClosedEvent.symbol))
      .subscribe((x) => {
        refresh();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader
        title={t('abandon_wallet.abandon_wallet')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<AbandonWallet>
        bordered
        rowKey={(row) =>
          `${row.systemId}:${row.channel}:${row.token}:${row.walletAddress}`
        }
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        onReset={() => {
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        requestData={(params) =>
          AbandonWalletProvider.getList(
            PaginationTransformer.paramTransfer(params),
          ).then((rs) => {
            loggger.log({
              action: '查詢',
              payload: { req: params },
              systemId: params.systemId
            });
            return PaginationTransformer.responseTransfer(rs);
          })
        }
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            fixed: 'left',
            hideInSearch: false,
            width: 100,
            align: 'center',
            title: '商戶名稱',
            dataIndex: 'systemId',
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            valueType: 'select',
            valueEnum: Token,
            onFilter: true,
            width: 70,
          },
          {
            hideInSearch: false,
            width: 100,
            align: 'center',
            title: t('payment.wallet_address'),
            ellipsis: true,
            dataIndex: 'walletAddress',
            render: (node, row) => (
              <HexLabel hex={row.walletAddress} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            width: 80,
            align: 'right',
            title: t('payment.txnBalance'),
            dataIndex: 'txnBalance',
          },
          {
            hideInSearch: true,
            width: 80,
            align: 'right',
            title: t('payment.availableBalance'),
            dataIndex: 'availableBalance',
          },
          {
            hideInSearch: true,
            width: 80,
            align: 'center',
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            title: t('common.operate'),
            key: 'actions',
            width: 80,
            valueType: 'option',
            fixed: 'right',
            align: 'center',
            render: (dom, row) => (
              <Space>
                {
                  <Button
                    key={1}
                    type="primary"
                    size={'small'}
                    disabled={!checkTransfer(row) || !_module.can([WRITE_ABANDON_WALLET])}
                    onClick={() => {
                      setTransferState({
                        visible: true,
                        balance: row.txnBalance.toString(),
                        channel: row.channel,
                        token: row.token,
                        walletAddress: row.walletAddress,
                        systemId: row.systemId,
                      });
                    }}
                  >
                    下發
                  </Button>
                }
              </Space>
            ),
          },
        ]}
      />
      <TransferForm {...transferState} />
    </>
  );
}
