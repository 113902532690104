import React, { useEffect, useRef } from 'react';
import { Bar, BarOptions } from '@antv/g2plot';
import BigNumber from 'bignumber.js';
import { AgentRefundItem } from '../../../domain/report-provider/chartProvider';
import * as _ from 'lodash';
import { leepayFormat } from '../../../core/utils/leepayFormat';

export type AgentRefundChartProps = {
  dataSet: Array<AgentRefundItem>;
};

const AgentRefundChart = (props: AgentRefundChartProps) => {
  const ref = useRef<any>();
  const chartRef = useRef<Bar>();

  const changeData = (data: Array<AgentRefundItem>) => {
    // @ts-ignore
    chartRef.current?.changeData(calculateChartData(data));
  };

  useEffect(() => {
    const rawData = props.dataSet;
    const chartData = calculateChartData(rawData);

    const config: BarOptions = {
      data: chartData.reverse(),
      isStack: true,
      padding: 'auto',
      xField: 'profitAmount',
      yField: 'parentSystem',
      seriesField: 'systemId',
      label: {
        // 可手动配置 label 数据标签位置
        position: 'middle', // 'left', 'middle', 'right'
        // 可配置附加的布局方法
        layout: [
          // 柱形图数据标签位置自动调整
          { type: 'interval-adjust-position' },
          // 数据标签防遮挡
          { type: 'interval-hide-overlap' },
          // 数据标签文颜色自动调整
          { type: 'adjust-color' },
        ],
        content: (item) => {
          if(item.profitAmount === 0) {
            return ''
          }
          return _.round(new BigNumber(item.profitAmount).toNumber(), 2).toString();
        },
      },
    };
    // config.data = props.dataSet
    const line = new Bar(ref.current, config);
    chartRef.current = line;
    line.render();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeData(props.dataSet);
  }, [props.dataSet]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default AgentRefundChart;

function calculateChartData(rawData: AgentRefundItem[]) {

  const groupbyKeys = function(arr: any[], ...keys: string[]) {
    const keysFieldName = keys.join();
    return arr.map(ele => {
      const keysField = {};
      // @ts-ignore
      keysField[keysFieldName] = keys.reduce((keyValue, key) => {
        return keyValue + ele[key];
      }, '');
      return Object.assign({}, ele, keysField);
    }).reduce((groups, ele) => {
      (groups[ele[keysFieldName]] = groups[ele[keysFieldName]] || [])
        .push([ele].map(e => {
          if (keys.length > 1) {
            delete e[keysFieldName];
          }
          return e;
        })[0]);
      return groups;
    }, {});
  };


  const result = [];
  //日墊資紀錄(維運充值)
  const group = groupbyKeys(rawData, 'systemId', 'parentSystem');
  const groupKeys = Object.keys(group);

  if (groupKeys.length > 0) {
    for (const key of groupKeys) {

      const profitAmount = _.chain(group[key])
        .reduce((profitAmount, order) => {
          return new BigNumber(profitAmount).plus(new BigNumber(order.profitAmount).toNumber()).toNumber();
        }, new BigNumber(0).toNumber()).value();

      result.push({
        profitAmount: _.round(new BigNumber(profitAmount).toNumber(), 2),
        systemId: group[key][0].systemId,
        parentSystem: leepayFormat(group[key][0].parentSystem),
      })
    }}

  return _.sortBy(result, (item) => item.profitAmount);
}
