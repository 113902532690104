import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import { Button, Input, List, message, Modal, PageHeader } from 'antd';
import { UserWallet, UserWalletProvider } from '../../domain/usdt-providers/userWalletProvider';
import { IPage, Page } from '../../core/configs/types';
import { OPERATOR_COLLOECT } from '../configs/scopes';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { CollectType } from '../../domain/order-status/collectType';
import { adminClientMessage } from '../../../config/adminRequest';
import { CollectTaskProvider } from '../../domain/usdt-providers/collectTaskProvider';
import { useUserWalletCollectStatusTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { useTranslation } from 'react-i18next';
import { ChannelValueEnum, CollectToken, Token } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { getStorage } from '../../../lib/webStorage';

interface Param extends UserWallet {
  minBalance: number;
}

interface PageOp extends CoreProTableState<Param> {}

export default function CollectMultipleWallet() {
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const userWalletCollectStatusTranslateEnum = useUserWalletCollectStatusTranslate();
  const { t } = useTranslation();
  // state
  const [selectRecord, setSelectRecord] = useState<UserWallet[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // ref
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();

  // const [tokenData, setTokenData] = useState({ channel: '', token: '' });
  // const pageTokenOp = usePageState('SELECT_TOKEN', {
  //   channel: tokenData.channel,
  //   token: tokenData.token,
  // });

  // redux state
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const [filter, setFilter] = useState<{
    minBalance?: number;
    channel?: string;
  }>({
    minBalance: pageOp.get().params.minBalance,
  });

  const collectWalletFlow = async () => {
    if (!selectRecord || selectRecord.length === 0) {
      message.warn({
        content: t(`collect.unselect_collect_wallet`),
      });
      return;
    }
    Modal.confirm({
      title: t('collect.collect_multiple_title'),
      autoFocusButton: 'ok',
      width: '600px',
      content: (
        <>
          <List
            header={t('collect.start_collect_these_wallet')}
            bordered
            dataSource={selectRecord}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.userId}
                  description={`${t('payment.availableBalance')}: ${
                    item.availableBalance
                  }, ${t('payment.wallet_address')}: ${item.walletAddress}`}
                />
              </List.Item>
            )}
          />
        </>
      ),
      onOk: async () => {
        try {
          const selectWallet = _.map(selectRecord, (x) => {
            return {
              srcAddress: x.walletAddress,
              channel: x.channel,
              token: x.token,
            };
          });
          const res = await CollectTaskProvider.createMultipleCollectTask(
            selectWallet,
            user.userId,
          );
          Modal.success({
            title: t('collect.collect_task_has_builded'),
            content: `${t(
              'collect.multiple_collect_success_description.has_build',
            )} ${selectRecord.length} ${t(
              'collect.multiple_collect_success_description.collect_task',
            )}`,
            // content: `已建立 ${selectRecord.length} 筆歸集任務`,
          });
        } catch (err: any) {
          Modal.error({
            content:
              err.displayMessage ??
              adminClientMessage.get(err.clientCode) ??
              err.message,
            okText: t('common.confirm'),
          });
        } finally {
          onReset();
        }
      },
    });
  };

  const onReset = () => {
    pageOp.update((prevState) => ({
      ...prevState,
      needRequest: true,
      formType: undefined,
      formPayload: undefined,
      params: {},
    }));
    setSelectedRowKeys([]);
    actionRef.current?.reload();
    setSelectRecord([]);
    setFilter({});
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  // function onCascaderChange(value: { channel: string, token: string }) {
  //   setTokenData({ channel: value.channel, token: value.token });
  //   pageTokenOp.update({ channel: value.channel, token:value.token });
  // }
  const settings = getStorage('systemSettings');
  const hiddenTxnBalance = settings.depositMode !== 5 ? true : false;
  return (
    <>
      <PageHeader
        title={t('collect.collect_multiple_title')}
        // subTitle={t('collect.collect_multiple_subtitle')}
        extra={<></>}
      >
        {/*<Text>{t('payment.channel') + '/' + t('payment.token')}</Text>*/}
        {/*<br />*/}
        {/*<ChannelTokenCascader onChange={onCascaderChange} option={{ channel: pageTokenOp.get().channel, token: pageTokenOp.get().token }} />*/}

        {/*<Select options={ChannelSelect} />*/}
      </PageHeader>
      <CoreProTable<UserWallet>
        headerTitle={t('collect.pick_wanna_collected_wallet')}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          console.log('updateTableState', state);
          pageOp.update((v) => {
            const t: PageOp = {
              ...v,
              ...(state as any),
            };
            return t;
          });
        }}
        actionRef={actionRef as any}
        toolBarRender={() => [
          <Button key={1} type="primary" onClick={() => collectWalletFlow()}>
            {t('collect.collect_multiple_title')}
          </Button>,
        ]}
        rowKey={(record) => `${record.userId}-${record.walletAddress}-${record.token}`}
        rowSelection={{
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: UserWallet[],
          ) => {
            setSelectRecord(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
          },
          selectedRowKeys,
        }}
        search={{ layout: 'vertical' }}
        onReset={() => onReset()}
        requestData={(params: any) => {
          pageOp.update((v) => {
            v.params = {
              ...params,
              minBalance: filter.minBalance,
            };
            return v;
          });
          return UserWalletProvider.mchGetUserWalletList({
            page: params.current,
            limit: params.pageSize,
            collectStatus: CollectType.CollectWalletStatus.UnCollect,
            availableBalance: filter.minBalance,
            channel: params.channel,
            token: params.token ?? Token.USDT,
          }).then((res) => {
            return PaginationTransformer.responseTransfer<UserWallet>(res);
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.merchant_user_id'),
            dataIndex: 'userId',
          },
          {
            hideInSearch: true,
            align: 'center',
            title: t('payment.channel'),
            dataIndex: 'channel',
            valueType: 'select',
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70,
          },
          {
            hideInSearch: false,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            valueType: 'select',
            valueEnum: CollectToken,
            onFilter: true,
            initialValue: Token.USDT,
            width: 70,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.wallet_address'),
            dataIndex: 'walletAddress',
            render: (n, row) => (
              <HexLabel hex={row.walletAddress} size={'small'} />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: hiddenTxnBalance,
            width: 100,
            align: 'center',
            title: t('payment.txnBalance'),
            dataIndex: 'txnBalance',
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.availableBalance'),
            dataIndex: 'availableBalance',
          },
          {
            hideInSearch: false,
            hideInTable: true,
            title: t('collect.minBalance'),
            dataIndex: 'minBalance',
            renderFormItem: (_, row) => (
              <Input
                type={'number'}
                step={100}
                value={filter.minBalance}
                onChange={(event) => {
                  setFilter((v) => ({
                    ...v,
                    minBalance: Number(event.target.value),
                  }));
                }}
              />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('collect.user_wallet_collect_status'),
            dataIndex: 'collectStatus',
            valueEnum: userWalletCollectStatusTranslateEnum,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('collect.user_wallet_collect_count'),
            dataIndex: 'collectCount',
          },
          // {
          //   hideInSearch: true,
          //   hideInTable: true,
          //   width: 100,
          //   align: 'center',
          //   title: '建立時間',
          //   dataIndex: 'balanceUpdatedAt',
          //   renderFormItem: TableColumnRangePicker,
          //   render: (text: any, row: any) =>
          //     new Date(row.balanceUpdatedAt).toLocaleString(),
          // },
        ]}
      />
    </>
  );
}

export const CollectMultipleWalletPageInfo: IPage = Page.createInstance({
  id: 20365,
  uri: '',
  component: CollectMultipleWallet,
  scopes: [OPERATOR_COLLOECT],
  onClose: () => {},
});
