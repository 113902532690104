// eslint-ignore-next-line

import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { DepositWallet } from '../../domain/usdt-providers/walletProvider';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import { Button, Modal, PageHeader, Space } from 'antd';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { UserWalletProvider } from '../../domain/usdt-providers/userWalletProvider';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import { useTranslation } from 'react-i18next';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { ADJUST_PAYMENT } from '../configs/scopes';
import _module from '../configs/module';
import ChangeAccountForm, { ChangeAccountProps, FormCloseEvent } from '../components/ChangeAccountForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import * as profitForm from '../components/ProfitWithdrawFrom';
import { useUserWalletCollectStatusTranslate } from '../../domain/order-status/orderStatusTranslateHook';
import { OPERATOR_COLLOECT } from '../../orders/configs/scopes';
import { CollectTaskProvider } from '../../domain/usdt-providers/collectTaskProvider';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';

interface PageOp extends CoreProTableState<DepositWallet> {
}

const defaultChangeAccount: ChangeAccountProps = {
  availableBalance: 0,
  txnBalance: 0,
  channel: "",
  token: "",
  systemId: "",
  visible: false,
  walletAddress: "",
  walletType: "User"
};

// eslint-disable-next-line
export default (props: { title: string; subTitle: string }) => {
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const userWalletCollectStatusTranslateEnum = useUserWalletCollectStatusTranslate();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>("PAGE_REMEMBER", {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1
    },
    needRequest: true
  });
  const [changeAccount, setChangeAccount] = useState<ChangeAccountProps>(
    defaultChangeAccount
  );
  const loggger = useOperatorLogger();
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  
  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === FormCloseEvent.symbol))
      .subscribe(() => {
        setChangeAccount(defaultChangeAccount);
      });
    EventProvider.subject
      .pipe(
        filter(
          (x) =>
            x.eventSymbol === FormCloseEvent.symbol ||
            x.eventSymbol === profitForm.FormClosedEvent.symbol
        )
      )
      .subscribe(() => {
        refresh();
      });
    // eslint-disable-next-line
  }, []);
  
  const refresh = () => {
    pageOp.update((v) => {
      v.needRequest = true;
      return v;
    });
    actionRef.current?.reload();
  };
  const doCollect = (row: DepositWallet) => {
    Modal.confirm({
      title: t("collect.collect_description.title"),
      content: t("collect.collect_description.content"),
      okText: t("common.confirm"),
      cancelText: t("common.cancel"),
      centered: true,
      onOk: () => {
        return CollectTaskProvider.adminCreateCollectTask({
          channel: row.channel,
          token: row.token,
          srcAddress: row.walletAddress,
          systemId: row.systemId,
          userId: user.userId
        })
          .then((res) => {
            const rs = res;
            Modal.success({
              title: t("collect.success"),
              content: (
                <>
                  <br />
                  <div key={1}>
                    <strong>{t("payment.wallet_address")}：</strong>
                    {rs.data.srcAddress}
                  </div>
                  <div key={2}>
                    <strong>{t("collect.task_id")}：</strong>
                    {rs.data.taskId}
                  </div>
                </>
              ),
              okText: t("common.confirm")
            });
          })
          .finally(() => {
            pageOp.update((v) => ({ ...v, needRequest: true }));
            actionRef.current?.reload();
          });
      }
    });
  };
  return (
    <>
      <PageHeader title={props.title} subTitle={props.subTitle} extra={<></>} />
      <CoreProTable
        bordered
        rowKey="walletAddress"
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        scroll={{ x: "fixed" }}
        tableState={pageOp.get()}
        
        requestData={(params) =>
          UserWalletProvider.getDepositWalletPagination(params).then((rs) => {
            loggger.log({
              action: "查詢",
              payload: { req: params },
              systemId: params.systemId
            });
            return rs;
          })
        }
        
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            fixed: "left",
            hideInSearch: false,
            width: 80,
            align: "center",
            title: "商戶名稱",
            dataIndex: "systemId",
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            )
          },
          {
            hideInSearch: false,
            width: 100,
            ellipsis: true,
            align: "center",
            title: "使用者代號",
            dataIndex: "userId"
          },
          {
            align: "center",
            title: t("payment.channel"),
            dataIndex: "channel",
            valueType: "select",
            valueEnum: ChannelValueEnum,
            onFilter: true,
            width: 70
          },
          {
            align: "center",
            title: t("payment.token"),
            dataIndex: "token",
            valueType: "select",
            valueEnum: Token,
            onFilter: true,
            width: 70
          },
          {
            hideInSearch: false,
            width: 100,
            align: "center",
            title: "錢包地址",
            ellipsis: true,
            dataIndex: "walletAddress"
          },
          {
            hideInSearch: true,
            width: 80,
            align: "right",
            title: "餘額",
            dataIndex: "balance"
          },
          {
            hideInSearch: true,
            width: 100,
            align: "center",
            title: "更新時間",
            dataIndex: "balanceUpdatedAt",
            render: (text: any, row: any) =>
              transfer2LocalTime(new Date(row.balanceUpdatedAt))
          },
          {
            hideInSearch: true,
            width: 80,
            align: "right",
            title: "帳變餘額",
            dataIndex: "txnBalance"
          },
          {
            hideInSearch: true,
            width: 80,
            align: "right",
            title: "可用餘額",
            dataIndex: "availableBalance"
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: "center",
            title: t("collect.user_wallet_collect_status"),
            dataIndex: "collectStatus",
            valueEnum: userWalletCollectStatusTranslateEnum
          },
          {
            title: "操作",
            key: "actions",
            valueType: "option",
            fixed: "right",
            align: "center",
            render: (dom, row) => (
              <Space>
                <Button
                  key={2}
                  type="primary"
                  size={"small"}
                  disabled={parseFloat(row.txnBalance as any) === 0 || !_module.can([ADJUST_PAYMENT])}
                  onClick={(x) =>
                    setChangeAccount({
                      systemId: row.systemId,
                      visible: true,
                      walletAddress: row.walletAddress,
                      channel: row.channel,
                      token: row.token,
                      availableBalance: parseFloat(
                        row.availableBalance
                      ),
                      txnBalance: parseFloat(row.txnBalance),
                      walletType: "User"
                    })
                  }
                >
                  調帳
                </Button>
                {row.collectStatus === "UnCollect" && (<Button
                  key={3}
                  size={"small"}
                  disabled={!_module.can([OPERATOR_COLLOECT])}
                  type="primary"
                  onClick={() => doCollect(row)}
                >
                  {t("collect.collect")}
                </Button>)}
              </Space>
            )
          }
        ]}
      />
      <ChangeAccountForm {...changeAccount} />
    </>
  );
};
