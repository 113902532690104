export const READ_MERCHANT_USERS = 'READ_MERCHANT_USERS';
export const WRITE_MERCHANT_USERS = 'WRITE_MERCHANT_USERS';
export const DELETE_MERCHANT_USERS = 'DELETE_MERCHANT_USERS';
export const READ_MERCHANT_GROUPS = 'READ_MERCHANT_GROUPS';
export const WRITE_MERCHANT_GROUPS = 'WRITE_MERCHANT_GROUPS';
export const DELETE_MERCHANT_GROUPS = 'DELETE_MERCHANT_GROUPS';
export const READ_MERCHANT_ROLES = 'READ_MERCHANT_ROLES';
export const WRITE_MERCHANT_ROLES = 'WRITE_MERCHANT_ROLES';
export const DELETE_MERCHANT_ROLES = 'DELETE_MERCHANT_ROLES';
export const READ_SYSTEM = 'READ_SYSTEM';
export const WRITE_SYSTEM = 'WRITE_SYSTEM';
