import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri } from '../../core/configs/services';
import { PaginationQuery } from '../../core/utils/paginationTransformer';
import _ from 'lodash';

export interface RefundDailyType {
  agentSystemId: string;
  systemId: string;
  reportDate: Date;
  txnType: string;
  status: string;
  orderCount: number;
  orderAmount: number;
  refundAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface RefundOrderType {
  agentSystemId: string;
  orderId: string;
  systemId: string;
  secondAgentSystemId: string;
  txnType: string;
  orderNo: string;
  orderAmount: number;
  actualAmount: number;
  status: string;
  agentFeeRate: number;
  secondAgentFeeRate: number;
  refundRate: number;
  refundAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface OrderQueryProps extends PaginationQuery {
  status?: string[] | string;
  systemId?: string;
  orderNo?: string;
  start?: string;
  end?: string;
}

export interface DailyQueryProps {
  status?: string[] | string;
  systemId?: string;
  start?: string;
  end?: string;
}

export class AgentReportProvider {
  static async getDepositOrder(param: OrderQueryProps) {
    const status = this.parseDepositStatus(param);
    const res = await adminRequest.get<AdminListResponse<RefundOrderType>>(
      ReportUri('mch/agent-report/deposit-order'),
      { params: { ...param, status } },
    );
    return res.data;
  }

  static async getPayoutOrder(param: OrderQueryProps) {
    const res = await adminRequest.get<AdminListResponse<RefundOrderType>>(
      ReportUri('mch/agent-report/payout-order'),
      { params: param },
    );
    return res.data;
  }

  static async getDepositDaily(param: DailyQueryProps & PaginationQuery) {
    const status = this.parseDepositStatus(param);
    const res = await adminRequest.get<AdminListResponse<RefundDailyType>>(
      ReportUri('mch/agent-report/deposit-stat'),
      { params: { ...param, status } },
    );
    return res.data;
  }

  static async getDepositDailyExcel(param: DailyQueryProps) {
    const status = this.parseDepositStatus(param);
    const res = await adminRequest.get<AdminListResponse<RefundDailyType>>(
      ReportUri('mch/agent-report/deposit-stat/xlsx'),
      { params: { ...param, status }, responseType: 'blob' },
    );
    return res.data;
  }

  static async getPayoutDaily(param: DailyQueryProps & PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<RefundDailyType>>(
      ReportUri('mch/agent-report/payout-stat'),
      { params: param },
    );
    return res.data;
  }

  static async getPayoutDailyExcel(param: DailyQueryProps) {
    const res = await adminRequest.get<AdminListResponse<RefundDailyType>>(
      ReportUri('mch/agent-report/payout-stat/xlsx'),
      { params: param, responseType: 'blob' },
    );
    return res.data;
  }

  static parseDepositStatus(param: OrderQueryProps | DailyQueryProps) {
    if (!param.status) {
      return undefined;
    }
    let statusArr: string[] = [];
    if (!!param.status && _.isString(param.status)) {
      if (param.status === 'MappingSuccess' || param.status === 'Success') {
        statusArr = ['MappingSuccess', 'Success'];
      } else {
        statusArr = [param.status];
      }
    }
    return statusArr;
  }
}
