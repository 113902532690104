import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';
import * as _ from 'lodash';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { DepositWallet } from './walletProvider';

interface UserWalletSummary {
  systemId: string;
  walletNumber: string;
  balance: string;
  txnBalance: string;
  availableBalance: string;
}

export interface UserWallet {
  walletAddress: string;
  systemId: string;
  userId: string;
  available: boolean;
  sign: string;
  balance: number;
  balanceUpdatedAt: string;
  txnBalance: number;
  availableBalance: number;
  collectCount: number;
  collectStatus: string;
  createdAt: string;
  channel: string;
  token:string;
}

export class UserWalletProvider {
  static async mchGetUserWalletSummery(token?:string) {
    const res = await adminRequest.get<AdminResponse<UserWalletSummary>>(
      UsdtUri('merchant/payment/deposit-wallet/merchant-summary'),{
        params: {token}
      }
    );
    return res.data;
  }

  static async mchGetUserWalletList(params: {
    page: number;
    limit: number;
    uid?: string;
    walletAddress?: string;
    availableBalance?: number;
    collectStatus?: string;
    channel?: string;
    token?:string;
  }) {
    const res = await adminRequest.get<AdminListResponse<UserWallet>>(
      UsdtUri('merchant/payment/deposit-wallet'),
      { params:{...params} },
    );
    return res.data;
  }

  static async getDepositWalletPagination(params: any) {
    console.log(params);
    const rs = await adminRequest.get<AdminListResponse<DepositWallet>>(
      UsdtUri(`/admin/payment/deposit-wallet`),
      {
        params: {
          ..._.omit(
            PaginationTransformer.paramTransfer(params),
            'merchantUserId',
          ),
          uid: params.userId,
        },
      },
    );
    return PaginationTransformer.responseTransfer(rs.data);
  }

  static async mchIssueWallet(data: { uid: string, channel: string, token: string }) {
    const res = await adminRequest.post<
      AdminResponse<{ walletAddress: string }>
    >(UsdtUri('/merchant/payment/deposit-wallet/issue'), data);
    return res.data;
  }

  static async adminFixUserDepositOrder(
    systemId: string,
    walletAddress: string,
  ) {
    const res = await adminRequest.post<AdminResponse<any>>(
      UsdtUri('/admin/payment/deposit-order/fix'),
      {
        systemId,
        walletAddress,
      },
    );
    return res.data;
  }

  static async disableWallet(data: { walletAddress: string,channel:string,token:string }) {
    const res = await adminRequest.patch<AdminResponse<{}>>(
      UsdtUri(`/merchant/payment/deposit-wallet/disable/${data.walletAddress}`),
      {
        channel:data.channel,
        token:data.token
      }
    );
    return res.data;
  }

  static async enableWallet(data: { walletAddress: string,channel:string,token:string }) {
    const res = await adminRequest.patch<AdminResponse<{}>>(
      UsdtUri(`/merchant/payment/deposit-wallet/enable/${data.walletAddress}`),
      {
        channel:data.channel,
        token:data.token
      }
    );
    return res.data;
  }
}
