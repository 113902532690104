import { omit } from 'lodash';
import { RequestData } from '@ant-design/pro-table';
import { AdminListResponse } from '../../domain/models/adminListResponse';
import { expand, reduce } from "rxjs/operators";
import { fromPromise } from "rxjs/internal-compatibility";
import { empty } from "rxjs";

export interface PaginationQuery {
  page: number;
  limit: number;
}

type GetPaginateFunc<TItem, TQuery extends PaginationQuery> = (
  param: TQuery,
) => Promise<AdminListResponse<TItem>>;

export default class PaginationTransformer {
  static paramTransfer(params: any): PaginationQuery {
    return {
      ...omit(params, ['current', 'pageSize']),
      page: params.current,
      limit: params.pageSize,
    };
  }

  static responseTransfer<T = any>(rs: AdminListResponse<T>): RequestData<T> {
    return {
      success: true,
      data: rs.data.items,
      total: rs.data.meta.totalItems,
    };
  }
  
  static async getAllFromPagination<TItem, TQuery>(
    func: GetPaginateFunc<TItem, TQuery & PaginationQuery>,
    query: TQuery,
  ) {
    const take = 50;
    const nextPage = (curPage: number) =>
      fromPromise(func({ limit: take, page: curPage, ...query }));
    const result = nextPage(1).pipe(
      expand((value) =>
        value.data.meta.totalPages > value.data.meta.currentPage
          ? nextPage(+value.data.meta.currentPage + 1)
          : empty(),
      ),
      reduce<AdminListResponse<TItem>, TItem[]>((collect, res) => {
        return collect.concat(res.data.items);
      }, []),
    );
    return await result.toPromise();
  }
}
