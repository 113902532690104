import { Button, Form, Input, message, Modal, PageHeader, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { usePageState } from '../../core/hooks/usePageState';
import { ProfitProvider, ProfitWhiteList } from '../../domain/usdt-providers/profitProvider';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { ChannelSelect, ChannelValueEnum } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';
import { WRITE_PROFIT_WHITELIST } from '../configs/scopes';
import _module from '../configs/module';

type Props = {};

interface PageOp extends CoreProTableState<ProfitWhiteList> {}

export default function ProfitWithdrawWhiteListPage(props: Props) {
  const { t } = useTranslation();
  const [whiteListFormVisible, setWhiteListFormVisible] = useState(false);
  const whiteListForm = React.createRef<FormInstance>();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const refreshDataCommand = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
  };

  const deleteCommand = (item: ProfitWhiteList) => {
    Modal.confirm({
      content: `${t('common.delete_confirm')} ${item.walletAddress} ?`,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        ProfitProvider.deleteWhitList({
          walletAddress: item.walletAddress,
          opId: user.userId,
        })
          .then(() => {
            message.success({
              content: `${t('common.delete_success')}`,
            });
          })
          .finally(() => {
            refreshDataCommand();
          });
      },
    });
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);

  return (
    <>
      <PageHeader title={'利潤下發白名單'} subTitle={''} extra={<></>} />
      <CoreProTable<ProfitWhiteList>
        bordered={true}
        formRef={searchFormRef}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        toolBarRender={() => [
          <Button
            key={1}
            type="primary"
            disabled={!_module.can([WRITE_PROFIT_WHITELIST])}
            onClick={(event) => {
              event.stopPropagation();
              setWhiteListFormVisible(true);
            }}
          >
            {i18next.t('common.add')}
          </Button>,
        ]}
        requestData={async (params) => {
          const res = await ProfitProvider.getWhiteListPage(
            PaginationTransformer.paramTransfer(params),
          );
          return PaginationTransformer.responseTransfer(res);
        }}
        columns={[
          {
            fixed: 'left',
            align: 'center',
            width: 100,
            title: t('payment.channel'),
            hideInSearch: false,
            valueEnum: ChannelValueEnum,
            dataIndex: 'channel',
          },
          // {
          //   fixed: 'left',
          //   align: 'center',
          //   width: 100,
          //   title: t('payment.token'),
          //   hideInSearch: false,
          //   valueEnum: Token,
          //   dataIndex: 'token',
          // },
          {
            align: 'center',
            width: 100,
            title: t('payment.wallet_address'),
            hideInSearch: true,
            dataIndex: 'walletAddress',
            render: (node, row) => <HexLabel hex={row.walletAddress} size={"small"}/>,
          },
          {
            width: 100,
            align: 'center',
            title: t('common.operate'),
            hideInSearch: true,
            dataIndex: 'walletAddress',
            key: '_action',
            render: (text, row) => [
              <Button
                key={2}
                size="small"
                disabled={!_module.can([WRITE_PROFIT_WHITELIST])}
                danger={_module.can([WRITE_PROFIT_WHITELIST])}
                onClick={() => deleteCommand(row)}
              >
                {i18next.t('common.delete')}
              </Button>,
            ],
          },
        ]}
      />

      <Modal
        destroyOnClose
        title={'新增利潤下發白名單'}
        visible={whiteListFormVisible}
        okText={i18next.t('common.add_confirm')}
        cancelText={i18next.t('common.cancel')}
        onOk={() => {
          whiteListForm.current?.validateFields().then((values) => {
            ProfitProvider.addWhitList({
              walletAddress: values.walletAddress.trim(),
              channel: values.channel,
              opId: user.userId,
            }).then(async (res) => {
              Modal.success({
                content: `${res.data.walletAddress} ${t('common.add_success')}`,
                okText: t('common.confirm'),
              });
              refreshDataCommand();
              setWhiteListFormVisible(false);
            });
          });
        }}
        onCancel={() => setWhiteListFormVisible(false)}
      >
        <Form ref={whiteListForm}>
          <Form.Item
            name="channel"
            label={t('payment.channel')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select options={ChannelSelect} />
          </Form.Item>
          <Form.Item
            name="walletAddress"
            label={'白名單錢包地址'}
            rules={[
              {
                required: true,
                message: t(
                  'transferback.message.plz_enter_transferback_white_list',
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
