import { Menu } from '../../modules/core/configs/menus';
import MenuStoreAction from './action';

export type MenuCurrent = {
  now: any[];
  list: { [item: string]: any };
};

export type MenuStore = {
  leftMenuCollapsed: boolean;
  treeMenu: Menu[];
  current: MenuCurrent;
};

const defaultState = {
  leftMenuCollapsed: false,
  treeMenu: [] as Menu[],
  current: { now: [] as any[], list: {} },
};

const menu = (
  state = defaultState,
  action: { type: string; payload: Menu[] | boolean | MenuCurrent },
) => {
  if (action.type === MenuStoreAction.updateKey) {
    state.treeMenu = action.payload as Menu[];
    return state;
  }
  if (action.type === MenuStoreAction.leftMenuCollapseKey) {
    state.leftMenuCollapsed = action.payload as boolean;
  }
  if (action.type === MenuStoreAction.menuCurrent) {
    const payload = action.payload as MenuCurrent;
    state.current.now = payload.now;
    state.current.list = payload.list;
    // state.current = payload;
  }
  return state;
};

export default menu;
