import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import { Checkbox, PageHeader } from 'antd';
import { transfer2LocalDate, transfer2LocalTime } from '../../core/utils/timeFormat';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useTranslation } from 'react-i18next';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import AdvanceFundCard, { advanceNumbersType } from '../components/AdvanceFundCard';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import { AdvanceFund, AdvanceFundDetail, AdvanceFundProvider } from '../../domain/report-provider/advanceFundProvider';

interface PageOp extends CoreProTableState<AdvanceFund> {
}

interface PageOpDetail extends CoreProTableState<AdvanceFundDetail> {
}

export default function() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const searchFormRefDetail = useRef<FormInstance>();
  const { t } = useTranslation();
  const [systemId, setSystemId] = useState<string>();
  const [fund, setFund] = useState<advanceNumbersType>({
    lpwBalanceAmount:0,
    mchAvailableAmount:0,
    lpwAvailableAmount:0
  });

  const checkedState = usePageState<{
    checked: boolean;
  }>('CheckedState', {
    checked: true,
  });

  const refreshFund = () => {
    AdvanceFundProvider.getCurrentFund()
      .then((rs) => setFund({
        lpwBalanceAmount:rs.data.lpwBalanceAmount,
        lpwAvailableAmount:rs.data.lpwAvailableAmount,
        mchAvailableAmount:rs.data.mchAvailableAmount,
      }))
      .catch((e) => {
        console.error('refreshFund', e);
      });
  }

  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const pageOpDetail = usePageState<PageOpDetail>('PAGE_REMEMBER_DETAIL', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('report.settlementDate'),
    pageOp,
    defaultDateRange: 0,
    defaultDate: 1,
    state: dateRangeState,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
    searchFormRefDetail.current?.setFieldsValue(pageOpDetail.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    refreshFund();
  }, []);

  return (
    <>
      <PageHeader title={t('report.advanceFundPage')} subTitle={'此報表從 2022/04/26 開始統計，資料內容正確性驗證中'}>
        <AdvanceFundCard lpwBalanceAmount={fund.lpwBalanceAmount} lpwAvailableAmount={fund.lpwAvailableAmount} mchAvailableAmount={fund.mchAvailableAmount} />
      </PageHeader>
      <CoreProTable<AdvanceFund>
        headerTitle="當期結算統計"
        bordered
        rowKey={(record) => `${record.reportDate}-${record.systemId}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        formRef={searchFormRef}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        onReset={() => {
          dateRangeColumn.reset();
          setSystemId(undefined);
          pageOp.update((v) => {
            v.params = {};
            v.needRequest = false;
            return v;
          });
          pageOpDetail.update((v) => {
            v.params = {};
            v.needRequest = false;
            return v;
          });
        }}
        requestData={async (params: any) => {
          setSystemId(params.systemId);
          const res = await AdvanceFundProvider.getList({
            ...PaginationTransformer.paramTransfer({
              ...params,
              start: dateRangeColumn.getParamAndUpdatePage().start,
              end: dateRangeColumn.getParamAndUpdatePage().end,
              hideLessOne: checkedState.get().checked,
            }),
          }).catch((e) => {
            console.error(e);
            throw e;
          });

          return PaginationTransformer.responseTransfer(res);
        }}
        onSubmit={() => {
          pageOp.get().params.systemId = systemId;
          pageOpDetail.get().params.systemId = systemId;

          pageOp.update((v) => {
            v.needRequest = true;
            v.params = { systemId };
            return v;
          });
          pageOpDetail.update((v) => {
            v.needRequest = true;
            v.params = { systemId };
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('report.settlementDate'),
            dataIndex: 'reportDate',
            render: (text, row) => transfer2LocalDate(text as any),
          },
          {
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) => {
                  form.setFieldsValue({ [_.dataIndex as string]: value });
                  setSystemId(value);
                }}
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          dateRangeColumn.column,
          // {
          //   hideInSearch: true,
          //   hideInTable: false,
          //   width: 100,
          //   dataIndex: 'token',
          //   title: t('payment.token'),
          // },
          {
            hideInSearch: false,
            hideInTable: true,
            width: 80,
            ellipsis: true,
            dataIndex: 'hideLessOne',
            title: '隱藏所有數值欄位皆小於1的資料',
            renderFormItem: (value) => (
              <Checkbox
                onChange={(value) => {
                  checkedState.update({
                    checked: value.target.checked,
                  });
                  return;
                }}
                checked={checkedState.get().checked}
              />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'lpwAccTotalFee',
            tooltip: '平台收付業務賺取之手續費',
            title: t('report.lpwAccTotalFee'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'lpwWithdraw',
            tooltip: '包括利潤下發與更換商戶錢包(棄用)',
            title: t('report.lpwWithdraw'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'lpwUnWithdraw',
            tooltip: '「平台累計利潤」-「平台已提」',
            title: t('report.lpwUnWithdraw'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'mchTxnBalance',
            tooltip: '當日結束之時間結算商戶帳變餘額',
            title: t('report.mchTxnBalance'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'mchAvailableBalance',
            tooltip: '當日結束之時間結算商戶可用餘額',
            title: t('report.mchAvailableBalance'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'lpwAccAvailableBalance',
            tooltip: '「商戶帳變餘額」-「商戶可用餘額」',
            title: t('report.lpwAccAvailableBalance'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'advanceFund',
            tooltip: '即下表當期墊資紀錄加總 (此為實際墊資金額)',
            title: t('report.advanceFund'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'accAdvanceFund',
            tooltip: '「平台可用餘額」-「平台未提」 (此為推估墊資金額)',
            title: t('report.accAdvanceFund'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'createdAt',
            title: t('common.createdAt'),
            render: (_: any, row: AdvanceFund) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
        ]}
      />
      <CoreProTable<AdvanceFundDetail>
        headerTitle="當期墊資紀錄"
        bordered
        rowKey={(record) => `${record.reportDate}-${record.systemId}`}
        search={false}
        actionRef={actionRef as any}
        tableState={pageOpDetail.get()}
        scroll={{ x: 'fixed' }}
        formRef={searchFormRefDetail}
        updateTableState={(state: any) => {
          pageOpDetail.update((v) => ({ ...v, ...state }));
        }}
        requestData={async (params: any) => {
          const res = await AdvanceFundProvider.getDetailList({
            ...PaginationTransformer.paramTransfer({
              systemId,
              start: dateRangeColumn.getParamAndUpdatePage().start,
              end: dateRangeColumn.getParamAndUpdatePage().end,
            }),
          }).catch((e) => {
            console.error(e);
            throw e;
          });

          return PaginationTransformer.responseTransfer(res);
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('report.settlementDate'),
            dataIndex: 'reportDate',
            render: (text, row) => transfer2LocalDate(text as any),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'channel',
            title: t('payment.channel'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'token',
            title: t('payment.token'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'advanceFund',
            title: t('report.advanceFundAmount'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'walletAddress',
            title: t('report.advanceAddress'),
          },
        ]}
      />
    </>
  );
}
