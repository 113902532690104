import React, { useEffect, useRef } from 'react';
import { Heatmap } from '@antv/g2plot';
import { transfer2LocalDate } from '../../../core/utils/timeFormat';
import BigNumber from 'bignumber.js';
import { RevenueItem } from '../../../domain/report-provider/chartProvider';
import _ from 'lodash';
import moment from 'moment';

export type RevenueChartProps = {
  dataSet: Array<RevenueItem>;
};

const TotalFeeWeeklyChart = (props: RevenueChartProps) => {
  const ref = useRef<any>();
  const chartRef = useRef<Heatmap>();

  const changeData = (data: Array<RevenueItem>) => {
    chartRef.current?.changeData(calculateChartData(data));
  };

  useEffect(() => {
    const rawData = props.dataSet;
    const chartData = calculateChartData(rawData);
    
    const config = {
      data: chartData,
      height: 400,
      autoFit: false,
      xField: 'week',
      yField: 'day',
      colorField: 'summeryTotalFee',
      reflect: 'y',
      shape: 'boundary-polygon',
      meta: {
        day: {
          type: 'cat',
          values: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        },
        week: {
          type: 'cat',
        },
        summeryTotalFee: {
          sync: true,
        },
        date: {
          type: 'cat',
        },
      },
      yAxis: {
        grid: null,
      },
      tooltip: {
        title: 'date',
        showMarkers: false,
      },
      interactions: [{ type: 'element-active' }],
      xAxis: {
        position: 'top',
        tickLine: null,
        line: null,
        label: {
          offset: 12,
          style: {
            fontSize: 12,
            fill: '#666',
            textBaseline: 'top',
          },
          // formatter: (val: number) => {
          //   const specWeek2Date = moment().startOf('years').add(val - 1 , 'week').format('YYYY-MM-DD'); // 該週數日期
          //   const startOf1stByMonth = moment(specWeek2Date).startOf('months').format('YYYY-MM-DD'); // 該週數日期第一週初始日期

          //   const firstWeekDay = moment(startOf1stByMonth, 'YYYY-MM-DD').weekday();//moment(startOf1stByMonth)..format('YYYY-MM-DD'); // 該週數日期第一週結束日期
          //   const firstEndWeekDays = 7 - firstWeekDay;
          //   // const 
          //   const startOfFinalByMonth = moment(specWeek2Date).startOf('months').add(firstEndWeekDays, 'days').format('YYYY-MM-DD'); // 該週數日期第一週初始日期
          //   if(moment(specWeek2Date, 'YYYY-MM-DD').isAfter(startOf1stByMonth) &&
          //   moment(specWeek2Date, 'YYYY-MM-DD').isBefore(startOfFinalByMonth)
          //   ) {
          //     const displayMonth =  moment(specWeek2Date, 'YYYY-MM-DD').month() + 1;
          //     switch(displayMonth) {
          //       case 1: return 'Jan.';
          //       case 2: return 'Feb.';
          //       case 3: return 'Mar.';
          //       case 4: return 'Apr.';
          //       case 5: return 'May.';
          //       case 6: return 'June.';
          //       case 7: return 'July.';
          //       case 8: return 'Aug.';
          //       case 9: return 'Sep.';
          //       case 10: return 'Oct.';
          //       case 11: return 'Nov.';
          //       case 12: return 'Dec.';
          //     }
          //   }
          //   return ''
          // },
          formatter: (yearWeeks: number) => {
            const specWeek2Date = moment().startOf('years').add(yearWeeks - 1 , 'week').format('YYYY-MM-DD'); // 該週數日期
            const displayMonth =  moment(specWeek2Date, 'YYYY-MM-DD').month() + 1;
            switch(displayMonth) {
              case 1: return `Jan. / ${yearWeeks} weeks`;
              case 2: return `Feb. / ${yearWeeks} weeks`;
              case 3: return `Mar. / ${yearWeeks} weeks`;
              case 4: return `Apr. / ${yearWeeks} weeks`;
              case 5: return `May. / ${yearWeeks} weeks`;
              case 6: return `June. / ${yearWeeks} weeks`;
              case 7: return `July. / ${yearWeeks} weeks`;
              case 8: return `Aug. / ${yearWeeks} weeks`;
              case 9: return `Sep. / ${yearWeeks} weeks`;
              case 10: return `Oct. / ${yearWeeks} weeks`;
              case 11: return `Nov. / ${yearWeeks} weeks`;
              case 12: return `Dec. / ${yearWeeks} weeks`;
            }
            return ''
          },
        },
      },
    };
    // config.data = props.dataSet
    const heatmap = new Heatmap(ref.current, config as any);
    chartRef.current = heatmap;
    heatmap.render();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeData(props.dataSet);
  }, [props.dataSet]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};

export default TotalFeeWeeklyChart;

function calculateChartData(rawData: RevenueItem[]) {
  const sumDictionary: any = {};
  for (const item of rawData) {
    const date = transfer2LocalDate(item.reportDate);
    const profitAmount = item.profitAmount;
    // const totalFee = item.totalFee;
    const totalFee = new BigNumber(item.totalFee)
      .plus(new BigNumber(item.payforUsdtFee))
      .toNumber();
    if (!sumDictionary[date.toString()]) {
      sumDictionary[date.toString()] = {
        summeryProfit: 0,
        summeryTotalFee: 0,
      };
      sumDictionary[date.toString()].summeryProfit = new BigNumber(
        profitAmount,
      ).toNumber();
      sumDictionary[date.toString()].summeryTotalFee = new BigNumber(
        totalFee,
      ).toNumber();
    } else {
      sumDictionary[date.toString()].summeryProfit = new BigNumber(
        sumDictionary[date.toString()].summeryProfit,
      )
        .plus(profitAmount)
        .toNumber();
      sumDictionary[date.toString()].summeryTotalFee = new BigNumber(
        sumDictionary[date.toString()].summeryTotalFee,
      )
        .plus(totalFee)
        .toNumber();
    }
  }
  const result = [];
  for (const i in sumDictionary) {
    const summeryTotalFee = sumDictionary[i].summeryTotalFee;
    const weekNumber = moment(i, 'YYYY-MM-DD').weekday();
    const week = moment(i, 'YYYY-MM-DD').week();
    const month = moment(i, 'YYYY-MM-DD').month();
    result.push({
      date: i,
      timestamp: moment(i).valueOf(),
      summeryTotalFee,
      day: weekNumber,
      month: month,
      week: week
    })
  }
  // console.log(11111,result)
  return _.sortBy(result, (item) => item.timestamp).map(item => {
    return {
      ...item,
      timestamp: undefined
    }
  });
}
