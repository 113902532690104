import { Button, Form, Input, Select, Space, Spin, Switch, Typography } from 'antd';
import CoreDrawer from '../../core/components/CoreDrawer';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import { Permission } from '../types';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PermissionProvider } from '../../domain/admin-providers/permissionProvider';
import adminRequest from '../../../config/adminRequest';
import { AdminResponse } from '../../domain/models/adminResponse';
import { AdminUri } from '../../core/configs/services';

const { TextArea } = Input;
const { Text } = Typography;

export type ScopeFormProps = {
  record?: Permission;
  visible: boolean;
  isEdit: boolean;
};

export class CloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: CloseEvent.symbol,
      payload: undefined,
      type: CloseEvent.name,
    });
  }
}

export default function ScopeForm(props: ScopeFormProps) {
  const formRef = React.createRef<FormInstance>();
  const [formState, setFormState] = useState<{
    isEdit: boolean;
    permission?: Permission;
  }>({
    isEdit: props.isEdit,
    permission: undefined,
  });
  const [moduleList, setModuleList] = useState<Partial<Permission>[]>([]);
  const [moduleCreateMode, setModuleCreateMode] = useState(false);

  const close = () => {
    EventProvider.addEvents(new CloseEvent());
  };

  const save = (formRef: React.RefObject<FormInstance>) => {
    formRef.current?.validateFields().then(async (values: any) => {
      if (formState.isEdit) {
        // 編輯
        const payload: Partial<Permission> = {
          id: formState.permission?.id,
          moduleName: values.moduleName ?? values.moduleIdentity,
          moduleIdentity: values.moduleIdentity,
          scope: values.scope,
          scopeDescription: values.scopeDescription ?? '',
        };
        await PermissionProvider.update(payload);
        close();
      } else {
        // 新增
        const payload: { bulk: Partial<Permission>[] } = {
          bulk: values.scopes.map((scope: Partial<Permission>) => ({
            ...scope,
            scope: scope.scope?.trim(),
            scopeDescription: scope.scopeDescription?.trim(),
            moduleName: values.moduleName ?? values.moduleIdentity,
            moduleIdentity: values.moduleIdentity,
          })),
        };
        await PermissionProvider.add(payload);
        close();
      }
    });
  };

  useEffect(() => {
    if (moduleList.length === 0) {
      adminRequest
        .get<AdminResponse<Partial<Permission>[]>>(AdminUri(`admin/modules`), {
          params: {
            page: 1,
            limit: 999,
          },
        })
        .then((rs) => {
          setModuleList(rs.data.data);
          // NOTE: 快取10 分鐘
          setTimeout(() => {
            setModuleList([]);
          }, 600000);
        })
    }
    // eslint-disable-next-line
  }, [formState]);

  useEffect(() => {
    if (!formRef.current) {
      return;
    }
    formRef.current?.setFieldsValue({
      ...formState.permission,
    });
  }, [formState, formRef]);

  useEffect(() => {
    setFormState({ permission: props.record, isEdit: props.isEdit });
    if (!props.visible) {
      return;
    }
  }, [props]);

  return (
    <CoreDrawer
    width={640}
    title={!!formState.isEdit ? `編輯權限(id: ${formState.permission?.id})` : `新增權限`}
    visible={props.visible}
    onClose={() => close()}
    footerbuttons={[
      <Button key={1} type="primary" onClick={() => save(formRef)}>
        儲存
      </Button>,
    ]}
  >
      <Spin spinning={false}>
      <Form ref={formRef} layout="vertical" name="permission_form">
        <Form.Item
          required
          label={
            <Space>
              模組
              <Switch
                checked={moduleCreateMode}
                onChange={() => setModuleCreateMode((s) => !s)}
                checkedChildren="創建新的"
                unCheckedChildren="選擇舊有"
              />
            </Space>
          }
        >
          {moduleCreateMode ? (
            <Input.Group compact>
              <Form.Item
                noStyle
                name="moduleName"
                rules={[{ required: true, message: '請輸入模組名稱' }]}
              >
                <Input
                  prefix={<Text type="secondary">名稱</Text>}
                  style={{ width: '50%' }}
                  placeholder="創建新模組 名稱"
                />
              </Form.Item>
              <Form.Item
                noStyle
                normalize={(v) => v.toUpperCase()}
                name="moduleIdentity"
                rules={[{ required: true, message: '請輸入模組識別碼' }]}
              >
                <Input
                  prefix={
                    <>
                      <Text type="warning">識別碼</Text>
                      <Text type="danger">*</Text>
                    </>
                  }
                  style={{ width: '50%' }}
                  placeholder="創建新模組 識別碼"
                />
              </Form.Item>
            </Input.Group>
          ) : (
            <Form.Item
              name="moduleIdentity"
              rules={[{ required: true, message: '請選擇模組' }]}
            >
              <Select
                allowClear
                onChange={(_, o: any) =>
                  formRef.current?.setFieldsValue({
                    moduleIdentity: o?.value ?? '',
                    moduleName: o?.label ?? '',
                  })
                }
                placeholder="請選擇模組"
                options={moduleList.map((m) => ({
                  label: m.moduleName ?? '',
                  value: m.moduleIdentity ?? '',
                }))}
              />
            </Form.Item>
          )}
        </Form.Item>
        {!formState.isEdit ? (
          <>
            <Form.List name="scopes">
              {(fields, { add, remove }) => {
                if (props.visible && fields.length === 0) {
                  add();
                }
                return (
                  <div>
                    {fields.map((field) => (
                      <Space key={field.key} align="baseline">
                        <Form.Item
                          {...field}
                          name={[field.name, 'scope']}
                          fieldKey={[field.fieldKey, 'scope']}
                          rules={[
                            { required: true, message: '請輸入權限名稱' },
                          ]}
                        >
                          <Input
                            placeholder="權限名稱"
                            onChange={(e) => {
                              try {
                                const val = e.target.value;
                                if (/^(([^:]+):[^,]+,?)+$/.test(val)) {
                                  val
                                    .match(/([^:]+:[^,]+),?/g)
                                    ?.forEach((m: string) => {
                                      const [_, scope, scopeDescription] =
                                        /([^:]+):([^,]+)/.exec(m) ?? [];
                                      if (_) {
                                        add({ scope, scopeDescription });
                                      }
                                    });
                                  remove(field.name);
                                }
                              } catch (e) {}
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'scopeDescription']}
                          fieldKey={[field.fieldKey, 'scopeDescription']}
                        >
                          <Input placeholder="權限說明" />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> 新增權限
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </>
        ) : (
          <>
            <Form.Item
              name="scope"
              label="權限名稱"
              rules={[{ required: true, message: '請輸入權限名稱' }]}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item name="scopeDescription" label="權限說明">
              <TextArea allowClear />
            </Form.Item>
          </>
        )}
      </Form>
    </Spin>
  </CoreDrawer>
  );
}
