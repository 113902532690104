import { Dispatch } from 'react';
import { Menu } from '../../modules/core/configs/menus';
import { MenuCurrent } from './index';

export default class MenuStoreAction {
  static updateKey = 'MenuStore';
  static leftMenuCollapseKey = 'MenuStore:leftMenuCollapsed';
  static menuCurrent = 'MenuStore:CurrentMenu';
  static reset(dispatch: Dispatch<any>, menu: Menu[]) {
    dispatch({ type: MenuStoreAction.updateKey, payload: menu });
  }
  static leftMenuCollapse(dispatch: Dispatch<any>, collapsed: boolean) {
    dispatch({
      type: MenuStoreAction.leftMenuCollapseKey,
      payload: collapsed,
    });
  }
  static setCurrent(dispatch: Dispatch<any>, menu: MenuCurrent) {
    dispatch({
      type: MenuStoreAction.menuCurrent,
      payload: menu,
    });
  }
}
