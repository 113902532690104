import React, { useEffect, useRef, useState } from 'react';
import { Collapse, FormInstance, PageHeader, Table } from 'antd';
import { useTranslation } from 'react-i18next';
// import RevenueChart, { RevenueChartProps } from '../components/chart/RevenueChart';
import { usePageState } from '../../core/hooks/usePageState';
import { transfer2LocalDate } from '../../core/utils/timeFormat';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ProCoreActionType } from '@ant-design/pro-utils';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { roundPrecision } from '../../core/utils/precisionFormat';
import { state } from '@antv/g2plot/lib/adaptor/common';
import { NetworkFeeDepositExpendProvider } from '../../domain/report-provider/networkFeeDepositExpendProvider';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

// import NetworkFeeSummeryTable from '../components/NetworkFeeSummeryTable';

interface NetworkFeeDepositExpendItem {
  reportDate: Date;
  systemId: string;
  token: string;
  depositAtMch: number;
  depositAtUser: number;
  depositSum: number;
  expendAtMch: number;
  expendAtUser: number;
  expendSum: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface NetworkFeeDepositExpendSummery {
  token: string,
  depositSummery: number,
  expendSummery: number,
  lastNetworkFee: number,
  exchangeRate: number,
  usdValue: number,
}

interface PageOp extends CoreProTableState<NetworkFeeDepositExpendItem> {
}

// eslint-disable-next-line
export default function() {
  const { Panel } = Collapse;
  const [queryData, setQueryData] = useState<any>({});
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    defaultDateRange: 30,
    title: t('report.reportDate'),
    pageOp,
    state: dateRangeState,
  });
  const [summery, setSummery] = useState([{
    token: 'UnknownToken',
    depositSummery: 0,
    expendSummery: 0,
    lastNetworkFee: 0,
    exchangeRate: 0,
    usdValue: 0,
  }]);
  const [usdValueSum, setUsdValueSum] = useState(0);
  const searchState = usePageState<{
    queryStr: string;
  }>('SearchState', {
    queryStr: '',
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  useEffect(()=>{
    pageOp.update((v) => {
      const start = (v as any).start;
      const end = (v as any).end;
      const systemId = v.params.systemId;
      const token = v.params.token;
      const searchQueryStr = `${start}:${end}:${systemId}:${token}`;

      searchState.update({
        queryStr: searchQueryStr,
      });
      NetworkFeeDepositExpendProvider.getPageList({
        startDate: start,
        endDate: end,
        systemId: systemId,
        token: token
      })
      .then((rs) => {
        const data = (rs.data as any).pageSummery
        let usdValueSum = 0;
        for(const item of data) {
          const usdValue = item.usdValue;
          usdValueSum = new BigNumber(usdValueSum).plus(usdValue).toNumber();
        }
        setUsdValueSum(_.round(usdValueSum, 4));
        setSummery(data);
      })
      .catch((e) => {
        console.error(e);
        throw e;
      })
      return { ...v, ...state };
    });
  },[queryData])
  return (
    <>
      <PageHeader
        title='礦工費充值與消耗報表(Beta版) *'//{t('report.company_profit')}
        subTitle={`此報表從 2022/04/29 開始統計 (資料內容正確性驗證中)、
        2022/04/28 充值資料為初始化資料 (即帳上28號前礦工資金統計)`}
        extra={<></>}
      />
      <Collapse defaultActiveKey={['1']}>
        <Panel header={'查詢統計'} key={1}>
          <Table 
            dataSource={summery} 
            columns={[
              {
                title: '代幣',
                dataIndex: 'token',
                key: 'token',
                align: 'center'
              },
              {
                title: '總充值礦工費',
                dataIndex: 'depositSummery',
                key: 'depositSummery',
                align: 'center',
                render: (_: any, row: NetworkFeeDepositExpendSummery) =>
                  roundPrecision(row.depositSummery, 4),
              },
              {
                title: '總消耗礦工費',
                dataIndex: 'expendSummery',
                key: 'expendSummery',
                align: 'center',
                render: (_: any, row: NetworkFeeDepositExpendSummery) =>
                  roundPrecision(row.expendSummery, 4),
              },
              {
                title: '總剩餘礦工費',
                dataIndex: 'lastNetworkFee',
                key: 'lastNetworkFee',
                align: 'center',
                render: (_: any, row: NetworkFeeDepositExpendSummery) =>
                  roundPrecision(row.lastNetworkFee, 4),
              },
              {
                title: '即期匯率',
                dataIndex: 'exchangeRate',
                key: 'exchangeRate',
                align: 'center',
                render: (_: any, row: NetworkFeeDepositExpendSummery) =>
                  roundPrecision(row.exchangeRate, 4),
              },
              {
                title: 'USD價值',
                dataIndex: 'usdValue',
                key: 'usdValue',
                align: 'center',
                render: (_: any, row: NetworkFeeDepositExpendSummery) =>
                  roundPrecision(row.usdValue, 4),
              },
            ]} 
            pagination={false} 
            summary={() => (
              <Table.Summary.Row style={{ backgroundColor: '#d9d9d9' }}>
                <Table.Summary.Cell index={1} align={'center'}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align= 'center'>
                  {
                    usdValueSum
                  }
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Panel>
      </Collapse>
      
      <CoreProTable<NetworkFeeDepositExpendItem>
        bordered
        rowKey={(item) => `${item.reportDate}:${item.systemId}:${item.token}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        title={() =>
          <Panel header={'每日數據'} key={1}></Panel>
        }
        formRef={searchFormRef}
        updateTableState={(state: any) => {
          pageOp.update((v) => {
            return { ...v, ...state };
          });
        }}
        requestData={async (params: any) => {
          let res;
          const param = PaginationTransformer.paramTransfer(params);
          const queryObj = {
            ...param,
            startDate: dateRangeColumn.getParamAndUpdatePage().start,
            endDate: dateRangeColumn.getParamAndUpdatePage().end,
          };
          res = await NetworkFeeDepositExpendProvider.getPageList(queryObj as any).catch((e) => {
            console.error(e);
            throw e;
          });
          setQueryData({
            ...param,
            startDate: dateRangeColumn.getParamAndUpdatePage().start,
            endDate: dateRangeColumn.getParamAndUpdatePage().end,
          })
          return PaginationTransformer.responseTransfer(res);
        }}
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('report.date'),
            dataIndex: 'reportDate',
            fixed: 'left',
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              transfer2LocalDate(new Date(row.reportDate)),
          },
          {
            hideInSearch: false,
            // hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            hideInSearch: false,
            // hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'token',
            valueEnum: {
              ETH: 'ETH',
              TRX: 'TRX',
              BNB: 'BNB',
            },
            title: t('payment.token'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'depositAtMch',
            title: '充值商戶錢包',//t('report.agentType'),
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              roundPrecision(row.depositAtMch, 4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 80,
            ellipsis: true,
            dataIndex: 'depositAtUser',
            title: '充值用戶錢包',//t('report.depositAmount'),
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              roundPrecision(row.depositAtUser, 4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'depositSum',
            title: '總充值礦工費',//t('report.depositFee'),
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              roundPrecision(row.depositSum, 4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'expendAtMch',
            title: '商戶錢包消耗',//t('report.depositFee'),
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              roundPrecision(row.expendAtMch, 4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'expendAtUser',
            title: '用戶錢包消耗',//t('report.depositFee'),
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              roundPrecision(row.expendAtUser, 4),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'expendSum',
            title: '總消耗礦工費',//t('report.depositFee'),
            render: (_: any, row: NetworkFeeDepositExpendItem) =>
              roundPrecision(row.expendSum, 4),
          },
          dateRangeColumn.column,
        ]}
      />
    </>
  );
}
