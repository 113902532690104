import { moduleInit } from '../../core/base/moduleInit';
import { IPage, Page } from '../../core/configs/types';
import dashboard from '../pages/MerchantDashboard';
import secureSetting from '../pages/MerchantSetting';
import merchantWallet from '../pages/MerchantWalletInfo';
import MchWalletChangeLogTable from '../../domain/components/change-log/MchWalletChangeLogTable';
import UserWalletChangeLogTable from '../../domain/components/change-log/UserWalletChangeLogTable';

export const ModuleName = 'MerchantCenter';
export const ModuleIdentity = 'MERCHANT-CENTER';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_MERCHANT_CENTER = Symbol();

const mchWalletChangeLog = Page.createInstance({
  id: 20353,
  uri: '',
  component: () => <MchWalletChangeLogTable />,
  scopes: ['READ_MCH_WALLET_TXN'],
  onClose: () => {},
});
const userWalletChangeLog = Page.createInstance({
  id: 20354,
  uri: '',
  component: () => <UserWalletChangeLogTable />,
  scopes: ['READ_USER_WALLET_TXN'],
  onClose: () => {},
});

const pages: IPage[] = Page.createInstances([
  {
    id: 20350,
    uri: '',
    component: dashboard,
    scopes: ['READ_DASHBOARD'],
    onClose: () => {},
  },
  {
    id: 20351,
    uri: '',
    component: secureSetting,
    scopes: ['MODIFY_SECURE_SETTING'],
    onClose: () => {},
  },
  {
    id: 20352,
    uri: '',
    component: merchantWallet,
    scopes: ['READ_WALLET'],
    onClose: () => {},
  },
]);
_module.addPages(pages.concat([mchWalletChangeLog, userWalletChangeLog]));
_module.setMenu([
  {
    symbol: MENU_SYMBOL_MERCHANT_CENTER,
    label: '商戶管理',
    menuId: 33,
    order: 33,
    iconId: 21,
    i18nLabel: { en_US: 'Merchant Center', zh_CN: '商戶中心',vi_VN:'Trung tâm Thương Nhân' },
    children: [
      {
        label: '儀表板',
        menuId: pages[0],
        i18nLabel: { en_US: 'Dashboard', zh_CN: '數據看板',vi_VN:'Tổng quan về tài khoản' },
      },
      {
        label: '安全設定',
        menuId: pages[1],
        i18nLabel: { en_US: 'Secure Setting', zh_CN: '安全中心',vi_VN:'Trung tâm bảo mật' },
      },
      {
        label: '商戶錢包',
        menuId: pages[2],
        i18nLabel: { en_US: 'Merchant Wallet', zh_CN: '商戶錢包',vi_VN:'Ví khách hàng' },
      },
      {
        label: '商戶錢包帳變',
        menuId: mchWalletChangeLog,
        i18nLabel: {
          en_US: 'Merchant Wallet Change Log',
          zh_CN: '商戶錢包帳變',vi_VN:'Biến động số dư Ví thương nhân'
        },
      },
      {
        label: '用戶錢包帳變',
        menuId: userWalletChangeLog,
        i18nLabel: { en_US: 'User Wallet Change Log', zh_CN: '用戶錢包帳變',vi_VN:'Biến động số dư ví người dùng' },
      },
    ],
  },
]);
