export const menuArray = (menu: any[], nav: any) => {
  let i = 0;
  while (i < menu.length) {
    const item = menu[i];
    if (Array.isArray(item.children)) {
      let result: any;
      result = menuArray(item.children, nav);
      if (result) {
        return result;
      }
    } else {
      if (item.menuId !== parseInt(nav)) {
        i++;
        continue;
      }
      return {
        list: {
          root: item.menuId,
          id: item.menuId,
          zh_CN: item.i18nLabel.zh_CN,
          en_US: item.i18nLabel.en_US,
          vi_VN: item.i18nLabel.vi_VN,
          url: `/${item.i18nLabel.en_US
              .split(" ")
              .join("")
              .replace(/^\S/, (s: any) => s.toLowerCase())}/${item.menuId}`,
        },
        now: [nav, item.menuId],
      };
    }
    i++;
  }
  return null;
}


export function tapMenu(tap: any, nav: any) {
  const list = tap ? tap.list || {} : {},
    menu = list[`${nav}s`] || {};
  return {
    list,
    now: [menu.id, menu.root]
  };
};