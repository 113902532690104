import { Card, Form, Radio } from 'antd';
import React, { useState } from 'react';

const DepositModeFrom = (props: {
  depositMode: number;
  onChange: (value: { depositMode: number }) => void
}) => {
  const [depositMode, setDepositMode] = useState<number>(
    props.depositMode ?? 1,
  );

  return (
    <>
      <Card title='充值模式'>
        <Form.Item
          name='depositMode'
          label='充值模式'
          rules={[{ required: true, message: '請選擇充值模式' }]}
        >
          <Radio.Group
            buttonStyle='solid'
            onChange={(e) => {
              setDepositMode(e.target.value);
              props.onChange({depositMode:e.target.value});
            }}
          >
            <Radio.Button disabled={true} value={1}>
              自動上分模式
            </Radio.Button>
            <Radio.Button value={2}>
              訂單模式
            </Radio.Button>
            <Radio.Button disabled={true} value={3}>
              輪詢錢包模式
            </Radio.Button>
            <Radio.Button value={4}>
              收付一體
            </Radio.Button>
            <Radio.Button value={5}>
              輪詢自動歸集模式
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Card>
    </>
  );
};

export default DepositModeFrom;
