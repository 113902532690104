import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Space, Tooltip, Typography } from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  HomeOutlined,
  LogoutOutlined,
  SplitCellsOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import { getStorage } from '../../lib/webStorage';
import { UserStore } from '../../stores/user';
import authorize from '../../modules/core/libs/auth';
import { withTranslation } from 'react-i18next';
import { LangMenu } from '../../components/langMenu';

const { Text } = Typography;

interface props {
  dispatch: any;
  // menuList: any;
  // homeList: any;
  systemName: string;
  userName: string;
  t: any;
}

interface state {
  profileVisible: boolean;
  profileInfo: string;
  telescopic: boolean;
  selectMerchant: boolean;
  taskType: string;
  taskOpen: boolean;
}

class UserInfo extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      profileVisible: false,
      profileInfo: '',
      telescopic: false,
      selectMerchant: false,
      taskType: '',
      taskOpen: false,
    };
  }

  componentDidMount() {
    // this.props.dispatch(getAvailableTasks({ pageNo: 1, maxResult: 100 }));
    // this.props.dispatch(getMyTask({ pageNo: 1, maxResult: 100 }));
  }

  logOut = () => {
    const result = () => {
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.href = '/';
    };
    result();
  };

  telescopicChange = () => {
    this.setState({ telescopic: !this.state.telescopic });
  };

  render() {
    const { t } = this.props;
    const { telescopic } = this.state;
    const settings = getStorage('systemSettings');
    // console.log(settings?.systemName, this.props.systemName, getStorage('systemId'))
    const systemName =
      settings?.systemName ??
      (!this.props.systemName ? null : this.props.systemName) ??
      getStorage('systemId');
    return (
      <div className={telescopic ? 'header-user-main on' : 'header-user-main'}>
        <span>
          <Space>
            <Text>
              {t('common.hello')}，{this.props.userName}
            </Text>
            {!systemName || systemName === 'undefined' ? null : (
              <>
                <HomeOutlined />
                <Text>{systemName}</Text>
              </>
            )}
          </Space>
        </span>
        <span>
          <Dropdown overlay={() => <LangMenu />}>
            <Button style={{ background: 'black' }}>
              {t('lang.language')}
            </Button>
          </Dropdown>
        </span>
        <Tooltip placement="bottom" title={t('login.retry_login')}>
          <Button
            type="link"
            icon={<SyncOutlined />}
            onClick={async () => {
              await authorize.authRefresh(this.props.dispatch);
              authorize.autoAuthRefresh(this.props.dispatch);
            }}
          />
        </Tooltip>
        <Tooltip title={t('common.new_windows')}>
          <Button
            type="link"
            href="#/"
            target="_blank"
            icon={<SplitCellsOutlined />}
            style={{ marginRight: 0 }}
          />
        </Tooltip>
        <Tooltip title={t('login.logout')}>
          <Button type="link" onClick={this.logOut} icon={<LogoutOutlined />} />
        </Tooltip>
        <span className="telescopic" onClick={this.telescopicChange}>
          {telescopic ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
        </span>
      </div>
    );
  }
}

function userRes(state: any) {
  const systemId = getStorage('systemId');
  const user: UserStore = state.user;
  return {
    systemName:
      state['MERCHANT-CENTER'].STATE_MERCHANT_CENTER?.settings?.systemName ??
      systemId,
    userName: user.userName,
  };
}

export default connect(userRes)(withTranslation()(UserInfo));
