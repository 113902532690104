import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ChangeLibOrder, ChangeLibOrderProvider } from '../../domain/usdt-providers/changeLibOrderProvider';
import { Button, message, PageHeader, Space, Upload } from 'antd';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { UsdtUri } from '../../core/configs/services';
import adminRequest from '../../../config/adminRequest';
import moment from 'moment';
import { OperationType } from '../../core/types/operationType';
import ProForm, { DrawerForm, ProFormText } from '@ant-design/pro-form';
import { Domain } from '../../domain/usdt-providers/domainProvider';
import { UPDATE_CRYPTO_LIB_MANAGER } from '../configs/scopes';
import _module from '../configs/module';

interface PageOp extends CoreProTableState<ChangeLibOrder> {
}

export default function CryptoLibUpdatePage() {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [operateType, setOperateType] = useState<OperationType>();
  const [, setOperatePayload] = useState<Domain | undefined>(
    undefined,
  );

  // Form state
  const [form] = ProForm.useForm();
  const [, setFormRow] = useState('');
  const [formVisible, setFormVisible] = useState(false);

  const { t } = useTranslation();
  const user = useSelector<GlobalStoreMerge, UserStore>((x) => x.user);
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const execute = (type: OperationType, payload?: Domain) => {
    setOperateType(type);
    setOperatePayload(payload);
  };

  const refresh = () => {
    pageOp.update((v) => {
      v.needRequest = true;
      return v;
    });
    actionRef.current?.reload();
  };

  useEffect(() => {
    // Operate actions
    if (operateType === OperationType.ADD) {
      pageOp.update((prevState) => ({ ...prevState, formType: operateType }));
      setFormVisible(true);
    }
    if (operateType === OperationType.CLOSE) {
      setFormRow('');
      setFormVisible(false);
      form.resetFields();
      pageOp.update((s) => ({
        ...s,
        formType: undefined,
        formPayload: undefined,
      }));
      execute(OperationType.RELOAD);
    }

    if (operateType === OperationType.RELOAD) {
      pageOp.update((s) => ({ ...s, needRequest: true }));
      // eslint-disable-next-line no-unused-expressions
      if (!!actionRef.current?.reload) actionRef.current.reload();
    }
    // eslint-disable-next-line
  }, [operateType]);

  return (
    <>
      <PageHeader
        title={t('crypto_lib.update_crypto_lib_label')}
        subTitle={''}
        extra={<></>}
      />

      <CoreProTable<ChangeLibOrder>
        bordered
        search={false}
        rowKey={(row) => `${row.orderId}`}
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        onReset={() => {
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        tableState={pageOp.get()}
        requestData={async (params) => {
          const res = await ChangeLibOrderProvider.getList(
            PaginationTransformer.paramTransfer(params),
          );
          return PaginationTransformer.responseTransfer(res);
        }}
        actionRef={actionRef as any}
        toolBarRender={() => [
          _module.canRender(
            UPDATE_CRYPTO_LIB_MANAGER,
          <Button
            key={1}
            type='primary'
            onClick={() => execute(OperationType.ADD)}
          >
            更換加密套件
          </Button>,
            )
        ]}
        formRef={searchFormRef}
        columns={[
          {
            hideInSearch: true,
            align: 'center',
            title: t('common.version'),
            dataIndex: 'version',
            width: 70,
          },
          {
            hideInSearch: true,
            align: 'center',
            title: t('crypto_lib.newLibName'),
            dataIndex: 'newLibName',
            width: 70,
          },
          {
            hideInSearch: true,
            align: 'center',
            title: t('crypto_lib.status'),
            dataIndex: 'status',
            width: 70,
          },
          {
            hideInSearch: true,
            align: 'center',
            title: t('crypto_lib.errorCause'),
            dataIndex: 'errorCause',
            width: 70,
          },
          {
            hideInSearch: true,
            width: 70,
            align: 'center',
            title: t('common.createdAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.createdAt)),
          },
          {
            hideInSearch: true,
            width: 70,
            align: 'center',
            title: t('common.updatedAt'),
            dataIndex: 'createdAt',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.updatedAt)),
          },
        ]}
      />

      <DrawerForm
        visible={formVisible}
        form={form}
        submitter={{
          render: () => (
            <Space>
              <Upload
                showUploadList={false}
                customRequest=
                  {(opt) => {
                    form.validateFields().then((values) => {
                      const data = new FormData();
                      data.append('iv', values.iv);
                      data.append('key', values.key);
                      data.append('lib', opt.file);
                      data.append(
                        'version',
                        moment(new Date()).format('YYYY.MM.DD.HHmmss'),
                      );
                      data.append('operatorId', user.userId);
                      data.append('operatorName', user.userName);
                      data.append('path', './lib_source');
                      adminRequest
                        .post(UsdtUri('/change-crypto-lib/change-lib'), data)
                        .then((res: any) => {
                          if (!!opt && opt.onSuccess) {
                            opt.onSuccess(res.data, opt.file as any);
                          }
                        })
                        .catch((e) => {
                          if (!!opt && opt.onError) {
                            opt.onError(e, opt.file as any);
                          }
                        });
                    });
                  }}
                onChange={(info) => {
                  if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    refresh();
                    execute(OperationType.CLOSE);
                  } else if (info.file.status === 'error') {
                    // message.error(`${info.file.name} file upload failed.`);
                    refresh();
                  }
                }}
              >
                <Button key={1}
                        type='primary'
                        onClick={() => {
                        }}
                        icon={<UploadOutlined />}>{t('common.upload')}</Button>
              </Upload>
            </Space>
          ),
        }}
        layout='vertical'
        drawerProps={{
          title: t('crypto_lib.update_crypto_lib_label'),
          width: 480,
          destroyOnClose: true,
          onClose: () => execute(OperationType.CLOSE),
        }}
      >
        <ProFormText
          status='success'
          name='iv'
          label='舊IV'
          rules={[{ required: true, message: t('common.must_fill_required_field') }]}
        />

        <ProFormText status='success' name='key' label='舊Key'
                     rules={[{ required: true, message: t('common.must_fill_required_field') }]} />
      </DrawerForm>
    </>
  );
}
