export interface IPageState {
  current: number;
  pages: { [key: number]: { [key: string]: any } };
}

const initialState: IPageState = {
  current: 0,
  pages: {},
};

const pageState = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  const id = action.payload?.id?.toString() || '';
  switch (action.type) {
    case 'SETUP_PAGE':
      if (typeof state.pages[id] === 'undefined') {
        state.pages[id] = {};
      }
      state.current = id;
      return state;
    case 'SET_PAGE_STATE':
      const page = state.pages[id];
      if (typeof page === 'undefined') {
        console.error('Never Setup');
        return state;
      }
      const data = page[action.payload.symbol];
      if (typeof data === 'undefined') {
        state.pages[id][action.payload.symbol] = action.payload.state;
        return state;
      }
      state.pages[id][action.payload.symbol] = {
        ...data,
        ...action.payload.state,
      };
      return state;
    case 'CLEAR_PAGE':
      if (typeof state.pages[id] !== 'undefined') {
        delete state.pages[id];
      }
      return state;
  }
  return state;
};

export default pageState;
