import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProCoreActionType } from '@ant-design/pro-utils';
import _module from '../configs/module';
import { FormInstance } from 'antd/lib/form';
import { DELETE_MERCHANT_ROLES, WRITE_MERCHANT_ROLES } from '../configs/scopes';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import { MchRoleProvider, Role } from '../../domain/admin-providers/mchRoleProvider';
import RoleForm, { CloseEvent, RoleFormProps } from '../components/RoleForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import { useTranslation } from 'react-i18next';

interface PageOp extends CoreProTableState<Role> {
}

const defultRoleFormProp: RoleFormProps = {
  record: undefined,
  visible: false,
  isEdit: false,
};

// eslint-disable-next-line
export default () => {
  const { t } = useTranslation();
  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [roleFormState, setRoleFormState] = useState(defultRoleFormProp);
  const logger = useOperatorLogger();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const refresh = () => {
    pageOp.update((x) => {
      x.needRequest = true;
      return x;
    });
    tableActionRef.current?.reload();
  };
  const deleteCommand = (row: Role) => {
    Modal.confirm({
      title: `刪除?`,
      content: `確認刪除 ${row?.name} (編號: ${row?.id})?`,
      icon: <ExclamationCircleOutlined />,
      okText: '確認',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        MchRoleProvider.delete(row)
          .then((rs) => {
            logger.log({ action: '刪除', payload: { req: row, res: rs.data } });
            refresh();
          })
          .catch((e) => {
            logger.log({ action: '刪除', payload: { req: row, res: e } });
          });
      },
    });
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === CloseEvent.symbol))
      .subscribe((x) => {
        setRoleFormState(defultRoleFormProp);
        refresh();
      });
    // eslint-disable-next-line
  }, []);
  console.log('roleFormState', roleFormState);
  return (
    <>
      <PageHeader
        title={t('mch_permission.RoleList')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<Role>
        bordered
        // manualRequest={!systemId}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={tableActionRef as any}
        rowKey={(row) => row.id}
        columns={[
          {
            width: 50,
            hideInSearch: true,
            title: '角色編號',
            dataIndex: 'id',
            fixed: 'left',
            align: 'center',
          },
          {
            width: 100,
            title: '角色名稱',
            dataIndex: 'name',
            fixed: 'left',
          },
          {
            width: 150,
            hideInSearch: true,
            title: '角色說明',
            dataIndex: 'description',
          },
          {
            hideInSearch: true,
            key: 'operation',
            title: '操作',
            width: 100,
            align: 'center',
            render: (text, record) =>
              <Space>
                <Button
                  size={'small'}
                  key='editable'
                  disabled={!_module.can([WRITE_MERCHANT_ROLES])}
                  onClick={(x) => {
                    setRoleFormState({ visible: true, record, isEdit: true });
                  }}
                >
                  編輯
                </Button>
                <Button
                  size={'small'}
                  key='delete'
                  disabled={!_module.can([DELETE_MERCHANT_ROLES])}
                  danger={_module.can([DELETE_MERCHANT_ROLES])}
                  onClick={(x) => {
                    deleteCommand(record);
                  }}
                >
                  刪除
                </Button>
              </Space>,
          },
        ]}
        toolBarRender={() => [
          _module.canRender(
            WRITE_MERCHANT_ROLES,
            <Button
              key={1}
              type='primary'
              onClick={() => setRoleFormState({ visible: true, isEdit: false })}
            >
              新增角色
            </Button>),
        ]}
        requestData={(params) => {
          const req = PaginationTransformer.paramTransfer(params);
          return MchRoleProvider.getList({
            ...req,
          }).then((rs) => {
            logger.log({ action: '查詢', payload: { req, res: rs.data } });
            return PaginationTransformer.responseTransfer(rs);
          });
        }}
      />
      <RoleForm {...roleFormState} />
    </>
  );
};
