export const setStorage = (code: string, value: any, type: number = 1) => {
  const objType = Object.prototype.toString.call(value),
    strType = objType.split(' ')[1].substr(0, objType.split(' ')[1].length - 1);
  // strCode = "cash_" + strType + "_" + code;
  // const c = strPrivacy(strCode);
  const c = code;
  let res: any;
  if (strType === 'Object' || strType === 'Array') {
    res = JSON.stringify(value);
  } else {
    res = value;
  }
  if (type !== 1) {
    window.localStorage.setItem(c, res);
  } else {
    window.sessionStorage.setItem(c, res);
  }
  const localList = JSON.parse(
    window.localStorage.getItem('localList') || '{}',
  );
  if (!localList[code]) {
    localList[code] = strType;
    window.localStorage.setItem('localList', JSON.stringify(localList));
  }
};

export const getStorage = (code: string, type: number = 1) => {
  const localList = JSON.parse(
    window.localStorage.getItem('localList') || '{}',
  );
  // const c = strPrivacy("cash_" + localList[code] + "_" + code);
  const c = code;
  const vl: any =
    type !== 1
      ? window.localStorage.getItem(c)
      : window.sessionStorage.getItem(c);
  switch (localList[code]) {
    case 'Boolean':
      return vl === 'true';
    case 'Array':
    case 'Object':
      return JSON.parse(vl);
    default:
      try {
        if (typeof JSON.parse(vl) == 'object') {
          return JSON.parse(vl);
        }
      } catch (e) {}
      return vl;
  }
};

export const removeStorage = (code: string, type: number = 1) => {
  const c = code;
  if (type !== 1) {
    window.localStorage.removeItem(c);
  } else {
    window.sessionStorage.removeItem(c);
  }
};

export const sortList = (res: any, key: Array<String> = []) => {
  for (const k in res) {
    if (key.indexOf(k) >= 0) {
      setStorage(k, res[k]);
    }
  }
};
