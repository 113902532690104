import { Button, Form, Input, Modal, PageHeader } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteList, WhiteListProvider } from '../../domain/usdt-providers/whiteListProvider';
import i18next from 'i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { usePageState } from '../../core/hooks/usePageState';
import _module from '../configs/module';
import { ADD_WITHDRAW_WHITLIST } from '../configs/scopes';
import { ChannelValueEnum, Token } from '../../domain/channel/channel';
import ChannelTokenCascader from '../../domain/components/setttings/ChannelTokenCascader';

type Props = {};
type TokenId = { channel: string; token: string };

interface PageOp extends CoreProTableState<WhiteList> {}

export default function WithdrawWhiteList(props: Props) {
  const { t } = useTranslation();
  const [whiteListFormVisible, setWhiteListFormVisible] = useState(false);
  const whiteListForm = React.createRef<FormInstance>();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 50,
      current: 1,
    },
    needRequest: true,
  });

  const refreshDataCommand = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
  };

  const fetchDataCommand = async (walletAddress?: string) => {
    const res = await WhiteListProvider.getWhiteList(walletAddress);
    return res.data;
  };

  const deleteCommand = (item: WhiteList) => {
    Modal.confirm({
      content: `${t('common.delete_confirm')} ${item.walletAddress} ?`,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        WhiteListProvider.delete(item.walletAddress, item.channel, item.token)
          .then((res) => {
            Modal.success({
              content: `${t('common.delete_success')}: ${
                res.data.walletAddress
              },+'\n',
                ${res.data.channel},'\n',
                ${res.data.token}
              }`,
              okText: t('common.confirm'),
            });
          })
          .catch((e) => {
            Modal.error({
              content: e.message,
              okText: t('common.confirm'),
            });
          })
          .finally(() => {
            refreshDataCommand();
          });
      },
    });
  };

  const addCommand = async (
    address: string,
    channel: string,
    token: string,
    comment?: string
  ) => {
    const res = await WhiteListProvider.add(address.trim(), channel, token, comment);
    refreshDataCommand();
    return res.data;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);
  const [channelToken, setChannelToken] = useState<TokenId>({
    channel: '',
    token: '',
  });

  function onCascaderChange(value: { channel?: string; token?: string }) {
    setChannelToken({ channel: value.channel??'', token: value.token??'' });
  }

  return (
    <>
      <PageHeader
        title={t('transferback.white_list')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<WhiteList>
        bordered={true}
        // search={false}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        rowKey={data => `${data.walletAddress}:${data.channel}:${data.token}`}
        toolBarRender={() => [
          _module.canRender(
            ADD_WITHDRAW_WHITLIST,
            <Button
              key={1}
              type="primary"
              onClick={(event) => {
                event.stopPropagation();
                setWhiteListFormVisible(true);
              }}
            >
              {i18next.t('common.add')}
            </Button>,
          ),
        ]}
        requestData={(param: any) =>
          fetchDataCommand(param.walletAddress).then((res) => {
            return {
              success: true,
              data: res,
              total: res.length,
            };
          })
        }
        columns={[
          {
            align: 'center',
            title: t('payment.wallet_address'),
            hideInSearch: false,
            hideInTable: false,
            dataIndex: 'walletAddress',
          },
          {
            align: 'center',
            title: t('payment.channel'),
            hideInSearch: true,
            dataIndex: 'channel',
            valueEnum: ChannelValueEnum,
          },
          {
            align: 'center',
            title: t('payment.token'),
            hideInSearch: true,
            dataIndex: 'token',
            valueEnum: Token,
          },
          {
            align: 'center',
            title: "備註",
            hideInSearch: true,
            dataIndex: 'comment',
          },
          {
            align: 'center',
            title: t('common.operate'),
            hideInSearch: true,
            dataIndex: 'walletAddress',
            key: '_action',

            render: (text, row) => [
              <Button
                key={2}
                size="small"
                disabled={!_module.can([ADD_WITHDRAW_WHITLIST])}
                danger={_module.can([ADD_WITHDRAW_WHITLIST])}
                onClick={() => deleteCommand(row)}
              >
                {i18next.t('common.delete')}
              </Button>,
            ],
          },
        ]}
      />

      <Modal
        destroyOnClose
        title={i18next.t('transferback.add_white_list')}
        visible={whiteListFormVisible}
        okText={i18next.t('common.add_confirm')}
        cancelText={i18next.t('common.cancel')}
        onOk={() => {
          whiteListForm.current?.validateFields().then((values) => {
            addCommand(
              values.walletAddress,
              channelToken.channel,
              channelToken.token,
              values.comment,
            ).then(async (res) => {
              Modal.success({
                content: `${res.walletAddress} ${t('common.add_success')}`,
                okText: t('common.confirm'),
              });
              refreshDataCommand();
              setWhiteListFormVisible(false);
            });
          });
        }}
        onCancel={() => setWhiteListFormVisible(false)}
      >
        <Form ref={whiteListForm}>
          <Form.Item
            name="walletAddress"
            label={i18next.t('transferback.white_list_address')}
            rules={[
              {
                required: true,
                message: t(
                  'transferback.message.plz_enter_transferback_white_list',
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="channel"
            label={t('payment.channel') + '/' + t('payment.token')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <ChannelTokenCascader
              onChange={onCascaderChange}
              option={{ channel: '', token: '' }}
            />
            {/*<Select*/}
            {/*  options={ChannelSelect}*/}
            {/*  onChange={(value) => setChannel(value as string)}*/}
            {/*/>*/}
          </Form.Item>

          <Form.Item
            name="comment"
            label="備註"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
