import React from 'react';
import { connect } from 'react-redux';

import PlatformInfo from './platformInfo';

import './index.less';
import CurrentUsdt2EthChart from '../../modules/currency-rate/CurrentUsdt2EthChart';

interface props {
  history: any;
  homeList: any;
  dispatch: any;
}

class Index extends React.Component<props> {
  UNSAFE_componentWillMount() {
    // const { dispatch } = this.props;
    // dispatch(getActiveAnnouncement());
  }

  render() {
    const { homeList = {} } = this.props;
    const activeAnnouncement = homeList.activeAnnouncement;
    return (
      <div className="admin-content">
        <PlatformInfo homeList={activeAnnouncement} />
        <CurrentUsdt2EthChart />
      </div>
    );
  }
}

function welcome(state: any) {
  const { homeList } = state;
  return { homeList };
}

export default connect(welcome)(Index);
