import { Group } from './mchGroupProvider';
import { AdminUri } from '../../core/configs/services';
import adminRequest from '../../../config/adminRequest';
import { AdminResponse } from '../models/adminResponse';
import { AdminListResponse } from '../models/adminListResponse';
import { PaginationQuery } from '../../core/utils/paginationTransformer';

export interface UserBase {
  id?: string;
  username: string;
  password: string;
  displayName: string;
  email: string;
  firstTimeLogin: boolean;
}

export interface User extends UserBase {
  key: string;
  id: string;
  username: string;
  password: string;
  displayName: string;
  email: string;
  groupId: number;
  group: Group;
  groupDisplayName: string;
  systemId: string;
}

export class MchUserProvider {
  static async getList(params: PaginationQuery, systemId?: string) {
    const res = await adminRequest.get<AdminListResponse<User>>(
      AdminUri('admin/merchant/users'),
      {
        params: {
          ...params,
          systemId: systemId,
        },
      },
    );

    return res.data;
  }

  static async getUser(id: string) {
    const res = await adminRequest.get<AdminResponse<User>>(
      AdminUri(`admin/merchant/users/${id}`),
    );
    return res.data.data;
  }

  static async update(user: Partial<User>) {
    const res = await adminRequest.patch<AdminResponse<User>>(
      AdminUri(`admin/merchant/users/${user.id}`),
      user,
    );
    return res.data.data;
  }

  static async add(user: Partial<User>) {
    const res = await adminRequest.post<AdminResponse<User>>(
      AdminUri(`admin/merchant/users`),
      user,
    );
    return res.data.data;
  }

  static async delete(id: string) {
    const res = await adminRequest.delete<AdminResponse<any>>(
      AdminUri(`admin/merchant/users/${id}`),
    );
    return res.data.data;
  }
}
