import systemList, { SystemListStore } from './system-list';
import user, { UserStore } from './user';
import menu, { MenuStore } from './menu';
import pageState, { IPageState } from './page-state';

export type GlobalStoreMerge = {
  user: UserStore;
  menu: MenuStore;
  pageState: IPageState;
  systemList: SystemListStore;
};
// eslint-disable-next-line
export default {
  systemList,
  user,
  menu,
  pageState,
};
