import { moduleInit } from '../../core/base/moduleInit';
import { IPage, Page } from '../../core/configs/types';
import React from 'react';
import CreditReportList from '../pages/CreditReportList';
import PayforReportList from '../pages/PayforReportList';
import {
  PROFIT_WITHDRAW_MANAGER,
  READ_COLLECT_ORDER,
  READ_CREDIT_ORDER,
  READ_PAY_ORDER,
  READ_WITHDRAW_ORDER,
} from './scopes';
import CollectReportList from '../pages/CollectReportList';
import TransferBackReportList from '../pages/TransferBackReportList';
import ProfitTransferPage from '../pages/ProfitTransferPage';
import CreateFailList from '../pages/CreateFailList';

export const ModuleName = 'TransactionManager';
export const ModuleIdentity = 'TRANSACTION-MANAGER';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

const profitTransferPage = Page.createInstance({
  id: 80005,
  uri: '',
  component: () => <ProfitTransferPage />,
  scopes: [PROFIT_WITHDRAW_MANAGER],
});
const createFailListPage = Page.createInstance({
  id: 80006,
  uri: '',
  component: () => <CreateFailList title="代付建單失敗清單" subTitle="" />,
  scopes: [READ_PAY_ORDER],
});

const pages: IPage[] = Page.createInstances([
  {
    id: 80001,
    uri: '',
    component: () => <CreditReportList title="充值訂單查詢" subTitle="" />,
    scopes: [READ_CREDIT_ORDER],
  },
  {
    id: 80002,
    uri: '',
    component: () => <PayforReportList title="代付訂單查詢" subTitle="" />,
    scopes: [READ_PAY_ORDER],
  },
  {
    id: 80003,
    uri: '',
    component: () => <CollectReportList title="歸集訂單查詢" subTitle="" />,
    scopes: [READ_COLLECT_ORDER],
  },
  {
    id: 80004,
    uri: '',
    component: () => (
      <TransferBackReportList title="下發訂單查詢" subTitle="" />
    ),
    scopes: [READ_WITHDRAW_ORDER],
  },
]);

_module.addPages([...pages, profitTransferPage,createFailListPage]);
_module.setMenu([
  {
    symbol: Symbol(),
    label: '交易管理',
    menuId: 80,
    order: 80,
    iconId: 22,
    i18nLabel: { en_US: 'Transactions Management', zh_CN: '交易管理',vi_VN:'交易管理' },
    children: [
      {
        label: '充值訂單查詢',
        menuId: pages[0],
        i18nLabel: { en_US: 'Admin Credit Order List', zh_CN: '充值訂單查詢',vi_VN:'充值訂單查詢' },
      },
      {
        label: '代付訂單查詢',
        menuId: pages[1],
        i18nLabel: { en_US: 'Admin Payfor Order List', zh_CN: '代付訂單查詢' ,vi_VN:'代付訂單查詢'},
      },
      {
        label: '歸集訂單查詢',
        menuId: pages[2],
        i18nLabel: { en_US: 'Admin Collect Order List', zh_CN: '歸集訂單查詢',vi_VN:'歸集訂單查詢' },
      },
      {
        label: '下發訂單查詢',
        menuId: pages[3],
        i18nLabel: {
          en_US: 'Admin TransferBack Order List',
          zh_CN: '下發訂單查詢',vi_VN:'下發訂單查詢'
        },
      },
      {
        label: '利潤下發紀錄',
        menuId: profitTransferPage,
        i18nLabel: {
          en_US: 'Profit Transfer Order List',
          zh_CN: '利潤下發紀錄',vi_VN:'利潤下發紀錄'
        },
      },
      {
        label: '代付建單失敗清單',
        menuId: createFailListPage,
        i18nLabel: {
          en_US: 'Payfor Create Fail List',
          zh_CN: '代付建單失敗清單',vi_VN:'代付建單失敗清單'
        },
      },
    ],
  },
]);
