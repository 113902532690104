import { moduleInit } from '../../core/base/moduleInit';
import { Page } from '../../core/configs/types';
import FundingDetails from '../pages/FundingDetails';
import RefundWithdrawOrder from '../pages/RefundWithdrawOrder';
import {
  READ_FUNDING_DETAILS,
  READ_FUNDING_WALLET_LIST,
  READ_FUNDING_WALLET_TXN,
  READ_REFUND_APPROVE_LIST,
  READ_REFUND_WITHDRAW_ORDER,
} from './scopes';
import i18next from 'i18next';
import AgentFundingWalletPage from '../pages/AgentFundingWalletPage';
import AgentFundingTxnPage from '../pages/AgentFundingTxnPage';
import AgentFundingWalletInfo from '../pages/AgentFundingWalletInfo';

export const ModuleName = 'FUNDING-MANAGER';
export const ModuleIdentity = 'FUNDING-MANAGER';

const _module = new moduleInit(ModuleName, ModuleIdentity);
export default _module;

export const MENU_SYMBOL_AGENT_REPORT = Symbol();

const mchFundingDetails = Page.createInstance({
  id: 21100,
  uri: '',
  component: () => <FundingDetails />,
  scopes: [READ_FUNDING_DETAILS],
  onClose: () => null,
});
const refundWithDrawOrders = Page.createInstance({
  id: 21101,
  uri: '',
  component: () => (
    <RefundWithdrawOrder title={i18next.t('funding.refund_withdraw_order')} />
  ),
  scopes: [READ_REFUND_WITHDRAW_ORDER],
  onClose: () => null,
});

const refundWithDrawOrderManagement = Page.createInstance({
  id: 21102,
  uri: '',
  component: () => <RefundWithdrawOrder title={'提領佣金審核'} />,
  scopes: [READ_REFUND_APPROVE_LIST],
  onClose: () => null,
});

const agentFundingWalletManagement = Page.createInstance({
  id: 21103,
  uri: '',
  component: () => <AgentFundingWalletPage/>,
  scopes: [READ_FUNDING_WALLET_LIST],
  onClose: () => null,
});
const agentFundingChangeLogManagement = Page.createInstance({
  id: 21104,
  uri: '',
  component: () => <AgentFundingTxnPage/>,
  scopes: [READ_FUNDING_WALLET_TXN],
  onClose: () => null,
});
const agentFundingWalletInfoManagement = Page.createInstance({
  id: 21105,
  uri: '',
  component: () => <AgentFundingWalletInfo/>,
  scopes: [READ_FUNDING_WALLET_TXN],
  onClose: () => null,
});

_module.addPages([
  mchFundingDetails,
  refundWithDrawOrders,
  refundWithDrawOrderManagement,
  agentFundingWalletManagement,
  agentFundingChangeLogManagement,
  agentFundingWalletInfoManagement,
]);

_module.setMenu([
  {
    symbol: MENU_SYMBOL_AGENT_REPORT,
    label: '代理資金',
    menuId: 211,
    order: 50,
    iconId: 14,
    i18nLabel: { en_US: 'Funding Management', zh_CN: '代理資金',vi_VN:'Tài chính Đại Lý' },
    children: [
      {
        label: '代理錢包資金流水',
        menuId: mchFundingDetails,
        i18nLabel: { en_US: 'Funding Details', zh_CN: '代理錢包資金流水' ,vi_VN:'Dòng tiền của ví proxy'},
      },
      {
        label: '提領佣金紀錄',
        menuId: refundWithDrawOrders,
        i18nLabel: { en_US: 'Refund Withdraw Order', zh_CN: '提領佣金紀錄',vi_VN:'Lịch sử rút phí hoa hồng' },
      },
      {
        label: '提領佣金審核',
        menuId: refundWithDrawOrderManagement,
        i18nLabel: {
          en_US: 'Refund Withdraw Order Management',
          zh_CN: '提領佣金審核',vi_VN:'提領佣金審核'
        },
      },
      {
        label: '代理資金池',
        menuId: agentFundingWalletInfoManagement,
        i18nLabel: {
          en_US: 'Agent Funding Wallet Management',
          zh_CN: '代理資金池',vi_VN:'代理資金池'
        },
      },
      // {
      //   label: '代理資金池列表',
      //   menuId: agentFundingWalletManagement,
      //   i18nLabel: {
      //     en_US: 'Agent Funding Wallet Manager',
      //     zh_CN: '代理資金池列表',
      //   },
      // },
      {
        label: '資金池資金流水',
        menuId: agentFundingChangeLogManagement,
        i18nLabel: {
          en_US: 'Agent Funding Change Log Management',
          zh_CN: '資金池資金流水',vi_VN:'資金池資金流水'
        },
      },
    ],
  },
]);
