import React, { useEffect, useState } from 'react';
import { Card, Col, Collapse, PageHeader, Row, Space, Statistic, Typography } from 'antd';
import moment from 'moment';
import { DepositOrderProvider } from '../../domain/usdt-providers/depositOrderProvider';
import { PayforOrderProvider } from '../../domain/usdt-providers/payforOrderProvider';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import DepositSettingProvider from '../../domain/usdt-providers/depositSettingProvider';
import PayforSettingProvider from '../../domain/usdt-providers/payforSettingProvider';
import WithdrawSettingProvider from '../../domain/usdt-providers/withdrawSettingProvider';
import * as _ from 'lodash';
import ChannelTokenCascader from '../../domain/components/setttings/ChannelTokenCascader';
import { usePageState } from '../../core/hooks/usePageState';
import BigNumber from 'bignumber.js';

const { Text } = Typography;


interface Summary {
  todayDeposit: string;
  todayDepositOrders: number;
  todayPayfor: string;
  todayPayforOrders: number;
  yesterdayDeposit: string;
  yesterdayDepositOrders: number;
  yesterdayPayfor: string;
  yesterdayPayforOrders: number;
  todayFee: number;
  yesterdayFee: number;
  usdtWalletBalance: string;
  ethWalletBalance: string;
}

const initialSummary: Summary = {
  todayDeposit: '0',
  todayDepositOrders: 0,
  todayPayfor: '0',
  todayPayforOrders: 0,
  yesterdayDeposit: '0',
  yesterdayDepositOrders: 0,
  yesterdayPayfor: '0',
  yesterdayPayforOrders: 0,
  todayFee: 0,
  yesterdayFee: 0,
  usdtWalletBalance: '0',
  ethWalletBalance: '0',
};

interface FeeRate {
  creditFeeRate: number;
  innerDepositRate: number;
  payforFeeRate: number;
  withdrawFeeRate: number;

}

const initialFeeRate: FeeRate = {
  creditFeeRate: 0,
  innerDepositRate: 0,
  payforFeeRate: 0,
  withdrawFeeRate: 0,
};

const { Panel } = Collapse;

// eslint-disable-next-line
export default () => {
  const userInfo = useSelector<GlobalStoreMerge, UserStore>(
    (store) => store.user,
  );
  const [tokenData, setTokenData] = useState<{channel?:string,token?:string}>({ channel: undefined, token: undefined });
  const extraInfoStyle = { marginTop: 30 };
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(initialSummary);
  const [feeRate, setFeeRate] = useState(initialFeeRate);
  const orderAmount = i18next.t('common.order_count');
  const pageOp = usePageState('SELECT_TOKEN', {
    channel: tokenData.channel,
    token: tokenData.token,
  });


  useEffect(() => {
    const today = moment().startOf('day').toDate();
    const yesterday = moment().subtract(1, 'day').startOf('day').toDate();
    setLoading(true);
    setSummary(initialSummary)
    let todayFee = 0
    let yesterdayFee = 0
    Promise.all([
      DepositOrderProvider.getDailySummary({
        dataDate: today,
        channel: tokenData.channel??undefined,
        token: tokenData.token??undefined,
      }).then((rs) => {
        todayFee += new BigNumber(rs.data?.totalFee).toNumber()
        setSummary((summary) => ({
          ...summary,
          todayDeposit: rs.data?.totalOrderAmount,
          todayDepositOrders: rs.data?.orderCount,
        }));
        return rs;
      }),
      DepositOrderProvider.getDailySummary({
        dataDate: yesterday,
        channel: tokenData.channel,
        token: tokenData.token,
      }).then((rs) => {
        yesterdayFee+= new BigNumber(rs.data?.totalFee).toNumber()
        setSummary((summary) => ({
          ...summary,
          yesterdayDeposit: rs.data?.totalOrderAmount,
          yesterdayDepositOrders: rs.data?.orderCount,
        }));
        return rs;
      }),
      PayforOrderProvider.getDailySummary({
        dataDate: today,
        channel: tokenData.channel,
        token: tokenData.token,
      }).then((rs) => {
        todayFee += new BigNumber(rs.data?.totalFee).toNumber()
        setSummary((summary) => ({
          ...summary,
          todayPayfor: rs.data?.totalOrderAmount,
          todayPayforOrders: rs.data?.orderCount,
        }));
        return rs;
      }),
      PayforOrderProvider.getDailySummary({
        dataDate: yesterday,
        channel: tokenData.channel,
        token: tokenData.token,
      }).then((rs) => {
        yesterdayFee+= new BigNumber(rs.data?.totalFee).toNumber()
        setSummary((summary) => ({
          ...summary,
          yesterdayPayfor: rs.data?.totalOrderAmount,
          yesterdayPayforOrders: rs.data?.orderCount,
          yesterdayFee: summary.yesterdayFee + +rs.data?.totalFee,
        }));
        return rs;
      }),
    ]).finally(() => {
      setSummary((summary) => ({
        ...summary,
        todayFee:todayFee,
        yesterdayFee:yesterdayFee
      }));
      setLoading(false);
    });
  }, [tokenData]);
  useEffect(() => {
    setLoading(true);
    Promise.all([
      DepositSettingProvider.getSetting(userInfo.systemId).then((rs) => {

        let creditFeeRate = 0;
        let innerDepositRate = 0;

        _.map(rs.tokenSetting, (channelObj, channel) => {
          _.map(channelObj, (tokenObj, token) => {
            if (tokenData.channel === channel && tokenData.token === token) {
              creditFeeRate = tokenObj.creditFeeRate;
              innerDepositRate = tokenObj.innerDepositRate;
            }
          });
        });

        setFeeRate((feeRate) => ({
          ...feeRate,
          creditFeeRate,
          innerDepositRate,
        }));
        return rs;
      }),
      PayforSettingProvider.getSetting(userInfo.systemId).then((rs) => {

        let payforFeeRate = 0;

        _.map(rs.tokenSetting, (channelObj, channel) => {
          _.map(channelObj, (tokenObj, token) => {
            if (tokenData.channel === channel && tokenData.token === token) {
              payforFeeRate = tokenObj.feeRate;
            }
          });
        });

        setFeeRate((feeRate) => ({
          ...feeRate,
          payforFeeRate,
        }));
        return rs;
      }),
      WithdrawSettingProvider.getSetting(userInfo.systemId).then((rs) => {

        let withdrawFeeRate = 0;

        _.map(rs.tokenSetting, (channelObj, channel) => {
          _.map(channelObj, (tokenObj, token) => {
            if (tokenData.channel === channel && tokenData.token === token) {
              withdrawFeeRate = tokenObj.feeRate;
            }
          });
        });

        setFeeRate((feeRate) => ({
          ...feeRate,
          withdrawFeeRate,
        }));
        return rs;
      }),
    ]).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [tokenData]);

  function onCascaderChange(value: { channel?: string, token?: string }) {
    pageOp.update({ channel: value.channel??'', token: value.token??'' });
    setTokenData({ channel: value.channel??'', token: value.token??'' });
  }

  return (
    <>
      <PageHeader title={i18next.t('common.dashboard')}>
        <ChannelTokenCascader onChange={onCascaderChange}
                              option={{ channel: pageOp.get().channel, token: pageOp.get().token }} />
        {/*<Cascader options={cascaderData} onChange={onCascaderChange} style={{ width: `${400}px` }} placeholder='請選擇' />*/}
        <br />
        <br />
        <Collapse defaultActiveKey={['1', '2']}>
          <Panel header={i18next.t('common.mch_balance')} key={1}>
            <Row gutter={[24, 24]} style={{ margin: '0.2rem' }}>
              <Col span={6}>
                <Card loading={loading}>
                  <Statistic
                    title={i18next.t('credit.mch_today_total')}
                    value={summary.todayDeposit}
                    precision={6}
                  />
                  <Space style={extraInfoStyle}>
                    {orderAmount} {summary.todayDepositOrders}
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card loading={loading}>
                  <Statistic
                    title={i18next.t('payfor.mch_today_total')}
                    value={summary.todayPayfor}
                    precision={6}
                  />
                  <Space style={extraInfoStyle}>
                    {orderAmount} {summary.todayPayforOrders}
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card loading={loading}>
                  <Statistic
                    title={i18next.t('credit.mch_yesterday_total')}
                    value={summary.yesterdayDeposit}
                    precision={6}
                  />
                  <Space style={extraInfoStyle}>
                    {orderAmount} {summary.yesterdayDepositOrders}
                  </Space>
                </Card>
              </Col>
              <Col span={6}>
                <Card loading={loading}>
                  <Statistic
                    title={i18next.t('payfor.mch_yesterday_total')}
                    value={summary.yesterdayPayfor}
                    precision={6}
                  />
                  <Space style={extraInfoStyle}>
                    {orderAmount} {summary.yesterdayPayforOrders}
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ margin: '0.2rem' }}>
              <Col span={6}>
                <Card loading={loading}>
                  <Statistic
                    title={i18next.t('payment.mch_today_fee')}
                    value={summary.todayFee}
                    precision={6}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card loading={loading}>
                  <Statistic
                    title={i18next.t('payment.mch_yesterday_fee')}
                    value={summary.yesterdayFee}
                    precision={6}
                  />
                </Card>
              </Col>
              {/*<Col span={6}>*/}
              {/*    <Card loading={loading}>*/}
              {/*        <Statistic*/}
              {/*          title={i18next.t('payment.mch_wallet_usdt_balance')}*/}
              {/*          value={summary.usdtWalletBalance}*/}
              {/*          precision={6}*/}
              {/*        />*/}
              {/*    </Card>*/}
              {/*</Col>*/}
              {/*<Col span={6}>*/}
              {/*    <Card loading={loading}>*/}
              {/*        <Statistic*/}
              {/*          title={i18next.t('payment.mch_wallet_eth_balance')}*/}
              {/*          value={summary.ethWalletBalance}*/}
              {/*          precision={6}*/}
              {/*        />*/}
              {/*    </Card>*/}
              {/*</Col>*/}
            </Row>
          </Panel>
          <Panel key={2} header={i18next.t('payment.fee_rate')}>
            <Row gutter={16}>
              <Col span={4}>
                <Statistic
                  title={i18next.t('credit.fee_rate_GT0')}
                  valueRender={() =>
                    feeRate.creditFeeRate ?? (
                      <Text strong type='danger'>
                        {i18next.t('common.undefined')}
                      </Text>
                    )
                  }
                  precision={6}
                />
              </Col>
              <Col span={4}>
                <Statistic
                  title={i18next.t('credit.inner_fee_rate')}
                  valueRender={() =>
                    feeRate.innerDepositRate ?? (
                      <Text strong type='danger'>
                        {i18next.t('common.undefined')}
                      </Text>
                    )
                  }
                  precision={6}
                />
              </Col>
              <Col span={4}>
                <Statistic
                  title={i18next.t('payfor.fee_rate_GT0')}
                  valueRender={() =>
                    feeRate.payforFeeRate ?? (
                      <Text strong type='danger'>
                        {i18next.t('common.undefined')}
                      </Text>
                    )
                  }
                  precision={6}
                />
              </Col>
              <Col span={4}>
                <Statistic
                  title={i18next.t('transferback.fee_rate_GT0')}
                  valueRender={() =>
                    feeRate.withdrawFeeRate ?? (
                      <Text strong type='danger'>
                        {i18next.t('common.undefined')}
                      </Text>
                    )
                  }
                  precision={6}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </PageHeader>
    </>
  );
};
