import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminListResponse } from '../models/adminListResponse';
import { Permission } from '../../permissions/types';
import adminRequest from '../../../config/adminRequest';
import { AdminUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';

export class PermissionProvider {

  static async getList(
    param: PaginationQuery,
  ): Promise<AdminListResponse<Permission>> {
    const res = await adminRequest.get(AdminUri(`admin/module/permissions`),
      { params:param});
    return res.data;
  }

  static async getAll(): Promise<Permission[]> {
    const res = await PaginationTransformer.getAllFromPagination<
      Permission,
      null
    >((query) => PermissionProvider.getList(query), null);
    return res;
  }
  static async add(payload?: {bulk: Partial<Permission>[]}) {
    const res = await adminRequest.post<AdminResponse<Permission>>(
      AdminUri(`admin/module/permissions/bulk`),
      payload,
    );
    return res.data;
  }

  static async update(payload?:Partial<Permission>){
    const res = await adminRequest.patch<AdminResponse<Permission>>(
      AdminUri(`admin/module/permissions/${payload?.id}`),
      payload,
    );
    return res.data;
  }
  static async delete(id?: number) {
    await adminRequest.delete(AdminUri(`admin/module/permissions/${id}`));
  }
}
