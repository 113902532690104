import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { CollectRecord } from '../../orders/types/collectRecordListResponse';
import { AdminListResponse } from '../models/adminListResponse';
import { AdminResponse } from '../models/adminResponse';
import { RequestData } from '@ant-design/pro-table';
import { omit } from 'lodash';
import PaginationTransformer from '../../core/utils/paginationTransformer';

interface CreateCollectTask {
  srcAddress: string;
  taskId: string;
}

export interface CollectOrder {
  taskId: string;
  systemId: string;
  channel: string;
  token: string;
  status: string;
  merchantUserId: string;
  networkFeeOrderId?: string;
  tokenTxnOrderId?: string;
  srcAddress: string;
  targetAddress: string;
  availableAmount: number;
  txnAmount: number;
  networkFee: number;
  networkFeeRate: number;
  failReason?: string;
  createdAt: string;
}

export type CollectReq = {
  srcAddress: string;
  channel: string;
  token: string;
  userId: string;
};

export class CollectTaskProvider {
  static async adminCreateCollectTask(
    model: CollectReq & { systemId: string },
  ) {
    const res = await adminRequest.post<AdminResponse<CreateCollectTask>>(
      UsdtUri('/admin/payment/collect/single'),
      model,
    );
    return res.data;
  }

  static async createCollectTask(model: CollectReq) {
    const res = await adminRequest.post<AdminResponse<CreateCollectTask>>(
      UsdtUri('/merchant/payment/collect/single'),
      model,
    );
    return res.data;
  }

  static async createMultipleCollectTask(
    params: {
      srcAddress: string;
      channel: string;
      token: string;
    }[],
    userId: string
  ) {
    const res = await adminRequest.post<AdminResponse<CreateCollectTask[]>>(
      UsdtUri('/merchant/payment/collect/batch'),
      { wallets: params },
    );
    return res.data;
  }

  static async getCollectTaskList(params: {
    page: number;
    limit: number;
    start?: string;
    end?: string;
    status?: string;
    walletAddress?: string;
    merchantUserId?: string;
  }) {
    const res = await adminRequest.get<AdminListResponse<CollectRecord>>(
      UsdtUri('merchant/payment/collect/collect-task'),
      { params },
    );
    return res.data;
  }

  static async getExcel(params: { start: Date; end: Date }) {
    const res = await adminRequest.get<any>(
      UsdtUri('merchant/payment/collect/collect-task/xlsx'),
      { params: params, responseType: 'blob' },
    );
    return res.data;
  }

  static async getAdminCollectOrder(
    params: any,
  ): Promise<RequestData<CollectOrder>> {
    const res = await adminRequest.get(
      UsdtUri(`/admin/payment/collect/collect-task`),
      {
        params: {
          ...omit(params, ['current', 'pageSize']),
          page: params.current,
          limit: params.pageSize,
        },
      },
    );
    return PaginationTransformer.responseTransfer(res.data);
  }
}
