import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { MchDepositSourceProvider, MchDepositSourceType } from '../../domain/usdt-providers/mchDepositSourceProvider';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { usePageState } from '../../core/hooks/usePageState';
import { Button, Form, Input, Modal, PageHeader, Select } from 'antd';
import i18next from 'i18next';
import { ChannelSelect, ChannelValueEnum, TokenSelect } from '../../domain/channel/channel';
import HexLabel from '../../core/components/HexLabel';

interface PageOp extends CoreProTableState<MchDepositSourceType> {
}

export default function OPDepositSourceList(props: {}) {
  const { t } = useTranslation();
  const [formVisible, setFormVisible] = useState(false);
  const editForm = React.createRef<FormInstance>();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  // const [tokenData, setTokenData] = useState(['']);
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 50,
      current: 1,
    },
    needRequest: true,
  });
  const refreshDataCommand = () => {
    pageOp.update((v) => ({ ...v, needRequest: true }));
    actionRef.current?.reload();
  };

  const deleteCommand = (item: MchDepositSourceType) => {
    Modal.confirm({
      content: `${t('common.delete_confirm')} ${item.address} ?`,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        MchDepositSourceProvider.opDelete(item.address, item.channel,item.token)
          .then((res) => {
            Modal.success({
              content: `${t('common.delete_success')}`,
              okText: t('common.confirm'),
            });
          })
          .catch((e) => {
            Modal.error({
              content: e.message,
              okText: t('common.confirm'),
            });
          })
          .finally(() => {
            refreshDataCommand();
          });
      },
    });
  };

  const addCommand = async (address: string, channel: string, token: string) => {
    const res = await MchDepositSourceProvider.opAdd(address, channel, token);
    refreshDataCommand();
    return res.data;
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [pageOp, searchFormRef]);

  // function onCascaderChange(value: { channel: string, token: string }) {
  //   setTokenData([value.channel, value.token]);
  // }

  return (
    <>
      <PageHeader
        title={t('credit.op_deposit_source')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<MchDepositSourceType>
        bordered={true}
        search={false}
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        toolBarRender={() => [
            <Button
              key={1}
              type='primary'
              onClick={(event) => {
                event.stopPropagation();
                setFormVisible(true);
              }}
            >
              {i18next.t('common.add')}
            </Button>
          ]}
        requestData={() =>
          MchDepositSourceProvider.getOpList()
            .then((res) => {
              return {
                success: true,
                data: res.data,
                total: res.data.length,
              };
            })
            .catch((e) => {
              console.error(e);
              return {
                success: false,
                data: [],
                total: 0,
              };
            })
        }
        columns={[
          {
            align: 'center',
            title: t('payment.channel'),
            hideInSearch: true,
            dataIndex: 'channel',
            valueEnum: ChannelValueEnum,
          },
          {
            align: 'center',
            title: t('payment.token'),
            hideInSearch: true,
            dataIndex: 'token',
          },
          {
            align: 'center',
            title: t('payment.wallet_address'),
            hideInSearch: true,
            dataIndex: 'address',
            render: (node, row) => <HexLabel hex={row.address} size={'small'} />,
          },
          {
            align: 'center',
            title: t('common.operate'),
            hideInSearch: true,
            dataIndex: 'address',
            key: '_action',

            render: (text, row) => [
              <Button
                key={2}
                size='small'
                disabled={false}
                danger={true}
                onClick={() => deleteCommand(row)}
              >
                {t('common.delete')}
              </Button>,
            ],
          },
        ]}
      />

      <Modal
        destroyOnClose
        title={t('common.add') + t('credit.op_deposit_source')}
        visible={formVisible}
        okText={t('common.add_confirm')}
        cancelText={i18next.t('common.cancel')}
        onOk={() => {
          editForm.current?.validateFields().then((values) => {
            addCommand(values.walletAddress, values.channel, values.token).then(async (res) => {
              Modal.success({
                content: `${values.walletAddress} ${t('common.add_success')}`,
                okText: t('common.confirm'),
              });
              refreshDataCommand();
              setFormVisible(false);
            });
          });
        }}
        onCancel={() => setFormVisible(false)}
      >
        <Form ref={editForm}>
          <Form.Item
            name='walletAddress'
            label={t('payment.wallet_address')}
            rules={[
              {
                required: true,
                message: t('common.must_fill_required_field'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='channel'
            label={t('payment.channel')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select options={ChannelSelect} />
          </Form.Item>
          <Form.Item
            name='token'
            label={t('payment.token')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select options={TokenSelect} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
