import * as _ from 'lodash';
import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';

export interface WhiteList {
  walletAddress: string;
  systemId: string;
  channel: string;
  token: string;
  comment?: string;
}

export class WhiteListProvider {
  static async getWhiteList(walletAddress?: string) { 
    const res = await adminRequest.get<AdminResponse<WhiteList[]>>(
      UsdtUri('merchant/payment/white-list'),
      {
        params: {
          walletAddress
        },
      }
    );
    return res.data;
  }

  static async delete(address: string, channel: string, token: string) {
    const res = await adminRequest.delete<AdminResponse<WhiteList>>(
      UsdtUri(`merchant/payment/white-list/`),
      {
        params: {
          address,
          channel,
          token,
        },
      },
    );
    return res.data;
  }

  static async add(address: string, channel: string, token: string, comment?: string) {
    const res = await adminRequest.post<AdminResponse<WhiteList>>(
      UsdtUri('merchant/payment/white-list'),
      {
        address: address,
        channel,
        token,
        comment,
      },
    );
    return res.data;
  }
}
