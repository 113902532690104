import adminRequest from '../../../config/adminRequest';
import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';

export interface MchDepositSourceType {
  systemId: string;
  channel: string;
  token: string;
  address: string;
}

export enum DepositSrcEnum {
  MCH = 'MCH',
  USER = 'USER',
  OP = 'OP',
}

export class MchDepositSourceProvider {
  static async getList() {
    const res = await adminRequest.get<AdminResponse<MchDepositSourceType[]>>(
      UsdtUri('payment/mch-deposit-source'),
      {
        params: { type: [DepositSrcEnum.MCH, DepositSrcEnum.USER] },
      },
    );
    return res.data;
  }

  static async add(address: string, channel: string, token: string) {
    const res = await adminRequest.post(UsdtUri('payment/mch-deposit-source'), {
      address,
      channel,
      token,
      type: DepositSrcEnum.MCH,
    });
    return res.data;
  }

  static async delete(address: string, channel: string,token:string) {
    const res = await adminRequest.delete(
      UsdtUri(`payment/mch-deposit-source/${address}`),
      {
        params: {
          channel: channel,
          token: token,
        },
      },
    );
    return res.data;
  }

  static async getOpList() {
    const res = await adminRequest.get<AdminResponse<MchDepositSourceType[]>>(
      UsdtUri('payment/op-deposit-source'),
      {
        params: { systemId: 'Admin', type: [DepositSrcEnum.OP] },
      },
    );
    return res.data;
  }

  static async opAdd(address: string, channel: string, token: string) {
    const res = await adminRequest.post(UsdtUri('payment/op-deposit-source'), {
      systemId: 'Admin',
      address,
      channel,
      token,
      type: DepositSrcEnum.OP,
    });
    return res.data;
  }

  static async opDelete(address: string, channel: string, token: string) {
    const res = await adminRequest.delete(
      UsdtUri(`payment/op-deposit-source/${address}`),
      {
        params: {
          systemId: 'Admin',
          channel: channel,
          token: token,
        },
      },
    );
    return res.data;
  }

}
