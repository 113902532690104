import { useTranslation } from 'react-i18next';

export const useCreditStatusTranslate = () => {
  const { t } = useTranslation();
  return {
    ALL: t('credit.status.all'),
    Pending: t('credit.status.pending'),
    Success: t('credit.status.success'),
    Changed: t('credit.status.changed'),
    MappingSuccess: t('credit.status.mapping_success'),
    MappingFail: t('credit.status.mapping_fail'),
    Expired: t('credit.status.expired'),
  };
};

export const usePayforStatusTranslate = () => {
  const { t } = useTranslation();
  return {
    Draft: t('payfor.status.draft'),
    Pending: t('payfor.status.pending'),
    Success: t('payfor.status.success'),
    Fail: t('payfor.status.fail'),
  };
};

export const useTransferBackTranslate = () => {
  const { t } = useTranslation();
  return {
    Draft: t('transferback.status.draft'),
    Pending: t('transferback.status.pending'),
    Success: t('transferback.status.success'),
    Fail: t('transferback.status.fail'),
  };
};

export const useNotifyStatusTranslate = () => {
  const { t } = useTranslation();
  return {
    NotNotify: t('notify.not_notify'),
    Notifying: t('notify.notifying'),
    NotifySuccess: t('notify.notify_success'),
  };
};

export const useCollectStatusTranslate = () => {
  const { t } = useTranslation();
  return {
    OrderCreated : t('collect.status.ordercreated'),
    PayerFundInSufficient : t('collect.status.payerfundinsufficient'),
    SourceFundSufficient : t('collect.status.sourcefundsufficient'),
    SourceFundInSufficient : t('collect.status.sourcefundinsufficient'),
    NetworkFeeTxnCreated : t('collect.status.networkfeetxncreated'),
    NetworkFeeTxnSuccess : t('collect.status.networkfeetxnsuccess'),
    NetworkFeeTxnFail : t('collect.status.networkfeetxnfail'),
    NetworkFeeSufficient : t('collect.status.networkfeesufficient'),
    TokenTxnCreated : t('collect.status.tokentxncreated'),
    TokenTxnSuccess : t('collect.status.tokentxnsuccess'),
    TokenTxnFail : t('collect.status.tokentxnfail'),
  };
};

export const useUserWalletCollectStatusTranslate = () => {
  const { t } = useTranslation();
  return {
    Collecting: t('collect.wallet_status.collecting'),
    UnCollect: t('collect.wallet_status.unCollect'),
  };
};
