import { Modal } from 'antd';
import i18next from 'i18next';

const QRCode = require('qrcode.react');

export default function WalletQrCode(props: { address: string }) {
  return <QRCode value={props.address} size={160} />;
}

export const popOutQRCode = (props: { address: string }) => {
  Modal.info({
    title: i18next.t('payment.wallet_address'),
    content: <WalletQrCode address={props.address} />
  })
}
