import { USER_STORE, UserStore } from './index';
import { Dispatch } from 'react';
import { setStorage } from '../../lib/webStorage';
import { AuthProvider } from '../../core/providers/authProvider';

const decodeToken = (token: string) => atob(token.split('.')[1]);

const updateUserLoginInfo = (dispatch: Dispatch<any>, data: UserStore) => {
  let id: string = '';
  if (!!data.token) {
    const claim = JSON.parse(decodeToken(data.token));
    id = claim.sub;
  }
  dispatch({
    type: USER_STORE,
    payload: {
      userName: data.userName,
      systemId: data.systemId,
      token: data.token,
      googleAuthKey: data.googleAuthKey,
      isLogin: data.isLogin ?? true,
      merchantType: data.merchantType,
      userId: id,
    } as UserStore,
  });
  setStorage('uga', data.googleAuthKey);
  setStorage('systemId', data.systemId);
  setStorage('temporaryToken', data.token);
};

const updateUserLoginStatus = (
  dispatch: Dispatch<any>,
  data: Pick<UserStore, 'isLogin'>,
) => {
  dispatch({
    type: USER_STORE,
    payload: {
      isLogin: data.isLogin ?? true,
    } as UserStore,
  });
};

const refreshToken = async (dispatch: Dispatch<any>) => {
  const res = await AuthProvider.refreshToken();
  const accessToken = res.token.access_token;
  const data: Partial<UserStore> = {
    token: accessToken,
  };
  dispatch({
    type: USER_STORE,
    payload: data,
  });
  setStorage('temporaryToken', accessToken);
  setStorage('token', accessToken);
  return accessToken;
};

const updateUserLang = (dispatch: Dispatch<any>, data: { lang: string }) => {
  dispatch({
    type: USER_STORE,
    payload: {
      lang: data.lang,
    } as Partial<UserStore>,
  });
};

const updateMerchantType = (
  dispatch: Dispatch<any>,
  data: { merchantType: string },
) => {
  dispatch({
    type: USER_STORE,
    payload: {
      merchantType: data.merchantType,
    } as Partial<UserStore>,
  });
};

const logout = ()=>{
  window.sessionStorage.clear();
  window.localStorage.clear();
  window.location.href = '/';
}

const UserStoreAction = {
  updateUserLoginInfo,
  refreshToken,
  updateUserLoginStatus,
  updateUserLang,
  updateMerchantType,
  logout
};

export default UserStoreAction;
