const OrderStatusTypes = {
  ALL: 'ALL',
  Pending: 'Pending',
  Success: 'Success',
  MappingSuccess: 'MappingSuccess',
  MappingFail: 'MappingFail',
  Expired: 'Expired',
};

const NotifyStatusTypes = {
  NotNotify: 'NotNotify',
  // retry notify client
  Notifying: 'Notifying',
  // notify client success
  NotifySuccess: 'NotifySuccess',
};

const DepositModeTypes = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};
const DepositMode2Chinese = {
  1: '自動上分模式',
  2: '訂單模式',
  3: '輪詢錢包模式',
  4: '收付一體模式',
  5: '輪詢自動歸集模式',
};
const CreditOrderStatusType = {
  DepositMode2Chinese,
  OrderStatusTypes,
  NotifyStatusTypes,
  DepositModeTypes,
};
export default CreditOrderStatusType;
