import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, PageHeader, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProCoreActionType } from '@ant-design/pro-utils';
import _module from '../configs/module';
import { FormInstance } from 'antd/lib/form';
import { Permission } from '../types';
import { DELETE_PERMISSION, WRITE_PERMISSION } from '../configs/scopes';
import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { usePageState } from '../../core/hooks/usePageState';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { PermissionProvider } from '../../domain/admin-providers/permissionProvider';
import ScopeForm, { CloseEvent, ScopeFormProps } from '../components/ScopeForm';
import { EventProvider } from '../../../event/eventProvider';
import { filter } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';

interface PageOp extends CoreProTableState<Permission> {
}

const defultScopeFormProp: ScopeFormProps = {
  record: undefined,
  isEdit: false,
  visible: false,
};

export default function ScopeTableList() {
  const { t } = useTranslation();
  const tableActionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const [scopeFormState, setScopeFormState] = useState(defultScopeFormProp);
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const deleteCommand = (row: Permission) => {
    Modal.confirm({
      title: `刪除?`,
      content: `確認刪除 ${row.scopeDescription} (編號: ${row.id})?`,
      icon: <ExclamationCircleOutlined />,
      okText: '確認',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        PermissionProvider.delete(row.id).then((rs: any) => {
          pageOp.update((s) => ({ ...s, needRequest: true }));
          refresh();
        });
      },
    });
  };

  const refresh = () => {
    pageOp.update((x) => {
      x.needRequest = true;
      return x;
    });
    tableActionRef.current?.reload();
  };

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  useEffect(() => {
    EventProvider.subject
      .pipe(filter((x) => x.eventSymbol === CloseEvent.symbol))
      .subscribe((x) => {
        setScopeFormState(defultScopeFormProp);
        refresh();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader
        title={t('permission.PermissionList')}
        subTitle={''}
        extra={<></>}
      />
      <CoreProTable<Permission>
        bordered
        formRef={searchFormRef}
        tableState={pageOp.get()}
        updateTableState={(state) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        actionRef={tableActionRef as any}
        rowKey={(row) => row.id}
        columns={[
          {
            width: 50,
            title: '模組編號',
            dataIndex: 'id',
            align: 'center',
          },
          {
            width: 100,
            title: '模組代號',
            dataIndex: 'moduleIdentity',
            filterMultiple: false,
            filters: [],
          },
          {
            width: 100,
            title: '權限',
            dataIndex: 'scope',
          },
          {
            width: 100,
            hideInSearch: true,
            title: '權限說明',
            dataIndex: 'scopeDescription',
          },
          {
            width: 50,
            hideInSearch: true,
            key: 'operation',
            title: '操作',
            align: 'center',
            render: (text, record) =>
              <Space>
                <Button
                  size={'small'}
                  key='editable'
                  disabled={!_module.can([WRITE_PERMISSION])}
                  onClick={() => setScopeFormState({ visible: true, record, isEdit: true })}
                >
                  編輯
                </Button>
                <Button
                  size={'small'}
                  key='delete'
                  disabled={!_module.can([DELETE_PERMISSION])}
                  danger={_module.can([DELETE_PERMISSION])}
                  onClick={(x) => {
                    deleteCommand(record);
                  }}
                >
                  刪除
                </Button>
              </Space>,
          },
        ]}
        toolBarRender={() => [
          _module.canRender(
            WRITE_PERMISSION,
            <Button
              key={1}
              type='primary'
              onClick={() => setScopeFormState({ visible: true, isEdit: false })}
            >
              新增權限
            </Button>),
        ]}
        requestData={(params) =>
          PermissionProvider.getList({ ...PaginationTransformer.paramTransfer(params) }).then((rs) =>
            PaginationTransformer.responseTransfer(rs),
          )
        }
      />
      <ScopeForm {...scopeFormState} />
    </>
  );
};
