import CoreDrawer from '../../core/components/CoreDrawer';
import {
  Button,
  Form,
  Input,
  message,
  Space,
  Spin,
  Tree,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import {
  MchRoleProvider,
  Role,
} from '../../domain/admin-providers/mchRoleProvider';
import { Permission } from '../../permissions/types';
import { PermissionProvider } from '../../domain/admin-providers/permissionProvider';
import { DataNode } from 'antd/lib/tree';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';

const { Text } = Typography;
const { TextArea } = Input;

export type RoleFormProps = {
  record?: Role;
  visible: boolean;
  isEdit: boolean;
};

export class CloseEvent extends BaseEvent {
  static symbol = Symbol();

  constructor() {
    super({
      eventSymbol: CloseEvent.symbol,
      payload: undefined,
      type: CloseEvent.name,
    });
  }
}

export default function RoleForm(props: RoleFormProps) {
  const isEdit = props.isEdit;
  const formRef = React.createRef<FormInstance>();
  const [curScopes, setCurScopes] = useState<Array<Permission>>([]);
  const [formState, setFormState] = useState({
    loading: true,
    isPermissionForm: isEdit,
    role: props.record,
  });
  const [scopeList, setScopeList] = useState<Permission[]>([]);
  const logger = useOperatorLogger();
  const close = () => {
    EventProvider.addEvents(new CloseEvent());
  };

  const flattenTreeData = (data: any[]) => {
    return data
      .reduce<{ data: Array<DataNode>; key: Array<string> }>(
        (c, p) => {
          const index = c.key.indexOf(p.moduleIdentity ?? '');
          const data: DataNode = {
            title: (
              <Space>
                <Text code>{p.scope}</Text>
                <Text>{p.scopeDescription}</Text>
              </Space>
            ),
            key: p.id?.toString(),
          };
          if (index < 0) {
            c.key.push(p.moduleIdentity ?? '');
            c.data.push({
              title: p.moduleName,
              key: `module:${p.moduleIdentity}`,
              isLeaf: false,
              children: [data],
            });
          } else {
            c.data[index].children?.push(data);
          }
          return c;
        },
        { data: [], key: [] },
      )
      .data.map((x) => ({
        ...x,
        disabled:
          x.children?.length === x.children?.filter((c) => c.disabled).length,
      }));
  };
  const loading = (isloading: boolean) => {
    setFormState((x) => {
      x.loading = isloading;
      return x;
    });
  };

  const refreshRole = async (id: number) => {
    const data = await MchRoleProvider.getOne(id);
    const role: Role = data.data;
    setCurScopes(data.data.scopes);
    setFormState({ ...formState, role: role, isPermissionForm: true });
  };

  const save = () => {
    formRef.current?.validateFields().then(async (formData) => {
      const payload: Omit<Role, 'id'> = {
        name: formData.name,
        description: formData.description,
        scopes: curScopes,
      };
      if (formState.isPermissionForm) {
        if (!formState.role) {
          message.error({ content: '資料錯誤-未選取資料' });
          return;
        }
        await MchRoleProvider.patch({
          ...payload,
          id: formState.role.id,
        })
          .then((res) => {
            logger.log({
              action: '編輯',
              payload: { req: payload, res: res.data },
            });
          })
          .catch((e) => {
            logger.log({ action: '編輯', payload: { req: payload, res: e } });
            throw e;
          });
        close();
      } else {
        const data = await MchRoleProvider.add(payload).catch((e) => {
          logger.log({
            action: '新增角色',
            payload: { req: payload, res: e },
          });
          throw e;
        });
        logger.log({
          action: '新增角色',
          payload: { req: payload, res: data.data },
        });
        await refreshRole(data.data.id);
      }
    });
  };

  useEffect(() => {
    if (!props.visible) {
      return;
    }
    const pArr: Promise<any>[] = [];
    pArr.push(PermissionProvider.getAll().then((pers) => setScopeList(pers)));
    if (!!props.record?.id) {
      pArr.push(refreshRole(props.record?.id));
    }
    loading(true);
    Promise.all(pArr)
      .then(() => {})
      .catch((e) => {
        console.error(e);
        close();
      })
      .finally(() => {
        loading(false);
      });
    // eslint-disable-next-line
  }, [props.record, props.visible]);

  useEffect(() => {
    if (formState.role)
      formRef.current?.setFieldsValue({
        name: formState.role?.name,
        description: formState.role?.description,
      });
    // eslint-disable-next-line
  }, [formState]);
  return (
    <CoreDrawer
      destroyOnClose={true}
      width={640}
      title={isEdit ? `編輯角色(id: ${props.record?.id})` : `新增角色`}
      visible={props.visible}
      onClose={() => close()}
      footerbuttons={[
        <Button key={1} type="primary" onClick={() => save()}>
          {isEdit ? '儲存' : '新增並開始設定權限'}
        </Button>,
      ]}
    >
      <Spin spinning={false}>
        <Form ref={formRef} layout="vertical" name="role_form">
          <Form.Item
            name="name"
            label="角色名稱"
            rules={[{ required: true, message: '請輸入群組角色名稱' }]}
          >
            <Input allowClear bordered={!isEdit} readOnly={isEdit} />
          </Form.Item>
          <Form.Item name="description" label="角色說明">
            <TextArea allowClear />
          </Form.Item>
          {formState.isPermissionForm && (
            <Form.Item label="指定權限">
              <Tree
                blockNode
                checkable
                selectable={false}
                checkedKeys={curScopes.map((x) => x.id.toString())}
                treeData={flattenTreeData(scopeList)}
                onCheck={(keys) => {
                  setCurScopes(
                    scopeList.filter(
                      (x) => (keys as string[]).indexOf(x.id.toString()) >= 0,
                    ),
                  );
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </CoreDrawer>
  );
}

