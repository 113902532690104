import { moduleInit } from '../base/moduleInit';
import defaultMenu, { Menu } from '../configs/menus';
import modules from '../configs/modules';
import _ from 'lodash';

export type ExtraFunctionType = {
  func: string;
  displayName: string;
};

export const FuncionCollection: { [func: string]: ExtraFunctionType } = {
  login: { func: 'login', displayName: '登入' },
};

export const useMenuIdTranslate = () => {
  const tabList = getTabList();
  const menuIdDictionary: { [func: string]: string } = {};
  for (const tab of tabList) {
    const menuPrefixName = tab.i18nLabel.zh_CN;
    const menuList = tab.children;
    if (Array.isArray(menuList)) {
      for (const menu of menuList) {
        const menuId = (menu.menuId as any).id;
        const menuName = `${menuPrefixName}|${menu.i18nLabel.zh_CN}`;
        menuIdDictionary[menuId] = menuName;
      }
    }
  }
  _.forEach(FuncionCollection, (func) => {
    menuIdDictionary[func.func] = func.displayName;
  });
  return menuIdDictionary;
};

function getTabList() {
  return modules.reduce(
    (c, _m) =>
      _m.module.menus?.reduce((cc, om) => {
        const m = processMenuItem(_m.module, om);
        if (!m) return cc;
        if (m.parentSymbol) {
          // find parent and append into its children
          cc.some((cm) => {
            if (cm.symbol === m.parentSymbol) {
              cm.children = cm.children ?? [];
              if (m.order) {
                cm.children = [...cm.children, m];
              } else {
                cm.children = [...cm.children, m];
              }
              return true;
            }
            return false;
          });
          return cc;
        }
        // just append into menus
        if (m.order) {
          return [...cc, m];
        }
        return [...cc, m];
      }, c) ?? c,
    defaultMenu,
  );
}

function processMenuItem(module: moduleInit, _menu: Menu) {
  const menu = { ..._menu };
  if (menu.children && menu.children.length > 0) {
    menu.children = menu.children.reduce((c: Menu[], m) => {
      const nm = processMenuItem(module, m);
      return nm ? [...c, nm] : c;
    }, []);

    if (menu.children.length < 1) {
      return null;
    }
  }
  return menu;
}
// eslint-disable-next-line
function getMenuNamePrefix(menuName: string){
  switch(menuName){
    case '使用者清單':
    case '群組清單':
    case '角色清單':
    case '權限管理': {
      return '權限控管|';
    }
    case '商戶清單':
    case '商戶使用者清單':
    case '商戶群組清單':
    case '商戶角色清單': {
      return '商戶管控|';
    }
    case '域名管理':
    case '全域設定管理':
    case '產生加密套件':
    case '更換加密套件':
    case '利潤下發白名單':
    case '操作紀錄查詢': {
      return '平台設置|';
    }
    case '充值訂單查詢':
    case '代付訂單查詢':
    case '歸集訂單查詢':
    case '下發訂單查詢':
    case '利潤下發紀錄': {
      return '交易管理|';
    }
    case '商戶錢包查詢':
    case '用戶錢包查詢':
    case '商戶帳變':
    case '用戶錢包帳變': {
      return '錢包管理|';
    }
    case '日結算表': {
      return '報表管理|';
    }
    case '棄用錢包':
    case '資金流水':
    case '下發任務': {
      return '棄用錢包管理|';
    }
    default: {
      return '';
    }
  }
}
