import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getStorage } from '../lib/webStorage';
import { message } from 'antd';
import UserStoreAction from '../stores/user/action';

// const CancelToken = Axios.CancelToken;
// const source = CancelToken.source();
export type AdminResErrorType = {
  code: string;
  clientCode: string;
  message: string;
  displayMessage?: string;
  data: any;
};

const defaultRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = getStorage('token');
  const temporaryToken = getStorage('temporaryToken');
  const lang = getStorage('lang');
  if (!config.headers.Authorization)
    config.headers.Authorization = 'Bearer ' + (token ?? temporaryToken ?? '');
  config.headers.systemId =
    config.headers.SYSTEM_ID || getStorage('systemId') || '';
  config.headers.SYSTEM_ID =
    config.headers.SYSTEM_ID || getStorage('systemId') || '';
  switch(lang){
    case'zh':{
      config.headers.language = 'zh'
      break;
    }
    case'en':{
      config.headers.language = 'en'
      break;
    }
    case'vi':{
      config.headers.language = 'vi'
      break;
    }
    default:{
      config.headers.language = 'zh'
      break;
    }
  }
  if (config.method?.toLocaleUpperCase() === 'GET')
    config.params.timestamp = new Date().getTime();
  return config;
};

const defaultResponseHandle = (res: AxiosResponse) => {
  const data = res.data;
  if (data && data.hasOwnProperty('code') && data.code !== '00') {
    message.warn(
      `${
        !!data.displayMessage
          ? data.displayMessage
          : adminClientMessage.has(data.clientCode)
          ? adminClientMessage.get(data.clientCode)
          : data.message
      }`,
      3,
      () => {},
    );
    return Promise.reject(data);
  }
  return res;
};

export const adminClientMessage = new Map([
  ['mch.user.login.error', '不合法的使用者'],
  ['mch.user.duplicate', '請勿新增重複的使用者'],
  ['mch.user.group.duplicate', '請勿新增重複的群組'],
  ['mch.user.role.duplicate', '請勿新增重複的角色'],
  ['admin.user.duplicate', '請勿新增重複的使用者'],
  ['admin.user.group.duplicate', '請勿新增重複的群組'],
  ['admin.user.role.duplicate', '請勿新增重複的角色'],
  ['admin.user.group.cascade', '無法執行，該群組尚有使用者綁定'],
  ['admin.group.role.cascade', '無法執行，該角色尚有群組綁定'],
  ['admin.role.permission.cascade', '無法執行，該權限尚有角色綁定'],
  ['user.not.found', '無法登入，請確認帳號或密碼輸入是否正確'],
  ['user.login.error', '無法登入，請確認帳號或密碼輸入是否正確'],
  ['user.system.disabled', '該商戶已被禁用'],
  [
    'usdt.create.normal.system.response.error',
    '創建商戶服務回應錯誤，請稍後重新嘗試',
  ],
  [
    'usdt.create.agent.system.response.error',
    '創建代理商戶服務回應錯誤，請稍後重新嘗試',
  ],
  [
    'usdt.get.system.settings.response.error',
    '取得商戶服務回應錯誤，請稍後重新嘗試',
  ],
  ['proxy.token.expired', '驗證過期'],
  ['proxy.token.invalid', '驗證失效'],
  ['collect.target.notEq.mchWallet', '歸集地址需與商戶錢包地址一致'],
  [
    'transfer.amount.less.than.system.minAmount',
    '歸集金額小於商戶最低歸集金額',
  ],
  ['mch.user.group.cascade','無法執行，該群組尚有使用者綁定'],
  ['mch.group.role.cascade','無法執行，該角色尚有群組綁定'],
  ['mch.role.permission.cascade','無法執行，該權限尚有角色綁定'],
]);

const defaultResponseErrorHandle = (error: any) => {
  console.log('defaultResponseErrorHandle', error);
  if (error.response && error.response?.data && error.response?.status) {
    const { data, status } = error.response;
    if (status === 401) {
      switch (data?.clientCode) {
        case 'jwt.token.expired': {
          message.error('使用者權杖已過期請重新登入', 2, () => {
            UserStoreAction.logout();
          });
          break;
        }
        case 'common.system.deactivated': {
          message.error('商戶已停用', 2, () => {});
          break;
        }
        case 'common.system.banned': {
          message.error('商戶已禁用', 2, () => {
            UserStoreAction.logout();
          });
          break;
        }
        default: {
          message.error(
            `你沒有權限存取此功能 api ${
              !!data.displayMessage
                ? data.displayMessage
                : adminClientMessage.get(data.clientCode) ?? data.message
            }`,
            3,
            () => {},
          );
        }
      }
    } else if (status === 403) {
      message.error(
        `你沒有權限存取此功能 api ${
          !!data.displayMessage
            ? data.displayMessage
            : adminClientMessage.get(data.clientCode) ?? data.message
        }`,
        3,
        () => {},
      );
    } else {
      message.error(
        !!data.displayMessage
          ? data.displayMessage
          : adminClientMessage.get(data.clientCode) ?? data.message,
      );
    }
    return Promise.reject(data);
  } else {
    message.warning(error.message);
    return Promise.reject(error);
  }
};

let instance = Axios.create({
  method: 'get',
  headers: {
    Authorization: '',
    language: '',
    platform: '',
  },
  params: {},
  data: {},
  responseType: 'json',
  timeout: 2 * 60 * 1000,
  // withCredentials: true
});
instance.interceptors.request.use(
  defaultRequestInterceptor,
  defaultResponseErrorHandle,
);
instance.interceptors.response.use(
  defaultResponseHandle,
  defaultResponseErrorHandle,
);

export default instance;
