import adminRequest from '../../../config/adminRequest';
import { AdminResponse } from '../models/adminResponse';
import { UsdtUri } from '../../core/configs/services';
import { message } from 'antd';

export type SpeedUpRef = {
  orderGasPrice: number;
  curGasPriceInfo: {
    avg: number;
    high: number;
  };
  gaslimit: number;
  ethToUsdt: number;
};

export enum SpeedUpTxnType {
  payfor = 'payfor',
  transferback = 'transferback',
}

export type SpeedUpReq = {
  orderId: string;
  gasPrice: number;
  type: SpeedUpTxnType;
  systemId?: string;
};

export type SpeedupRes = {
  orderId: string;
  orderNo: string;
  txnHash: string;
};

export class SpeedupProvirder {
  static async getSpeedUpRef(params: { orderId: string }) {
    const res = await adminRequest.get<AdminResponse<SpeedUpRef>>(
      UsdtUri(`payment/order/speed-up-ref/payfor/${params.orderId}`),
    );
    return res.data.data;
  }

  static async speedUp(req: SpeedUpReq): Promise<AdminResponse<SpeedupRes>> {
    if (!req.type) {
      message.warn('不存在的加速訂單類型');
      throw Error('不存在的加速訂單類型');
    }
    const uri = req.systemId
      ? `admin/payment/tx/${req.type}/speed-up`
      : `mch/payment/tx/${req.type}/speed-up`;
    const res = await adminRequest.post<AdminResponse<SpeedupRes>>(
      UsdtUri(uri),
      req,
    );
    return res.data;
  }
}
