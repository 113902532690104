import { Dropdown, Menu } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuArray, tapMenu } from '../../lib/menu';
import { flattenPages, hookHistory } from '../../modules/core/bootstrap';
import { useHistory } from 'react-router-dom';
import { GlobalStoreMerge } from '../../stores/merge';
import { useTranslation } from 'react-i18next';
import { MenuCurrent, MenuStore } from '../../stores/menu';
import _ from 'lodash';
import MenuStoreAction from '../../stores/menu/action';
import { MenuEventEnum, MenuEventManager } from '../../event/menuEventManager';

const { SubMenu } = Menu;
const NULL_INDEX = -1;

function TapMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const menuRedux = useSelector<GlobalStoreMerge, MenuStore>(
    (store) => store.menu,
  );
  const lang = useSelector<GlobalStoreMerge, string>(
    (store) => store.user.lang,
  );
  const { t } = useTranslation();
  const [contextMenuTarget, setContextMenuTarget] = useState(NULL_INDEX);
  const [tapState, setTapState] = useState<MenuCurrent>({
    list: menuRedux.current.list,
    now: menuRedux.current.now,
  });
  const historyState: any = history.location.state || {};
  const now_2 = historyState.now_2 || '';
  let nKey = tapState.now[0] ? `${tapState.now[0]}s` : '0';
  nKey = now_2 ? `${nKey}_${now_2}` : nKey;
  const refreshData = (cur: MenuCurrent) => {
    setTapState((v) => {
      // v.list = cur.list;
      // v.now = cur.now;
      return {
        now: cur.now,
        list: cur.list,
      };
    });
    MenuStoreAction.setCurrent(dispatch, cur);
  };

  const activeTapMenu = () => {
    let menu: MenuCurrent = _.cloneDeep(menuRedux.current);
    const pathname = history.location.pathname || '';
    const e = pathname.split('/') || [];
    const current = e[e.length - 1];
    const select: any = tapMenu(menu, current);
    if (!menu) {
      menu = {
        now: [],
        list: {},
      };
    }
    if (isNaN(parseInt(current)) && !select.now[0]) {
      menu.now = [];
      if (pathname.indexOf('/index') < 0) {
        history.push('/index');
      }
    } else {
      let list: any = {};
      let all: any = menuArray(menuRedux.treeMenu, current);
      if (!all?.now) {
        all = select;
        list = all.list;
      } else {
        list[current + 's'] = all.list;
      }
      menu.now = all.now;
      menu.list = Object.assign(menu.list || {}, list);
      flattenPages[list[`${current}s`].id]?.onOpen?.apply(
        flattenPages[list[`${current}s`].id],
        [dispatch],
      );
    }
    refreshData(menu);
  };

  const handleClick = (e: any) => {
    const currentArray = _.cloneDeep(menuRedux.current);
    const list = currentArray.list || {};
    if (e.key === '0') {
      history.push('/index');
    } else {
      const keyList = e.key.split('_');
      let url, state;
      if (keyList.length > 1) {
        url = list[keyList[0]].url;
        state = {
          root: list[keyList[0]].root,
          children: list[keyList[0]].id,
          now_2: keyList[1],
        };
      } else if (list[e.key]) {
        url = list[e.key].url;
        state = {
          root: list[e.key].root,
          children: list[e.key].id,
        };
      }
      if (!!url) history.push(url, state);
      MenuEventManager.publish({ type: MenuEventEnum.Opened });
    }
  };
  const delAllClick = () => {
    const currentArray = _.cloneDeep(menuRedux.current);
    const list = currentArray.list || {};
    Object.keys(list).forEach((item) => {
      flattenPages[list[item].id]?.onClose?.apply(flattenPages[list[item].id], [
        dispatch,
      ]);
      delete list[item];
    });
    history.push('/index');
    const menu = {
      now: [],
      list: list,
    };
    refreshData(menu);
  };
  const delOthersClick = () => {
    const currentArray = _.cloneDeep(menuRedux.current);
    let now = currentArray.now;
    const list = currentArray.list;
    let isNow = false;
    Object.keys(list).forEach((item, index) => {
      if (contextMenuTarget !== index) {
        flattenPages[list[item].id]?.onClose?.apply(
          flattenPages[list[item].id],
          [dispatch],
        );
        delete list[item];
      } else {
        isNow = now.length > 0 && now[0] === item.slice(0, -1);
      }
    });
    if (!isNow) {
      now = [];
      history.push('/index');
    }
    const menu = {
      now: now,
      list: list,
    };
    setContextMenuTarget(NULL_INDEX);
    refreshData(menu);
  };
  const delClick = (e: any, item: string) => {
    e.stopPropagation();
    const currentArray = _.cloneDeep(menuRedux.current);
    let now = currentArray.now;
    const list = currentArray.list || {};
    flattenPages[list[item].id]?.onClose?.apply(flattenPages[list[item].id], [
      dispatch,
    ]);
    delete list[item];
    const last = Object.keys(list).slice(-1)[0];
    // 返回前一個頁籤
    if (last && item === `${now[0]}s`) {
      const target = list[last];
      now = target.id.toString();
      history.push(target.url);
    }
    // 返回首頁頁籤
    if (item === `${now[0]}s`) {
      now = [];
      history.push('/index');
    }
    const menu = {
      now: now,
      list: list,
    };
    refreshData(menu);
  };
  const getTapMenuLabel = (nav: any, item: any) => {
    switch(lang){
      case'zh':{
        return nav[item].zh_CN
        break;
      }
      case'en':{
        return nav[item].en_US
        break;
      }
      case'vi':{
        return nav[item].vi_VN
        break;
      }
      default:{
        return nav[item].en_US
        break;
      }
    }
  };
  const contextMenu = (
    <Menu>
      <Menu.Item key="1" onClick={delAllClick}>
        關閉所有頁籤
      </Menu.Item>
      {contextMenuTarget !== NULL_INDEX ? (
        <Menu.Item key={2} onClick={delOthersClick}>
          關閉其它頁籤
        </Menu.Item>
      ) : (
        <></>
      )}
    </Menu>
  );

  useEffect(() => {
    hookHistory(history, activeTapMenu);
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (menuRedux) {
      activeTapMenu();
    }
    // eslint-disable-next-line
  }, [menuRedux]);

  return (
    <div className="admin-tap-menu">
      <Dropdown
        key={`admin-tap-menu-dropdown`}
        overlay={contextMenu}
        trigger={['contextMenu']}
      >
        <Menu
          onContextMenuCapture={(e) => {
            let leftWidth =
              document.querySelector('.admin-left-menu')?.clientWidth ?? 0;
            let targetPosX = e.clientX - leftWidth;
            let target: number = NULL_INDEX;
            e.currentTarget
              .querySelectorAll(
                'li.ant-menu-item:not(.ant-menu-item-only-child)',
              )
              .forEach((x: any, k) => {
                if (
                  target === NULL_INDEX &&
                  targetPosX >= x.offsetLeft &&
                  targetPosX <= x.offsetLeft + x.clientWidth
                ) {
                  target = k;
                }
              });
            setContextMenuTarget(target);
          }}
          onClick={handleClick}
          selectedKeys={[nKey]}
          mode="horizontal"
          style={{ lineHeight: '1.6rem' }}
        >
          <Menu.Item key="0">{t('common.home')}</Menu.Item>
          {Object.keys(tapState.list).map((item: string) => {
            if (tapState.list[item].child) {
              const child = tapState.list[item].child;
              return (
                <SubMenu
                  key={item}
                  title={
                    <>
                      {getTapMenuLabel(tapState.list, item)}
                      <span
                        className="admin-tap-close"
                        onClick={(e) => delClick(e, item)}
                      >
                        <CloseCircleOutlined />
                      </span>
                    </>
                  }
                >
                  {Object.keys(child).map((key: string) => (
                    <Menu.Item key={`${item}_${key}`}>{key}</Menu.Item>
                  ))}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={item}>
                  {getTapMenuLabel(tapState.list, item)}
                  <span
                    className="admin-tap-close"
                    onClick={(e) => delClick(e, item)}
                  >
                    <CloseCircleOutlined />
                  </span>
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </Dropdown>
    </div>
  );
}

export default TapMenu;
