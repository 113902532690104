import React from 'react';

import { pages } from '../../modules/core/bootstrap';

interface props {
  match: any;
  location: any;
  history: any;
}

class AllChild extends React.Component<props, {}> {
  render() {
    const { match, history } = this.props;
    const params = match.params || {};
    const Elm: any = pages[params.id];
    return params.id && Elm ? (
      <div className="admin-content">
        <Elm history={history} />
      </div>
    ) : (
      <> </>
    );
  }
}

export default AllChild;
