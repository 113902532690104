import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri } from '../../core/configs/services';

export interface DepositOrderSuccessRateType {
  reportDate: string;
  systemId: string;
  orderCount: number | string;
  successCount: number | string;
  expireCount: number | string;
  changedCount: number | string;
  failCount: number | string;
  totalFee: number | string;
  successRate: number | string;
  failRate: number | string;
  createdAt: Date;
}

export class DepositOrderSuccessRateProvider {
  static async getList(params: any) {
    const res = await adminRequest.get<AdminListResponse<DepositOrderSuccessRateType>>(
      ReportUri('admin/deposit-order/success-rate'),
      { params: params },
    );
    return res.data;
  }
}