import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { DailyQueryProps, RefundDailyType } from '../../domain/report-provider/agentReportProvider';
import { AdminListResponse } from '../../domain/models/adminListResponse';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { usePageState } from '../../core/hooks/usePageState';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { PageHeader } from 'antd';
import PaginationTransformer, { PaginationQuery } from '../../core/utils/paginationTransformer';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import { transfer2LocalTime } from '../../core/utils/timeFormat';
import { useTxnType } from '../hook/orderStatus';
import ExcelDowloadButton from '../../core/components/ExcelDowloadButton';
import ReportRefundCard from '../components/ReportRefundCard';

interface PageOp extends CoreProTableState<RefundDailyType> {
  totalRefund: {
    summeryUsdt:number,
    summeryBusd:number,
    summeryUsdc:number,
  };
}

export default function RefundDaily(props: {
  title: string;
  request: (
    param: DailyQueryProps & PaginationQuery,
  ) => Promise<AdminListResponse<any>>;
  requestExcel: (param: DailyQueryProps) => Promise<any>;
  resStatusMapping: () => any;
  reqStatusEnum: () => any;
}) {
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    totalRefund: {
      summeryUsdt:0,
      summeryBusd:0,
      summeryUsdc:0,
    },
    needRequest: true,
  });
  const txnType = useTxnType();
  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    title: t('payment.report_date'),
    pageOp,
    state: dateRangeState,
  });
  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);

  return (
    <>
      <PageHeader title={props.title} subTitle="" extra={<></>} />
      <CoreProTable<RefundDailyType>
        headerTitle={[
          // <Statistic
          //   key={'totalRefund'}
          //   precision={3}
          //   value={pageOp.get().totalRefund}
          //   title={t('agent.refund_total')}
          // />,
          <ReportRefundCard token={'USDT'} balance={pageOp.get().totalRefund.summeryUsdt} />,
          <ReportRefundCard token={'BUSD'} balance={pageOp.get().totalRefund.summeryBusd} />,
          <ReportRefundCard token={'USDC'} balance={pageOp.get().totalRefund.summeryUsdc} />
        ]
        }
        bordered
        rowKey="txnId"
        updateTableState={(state) =>
          pageOp.update((v: any) => ({ ...v, ...state }))
        }
        onReset={() => {
          dateRangeColumn.reset();
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        scroll={{ x: 'fixed' }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        tableState={pageOp.get()}
        requestData={async (params) => {
          const param = PaginationTransformer.paramTransfer({
            ...params,
            ...dateRangeColumn.getParamAndUpdatePage(),
          });
          const res = await props.request(param);
          const totalRefund = (res.data as any)?.summery ??
            {
            summeryUsdt:0,
            summeryBusd:0,
            summeryUsdc:0,
          };
          pageOp.set('totalRefund', totalRefund);
          return PaginationTransformer.responseTransfer(res);
        }}
        toolBarRender={() => [
          <ExcelDowloadButton
            getExcel={async () => {
              return await props.requestExcel({
                ...pageOp.get().params,
                ...dateRangeColumn.getParamAndUpdatePage(),
              });
            }}
            filePrefix={'daily'}
          />,
        ]}
        actionRef={actionRef as any}
        formRef={searchFormRef}
        columns={[
          {
            align: 'center',
            title: t(`payment.system_id`),
            dataIndex: 'systemId',
            width: 70,
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          {
            align: 'center',
            hideInSearch: true,
            title: t('common.type'),
            dataIndex: 'txnType',
            valueEnum: txnType,
            initialValue: pageOp.get().params.txnType,
            ellipsis: true,
            width: 70,
          },
          {
            hideInTable: false,
            hideInSearch: true,
            align: 'center',
            title: t('payment.token'),
            dataIndex: 'token',
            width: 70,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            ellipsis: true,
            title: t('common.order_count'),
            dataIndex: 'orderCount',
            width: 70,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.order_amount'),
            dataIndex: 'orderAmount',
            width: 80,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('payment.actual_amount'),
            dataIndex: 'actualAmount',
            width: 80,
          },
          {
            align: 'right',
            hideInSearch: true,
            hideInTable: false,
            title: t('agent.refund_amount'),
            dataIndex: 'refundAmount',
            width: 80,
          },
          {
            align: 'center',
            hideInSearch: false,
            hideInTable: false,
            title: t('common.status'),
            dataIndex: 'status',
            valueEnum: props.reqStatusEnum(),
            render: (node, row) =>
              props.resStatusMapping()[row.status] ?? row.status,
            width: 70,
          },
          dateRangeColumn.column,
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: true,
            width: 100,
            title: t('payment.report_date'),
            dataIndex: 'reportDate',
            render: (text, row: any) =>
              transfer2LocalTime(new Date(row.reportDate)),
          },
        ]}
      />
    </>
  );
}
