import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';
import adminRequest from '../../../config/adminRequest';

export type TokenRowType ={
  channel:string,
  token:string,
  transferInfo:string;
  minimumAmount: number,
  collectDest:string
}

export interface TokenSettingType {
  active: boolean,
  collectDest: string,
  minimumAmount: number
}

export type TokenSetting = {
  [channel: string]: {
    [token: string]: TokenSettingType;
  };
}

export type NumberComponentType = {
  [id:string]:  number,
}

export type StringComponentType = {
  [id:string]:  string,
}

export type NetworkSetting = {
  [channel: string]: {
    [token: string]: {
      transferMode: string,
      staticFee?: number
    }
  };
}

export interface CollectSetting {
  systemId: string;
  tokenSetting: TokenSetting;
  networkFeeSetting: NetworkSetting;
}

export default class CollectSettingProvider {

  static async getSetting(systemId: string) {
    const result = await adminRequest.get<AdminResponse<CollectSetting>>(
      UsdtUri(`admin/collect/setting/${systemId}`),
      {
        headers: {
          SYSTEM_ID: systemId,
        },
      },
    );
    return result.data.data;
  }

  static async updateSetting(data: CollectSetting) {
    const result = await adminRequest.put<AdminResponse<CollectSetting>>(
      UsdtUri(`admin/collect/setting/`),
      data,
    );
    return result.data.data;
  }

}
