import adminRequest from '../../../config/adminRequest';
import { PaginationQuery } from '../../core/utils/paginationTransformer';
import { AdminListResponse } from "../models/adminListResponse";
import { UsdtUri } from "../../core/configs/services";

export interface ChangeLibOrder {
  orderId: string;
  version: string;
  status: string;
  newLibPath: string;
  newLibName: string;
  operatorId: string;
  errorCause: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export class ChangeLibOrderProvider {
  static async getList(param: PaginationQuery) {
    const res = await adminRequest.get<AdminListResponse<ChangeLibOrder>>(UsdtUri('/crypto-info'), {
      params: param,
    });
    return res.data;
  }
}
