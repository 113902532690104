export const STATE_AGENT_REPORT = 'agent-report';

const defaultState = {
  [STATE_AGENT_REPORT]: {},
};

// eslint-disable-next-line
export default (state = defaultState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
