import { Card, Form, InputNumber } from 'antd';
import React from 'react';
import { FeeSetting } from '../../usdt-providers/systemProvider';
import * as _ from 'lodash';
import { TokenSetting } from '../../usdt-providers/depositSettingProvider';

const DepositTokenSettingForm = (props: {
  channel: string;
  data: any;
  feeRange: FeeSetting;
  tokenSetting: TokenSetting;
}) => {
  return (
    <>
      {
        _.map(props.data, (tokenObj, token) => {
          let max = 1;
          let min = 0;
          _.map(props.feeRange, (feeChannelObj, channel) => {
            if (props.channel === channel) {
              _.map(feeChannelObj, (tokenObj, feeToken) => {
                if (feeToken === token) {
                  max = tokenObj.max;
                  min = tokenObj.min;
                }
              });
            }
          });

          if (tokenObj.active) {
            return (

              <Card key={props.channel + '_' + token} title={token + '設定'} style={{ marginBottom: 16 }}>
                <Form.Item
                  key={props.channel + '_' + token + '_creditFeeRate'}
                  name={props.channel + '_' + token + '_creditFeeRate'}
                  label='充值費率'
                  rules={[{ required: true, message: '請輸入 `充值費率`' }]}
                  initialValue={tokenObj.creditFeeRate}
                >
                  <InputNumber
                    precision={4}
                    min={min}
                    max={max}
                    defaultValue={tokenObj.creditFeeRate}
                    step={0.001}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_innerDepositRate'}
                  name={props.channel + '_' + token + '_innerDepositRate'}
                  label='內充費率'
                  rules={[{ required: true, message: '請輸入 `內充費率`' }]}
                  initialValue={tokenObj.innerDepositRate}
                >
                  <InputNumber
                    precision={4}
                    min={0}
                    max={1}
                    defaultValue={tokenObj.innerDepositRate}
                    step={0.001}
                  />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_creditAmountPrecision'}
                  name={props.channel + '_' + token + '_creditAmountPrecision'}
                  label='充值金額最大小數位數'
                  rules={[{ required: true, message: '請輸入 `充值金額最大小數位數`' }]}
                  tooltip={'訂單付款的小數位數'}
                  initialValue={tokenObj.creditAmountPrecision}
                >
                  <InputNumber precision={0} defaultValue={tokenObj.creditAmountPrecision} min={1} max={8} step={1} />
                </Form.Item>
                <Form.Item
                  key={props.channel + '_' + token + '_creditMinFee'}
                  name={props.channel + '_' + token + '_creditMinFee'}
                  label='充值保底金額'
                  rules={[{ required: true, message: '請輸入 `充值保底金額`' }]}
                  tooltip={'訂單付款的保底手續費'}
                  initialValue={tokenObj.creditMinFee}
                >
                  <InputNumber precision={4} defaultValue={tokenObj.creditMinFee} min={0} step={1} />
                </Form.Item>
              </Card>);
          }
        })
      }
    </>
  );
};

export default DepositTokenSettingForm;

