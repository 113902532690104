import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Modal } from 'antd';
import { useRef } from 'react';

import HexLabel from '../../core/components/HexLabel';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { DepositOrderProvider } from '../../domain/usdt-providers/depositOrderProvider';

type DepositTxnInfoType = {
  channel: string;
  token: string;
  txnHash: string;
};

type DepositTxnInfoProps = {
  visible: boolean;
  onFinish?: () => void;
};

export function ToBeVerifiedDepositTxnInfo(props: DepositTxnInfoProps) {
  const tableActionRef = useRef<ActionType>();
  return (
    <Modal
      destroyOnClose
      width={640}
      title="待驗證hash"
      visible={props.visible}
      onCancel={() => props.onFinish?.()}
      footer={<Button onClick={() => props.onFinish?.()} children="確認" />}
    >
      <ProTable<DepositTxnInfoType>
        bordered
        rowKey={(row) => `${row.channel}${row.token}${row.txnHash}`}
        search={false}
        toolBarRender={false}
        request={(params) =>
          DepositOrderProvider.getDepositTxnTempList(
            PaginationTransformer.paramTransfer(params),
          ).then((rs) => {
            return PaginationTransformer.responseTransfer(rs);
          })
        }
        actionRef={tableActionRef as any}
        columns={[
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: '渠道',
            dataIndex: 'channel',
            width: 60,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: '代幣',
            dataIndex: 'token',
            width: 60,
          },
          {
            align: 'center',
            hideInSearch: true,
            hideInTable: false,
            title: '哈希值',
            dataIndex: 'txnHash',
            width: 60,
            render: (n, row) => <HexLabel hex={row.txnHash} size={'small'} />,
          },
        ]}
      />
    </Modal>
  );
}
