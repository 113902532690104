import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMenuIdTranslate } from '../../core/hooks/menuIdTranslateHook';
import _ from 'lodash';

export type ActionSearchProps = {
  funcId?: string;
  onChange?: (actionId: string) => void;
};

export default function ActionSearch(props: ActionSearchProps) {
  const actionList = useMenuIdTranslate();
  const [state, setState] = useState({
    funcId: '',
  });

  useEffect(() => {
    setState({ funcId: props.funcId ?? '' });
  }, [props.funcId]);

  // useEffect(() => {
  //   if (props.onChange) {
  //     props.onChange(state.actionId);
  //   }
  // }, [state.actionId]);
  console.log('ActionSearch', state, props);

  return (
    <>
      <Select
        showSearch
        allowClear
        onChange={(value: string, option: any) => {
          const funcId = option.key;
          setState({ funcId: funcId });
          if (props.onChange) props.onChange(funcId);
        }}
        value={actionList[state.funcId]}
        placeholder="请输入"
      >
        {_.map(actionList, (value, key) => {
          // debugger
          return (
            <Select.Option key={key} value={value}>
              {value}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
}
