import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { AgentToken, Channel, ChannelSelect } from '../../domain/channel/channel';
import {
  AgentWalletInfo,
  AgentWalletProvider,
  WithdrawOrderType,
} from '../../domain/report-provider/agentWalletProvider';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import { DollarOutlined } from '@ant-design/icons';
import { EventType } from '../../../event/types';
import { BaseEvent } from '../../../event/baseEvent';
import { EventProvider } from '../../../event/eventProvider';
import { GlobalSettingProvider } from '../../domain/usdt-providers/globalSettingProvider';
import * as _ from 'lodash';

type Props = {
  visible: boolean;
  onClose: () => void;
  eventHandle?: (order: EventType) => void;
};

export class OrderCreatedEvent extends BaseEvent<WithdrawOrderType> {
  static symbol = Symbol();

  constructor(order: WithdrawOrderType) {
    super({
      type: OrderCreatedEvent.name,
      payload: order,
      eventSymbol: OrderCreatedEvent.symbol,
    });
  }
}

export default function RefundWithdrawForm(props: Props) {
  const [visible, setVisible] = useState(props.visible);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = React.createRef<FormInstance>();
  const [wallet, setWallet] = useState<AgentWalletInfo[]>([]);
  const [selectToken, setSelectToken] = useState<AgentWalletInfo>({
    token: '',
    systemId: '',
    available: true,
    balance: 0,
  });
  const user = useSelector<GlobalStoreMerge, UserStore>((store) => store.user);
  const closeFrom = () => {
    props.onClose();
    setVisible(false);
    setFee(0);
    setOrderAmountDesc({
      amount: 0,
      expectCost: 0,
      expectCommission: 0,
    });
  };
  const [orderAmountDesc, setOrderAmountDesc] = useState({
    amount: 0,
    expectCost: 0,
    expectCommission: 0,
  });

  const [fee, setFee] = useState(0);

  const sendOrder = () => {
    formRef.current
      ?.validateFields()
      .then((data: { token: string; channel: string; amount: number; address: string }) => {
        setConfirmLoading(true);
        AgentWalletProvider.createWithdrawOrder({
          systemId: user.systemId,
          token: data.token,
          balance: data.amount,
          channel: data.channel,
          operatorId: user.userId,
          toAddress: data.address,
        })
          .then((res) => {
            message.success(t('common.add_success'), 2);
            console.log(
              'EventProvider.addEvents(new OrderCreatedEvent(res.data));',
              res.data,
            );
            EventProvider.addEvents(new OrderCreatedEvent(res.data));
            closeFrom();
          })
          .finally(() => {
            setConfirmLoading(false);
          });
      });
  };

  useEffect(() => {
    setVisible(props.visible);
    if (props.visible) {
      AgentWalletProvider.getCurrentBalance({
        systemId: user.systemId,
      }).then((rs) => setWallet(rs));
    }
  }, [props.visible, user]);

  return (
    <>
      <Modal
        key={'refund_withdraw_form'}
        visible={visible}
        footer={[
          <Button key={2} onClick={() => closeFrom()} loading={confirmLoading}>
            {t('common.cancel')}
          </Button>,
          <Button
            key={1}
            type='primary'
            onClick={() => sendOrder()}
            loading={confirmLoading}
          >
            {t('common.confirm')}
          </Button>,
        ]}
        onCancel={() => closeFrom()}
        maskClosable={false}
        closable={false}
        title={t('funding.withdraw_action.withdraw')}
        destroyOnClose
      >
        <Statistic
          title={t('funding.refund_balance')}
          value={selectToken.balance}
          precision={3}
          valueStyle={{ color: '#3f8600' }}
          prefix={<DollarOutlined />}
          style={{ margin: '0.2rem' }}
        />
        <Form ref={formRef} layout='vertical'>
          <Form.Item
            name='token'
            label={t('payment.token')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select
              options={AgentToken}
              onSelect={(select) => {
                const index = _.findIndex(wallet, (x) => x.token === select);
                if (index == -1) {
                  setSelectToken( {
                    systemId: user.systemId,
                    token: select.toString(),
                    available: false,
                    balance: 0
                  });
                  setOrderAmountDesc({
                    amount: orderAmountDesc.amount,
                    expectCost: orderAmountDesc.amount as number + fee,
                    expectCommission: 0 - (orderAmountDesc.amount as number + fee),
                  });
                }
                else{
                  setSelectToken(wallet[index]);
                  setOrderAmountDesc({
                    amount: orderAmountDesc.amount,
                    expectCost: orderAmountDesc.amount as number + fee,
                    expectCommission: wallet[index].balance - (orderAmountDesc.amount as number + fee),
                  });
                }
                return select;
              }}
            />
          </Form.Item>
          <Form.Item
            name='channel'
            label={t('payment.channel')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Select
              options={ChannelSelect}
              onSelect={(select) => {
                GlobalSettingProvider.get().then((res) => {
                  const data = res.data;
                  let feeAmount = 0;
                  if (select === Channel.ETHEREUM) {
                    feeAmount = data['AGENTFUNDING_ETH_FEE'] as number;
                    setFee(feeAmount);
                  }
                  if (select === Channel.TRON) {
                    feeAmount = data['AGENTFUNDING_TRX_FEE'] as number;
                    setFee(feeAmount);
                  }
                  if (select === Channel.SOLANA) {
                    feeAmount = data['AGENTFUNDING_SOL_FEE'] as number;
                    setFee(feeAmount);
                  }
                  if (select === Channel.BSC) {
                    feeAmount = data['AGENTFUNDING_BNB_FEE'] as number;
                    setFee(feeAmount);
                  }
                  setOrderAmountDesc({
                    amount: orderAmountDesc.amount,
                    expectCost: orderAmountDesc.amount as number + feeAmount,
                    expectCommission: selectToken.balance - (orderAmountDesc.amount as number + feeAmount),
                  });
                });
                return select;
              }}
            />
          </Form.Item>
          <Form.Item
            name='address'
            label={t('payment.wallet_address')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='amount'
            label={t('payment.withdraw_amount')}
            rules={[
              { required: true, message: t('common.must_fill_required_field') },
            ]}
          >
            <InputNumber min={0} value={100} step={100} precision={3}
                         onChange={(value) => {
                           setOrderAmountDesc({
                             amount: value as number,
                             expectCost: value as number + fee,
                             expectCommission: selectToken.balance - (value as number + fee),
                           });
                           return value;
                         }}
            />
          </Form.Item>
        </Form>
        <Row gutter={16}>
          <Col span={8}>
            <Statistic
              title={t('comment.network_fee')}
              value={fee}
              precision={6}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('agent.estimate_fee')}
              value={orderAmountDesc.expectCost}
              precision={6}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('agent.remaining_refund')}
              value={orderAmountDesc.expectCommission}
              precision={6}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
