import adminRequest from '../../../config/adminRequest';
import { ReportUri } from '../../core/configs/services';
import { AdminListResponse } from '../models/adminListResponse';
import { advanceNumbersType } from '../../report/components/AdvanceFundCard';
import { AdminResponse } from '../models/adminResponse';
import { Token } from '../channel/channel';

export interface AdvanceFund {
  reportDate: Date;
  systemId: string;
  token: string;
  lpwAccTotalFee: number | string;
  lpwWithdraw: number | string;
  lpwUnWithdraw: number | string;
  mchTxnBalance: number | string;
  mchAvailableBalance: number | string;
  lpwAccAvailableBalance: number | string;
  advanceFund: number | string;
  accAdvanceFund: number | string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AdvanceFundDetail {
  reportDate: Date;
  systemId: string;
  channel: string;
  token: string;
  advanceFund: number | string;
  walletAddress: string;
  createdAt: Date;
  updatedAt: Date;
}

export class AdvanceFundProvider {
  static async getList(params: any) {
    const res = await adminRequest.get<AdminListResponse<AdvanceFund>>(
      ReportUri('/advance-fund/daily/info'),
      { params: {...params,token:Token.USDT} },
    );
    return res.data;
  }

  static async getDetailList(params: any) {
    const res = await adminRequest.get<AdminListResponse<AdvanceFundDetail>>(
      ReportUri('/advance-fund-detail/daily/info'),
      { params: {...params,token:Token.USDT} },
    );
    return res.data;
  }
  static async getCurrentFund(){
    const res = await adminRequest.get<AdminResponse<advanceNumbersType>>(
      ReportUri('/advance-fund/daily/info/acc-advance'),
    );
    return res.data;
  }
}
