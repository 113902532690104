import { IPage } from './types';

export declare interface Menu {
    label: string;
    menuId: number | IPage;
    i18nLabel: { en_US: string, zh_CN: string, vi_VN:string};

    symbol?: Symbol | null | undefined;
    id?: string | null | undefined;
    icon?: string | null | undefined;
    iconId?: number | undefined;
    url?: string | null | undefined;
    uri?: string | null | undefined;

    children?: Menu[] | null | undefined;
    parentSymbol?: Symbol | null | undefined;
    order?: number | null | undefined; // similar menuId

    isPage?: boolean;
}

const defaultMenu: Menu[] = [];
export default defaultMenu;
